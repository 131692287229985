import { useState } from '@wordpress/element';
import { UserProvider, ToastContextProvider, VisiteProvider } from '@context';
import { VisiteHeader, VisiteEditBody, VisiteEditFooter } from '@pages/visite-edit/';
import { ButtonIconOnly } from '@composants/button';

import './visite-edit.scss';

const VisiteEdit = () => {

    const [currentPage, setCurrentPage] = useState('infos');
    const [fsopen, setFsopen] = useState(false);

    const toggleFS = () => {
        setFsopen(!fsopen);
    }

    return(
        <UserProvider>
            <ToastContextProvider>
                <VisiteProvider>
                    <div className={`visite visite-edit position-relative ${fsopen ? 'fsopen' : ''}`.trim()}>
                        <div className='position-absolute fs-toggler'>
                            <ButtonIconOnly className="btn-fs btn-fs-open" variant='fs-open' action={toggleFS} />
                            <ButtonIconOnly className="btn-fs btn-fs-close" variant='fs-close' action={toggleFS} />
                        </div>
                        <VisiteHeader edit={true} />
                        <VisiteEditBody page={currentPage} isOpen={fsopen} />
                        <VisiteEditFooter page={currentPage} handler={setCurrentPage} />
                    </div>
                </VisiteProvider>
            </ToastContextProvider>
        </UserProvider>
    )

}

export default VisiteEdit;