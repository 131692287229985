import { useState, useEffect } from '@wordpress/element';
import { Button } from "@composants/button";
import { Panel } from "@composants";
import { PieceForm } from '@pages/visite-edit/composants/';
import { useVisiteDispatch } from '@context/';
import { typePieces } from '@constantes';

const VisiteSuperficies = ({ data, classement }) => {

    const [pieces, setPieces] = useState(data);
    const dispatch = useVisiteDispatch();

    const maxPersonnes = classement > 4 ? 3 : 4; // Nombre maximum de couchages par pièce d'habitation selon classement

    const addPiece = () => {

        const nextPieces = [...pieces, {
            type: typePieces[0].value,
            nom: '',
            largeur: 0,
            longueur: 0,
            surface: 0,
            personnes: 0,
            habitable: typePieces[0].habitable,
            habitation: typePieces[0].habitation,
        }];

        setPieces(nextPieces);
    }

    const deletePiece = (index) => {
        const nextPieces = [...pieces];
        nextPieces.splice(index, 1);
        setPieces(nextPieces);
    }

    const onPieceUpdate = (index, event) => {

        const nextPieces = [...pieces];
        const nextPiece = nextPieces[index];

        switch(event.target.name){
            case 'largeur':
            case 'longueur':
            case 'surface':
                nextPiece[event.target.name] = event.target.value;
                break;
            case 'personnes':
                const personnesCount = parseInt(event.target.value);
                nextPiece[event.target.name] = personnesCount > -1 ? personnesCount > maxPersonnes ? maxPersonnes : personnesCount : 0;
                break;
            case 'type':
                nextPiece[event.target.name] = event.target.value;
                const original = typePieces.find(p => p.value === event.target.value);
                nextPiece.habitable = original.habitable;
                nextPiece.habitation = original.habitation;
                if(!nextPiece.habitation) nextPiece.personnes = 0;
                break;
            default:
                nextPiece[event.target.name] = event.target.value;
                break;
        }

        if(event.target.name === 'largeur'){
            if(nextPiece.longueur > 0){
                const nextSurface = event.target.value * nextPiece.longueur;
                nextPiece.surface = nextSurface;
                // nextPiece.surface = Number.isInteger(nextSurface) ? nextSurface : parseFloat(nextSurface.toFixed(2));
            }
        }

        if(event.target.name === 'longueur'){
            if(nextPiece.largeur > 0){
                const nextSurface = event.target.value * nextPiece.largeur;
                nextPiece.surface = nextSurface;
            }
        }

        setPieces(nextPieces);
    }

    useEffect(() => {
        dispatch({
            type: 'superficies',
            value: pieces
        })
    }, [pieces]);

    return(
        <Panel>
            <h4 className='h4'>Superficies</h4>
            {pieces?.map((piece, index) => <PieceForm 
                key={index} 
                data={piece} 
                handler={(e) => onPieceUpdate(index, e)} 
                deleteHandler={() => deletePiece(index)} 
            />)}
            <div className='panel-bottom'>
                <Button onClick={addPiece}>Ajouter une pièce</Button>
            </div>
        </Panel>
    );
}

export default VisiteSuperficies;