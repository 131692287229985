import { ReactComponent as IconEye } from '@images/eye.svg';
import { ReactComponent as IconEdit } from '@images/edit.svg';
import { ReactComponent as IconCopy } from '@images/copy.svg';
import { ReactComponent as IconComment } from '@images/comment.svg';
import { ReactComponent as IconTrash } from '@images/trash.svg';
import { ReactComponent as IconDown } from '@images/download.svg';
import { ReactComponent as IconMail } from '@images/mail.svg';
import { ReactComponent as IconFSClose } from '@images/arrow-down.svg';
import { ReactComponent as IconFSOpen } from '@images/arrow-up.svg';

const ButtonIconOnly = ({ variant='', className='', children, action, disabled=false }) => {
    return(
        <button className={`btn btn-icon-only ${variant} ${className}`} onClick={action} disabled={disabled}>
            {children && children}
            {variant === 'read' && <IconEye />}
            {variant === 'edit' && <IconEdit />}
            {variant === 'copy' && <IconCopy />}
            {variant === 'comment' && <IconComment />}
            {variant === 'delete' && <IconTrash />}
            {variant === 'download' && <IconDown />}
            {variant === 'email' && <IconMail />}
            {variant === 'fs-open' && <IconFSOpen />}
            {variant === 'fs-close' && <IconFSClose />}
        </button>
    )
}

export default ButtonIconOnly;