import React, { useEffect , useState } from 'react';
import config from "@config";
import { UserProvider, FilterProvider } from '@context';
import TableArchivesContacts from './table-contacts';
import PageTitle from '@pages/page-title';
import ButtonArchiveActionsGroups from "@composants/button/button-archive-actions-groups";

const getArchivesContacts = async (idUser) => {

    const url = config.api(idUser).get.archives_contacts;

    return await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Cache-Control': 'no-cache',
        },
    })
        .then(response => response.json())
        .then(response => {
            if (response.success) {
                //console.log(response.contacts);
                if(response.data.contacts) {
                    return [...response.data.contacts];
                }
            }
        });
}

const PageArchivesContacts = (idUser) => {

    const [contacts, setContacts] = useState([]);
    const [exportIds, setExportIds] = useState([]);

    useEffect(() => {
        getArchivesContacts(idUser.idUser).then(result => {
            setContacts(result);
        });
    }, [])

//  console.log("contacts:", contacts);

    const contactsFormated = contacts.map(contact => {
        return {...contact,
            date_update: new Date(contact.date_update),
            archivage_date: new Date(contact.archivage_date),
            adresse: {
                rue: contact.adresse1,
                complement: contact.adresse2,
                cp: contact.code_postal,
                commune: contact.commune
            },
        }
    })

    const onSelection = async (selection) => {
        setExportIds(selection);
    }

    return(
        <UserProvider>
            <FilterProvider>
                <div className='content-wrapper'>
                    <header className="entry-header">
                        <div className='d-flex w-100 justify-content-between'>
                            <PageTitle exportIds={exportIds} entite='contact' />
                            <div className='d-flex align-items-center gap-3'>
                                <ButtonArchiveActionsGroups selectionEntities={exportIds} entity="contacts" status="archive" />
                            </div>
                        </div>
                    </header>
                    <div className="entry-content">
                        <TableArchivesContacts contacts={contactsFormated} selectionHandler={onSelection} />
                    </div>
                </div>
            </FilterProvider>
        </UserProvider>
    )
}

export default PageArchivesContacts;