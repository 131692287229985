// import DatePicker from 'react-date-picker';
// import 'react-date-picker/dist/DatePicker.css';
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import { ReactComponent as Icon } from '@images/calendar.svg';
import { OverlayTrigger, Popover } from 'react-bootstrap';

const DateInput = ({ value, label, handler, max }) => {
    
    const date = new Date(value);
    const dateLabel = date.toLocaleDateString();
    const dateLimite = max ? new Date(max) : null;

    const calendarPopover = (
        <Popover className='calendar-popover'>
            <Popover.Body className='p-3'>
                <Calendar 
                    defaultValue={date}
                    locale='fr-FR' 
                    onChange={handler} 
                    maxDate={dateLimite ? dateLimite : null}
                />
            </Popover.Body>
        </Popover>
    );

    return(
        <div className={`form-input date-input-wrapper`}>
            {label && <label className='form-input-label'>{label}</label>}
            <OverlayTrigger trigger="click" placement="auto" overlay={calendarPopover} rootClose={true}>
                <div key={`date-input-${label}`} className='date-input'>
                    <Icon />
                    <span className='date-label'>
                        {dateLabel}
                    </span>
                </div>
            </OverlayTrigger>
        </div>
    )
}

export default DateInput;