
import { useRef, useEffect } from '@wordpress/element';
import { ReactComponent as Icon } from '@images/check.svg';

const CheckboxSelection = ({ indeterminate, className='', ...rest }) => {

    const ref = useRef();

    useEffect(() => {

        if(typeof indeterminate === 'boolean'){
            ref.current.indeterminate = !rest.checked && indeterminate;
        }

    }, [ref, indeterminate]);

    return(
        <div className={`checkbox-input-wrapper ${className}`.trim()}>
            <label className='form-input-label checkbox-input-label'>
                <input 
                    ref={ref} 
                    type="checkbox" 
                    className="checkbox-input checkbox-selection" 
                    {...rest}
                />
                <Icon className="check-mark" />
            </label>
        </div>
    )
}

export default CheckboxSelection;