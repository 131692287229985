import { ReactComponent as IconEye } from '@images/eye.svg';
import { ReactComponent as IconClock } from '@images/clock.svg';
import { ReactComponent as IconEdit } from '@images/edit.svg';
import { ReactComponent as IconCopy } from '@images/copy.svg';
import { ReactComponent as IconComment } from '@images/comment.svg';
import { ReactComponent as IconTrash } from '@images/trash.svg';
import { ReactComponent as IconDown } from '@images/download.svg';
import { ReactComponent as IconMail } from '@images/mail.svg';
import { ReactComponent as IconLock } from '@images/lock.svg';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const ButtonAction = ({ size='', action, url, handler, className='', isDisabled=false, title='' }) => {

    let onClick = function() {};
    switch (action) {
        case 'read': if(title==='') title = "Consulter cette visite"; break;
        case 'read_meuble': if(title==='') title = "Consulter ce meublé"; break;
        case 'read_contact': if(title==='') title = "Consulter ce contact"; break;
        case 'edit' : if(title==='') title = "Modifier cette visite"; break;
        case 'edit_meuble' : if(title==='') title = "Modifier ce meublé"; break;
        case 'edit_contact' : if(title==='') title = "Modifier ce contact"; break;
        case 'copy': if(title==='') title = "Dupliquer cette visite"; break;
        case 'copy_meuble': if(title==='') title = "Dupliquer ce meublé"; break;
        case 'copy_contact': if(title==='') title = "Dupliquer ce contact"; break;
        case 'comment': if(title==='') title = "Commenter cette visite"; break;
        case 'comment-read': if(title==='') title = "Consulter le commentaire"; break;
        case 'revision_read': if(title==='') title = "Consulter l'historique"; break;
        case 'delete': if(title==='') title = "Supprimer cette visite"; break;
        case 'delete_contact': if(title==='') title = "Supprimer ce contact";break;
        case 'delete_meuble': if(title==='') title = "Supprimer ce meublé"; break;
        case 'download': if(title==='') title = "Télécharger cette visite"; break;
        case 'email': if(title==='') title = "Envoyer cette visite par e-mail"; break;
        case 'lock': if(title==='') title = "Sceller cette visite"; break;
        case 'new_visite': if(title==='') title = "Nouvelle visite"; break;
        case 'duplicate_meuble': if(title==='') title = "Dupliquer"; break;
    }
    onClick = handler;

    const getTooltip = () => (
        <Tooltip key={`${action}-tooltip`} >{title}</Tooltip>
    );

    return(
        <OverlayTrigger 
            key={action} 
            placement='auto'
            overlay={getTooltip()}
        >
            <a key={action}
               className={`btn btn-icon-only btn-action ${action === 'new_visite' && "fit-content"} ${isDisabled && "disabled"} ${size} ${className}`.trim()}
               href={url}
               onClick={onClick}>
                {action === 'new_visite' && <span className='text-nowrap'>Nouv. visite</span>}
                {(action === 'read' || action === 'read_meuble' || action === 'read_contact') && <IconEye />}
                {(action === 'revision_read') && <IconClock />}
                {(action === 'edit' || action === 'edit_meuble' || action === 'edit_contact') && <IconEdit />}
                {(action === 'copy' || action === 'copy_meuble' || action === 'copy_contact') && <IconCopy />}
                {(action === 'comment' || action === 'comment-read') && <IconComment />}
                {(action === 'delete' || action === 'delete_meuble' || action === 'delete_contact') && <IconTrash />}
                {action === 'download' && <IconDown />}
                {action === 'email' && <IconMail />}
                {action === 'lock' && <IconLock />}
            </a>
        </OverlayTrigger>
    );
}

export default ButtonAction;