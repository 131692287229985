import { useState, useMemo, useEffect } from '@wordpress/element';
import { Bar } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import Calendar from 'react-calendar'
import { Panel } from '@composants';
import { SelectInput } from '@composants/form';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { CATEGORIES, CHARTS_RGB_COLORS } from '@constantes';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
);

const rgbaColors = CHARTS_RGB_COLORS.map(rgb => "rgba(" + rgb + ", 1)");

const degrade =['#ffffb2', '#fed976', '#feb24c', '#fd8d3c', '#f03b20', '#bd0026'];

const chartOptions = {
    responsive: true,
    scales: {
        x: {
            stacked: true,
        },
        y: {
            stacked: true,
            title: {
                display: true, 
                text: 'Meublés'
            }
        },
    },
    plugins: {
        legend: {
            position: 'top',
        },
        zoom: {
            pan: {
                enabled: true,
                mode: 'x'
            },
            zoom: {
                pinch: {
                    enabled: true       // Enable pinch zooming
                },
                wheel: {
                    enabled: true       // Enable wheel zooming
                },
                mode: 'x',
            }
        }, 
    },
};

const moisValues = [{
    label: 'Tous',
    value: 'mois',
}, {
    label: 'Par catégorie',
    value: 'mois-categorie'
}, {
    label: 'Par inspecteur',
    value: 'mois-inspecteur'
}, {
    label: 'Par département',
    value: 'mois-departement'
}];

const datesValues = [{
    label: 'Tous',
    value: 'dates',
}, {
    label: 'Par inspecteur',
    value: 'dates-inspecteur'
}, {
    label: 'Par département',
    value: 'dates-departement'
}];

const anneeValues = [{
    label: 'Tous',
    value: 'annee',
}, {
    label: 'Par catégorie',
    value: 'annee-categorie'
}, {
    label: 'Par inspecteur',
    value: 'annee-inspecteur'
}, {
    label: 'Par département',
    value: 'annee-departement'
}];

const defaultDateFin = new Date();
const dateFin = new Date();
const defaultDateDebut = new Date(dateFin.setMonth(dateFin.getMonth() - 6));

const StatsMeubles = ({ meubles, visites }) => {

    const [dateRange, setDateRange] = useState([defaultDateDebut, defaultDateFin]);
    const [selection, setSelection] = useState('tous');

    /* La liste des inspecteurs du territoire */
    const inspecteurs = useMemo(() => {
        return meubles.reduce((result, meuble) => {
            if (!result.find(item => item.id === meuble.user_create)) {
                result.push({
                    id: meuble.user_create,
                    name: meuble.user_create_name
                });
            }
            return result;
        }, []).sort();
    }, [meubles]);

    /* La liste des départements du territoire */
    const departements = useMemo(() => {
        return meubles.reduce((result, meuble) => {
            if(meuble.dep && result.indexOf(meuble.dep) === -1){
                result.push(meuble.dep);
            }
            return result;
        }, []).sort();
    }, [meubles]);

    /* La liste des meublés avec visite regroupés par mois et année */
    const meublesVisites = useMemo(() => {
        return meubles.reduce((result, meuble) => {

            // Trouver les visites liées au meublé
            const visitesMeuble = visites.filter(visite => visite.meuble_id === meuble.id);

            // Si une visite est trouvée
            if(visitesMeuble.length > 0) {
                visitesMeuble.forEach((visiteMeuble) => {
                    // Formater la date de la visite
                    const dateVisite = new Date(visiteMeuble.date_visite);
                    const dateKey = dateVisite.toISOString().substring(0, dateVisite.toISOString().lastIndexOf('-'));
    
                    // Créer une copie du meublé avec les détails de la visite
                    const nextMeuble = {
                        ...meuble, 
                        date_visite: dateVisite.toLocaleString('sv-SV'), 
                        auteur_visite: visiteMeuble.user_create, 
                        visite_id: visiteMeuble.uid
                    };
    
                    // Ajouter le meuble au groupe de la date correspondante
                    if(!result[dateKey]) {
                        result[dateKey] = [nextMeuble];
                    } else {
                        if(!result[dateKey].find(m => m.id === nextMeuble.id)){
                            result[dateKey].push(nextMeuble);
                        }
                    }
                });
            }

            return result;

        }, {});
    }, [meubles, visites]);
    
    const meublesParAnnee = useMemo(() => {
        return meubles.reduce((result, meuble) => {
            const visitesMeuble = visites.filter(visite => visite.meuble_id === meuble.id);
            if(visitesMeuble.length > 0) {
                visitesMeuble.forEach(visiteMeuble => {
                    const dateVisite = new Date(visiteMeuble.date_visite);
                    const dateKey = dateVisite.toISOString().substring(0, 4);
                    const nextMeuble = {...meuble, date_visite: dateVisite.toLocaleString('sv-SV'), auteur_visite: visiteMeuble.user_create, visite_id: visiteMeuble.uid};

                    // Ajouter le meuble au groupe de la date correspondante
                    if(!result[dateKey]) {
                        result[dateKey] = [nextMeuble];
                    } else {
                        if(!result[dateKey].find(m => m.id === nextMeuble.id)){
                            result[dateKey].push(nextMeuble);
                        }
                    }
                });
            }
            return result;
        }, {});
    }, [meubles, visites]);

    /* La liste des meublés visités dans une plage de dates donnée */
    const meublesParDates = useMemo(() => {
        if(!dateRange) return;
        return meubles.reduce((result, meuble) => {
            const visitesMeuble = visites.filter(visite => visite.meuble_id === meuble.id);
            if(visitesMeuble.length > 0) {
                visitesMeuble.forEach(visiteMeuble => {
                    const dateVisite = new Date(visiteMeuble.date_visite);
                    if(dateVisite >= dateRange[0] && dateVisite <= dateRange[1]) {
                        result.push({
                            ...meuble, 
                            date_visite: dateVisite.toLocaleString('sv-SV'), 
                            auteur_visite: visiteMeuble.user_create, 
                            visite_id: visiteMeuble.uid
                        });
                    }
                });
            }
            return result;
        }, []);
    }, [dateRange]);

    /* Les datasets */

    const datasetParCategorie = useMemo(() => {
        return [{
            label: "Total des meublés",
            data: CATEGORIES.map(categorie => meubles.filter(meuble => meuble.classement === categorie.id).length),
            backgroundColor: degrade.map(couleur => couleur),
        }];
    }, [meubles]);

    const datasetParInspecteur = useMemo(() => {
        return inspecteurs.map((inspecteur, index) => {
            return {
                label: inspecteur.name,
                data: CATEGORIES.map(categorie => meubles.filter(meuble => parseInt(meuble.user_create) === parseInt(inspecteur.id) && meuble.classement === categorie.id).length),
                backgroundColor: rgbaColors[index],
            }
        });
    }, [meubles, inspecteurs]);

    const datasetParDepartement = useMemo(() => {
        return departements.map((departement, index) => {
            return {
                label: departement,
                data: CATEGORIES.map(categorie => meubles.filter(meuble => meuble.dep === departement && meuble.classement === categorie.id).length),
                backgroundColor: rgbaColors[index],
            }
        });
    }, [departements, meubles]);

    const datesTriees = Object.keys(meublesVisites).sort();
    const anneesTriees = Object.keys(meublesParAnnee).sort();

    const datasetParMois = useMemo(() => {
        return [{
            label: "Meublés visités",
            data: datesTriees.map(mois => meublesVisites[mois].length),
            backgroundColor: rgbaColors[0],
        }];
    }, [datesTriees, meublesVisites]);

    const datasetParMoisParCategorie = useMemo(() => {
        return CATEGORIES.map((categorie, index) => {
            return {
                label: categorie.label,
                data: datesTriees.map(mois => meublesVisites[mois].filter(meuble => meuble.classement === categorie.id).length),
                backgroundColor: degrade[index],
            }
        });
    }, [datesTriees, meublesVisites]);

    const datasetParMoisParInspecteur = useMemo(() => {
        return inspecteurs.map((inspecteur, index) => {
            return {
                label: inspecteur.name,
                data: datesTriees.map(mois => meublesVisites[mois].filter(meuble => parseInt(meuble.auteur_visite) === parseInt(inspecteur.id)).length),
                backgroundColor: rgbaColors[index],
            }
        });
    }, [inspecteurs,datesTriees, meublesVisites]);

    const datasetParMoisParDepartement = useMemo(() => {
        return departements.map((departement, index) => {
            return {
                label: departement,
                data: datesTriees.map(mois => meublesVisites[mois].filter(meuble => meuble.dep === departement).length),
                backgroundColor: rgbaColors[index],
            }
        });
    }, [departements, datesTriees, meublesVisites]);

    const datasetParAnnee = useMemo(() => {
        return [{
            label: 'Par année',
            data: anneesTriees.map(annee => meublesParAnnee[annee].length),
            backgroundColor: rgbaColors[0],
        }];
    }, [anneesTriees, meublesParAnnee]);

    const datasetParAnneeParCategorie = useMemo(() => {
        return CATEGORIES.map((categorie, index) => {
            return {
                label: categorie.label,
                data: anneesTriees.map(annee => meublesParAnnee[annee].filter(meuble => meuble.classement === categorie.id).length),
                backgroundColor: degrade[index],
            }
        });
    }, [anneesTriees, meublesParAnnee]);

    const datasetParAnneeParInspecteur = useMemo(() => {
        return inspecteurs.map((inspecteur, index) => {
            return {
                label: inspecteur.name,
                data: anneesTriees.map(annee => meublesParAnnee[annee].filter(meuble => parseInt(meuble.auteur_visite) === parseInt(inspecteur.id)).length),
                backgroundColor: rgbaColors[index],
            }
        });
    }, [inspecteurs, anneesTriees, meublesParAnnee]);

    const datasetParAnneeParDepartement = useMemo(() => {
        return departements.map((departement, index) => {
            return {
                label: departement,
                data: anneesTriees.map(annee => meublesParAnnee[annee].filter(meuble => meuble.dep === departement).length),
                backgroundColor: rgbaColors[index],
            }
        });
    }, [departements, anneesTriees, meublesParAnnee]);

    const datasetParDates = useMemo(() => {
        if(!dateRange) return;
        return [{
            label: `Du ${dateRange[0].toLocaleDateString('fr-FR')} au ${dateRange[1].toLocaleDateString('fr-FR')}`,
            data: CATEGORIES.map(categorie => meublesParDates.filter(meuble => meuble.classement === categorie.id).length),
            backgroundColor: degrade.map(couleur => couleur),
        }];
    }, [dateRange]);

    const datasetParDatesParInspecteur = useMemo(() => {
        if(!dateRange) return;
        return inspecteurs.map((inspecteur, index) => {
            return {
                label: inspecteur.name,
                data: CATEGORIES.map(categorie => meublesParDates.filter(meuble => parseInt(meuble.user_create) === parseInt(inspecteur.id) && meuble.classement === categorie.id).length),
                backgroundColor: rgbaColors[index],
            }
        });
    }, [dateRange, inspecteurs, meublesParDates]);

    const datasetParDatesParDepartement = useMemo(() => {
        if(!dateRange) return;
        return departements.map((departement, index) => {
            return {
                label: departement,
                data: CATEGORIES.map(categorie => meublesParDates.filter(meuble => meuble.dep === departement && meuble.classement === categorie.id).length),
                backgroundColor: rgbaColors[index],
            }
        });
    }, [dateRange, departements, meublesParDates]);
    
    /* Les événements */

    const onFilter = (event) => {
        // setSelection(event.target.value);
        if(event.target.id === 'dates'){
            event.preventDefault();
        } else {
            setSelection(event.target.value);
        }
    }

    const onDateRange = (range) => {
        setDateRange(range);
        if(selection.indexOf('dates') === -1){
            setSelection('dates');
        }
        document.getElementById('page').click();
    }

    /* Les données du graphique */

    const selectedDataset = () => {

        const selectionDatasetMapping = {
            'tous': datasetParCategorie,
            'departement': datasetParDepartement,
            'inspecteur': datasetParInspecteur,
            'mois': datasetParMois,
            'mois-inspecteur': datasetParMoisParInspecteur,
            'mois-categorie': datasetParMoisParCategorie,
            'mois-departement': datasetParMoisParDepartement,
            'annee': datasetParAnnee,
            'annee-inspecteur': datasetParAnneeParInspecteur,
            'annee-categorie': datasetParAnneeParCategorie,
            'annee-departement': datasetParAnneeParDepartement,
            'dates': datasetParDates,
            'dates-departement': datasetParDatesParDepartement,
            'dates-inspecteur': datasetParDatesParInspecteur,
        }

        const nextDataset = selectionDatasetMapping[selection];

        return nextDataset;
    }

    const data = {
        labels: selection.indexOf('mois') !== -1 ? 
                    datesTriees.map(date => `${date.substring(5,7)}-${date.substring(0,4)}`) :
                        selection.indexOf('annee') !== -1 ? anneesTriees.map(annee => `${annee}`) : 
                            CATEGORIES.map(c => c.label),
        datasets: selectedDataset()
    }

    /* Une popover pour afficher le calendrier */

    const calendarPopover = (props) => (
        <Popover id={`calendar-popover`} className='calendar-popover' {...props}>
            <Popover.Body className='p-3'>
                <Calendar 
                    returnValue='range' 
                    selectRange={true} 
                    locale='fr-FR' 
                    onChange={onDateRange} 
                    value={dateRange}
                />
            </Popover.Body>
        </Popover>
    );

    return (
        <Panel title="Parc étoilé" contentClassName='gap-2'>
            <div className="stats">

                <div className="stats-header d-flex align-items-center gap-3 mb-3">
                    <div className="filtres d-flex gap-2 flex-wrap justify-content-center">
                        <div className='d-flex gap-2'>
                            <div className="form-check form-switch mb-0">
                                <input className="form-check-input" type="checkbox" checked={selection === 'tous'} value="tous" id="tous" onChange={onFilter} />
                                <label className="form-check-label caption text-moyen" htmlFor="tous">Tous ({meubles.length})</label>
                            </div>
                            <div className="form-check form-switch mb-0">
                                <input className="form-check-input" type="checkbox" checked={selection === 'departement'} value="departement" id="departement" onChange={onFilter} />
                                <label className="form-check-label caption text-moyen" htmlFor="departement">Par département</label>
                            </div>
                            <div className="form-check form-switch mb-0">
                                <input className="form-check-input" type="checkbox" checked={selection === 'inspecteur'} value="inspecteur" id="inspecteur" onChange={onFilter} />
                                <label className="form-check-label caption text-moyen" htmlFor="inspecteur">Par inspecteur</label>
                            </div>
                        </div>
                        <div className='d-flex gap-2'>
                            <div className='d-flex gap-2 flex-nowrap'>
                                <div className="form-check form-switch mb-0">
                                    <input className="form-check-input" type="checkbox" checked={selection.indexOf('annee') !== -1} value="annee" id="annee" onChange={onFilter} />
                                    <label className="form-check-label caption text-moyen" htmlFor="annee">Par année</label>
                                </div>
                                {selection.indexOf('annee') !== -1 && <SelectInput size="sm" values={anneeValues} value={selection} handler={onFilter} />}
                            </div>
                            <div className='d-flex gap-2 flex-nowrap'>
                                <div className="form-check form-switch mb-0">
                                    <input className="form-check-input" type="checkbox" checked={selection.indexOf('mois') !== -1} value="mois" id="mois" onChange={onFilter} />
                                    <label className="form-check-label caption text-moyen" htmlFor="mois">Par mois</label>
                                </div>
                                {selection.indexOf('mois') !== -1 && <SelectInput size="sm" values={moisValues} value={selection} handler={onFilter} />}
                            </div>
                            <div className='d-flex gap-2 flex-nowrap'>
                                <OverlayTrigger key={`date-range-overlay`} trigger="click" placement="auto" overlay={calendarPopover} rootClose={true}>
                                    <div className="form-check form-switch mb-0" onClick={(e) => e.preventDefault()}>
                                        <input className="form-check-input" type="checkbox" checked={selection.indexOf('dates') !== -1} value="dates" id="dates" onChange={onFilter} />
                                        <label className="form-check-label caption text-moyen" htmlFor="dates">Par dates</label>
                                    </div>
                                </OverlayTrigger>
                                {selection.indexOf('dates') !== -1 && <SelectInput size="sm" values={datesValues} value={selection} handler={onFilter} />}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="stats-body">
                    {selection.indexOf('dates') !== -1 && <div className='text-center text-muted'><small>Meublés visités du {dateRange[0].toLocaleDateString('fr-FR')} au {dateRange[1].toLocaleDateString('fr-FR')}</small></div>}
                    <Bar options={chartOptions} data={data} plugins={[zoomPlugin]} style={{maxHeight: '50vh'}} />
                </div>
            </div>
        </Panel>
    )
}

export default StatsMeubles;