import { Text, View } from '@react-pdf/renderer';
import config from "@config";

const LettreBottom = ({ inspecteur, visite }) => {

    const getDate = () => {
        const date = new Date();
        console.log(date.toLocaleString());
        return date.toLocaleString();
    };
    const { organisme_adresse, organisme_adresse2, organisme_email, organisme_commune, organisme_cp, organisme_tel, organisme_siret, organisme_titre } = inspecteur;

    return (
        <View style={{position: 'absolute', left: '10mm', right: '10mm', bottom: '10mm', textAlign: 'center'}} wrap={false} fixed>
            <Text style={{fontSize: '7pt', marginBottom: '2mm'}}>Selon la loi « informatique et libertés », du 6 janvier 1978 modifiée, vous disposez d’un droit d’accès et de rectification des informations nominatives vous concernant.</Text>
            <Text style={{fontSize: '7pt', marginBottom: '3mm'}}>Généré par Nomade Classement version {config.version} - le {getDate()} - visite : {visite.uid}</Text>
            <View style={{fontSize: '10pt'}}>
                <Text style={{marginBottom: '2mm'}}>
                    {organisme_titre} – {organisme_adresse}{organisme_adresse2 ? ` ${organisme_adresse2}`: ''} – {organisme_cp} {organisme_commune}
                </Text>
                <Text>
                    Tél. : {organisme_tel} – Courriel : {organisme_email} – Siret : {organisme_siret}
                </Text>
            </View>
        </View>
    );
}

export default LettreBottom;