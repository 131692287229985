import { useState, useEffect, useRef, useContext } from '@wordpress/element';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useOnlineStatus } from '@hooks';
import { ButtonAction } from '@composants/button';
import { ModaleRevision } from '@composants';
import { ReactComponent as IconAction } from '@images/more.svg';

const ButtonArchiveMeubleActions = ({ meuble }) => {

    const { id: uid } = meuble;
    const isOnline = useOnlineStatus();

    const [show, setShow] = useState(false);
    const [showRevisionModal, setShowRevisionModal] = useState(false);

    const btnRef = useRef();

    const onActions = () => {
        setShow(!show);
    };

    const onRevisionAction = () => {
        setShowRevisionModal(!showRevisionModal);
    }

    useEffect(() => {

        const onDocumentClick = (event) => {
            if(btnRef.current && !btnRef.current.contains(event.target)){
                setShow(false);
            }
        }

        document.addEventListener('click', onDocumentClick);

        return () => {
            document.removeEventListener('click', onDocumentClick);
        }

    }, []);

    const actionsOverlay = (props) => (
        <Popover className='popover-actions' {...props}>
            <Popover.Body>
                <div className='d-flex'>
                    <ButtonAction key={`revision_read-${uid}`} action="revision_read" handler={onRevisionAction} isDisabled={!isOnline} />
                </div>
            </Popover.Body>
        </Popover>
    );

    return (
        <>
            <OverlayTrigger placement='top' show={show} overlay={actionsOverlay} rootClose={true}>
                <button key={`btn-${meuble.id}`} ref={btnRef} className={`btn btn-icon-only btn-transparent btn-lg`} onClick={onActions}>
                    <IconAction className="icon" />
                </button>
            </OverlayTrigger>
            {showRevisionModal && <ModaleRevision show={showRevisionModal} setShow={setShowRevisionModal} uid={uid} entite='meuble' />}
        </>
    )
}

export default ButtonArchiveMeubleActions;