
import { createContext, useReducer, useEffect, useContext } from '@wordpress/element';
import { useData } from '@hooks';

const VisitesContext = createContext(null);
const VisitesDispatchContext = createContext(null);

const visitesReducer = (visites, action) => {
    switch(action.type){
        case 'init': {
            return [...action.visites];
        }
        case 'comment': 
        case 'delete': 
        case 'duplicate': {
            return [...action.value];
        }
        default: {
            throw Error('Unknown action: ' + action.type);
        }
    }
}

const VisitesProvider = ({ children, sync, update }) => {

    const { isLoading, visites: allVisites, meubles, contacts } = useData(sync, update);
    const [visites, dispatchVisites] = useReducer(visitesReducer, null);

    useEffect(() => {

        if(allVisites){
            dispatchVisites({ type: 'init', visites: allVisites });
        }

    }, [allVisites]);

    return (
        <VisitesContext.Provider value={{ isLoading, visites, meubles, contacts }}>
            <VisitesDispatchContext.Provider value={dispatchVisites}>
                {children}
            </VisitesDispatchContext.Provider>
        </VisitesContext.Provider>
    );

}

const useVisites = () => useContext(VisitesContext);

const useVisitesDispatch = () => useContext(VisitesDispatchContext);

export {
    VisitesContext,
    VisitesProvider,
    useVisites, 
    useVisitesDispatch, 
}