import { Page, Text, View } from '@react-pdf/renderer';
import { typePieces, SURFACES_MIN } from '@constantes';

const ReleveSurfaces = ({ visite }) => {

    const { classement_cible, meuble, surfaces, superficies, capacite } = visite.infos;

    const surfaceTotale = `${parseFloat(surfaces.total).toFixed(2)} m²`;
    const surfaceHabitable = `${parseFloat(surfaces.totalHabitable).toFixed(2)} m²`;
    const surfaceMajoree = `${parseFloat(surfaces.majorees).toFixed(2)} m²`;
    const surfaceDeReference = `${parseFloat(surfaces.minimum).toFixed(2)} m²`;

    return(
        <Page size="A4" style={{ padding: '10mm 15mm 10mm 15mm', fontFamily: 'Lato', fontSize: '12pt' }}>

            <View style={{textAlign: 'center', textTransform: 'uppercase', fontWeight: 'bold', marginBottom: '5mm', fontSize: '15pt'}}>
                <Text>Relevé de surfaces</Text>
            </View>

            <View style={{display: 'flex', flexDirection: 'row', gap: '5mm', marginBottom: '5mm', width: '100%'}}>
                <Text><Text style={{fontWeight: 'bold'}}>Catégorie demandée : </Text> {classement_cible} {classement_cible > 1 ? 'étoiles' : 'étoile'}</Text>
                <Text><Text style={{fontWeight: 'bold'}}>Capacité demandée : </Text> {capacite} {capacite > 1 ? 'personnes' : 'personne'}</Text>
            </View>

            <View style={{padding: '3mm', border: '1pt solid', backgroundColor: '#ddd', border: '1pt solid #000', marginBottom: '5mm'}}>
                <View style={{textAlign: 'center', textTransform: 'uppercase', fontWeight: 'bold', marginBottom: '1mm'}}>
                    <Text>Pour le meublé identifié ci-dessous</Text>
                </View>
                {meuble.titre && <View>
                    <Text><Text style={{fontWeight: 'bold'}}>Dénomination commerciale : </Text>{meuble.titre}</Text>
                </View>}
                <View>
                    <Text><Text style={{fontWeight: 'bold'}}>Adresse du meublé : </Text>{meuble.adresse1}{meuble.adresse2 !== '' && ' ' + meuble.adresse2}</Text>
                </View>
                <View style={{display: 'flex', flexDirection: 'row', width: '100%', gap: '2mm'}}>
                    <Text><Text style={{fontWeight: 'bold'}}>Code postal : </Text>{meuble.code_postal}</Text>
                    <Text><Text style={{fontWeight: 'bold'}}>Ville : </Text>{meuble.commune}</Text>
                </View>
            </View>

            <View style={{border: '1pt solid', border: '1pt solid #000', borderBottom: '0', marginBottom: '5mm'}}>
                <View style={{display: 'flex', flexDirection: 'row', borderBottom: '1pt solid #000', fontWeight: 'bold'}}>
                    <View style={{padding: '1mm', borderRight: '1pt solid #000', width: '25%'}}><Text>Pièce / Espace</Text></View>
                    <View style={{padding: '1mm', borderRight: '1pt solid #000', width: '25%'}}><Text>Surface relevée</Text></View>
                    <View style={{padding: '1mm', borderRight: '1pt solid #000', width: '25%'}}><Text>Nom</Text></View>
                    <View style={{padding: '1mm', borderRight: '1pt solid #000', width: '25%'}}><Text>Habitable</Text></View>
                    <View style={{padding: '1mm', width: '25%'}}><Text>Couchages</Text></View>
                </View>
                {superficies.map(piece => <Piece key={`${piece.personnes}${piece.type}${piece.surface}`} piece={piece} />)}
            </View>

            <View style={{marginBottom: "3mm"}}>
                <Text><Text style={{fontWeight: 'bold'}}>Surface totale du meublé : </Text><Text> {surfaceTotale}</Text></Text>
            </View>

            <View style={{marginBottom: "3mm"}}>
                <Text><Text style={{fontWeight: 'bold'}}>Surface habitable du meublé : </Text><Text> {surfaceHabitable}</Text></Text>
            </View>

            <View style={{marginBottom: "3mm"}}>
                <Text><Text style={{fontWeight: 'bold'}}>Surface de référence : </Text><Text> {surfaceDeReference}</Text></Text>
                <Text>Surface habitable minimum exigée pour le classement, calculée en fonction de la catégorie demandée, du nombre de pièces habitables et de la capacité demandée.</Text>
            </View>

            <View style={{marginBottom: "5mm"}}>
                <Text><Text style={{fontWeight: 'bold'}}>Surface majorée du meublé : </Text><Text> {surfaceMajoree}</Text></Text>
            </View>

            <View style={{marginBottom: "5mm", fontStyle: 'italic'}}>
                <Text>Les surfaces mesurées lors de la visite de classement sont données à titre indicatif, elles n’engagent pas l’organisme évaluateur en dehors de l’instruction du dossier et du calcul des points nécessaires au classement des meublés de tourisme.</Text>
            </View>

        </Page>
    );
}

const Piece = ({ piece }) => {

    const isHabitable = ['chambre', 'cuisine-piece', 'sejour', 'salon'].indexOf(piece.type) !== -1;
    const typePiece = typePieces.find(type => type.value === piece.type);

    return(
        <View style={{display: 'flex', flexDirection: 'row', borderBottom: '1pt solid #000'}}>
            <View style={{padding: '1mm', borderRight: '1pt solid #000', width: '25%'}}><Text>{typePiece?.label}</Text></View>
            <View style={{padding: '1mm', borderRight: '1pt solid #000', width: '25%', textAlign: 'right'}}>
                <Text>{parseFloat(piece.surface).toFixed(2)}</Text>
            </View>
            <View style={{padding: '1mm', borderRight: '1pt solid #000', width: '25%'}}><Text>{piece.nom}</Text></View>
            <View style={{padding: '1mm', borderRight: '1pt solid #000', width: '25%', textAlign: 'right'}}><Text>{isHabitable ? 'Oui' : 'Non'}</Text></View>
            <View style={{padding: '1mm', width: '25%', textAlign: 'right'}}><Text>{piece.personnes}</Text></View>
        </View>
    );
}

export default ReleveSurfaces;