import Meubles from "./meubles";
import MeubleEdit from "./meuble-edit";
import MeubleVoir from "./meuble-voir";
import PageMeubles from './page-meubles';
import MeubleLiaisonContact from "@meuble/meuble-liaison-contact.js";
import ButtonArchiveActionsGroups from "@composants/button/button-archive-actions-groups";

export {
    Meubles, 
    MeubleVoir,
    MeubleEdit,
    PageMeubles,
    MeubleLiaisonContact,
    ButtonArchiveActionsGroups
}