import { VisitesProvider, UserProvider, ToastContextProvider } from "@context";
import PageAccueil from '@pages/accueil/page-accueil';

const Accueil = () => {

    return (
        <UserProvider>
            <ToastContextProvider>
                <VisitesProvider sync={true} update={true}>
                    <PageAccueil />
                </VisitesProvider>
            </ToastContextProvider>
        </UserProvider>
    );

}

export default Accueil;