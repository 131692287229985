import { useContext } from '@wordpress/element';
import { DataContext, UserContext } from "@context";
import { useOnlineStatus } from '@hooks';
import { Vignette, Panel, Classement } from "@composants";
import ButtonNewVisite from '@composants/button/button-new-visite';
import { ContactItemMeuble } from '@composants/entites';

const PageMeubleVoir = () => {

    const { user } = useContext(UserContext);
    const { isLoading, meubles, visites, contacts } = useContext(DataContext);
    const isOnline = useOnlineStatus();

    if(isLoading) return <div className='text-center p-5'><h3 className='h3 text-moyen'>Chargement du meublé…</h3></div>;

    const searchParams = new URLSearchParams(window.location.search);
    const meuble = meubles.find(m => m.id === searchParams.get('id'));
    const { 
        id,
        titre, 
        identifiant, 
        type,
        description,
        capacite,
        classement, 
        date_classement, 
        num_classement, 
        adresse1, 
        adresse2, 
        code_postal, 
        commune, 
        site_web, 
        tel,
        date_create,
        date_update, 
        user_create, 
        user_create_name, 
        user_update_name, 
        vignette_url, 
    } = meuble;

    //const canEdit = parseInt(user.id) === parseInt(user_create)
    const canEdit = true;

    const visitesMeuble = visites.filter(visite => visite.meuble_id === id);
    const lastVisite = visitesMeuble.find(() => new Date(Math.max(...visitesMeuble.map(visite => new Date(visite.date_visite)))));
//{capacite} {parseInt(capacite) > 1 ? "personnes" : "non encore renseigné."}
    let capacite_max = 0;
    let capacite_accueil = 0;
    if( lastVisite ){
        capacite_max = lastVisite.infos.accueil_max;
        capacite_accueil = lastVisite.infos.capacite;
    }else{
        capacite_max = capacite;
    }

    const contactsMeuble = contacts.reduce((result, contact) => {
        const meubleContact = contact.meubles.find(m => m.id === id);
        if(meubleContact) result.push({ ...contact, role: meubleContact.contact_type });
        return result;
    }, []);

    return(
        <div className="content-wrapper">
            <div className="entry-header">
                <div className="d-flex justify-content-between w-100">
                    
                    <div className="identification d-flex gap-2">
                        <Vignette className="rond" variant="maison">
                            {vignette_url && <img src={vignette_url} />}
                        </Vignette>
                        <div className="d-flex flex-column">
                            <h3 className="h4">
                                <div className='d-flex flex-column'>
                                    <span>{identifiant}</span>
                                    <strong>{titre}</strong>
                                </div>
                            </h3>
                            <div className='d-flex gap-2'>
                                <span className="caption text-moyen">Créé le {new Date(date_create).toLocaleDateString()}{user_create_name && ` par ${user_create_name}`}</span>
                                <span className="caption text-moyen">Modifié le {new Date(date_update).toLocaleDateString()}{user_update_name && ` par ${user_update_name}`}</span>
                            </div>
                        </div>
                    </div>

                    <div className='actions d-flex gap-3'>
                        <a className='btn outline' href={`/meubles`}>Retour</a>
                        {canEdit && <button className='btn' onClick={() => window.location = `/meubles/meuble-edit/?id=${id}`} disabled={!isOnline}>Modifier</button>}
                        <ButtonNewVisite meuble={meuble} />
                    </div>

                </div>
            </div>
            <div className="entry-content border-up">

                <div className='d-flex gap-4 p-4 w-100'>

                    <Panel className="w-100" title="Informations générales">
                        <div className='d-flex flex-column gap-3'>
                            {type && <div>{type}</div>}
                            <div className='d-flex flex-column'>
                                <span className='body-2'>Capacité maximale d'accueil : <strong className='body-2-bold'>{capacite_max} {parseInt(capacite_max) > 1 ? "personnes" : "non encore renseigné."}</strong></span>
                            </div>
                            <div className='d-flex flex-column'>
                                <span className='body-2'>Adresse</span>
                                <strong className='body-2-bold'>{adresse1}</strong>
                                {adresse2 && <strong className='body-2-bold'>{adresse2}</strong>}
                                <strong className='body-2-bold'>{code_postal} {commune}</strong>
                                {site_web && <a href={site_web} title="Visiter le site web">{site_web}</a>}
                                {tel && <span className='body-2'>Téléphone : <a href={`tel:${tel}`} title="Appeler">{tel}</a></span>}
                                <span className='body-2 pt-2'><i>{description}</i></span>
                            </div>
                        </div>
                    </Panel>

                    <Panel className="w-100" title="Classement">
                        {classement ? <div className='d-flex flex-column gap-3'>
                            <div className='d-flex'><Classement count={classement} /></div>
                            <div className='d-flex flex-column'>
                                <span className='body-2'>Capacité d'accueil : <strong className='body-2-bold'>{parseInt(capacite_accueil) > 1 ? capacite_accueil+" personnes" : "pas encore visité."}</strong></span>
                            </div>
                            {date_classement !== '0000-00-00 00:00:00' && <div className='d-flex flex-column'>
                                <span className='body-2'>Date de décision de classement</span>
                                <strong className='body-2-bold'>{new Date(date_classement).toLocaleDateString()}</strong>
                            </div>}
                            {num_classement && <div className='d-flex flex-column'>
                                <span className='body-2'>Numéro de classement</span>
                                <strong className='body-2-bold'>{num_classement}</strong>
                            </div>}
                        </div> : <p>Ce meublé n'a pas de classement</p>}
                    </Panel>

                </div>

                <div className='px-4'>
                    <Panel title="Contacts">
                        {(contactsMeuble && contactsMeuble.length) ? 
                            <div className='d-flex flex-column gap-3'>
                                {contactsMeuble.map(contact => <ContactItemMeuble key={contact.id} contact={contact} />)}
                            </div> :
                            <p className='mb-0'>Aucun contact n'est lié à ce meublé</p>
                        }
                    </Panel>
                </div>

            </div>
        </div>
    )
}

export default PageMeubleVoir;