import { DataProvider, UserProvider } from '@context';
import { PageContacts } from '@contact';

const Contacts = () => {

    return(
        <UserProvider>
            <DataProvider sync={true}>
                <PageContacts />
            </DataProvider>
        </UserProvider>
    );
}

export default Contacts;