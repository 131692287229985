import ButtonIconOnly from '@composants/button/button-icon-only';
import { ReactComponent as Icon } from '@images/eye.svg';

const ButtonIconEye = ({ variant, action }) => {
    return(
        <ButtonIconOnly variant={variant} action={action}>
            <Icon />
        </ButtonIconOnly>
    )
}

export default ButtonIconEye;