import { useVisite } from '@context';
import { Panel, Badge } from "@composants"

const VisiteResultats = () => {

    const { visite } = useVisite();

    if(!visite) return <div></div>;

    const { scores } = visite.infos;

    // Liste des critères non-compensables
    const ONC = visite.grille.filter(critere => critere.statut === 'ONC' && critere.checked);

    // Seuil atteint si C >= B et si tous les critères compensables sont validés
    const seuilAtteint = scores.c >= scores.b && ONC.length === 5;

    return(
        <div className='visite-resultats'>
            <div className='d-flex gap-4'>
                
                <Panel 
                    className="w-100 square grid" 
                    title="Points obligatoires"
                    footer={<div className='score-points'>
                            <div className='d-flex align-items-center gap-3'>
                                <p className='label font-body-2 flex-grow-1 mb-0'><strong>Respect du seuil de nombre de points obligatoires à atteindre</strong></p>
                                <Badge variant="orange" className='square'>{seuilAtteint ? 'Oui' : 'Non'}</Badge>
                            </div>
                        </div>}
                >
                    <div className='d-flex flex-column gap-4'>
                        <div className='score-points'>
                            <div className='d-flex gap-3'>
                                <Badge size='lg' className='outline square'>A</Badge>
                                <p className='label font-body-2 flex-grow-1 mb-0'>Nombre total de points obligatoires à respecter pour le meublé dans la catégorie demandée (tenant compte des cas particuliers et des critères non applicables)</p>
                                <Badge variant="moyen" className='square'>{scores.a}</Badge>
                            </div>
                        </div>
                        <div className='score-points'>
                            <div className='d-flex gap-3'>
                                <Badge size='lg' className='outline square'>B</Badge>
                                <p className='label font-body-2 flex-grow-1 mb-0'>Nombre minimal de points obligatoires à&nbsp;respecter, 95% de A</p>
                                <Badge variant="moyen" className='square'>{scores.b}</Badge>
                            </div>
                        </div>
                        <div className='score-points'>
                            <div className='d-flex gap-3'>
                                <Badge size='lg' className='outline square'>C</Badge>
                                <p className='label font-body-2 flex-grow-1 mb-0'>Nombre de points obligatoires atteints</p>
                                <Badge variant="moyen" className='square'>{scores.c}</Badge>
                            </div>
                        </div>
                        <div className='score-points'>
                            <div className='d-flex gap-3'>
                                <Badge size='lg' className='outline square orange'>D</Badge>
                                <p className='label font-body-2 flex-grow-1 mb-0'>Nombre de points obligatoires à compenser dans la limite de 5% du total des points obligatoires à respecter (coefficient 3), soit (A-C)*3</p>
                                <Badge variant="orange" className='square'>{scores.d}</Badge>
                            </div>
                        </div>
                        
                    </div>
                </Panel>

                <Panel 
                    className="w-100 square grid" 
                    title="Points à la carte"
                    footer={
                        <div className='score-points'>
                            <div className='d-flex align-items-center gap-3'>
                                <p className='label font-body-2 flex-grow-1 mb-0'><strong>Respect du seuil de nombre de points à la carte à&nbsp;atteindre</strong></p>
                                <Badge variant="orange" className='square'>{scores.h >= scores.g ? 'Oui' : 'Non'}</Badge>
                            </div>
                        </div>}
                >
                    <div className='d-flex flex-column gap-4'>
                        <div className='score-points'>
                            <div className='d-flex align-items-center gap-3'>
                                <Badge size='lg' className='outline square'>E</Badge>
                                <p className='label font-body-2 flex-grow-1 mb-0'>Nombre total de points à la carte disponibles pour le meublé dans la&nbsp;catégorie demandée</p>
                                <Badge variant="moyen" className='square'>{scores.e}</Badge>
                            </div>
                        </div>
                        <div className='score-points'>
                            <div className='d-flex align-items-center gap-3'>
                                <Badge size='lg' className='outline square'>F</Badge>
                                <p className='label font-body-2 flex-grow-1 mb-0'>Nombre de points à la carte à respecter, soit 5%, 10%, 20%, 30%, 40% respectivement pour les catégories 1, 2, 3, 4, 5 étoiles</p>
                                <Badge variant="moyen" className='square'>{scores.f}</Badge>
                            </div>
                        </div>
                        <div className='score-points'>
                            <div className='d-flex align-items-center gap-3'>
                                <Badge size='lg' className='outline square'>G</Badge>
                                <p className='label font-body-2 flex-grow-1 mb-0'>Nombre de points à la carte à atteindre</p>
                                <Badge variant="moyen" className='square'>{scores.g}</Badge>
                            </div>
                        </div>
                        <div className='score-points'>
                            <div className='d-flex align-items-center gap-3'>
                                <Badge size='lg' className='outline square orange'>H</Badge>
                                <p className='label font-body-2 flex-grow-1 mb-0'><strong>Nombre de points à la carte atteints</strong></p>
                                <Badge variant="orange" className='square'>{scores.h}</Badge>
                            </div>
                        </div>
                    </div>
                </Panel>
            </div>
        </div>
    );
}

export default VisiteResultats;