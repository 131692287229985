import Modal from 'react-bootstrap/Modal';
import { useVisiteDispatch } from '@context/';
import { Button } from '@composants/button';
import { ReactComponent as IconClose } from '@images/close.svg';

const ModaleSceller = ({ show, setShow, uid }) => {

    const dispatch = useVisiteDispatch();

    const onLockVisite = () => {
        dispatch({ type: 'lock-visite' });
        setTimeout(() => {
            window.location = `/visites/visite-voir?id=${uid}`;
        }, 500);
    }

    return(
        <Modal show={show} onHide={() => setShow(false)} scrollable>
            <Modal.Header>
                <Modal.Title>Sceller la visite {uid}</Modal.Title>
                <button className='btn-close' data-bs-dismiss="modal" aria-label="Close">
                    <IconClose onClick={() => setShow(false)} />
                </button>
            </Modal.Header>
            <Modal.Body className='text-center'>
                Êtes-vous sur de vouloir sceller cette visite ?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline" name="cancel" onClick={() => setShow(false)}>Annuler</Button>
                <Button name="save" onClick={onLockVisite}>Sceller</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModaleSceller;