import React, { useRef } from 'react';
import { useState, useEffect, useContext } from '@wordpress/element';
import config from '@config';
import { Panel } from '@composants';
import Select from "react-select";

const getContactMeubles = async (contact) => {

    const url = config.api('', contact.id).get.contact_meubles;

    return await fetch(url+"&status=actif", {
        method: 'GET',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Cache-Control': 'no-cache',
        },
        //body: new URLSearchParams(data)
    })
        .then(response => response.json())
        .then(response => {
            if (response.success) {
                //console.log(response.meubles);
                if(response.data.meubles) {
                    return [...response.data.meubles];
                }
            }
        });
}

const ContactLiaisonMeuble = () => {

    const selectMeuble = useRef(null);
    const [contactMeubles, setContactMeubles] = useState([]);
    const [meublesToSelect, setMeublesToSelect] = useState();

    function valideButton(){

        const meubleSelected = selectMeuble.current ? selectMeuble.current.state.selectValue[0].value : null;
        const contactTypeSelected = document.getElementById("contact_type").value;
        if(meubleSelected != 999999 ){
            associer();
        }else{
            //redirection page creation meuble avec auto association
            window.location.href = "/meuble-edit?c-lk=1&c="+contact.id+"&ct="+contactTypeSelected;
        }
    }
    function dissocier(id_contact, id_meuble, contact_type) {

        if ( ! window.confirm('Souhaitez vous dissocier ce bien du propriétaire ?') ) {
            return false;
        }

        const data = {
            action: 'dissocier_contact_meuble',
            nonce: document.getElementById('nonce_dissocier_contact_meuble').value,
            id_contact: id_contact,
            id_meuble: id_meuble,
            contact_type: contact_type
        }
        //console.log("dissocier data : ", data);
        fetch('/wp-admin/admin-ajax.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Cache-Control': 'no-cache',
            },
            body: new URLSearchParams(data)
        })
            .then(response => response.json())
            .then(response => {
                //console.log(response)
                if (response.success) {
                    getContactMeubles(contact).then(meubles => {
                        setContactMeubles(meubles);
                        //btn supprimer contact
                        if (meubles && meubles.length > 0) {
                            document.querySelector(".delete_contact").disabled = true;
                        } else {
                            document.querySelector(".delete_contact").disabled = false;
                        }

                    });
                }
            });
    }

    function associer() {

        const meubleSelected = selectMeuble.current ? selectMeuble.current.state.selectValue[0].value : null;
        //const meubleSelected = document.getElementById("select_meubles").value;
        const contactTypeSelected = document.getElementById("contact_type").value;
        //console.log("associer", contact.id, meubleSelected);
        const data = {
            action: 'associer_contact_meuble',
            nonce: document.getElementById('nonce_associer_contact_meuble').value,
            id_contact: contact.id,
            id_meuble: meubleSelected,
            contact_type: contactTypeSelected
        }

        fetch('/wp-admin/admin-ajax.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Cache-Control': 'no-cache',
            },
            body: new URLSearchParams(data)
        })
            .then(response => response.json())
            .then(response => {
                //console.log(response);
                if (response.success) {

                    getContactMeubles(contact).then(meubles => {
                        setContactMeubles(meubles);
                        //rafraichi le select choix des meubles à associer
                        chargeMeublesSaufContactType();
                    });
                }
            });
    }

    function chargeMeublesSaufContactType() {
        const contactTypeSelected = document.getElementById("contact_type").value;

        const data = {
            action: 'liste_meubles_sauf_profil',
            contact_type: contactTypeSelected
        }
        fetch('/wp-admin/admin-ajax.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Cache-Control': 'no-cache',
            },
            body: new URLSearchParams(data)
        })
            .then(response => response.json())
            .then(response => {
                if (response.success) {
                    console.log(response.data.meubles);
                    const options = [];
                    options.push({value:"999999", label:"Créer un nouveau meublé"});
                    response.data.meubles.map( meuble =>
                        options.push( {value: meuble.id, label: ((meuble.titre)?meuble.titre:"")+"("+meuble.id+")"} )
                    );

                    setMeublesToSelect(options);

                    //setMeublesToSelect(response.data.meubles);
                    document.getElementById("associerAction").childNodes[0].nodeValue = "Créer & Associer";
                }
            });
    }

    useEffect(() => {
        getContactMeubles(contact).then(result => {
            setContactMeubles(result);
        });
    }, [])
    
    const customStyles = {
        container: provided => ({
            ...provided,
            width: 450
        })
    };
    
    return (
        <Panel title="Meublés associés à ce contact">
            
            <div className='table-responsive'>
                <table className="table table-hover align-middle">
                    <thead>
                        <tr>
                            <th className='py-3'>ID</th>
                            <th className='py-3'>Nom</th>
                            <th className='py-3'>Qualité</th>
                            <th className='py-3'>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {contactMeubles && contactMeubles.map(meuble =>
                            <tr key={meuble.id + meuble.contact_type}>
                                <th scope="row"><a href={`/meuble-edit?id=${meuble.id}`}>{meuble.identifiant?meuble.identifiant:meuble.id}</a></th>
                                <td>{meuble.titre}</td>
                                <td>{meuble.contact_type}</td>
                                <td>
                                    <button className='btn' type="button" onClick={() => dissocier(contact.id, meuble.id, meuble.contact_type)}>Dissocier</button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            <div className='d-flex flex-column gap-3'>
                <h4 className='h4'>Associer un meublé à ce contact</h4>
                <div className="d-flex gap-3 pb-5">
                    <div>
                        <label htmlFor="type" className="form-label">1. Rôle</label>
                        <select className='form-control' id="contact_type" onChange={() => chargeMeublesSaufContactType()}>
                            <option value="">1 - Choisir le rôle</option>
                            {contactTypes.map(type =>
                                <option key={type} value={type}>{type}</option>
                            )}
                        </select>
                    </div>
                    
                    {meublesToSelect && <div className='d-flex gap-3 align-items-end'>
                        <div id="div_select_meuble">
                            <label htmlFor="meubles" className="form-label">2. Choisir un meublé OU le créer (vérifier si non présent dans la liste)</label>

                            <Select
                                options={meublesToSelect} id={"select_meubles"}
                                ref={selectMeuble}
                                placeholder="Choisir"
                                isClearable={true}
                                isSearchable={true}
                                className="form-control"
                                styles={customStyles}
                                menuPlacement="top"
                            />
                        </div>
                        
                        <div className="col" id="div_associer_button">
                            <button className='btn' id='associerAction' type="button" onClick={() => valideButton()}>Créer & Associer</button>
                        </div>
                    </div>}

                </div>
            </div>

        </Panel>
    );
}

export default ContactLiaisonMeuble;