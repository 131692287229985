import { useEffect , useState } from '@wordpress/element';
import config from "@config";
import { UserProvider, FilterProvider } from '@context';
import TableArchivesMeubles from './table-meubles';
import PageTitle from '@pages/page-title';
import ButtonArchiveActionsGroups from "@composants/button/button-archive-actions-groups";

const getArchivesMeubles = async (idUser) => {

    const url = config.api(idUser).get.archives_meubles;

    return await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Cache-Control': 'no-cache',
        },
    })
        .then(response => response.json())
        .then(response => {
            if (response.success) {
                //console.log(response.meubles);
                if(response.data.meubles) {
                    return [...response.data.meubles];
                }
            }
        });
}

const PageArchivesMeubles = (idUser) => {

    const [meubles, setMeubles] = useState([]);
    const [exportIds, setExportIds] = useState([]);

    useEffect(() => {
        getArchivesMeubles(idUser.idUser).then(result => {
            setMeubles(result);
        });
    }, [])

    const meublesFormated = meubles.map(meuble => {
        return {...meuble,
            date_update: new Date(meuble.date_update),
            archivage_date: new Date(meuble.archivage_date),
            adresse: {
                rue: meuble.adresse1,
                complement: meuble.adresse2,
                cp: meuble.code_postal,
                commune: meuble.commune
            },
        }
    })

    const onSelection = async (selection) => {
        setExportIds(selection);
    }

    return(
        <UserProvider>
            <FilterProvider>
                <div className='content-wrapper'>
                    <header className="entry-header">
                        <div className='d-flex w-100 justify-content-between'>
                            <PageTitle exportIds={exportIds} entite='meuble' />
                            <div className='d-flex align-items-center gap-3'>
                                <ButtonArchiveActionsGroups selectionEntities={exportIds} entity="meubles" status="archive" exporter='ajax' />
                            </div>
                        </div>
                    </header>
                    <div className="entry-content">
                        <TableArchivesMeubles meubles={meublesFormated} selectionHandler={onSelection} />
                    </div>
                </div>
            </FilterProvider>
        </UserProvider>
    )
}

export default PageArchivesMeubles;