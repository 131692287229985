import Modal from 'react-bootstrap/Modal';
import { Button } from '../button';
import { ReactComponent as IconClose } from '@images/close.svg';

import './modale.scss';

const Modale = ({ show, hideHandler, size='', title='', dialogClass, children, save }) => {

    return(
        <Modal show={show} onHide={hideHandler} size={size} dialogClassName={dialogClass}>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
                <button className='btn-close' data-bs-dismiss="modal" aria-label="Close">
                    <IconClose onClick={hideHandler} />
                </button>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline" name="cancel" onClick={hideHandler}>Annuler</Button>
                {save && <Button name="save" onClick={hideHandler}>Enregistrer</Button>}
            </Modal.Footer>
        </Modal>
    )
}

export default Modale;