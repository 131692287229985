import { useState } from '@wordpress/element';
import { ListGroup } from 'react-bootstrap';
import { useUtiles } from '@hooks';
import { Modale } from '@composants';
import { ReactComponent as IconSearch } from '@images/search.svg';

const InseeInput = (codeInsee2) => {

    console.log("codeInsee:", codeInsee2.codeInsee);
    const { removeAccents } = useUtiles();

    const [codeInsee, setCodeinsee] = useState(codeInsee2.codeInsee);
    const [communesData, setCommunesData] = useState();

    const communeInput = document.getElementById('commune');
    const codePostalInput = document.getElementById('code_postal');

    const cleanValue = (value) => {
        let nextValue = value.replace(/\s+/g,'');
        return removeAccents(nextValue).toLowerCase();
    }

    const onCommune = (commune) => {
        setCodeinsee(commune.code);
        communeInput.value = commune.nom;
        setCommunesData(null);
    }

    const onCode = (event) => {
        setCodeinsee(event.target.value);
    }

    const lookForCommunes = async() => {
        
        /** Récupération de la valeur du champ code postal */
        const codePostal = cleanValue(codePostalInput.value);

        /** Afficher l'erreur si le code postal est vide */
        if(codePostal === '') {
            codePostalInput.focus();
            codePostalInput.classList.add('is-invalid');
            return false;
        } else {
            codePostalInput.classList.remove('is-invalid');
        }

        /** Appeler l'api pour récupérer les codes INSEE selon le code postal */
        const response = await fetch(`https://geo.api.gouv.fr/communes?codePostal=${codePostal}`);

        /** récupérer le tableau des communes */
        const communes = await response.json();

        /** Si le tbaleau des communes est vide, avertir l'utilisateur */
        if(!communes || !communes.length){
            setCommunesData(<p className='text-danger mb-0'>Le code postal {codePostal} n'est pas reconnu !</p>);
            return;
        }

        /** Liste des communes à afficher dans la modale */
        const communesList = <>
            <p>Choisir une commune ci-dessous</p>
            <ListGroup>
                {communes.map(commune => <ListGroup.Item key={commune.code} action onClick={() => onCommune(commune)}>
                    {commune.nom}
                </ListGroup.Item>)}
            </ListGroup>
        </>;

        setCommunesData(communesList);
    }

    return(
        <>
            <label htmlFor="code_insee" className="form-label">Code Insee *</label>
            <div className="input-group">
                <input
                    type="text" 
                    id="code_insee" 
                    name="code_insee" 
                    className="form-control" 
                    value={codeInsee}
                    onChange={onCode}
                    placeholder="Code insee" 
                    aria-label="Code insee" 
                    aria-describedby="button-api-insee" 
                    required 
                />
                <button className="btn" type="button" id="button-api-insee" onClick={lookForCommunes}>
                    <IconSearch width="24" height="24" />
                </button>
            </div>
            {communesData && <Modale 
                show={communesData} 
                title="Choisir une commune" 
                children={communesData} 
                hideHandler={() => setCommunesData(null)} 
            />}
        </>
    );

};

export default InseeInput;