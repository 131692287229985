import { useContext } from '@wordpress/element';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

import { DataContext } from '@context';
import { ButtonDropdown } from '@composants/button';
import { useOnlineStatus, useMeubles } from '@hooks';

const CellMeubles = ({ contact }) => {

    /**
     * Récupération des meublés disponibles dans le contexte
     */
    const { meubles } = useContext(DataContext);
    
    /**
     * Chargement des meublés liés au contact
     */
    const { getContactMeubles } = useMeubles();
    const contactMeubles = getContactMeubles(contact, meubles);

    /**
     * 
     * @param {string} id 
     * 
     * Ouvre le lien vers un meublé
     */
    const onMeuble = (id) => {
        window.location.href = '/meubles/meuble-voir?id=' + id;
    }

    const isOnline = useOnlineStatus();

    const onNouveauMeuble = () => {
        window.location = '/meuble-edit?c-lk=1&c='+contact.id+'&ct=Propriétaire';
    }

    /**
     * 
     * @param {Object} props 
     * @returns <Popover />
     */
    const renderPopover = (props) => (
        <Popover className='popover-meubles' {...props}>
            <Popover.Body>
                {(contactMeubles.length > 0) && contactMeubles.map(meuble => {
                    const { id, identifiant, titre } = meuble;
                    const meubleContact = contact.meubles.find(m => m.id === meuble.id);
                    return(
                        <div className='cell-item d-flex flex-column gap-1 p-2' key={id} onClick={(e) => onMeuble(id)}>
                            <strong className='call-id'>{identifiant}</strong>
                            <span className='call-titre'>{titre}</span>
                            {meubleContact && <strong className='cell-contact'>{meubleContact.contact_type}</strong>}
                        </div>
                    )
                })}
                {(contactMeubles.length === 0) && <div className='d-flex flex-direction-column gap-1 p-3 text-center no-click'>Ce contact n'est lié à aucun meuble</div>}
                <div className='cell-item d-flex flex-column gap-1 p-2' key={999}>
                    <a className='text-light' title='Nouveau meublé' href={`/meuble-edit?c-lk=1&c=${contact.id}&ct=Propriétaire`} onClick={onNouveauMeuble} disabled={!isOnline}>Nouveau meublé</a>
                </div>
            </Popover.Body>
        </Popover>
    );

    if(contactMeubles.length < 1) {
        return(
            <div className="cell cell-inner cell-meuble">
                <button className='btn text-nowrap' title='Nouveau meublé' onClick={onNouveauMeuble} disabled={!isOnline}>Nouveau meublé</button>
            </div>
        );
    }else{
        return (
            <div className="cell cell-actions cell-inner cell-dropdown cell-meuble">
                <OverlayTrigger placement='auto' trigger='click' overlay={renderPopover} rootClose={true}>
                    <ButtonDropdown key={contact.id} variant="outline text-nowrap">
                        {contactMeubles.length} meublé{(contactMeubles.length > 1) && 's'}
                    </ButtonDropdown>
                </OverlayTrigger>
            </div>
        )
    }
}

export default CellMeubles;