import Modale from "./modale";
import ModaleCommentaire from "./modale-commentaire";
import ModaleRevision from "./modale-revision";
import ModaleMeubles from "./modale-meubles";
import ModaleDelete from "./modale-delete";
import ModaleSceller from "./modale-sceller";
import ModaleExport from "./modale-export";
import ModaleArchivageAction from "./modale-archivage-action.js";

export {
    Modale, 
    ModaleCommentaire,
    ModaleRevision,
    ModaleMeubles, 
    ModaleDelete, 
    ModaleSceller, 
    ModaleExport,
    ModaleArchivageAction, 
}