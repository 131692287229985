import React, { useMemo, useEffect , useState } from 'react';
import {ToastContextProvider, useUser, VisitesProvider} from '@context';
import { createColumnHelper } from "@tanstack/react-table";
import { Classement, TableEntite, Cell, CellVisites } from '@composants';
import { ReactComponent as MaisonIcon } from '@images/maison.svg';
import { ReactComponent as AppartementIcon } from '@images/appartement.svg';
import {ButtonHelp} from '@composants/button';
import { CheckboxSelection } from '@composants/form';
import { useFilters } from '@hooks';

const columnHelper = createColumnHelper();
const { classementFilter, dateRangeFilter } = useFilters();

const TableRevisions = ({ meubles, selectionHandler }) => {

    // Tri par défaut par date_update
    meubles.sort((a,b) => new Date(b.date_update) - new Date(a.date_update) );
    const [rowSelection, setRowSelection] = useState({});

    const columns = useMemo(() => [
        {
            id: 'select',
            header: ({ table }) => (
                <CheckboxSelection {...{
                    checked: table.getIsAllRowsSelected(),
                    indeterminate: table.getIsSomeRowsSelected(),
                    onChange: table.getToggleAllRowsSelectedHandler(),
                }} />
            ),
            cell: ({ row }) => (
                <Cell className="cell-select">
                    <CheckboxSelection {...{
                        checked: row.getIsSelected(),
                        indeterminate: row.getIsSomeSelected(),
                        onChange: row.getToggleSelectedHandler(),
                    }} />
                </Cell>
            )
        },
        columnHelper.display({
            id: 'vignette',
            cell: props => <Cell className="vignette meuble" link={`/meubles/meuble-edit?id=${props.row.original.id}`}>
                {props.row.original.url_vignette ?
                    <img src={props.row.original.url_vignette} /> :
                    props.row.original.type === 'Maison' ? <MaisonIcon /> : <AppartementIcon />
                }
            </Cell>
        }),
        columnHelper.accessor(row => `${row.titre} ${row.identifiant} ${row.id}`, {
            id: 'identification',
            header: 'Identification',
            cell: props => {
                const original = props.row.original;
                return <Cell link={`/meubles/meuble-edit?id=${original.id}`}>
                    <span className='text-nowrap'>{original.identifiant?original.identifiant:original.id}</span>
                    <strong>{original.titre}</strong>
                </Cell>
            }
        }),
        columnHelper.accessor('classement', {
            header: 'Classement',
            cell: props => {
                const data = props.row.original;
                let date_classement = "";
                if( props.row.original.date_classement !== null && new Date(props.row.original.date_classement) != 'Invalid Date' ){
                    date_classement = new Date(props.row.original.date_classement).toLocaleDateString();
                }

                return <Cell link={`/meubles/meuble-edit?id=${data.id}`}>
                    <Classement count={data.classement} />
                    {date_classement.length > 0 ? date_classement:''}
                </Cell>
            },
            filterFn: (row, columnId, value) => classementFilter(row, columnId, value)
        }),
        /*columnHelper.accessor(row => `${row.search}`, {
            header: 'Contact',
            cell: props => {
                const url = `/meubles/meuble-edit?id=${props.row.original.id}`;
                const contacts = props.row.original.contacts;
                const children = contacts && contacts.length > 0 ?
                    <div className='d-flex flex-column'>{contacts.map(c => <span key={c.nom}>{c.civilite} {c.prenom} {c.nom} ({c.contact_type})</span>)}</div> :
                    <span>Ce meublé n'a pas de contact</span>;
                return <Cell link={url} className='cell-contact'><span className='line-clamp'>{children}</span></Cell>
            }
        }),*/
        columnHelper.accessor(row => `${row.adresse.rue} ${row.adresse.complement} ${row.adresse.cp} ${row.adresse.commune}`, {
            header: 'Adresse',
            cell: props => {
                const original = props.row.original.adresse;
                return <Cell className='cell-adresse' link={`/meubles/meuble-edit?id=${props.row.original.id}`}>
                    <span className='caption text-nowrap'>{original.rue}</span>
                    {original.complement && <span className='caption'>{original.complement}</span>}
                    <span className='caption text-nowrap'>{original.cp} {original.commune}</span>
                </Cell>
            }
        }),
        columnHelper.accessor('date_update', {
            header: 'Date édition',
            cell: props => {
                const data = props.row.original;
                const dateString = data.date_update.toLocaleDateString();
                const userName = data.user_update_name;
                return <Cell className='cell-inspecteur caption'>
                    <div className='d-flex flex-column'>
                        <span>Le {dateString}</span>
                        {userName ? <span>par {userName}</span> : null}
                    </div>
                </Cell>
            },
            filterFn: (row, columnId, value) => dateRangeFilter(row, columnId, value)
        }),
        columnHelper.accessor('archivage_date', {
            header: 'Date archivage',
            cell: props => {
                return <Cell className='cell-inspecteur caption'>
                    <div className='d-flex flex-column'>
                        <span>Le {props.row.original.archivage_date.toLocaleDateString()}</span>
                        {props.row.original.archivage_user ? <span>par {props.row.original.archivage_user}</span> : null}
                        <ButtonHelp help={props.row.original.archivage_raison?props.row.original.archivage_raison:'Aucune information'} className="orange" />
                    </div>
                </Cell>
            },
            filterFn: (row, columnId, value) => dateRangeFilter(row, columnId, value)
        }),
    ]);

    useEffect(() => {
        const meublesToAction = Object.keys(rowSelection).map(key => meubles[key].id);
        selectionHandler(meublesToAction);
    }, [rowSelection]);

    return(
        <TableEntite data={meubles} columns={columns} rowSelection={rowSelection} selectionHandler={setRowSelection} className="hover" />
    )
}

export default TableRevisions;