import PageInformationsGenerales from './page-informations-generales';
import PageGrille from './page-grille';

const Annexe2 = ({ visite, meuble, contacts, inspecteur }) => {

    return(
        <>
            <PageInformationsGenerales 
                visite={visite} 
                meuble={meuble} 
                contacts={contacts} 
                inspecteur={inspecteur} 
            />

            <PageGrille
                visite={visite} 
                meuble={meuble} 
                contacts={contacts} 
                inspecteur={inspecteur} 
            />
        </>
    );
}

export default Annexe2