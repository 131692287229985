import { useState } from '@wordpress/element';
import { useVisites, UserProvider, FilterProvider } from '@context';
import PageTitle from '@pages/page-title';
import TableVisites from './table-visites';
import { ModaleExport } from '@composants';
import { ButtonExportCsv } from '@composants/button';
import { EXPORT_VISITE_COLONNES } from '@constantes';
import ButtonArchiveActionsGroups from "@composants/button/button-archive-actions-groups";

const PageVisites = () => {

    const { isLoading, visites, contacts, meubles } = useVisites();

    const [exportIds, setExportIds] = useState([]);
    const [showExport, setShowExport] = useState(false);

    if (isLoading || !visites) return <div className='text-center p-5'>
        <h3 className='text-moyen'>Chargement des visites…</h3>
    </div>

    const formatedVisites = [...visites].reduce((result, visite) => {
        const meuble = meubles.find(m => m.id === visite.meuble_id);
        const proprio = contacts.find(c => c.meubles.find(cm => cm.id === meuble?.id && cm.contact_type === 'Propriétaire'));
        const demandeur = visite.demandeur ? visite.demandeur : proprio ? proprio : null;

        result.push({
            uid: visite.uid,
            nom: meuble?.titre,
            meuble: meuble,
            type: meuble?.type,
            num_classement: meuble?.num_classement,
            user_create: visite.user_create,
            vignette: meuble?.vignette_url,
            comment: visite.infos.comment,
            identification: { uid: visite.uid, titre: meuble?.titre, date_scelle: visite.date_scelle },
            contact: demandeur && `${demandeur.civilite}${demandeur.prenom ? ' ' + demandeur.prenom : ''} ${demandeur.nom}`,
            date_visite: new Date(visite.date_visite),
            date_update: new Date(visite.date_update),
            commune: { nom: meuble?.commune, cp: meuble?.code_postal },
            classement: visite.infos.classement_obtenu ? visite.infos.classement_obtenu : 0,
            scelle: visite.scelle,
            capacite: visite.infos.capacite,
            hasLoueur: demandeur?.role === 'Propriétaire' || !!proprio,
        });
        return result;
    }, []);

    const onSelection = async (selection) => {
        setExportIds(selection);
    }

    return (
        <FilterProvider>
            <div className='content-wrapper'>
                <header className="entry-header">
                    <div className='d-flex align-items-center justify-content-between w-100'>
                        <PageTitle exportIds={exportIds} entite='visite' />
                        <div className='actions d-flex align-items-center gap-3'>
                            <ButtonArchiveActionsGroups selectionEntities={exportIds} entity="visites" status="publie" />
                            <UserProvider>
                                <ButtonExportCsv selection={exportIds} type='visite' />
                            </UserProvider>
                        </div>
                    </div>
                </header>
                <div className="entry-content">
                    {visites.length > 0 ?
                        <TableVisites visites={formatedVisites} selectionHandler={onSelection} /> :
                        <div className='p-5 text-center h-50 d-flex flex-column justify-content-center align-items-center pb-5'>
                            <h4 className='h4 mb-3'>Vous n'avez aucune visite</h4>
                            <p className='font-body text-moyen'>Pour faire une nouvelle visite, <a href="/meubles">Choisir un meublé</a> et cliquer sur Nouvelle visite.</p>
                        </div>
                    }
                </div>
                {(exportIds && exportIds.length > 0) && <ModaleExport show={showExport} setShow={setShowExport} ids={exportIds} entity="visites" defaultCols={EXPORT_VISITE_COLONNES} />}
            </div>
        </FilterProvider>
    )
}

export default PageVisites;