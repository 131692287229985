const VisiteUserDates = ({ visite }) => {

    const dateCreate = new Date(visite.date_create).toLocaleDateString();
    const dateUpdate = new Date(visite.date_update).toLocaleDateString();

    return(
        <div className='d-flex gap-1'>
            {visite.user_create_name && <span className="caption text-moyen" style={{lineHeight: '1rem'}}>Créée le {dateCreate} par {visite.user_create_name}.</span>}
            {visite.user_update_name && <span className="caption text-moyen" style={{lineHeight: '1rem'}}>Modifiée {dateUpdate} par {visite.user_update_name}.</span>}
        </div>
    )
}

export default VisiteUserDates;