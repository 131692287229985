import { useState, useContext } from '@wordpress/element';
import { Alert } from 'react-bootstrap';
import {useVisite, UserContext} from '@context';
import VisiteResultats from '@pages/visite-edit/visite-edit-body/visite-resultats';
import VisiteGrille from '@pages/visite-edit/visite-edit-body/visite-grille';
import { VisiteDocuments } from '@pages/visite-voir';
import { ButtonDecision } from '@composants/button';

import './visite-voir-body.scss';

const VisiteVoirBody = () => {

    const { user } = useContext(UserContext);
    const { visite, meuble } = useVisite();
    const [currentPage, setCurrentPage] = useState('resultats');

    if(!meuble && visite) return <div className='p-5 text-moyen text-center'>
        <Alert variant="danger">
            <p>Cette visite n'a pas de meublé, elle ne peut être consultée.</p>
            <a href='/visites/'>Revenir à la liste des visites.</a>
        </Alert>
    </div>;

    if(!user) return null;
    if(!visite) return null;

    return(
        <div className="visite-body visite-voir-body">
            <div className='visite-navigation gap-3'>
                <div className='tabs gap-2'>
                    <button className={`btn${currentPage === 'resultats' ? ' active' : ' outline'}`} onClick={() => setCurrentPage('resultats')}>Résultats</button>
                    <button className={`text-nowrap btn${currentPage === 'grille' ? ' active' : ' outline'}`} onClick={() => setCurrentPage('grille')}>Grille de classement</button>
                    <button className={`btn${currentPage === 'documents' ? ' active' : ' outline'}`} onClick={() => setCurrentPage('documents')}>Documents</button>
                </div>
                <div className='actions d-flex align-items-center gap-2'>
                    <ButtonDecision />
                </div>
            </div>
            <div className='visite-content'>
                {currentPage === 'resultats' && <div className='p-3'>
                    <VisiteResultats />
                </div>}
                {currentPage === 'grille' && <div className='p-3'>
                    <VisiteGrille />
                </div>}
                {currentPage === 'documents' && <div className='p-3'>
                    <VisiteDocuments />
                </div>}
            </div>
        </div>
    )

}

export default VisiteVoirBody;