const CellCoordonnees = (props) => {

    const { email, telephone, portable } = props;

    return (
        <div className="cell cell-inner cell-coordonnees">
            {email && <a href={`mailto:${email}`}>{email}</a>}
            {telephone && <a href={"tel:" + telephone}>{telephone}</a>}
            {portable && <a href={"tel:" + portable}>{portable}</a>}
        </div>
    )
}

export default CellCoordonnees;