import { useState, useContext, useRef, useMemo } from '@wordpress/element';
import { DataContext, FilterProvider } from '@context';
import { useMeubles, useContacts, useOnlineStatus } from '@hooks';
import TableMeubles from './table-meubles';
import PageTitle from '@pages/page-title';
import { SelectInput } from '@composants/form';
import { ButtonArchiveActionsGroups } from "@composants/button";

const selectOptions = [{
    label: "Tous les meublés",
    value: 'tous'
}, {
    label: "Meublés à revisiter dans les 6 mois",
    value: 'sixmois'
}, {
    label: "Meublés dont la date de décision est obsolète",
    value: 'cinqans'
}, {
    label: "Meublés sans classement",
    value: 'sans'
}];

const PageMeubles = () => {

    const { isLoading, meubles, visites } = useContext(DataContext);
    const { getMeublesSansClassement, getMeublesRevisiterSixmois, getMeublesPlusDeCinqAns } = useMeubles();
    const { getMeubleContacts } = useContacts();
    const isOnline = useOnlineStatus();

    const tableRef = useRef();
    const [currentFilter, setCurrentFilter] = useState('tous');
    const [exportIds, setExportIds] = useState([]);

    const onFiltre = (event) => {
        setCurrentFilter(event.target.value);
        setExportIds([]);
        tableRef.current.reset();
    }

    const onSelection = async (selection) => {
        setExportIds(selection);
    }

    const getMeublesFormated = (meublesToFormat) => {
        return meublesToFormat.map(meuble => {

            const contactsMeuble = getMeubleContacts(meuble);
            const contactsSearch = contactsMeuble.map(c => `${c.prenom} ${c.nom} `);

            return {...meuble,
                date_update: new Date(meuble.date_update),
                contacts : contactsMeuble,
                search: contactsSearch.join(' '),
                visites : visites.filter(visite => visite.meuble_id === meuble.id),
                adresse: {
                    rue: meuble.adresse1,
                    complement: meuble.adresse2,
                    cp: meuble.code_postal,
                    commune: meuble.commune
                },
            }
        });
    }

    const meublesFormated = useMemo(() => {
        if(meubles && visites){

            // Tri des meublés par défaut par date_update
            meubles.sort((a,b) => new Date(b.date_update) - new Date(a.date_update) );

            const listeMeubles = {
                'tous': meubles,
                'sixmois': getMeublesRevisiterSixmois(meubles),
                'cinqans': getMeublesPlusDeCinqAns(meubles),
                'sans': getMeublesSansClassement(meubles)
            };

            return {
                'tous': getMeublesFormated(listeMeubles.tous),
                'sixmois': getMeublesFormated(listeMeubles.sixmois),
                'cinqans': getMeublesFormated(listeMeubles.cinqans),
                'sans': getMeublesFormated(listeMeubles.sans),
            };

        }
    }, [meubles, visites]);

    if(isLoading || !meubles || !visites) return <div className='text-center p-5'><h3 className='h3 text-moyen'>Chargement des meublés</h3></div>;

    return (
        <FilterProvider>
            <div className='content-wrapper'>
                <header className="entry-header">
                    <div className='d-flex w-100 justify-content-between'>
                        <div className='d-flex align-items-center gap-2'>
                            <PageTitle exportIds={exportIds} entite='meuble' />
                            <SelectInput values={selectOptions} value={currentFilter} name="filtre_meubles" className="md select-filters" handler={onFiltre} />
                        </div>
                        <div className='d-flex align-items-center gap-3'>
                            <ButtonArchiveActionsGroups selectionEntities={exportIds} entity="meubles" status="publie" exporter={true} />
                            <button className='btn' title='Nouveau meublé' onClick={() => window.location = '/meubles/meuble-edit/'} disabled={!isOnline}>Nouveau meublé</button>
                        </div>
                    </div>
                </header>
                <div className="entry-content">
                    <TableMeubles ref={tableRef} meubles={meublesFormated[currentFilter]} handler={onSelection} />
                </div>
            </div>
        </FilterProvider>
    );

}

export default PageMeubles;