import { Vignette } from '@composants';

const ContactItemMeuble = ({ contact }) => {

    const contactUrl = `/contacts/contact-voir/?id=${contact.id}`;

    return (
        <div className="entite-item square contact-item" onClick={ () => window.location=contactUrl }>
            <div className="d-flex">
                <Vignette variant="contact">{contact.vignette_url && <img src={contact.vignette_url} />}</Vignette>
                <div className="body d-flex gap-3">
                    <div className='identification d-flex flex-column justify-content-center h-100'>
                        <strong className='nom'>{contact.civilite} {contact.prenom} {contact.nom}</strong>
                        <span>{contact.role}</span>
                    </div>
                    <div className='adresse d-flex flex-column justify-content-center h-100'>
                        <span>{contact.adresse1}</span>
                        {contact.adresse2 && <span>{contact.adresse2}</span>}
                        {contact.adresse3 && <span>{contact.adresse3}</span>}
                        <span>{contact.code_postal} {contact.commune}</span>
                    </div>
                    <div className='coordonnees d-flex flex-column justify-content-center h-100'>
                        <a href={`mailto:${contact.email}`} title="Envoyer un e-mail">{contact.email}</a>
                        <a href={`tel:${contact.telephone}`} title="Téléphoner">{contact.telephone}</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactItemMeuble;