import { useState } from '@wordpress/element';
import { Panel } from '@composants';
import { Alert } from 'react-bootstrap';

const news = [
    /*{
        type: 'success', // primary, secondary, success, danger, warning, info, light, dark
        title: '[NOUVEAU] Tableau de bord : les visites à effectuer',
        body: <>
            <p>Dans cette page, un nouveau bloc affiche les meublés à visiter (sans date de décision) ainsi que les meublés dont la date de décision a plus de 4,5 années.</p>
        </>,
    },*/
    {
        type: 'success', // primary, secondary, success, danger, warning, info, light, dark
        title: 'Nouvelle version 1.4.0 🤗',
        body: <>
            <ul>
                <li>Fonctionnalité : retrouver toute l'historisation des actions effectuées sur vos contacts, meublés et
                    visites (création, modification, suppression, archivage,
                    restauration, scellage...).<br/>
                    N'hésitez pas à renseigner les commentaires, l'information est la clé du succès !<br/>
                    <img className="wp-image-2131"
                         src="https://classement.clevacances.com/wp-content/uploads/historisation.png"
                         alt=""/>
                    <img className="wp-image-2132"
                         src="https://classement.clevacances.com/wp-content/uploads/Capture-decran-2024-07-01-a-11.52.08.png"
                         alt=""/><br/><br/>
                    Ps : une seule modification par jour et par entité est enregistrée afin de ne pas charger
                    l'historique.
                </li>
            </ul>
        </>,
    },
    /*{
        type: 'success', // primary, secondary, success, danger, warning, info, light, dark
        title: 'Nouvelle version 1.2.0 🤗',
        body: <>
            <ul>
                <li>Fonctionnalité : recherche étendue au nom / prénom d'un propriétaire ou mandataire lors de la duplication d'une visite</li>
                <li>Fonctionnalité : à la création ou duplication d'une visite, le demandeur est automatiquement sélectionné si un seul contact (propriétaire ou mandataire) est lié au meublé</li>
                <li>Fonctionnalité : plusieurs contacts peuvent avoir la même adresse email</li>
                <li>Correctif : dans la décision de classement, affichage de la date de décision si la visite est scellée.</li>
            </ul>
        </>,
    },*/
    /*{
        type: 'success', // primary, secondary, success, danger, warning, info, light, dark
        title: '[NOUVEAU] Assistance à la saisie de la grille de classement',
        body: <>
            <p>Afin de faciliter la saisie de la grille de classement, vous pouvez :</p>
            <ul>
                <li>naviguer d'un critère à l'autre grâce à la touche "Tabulation"</li>
                <li>valider / invalider un critère grâce à la touche "Entrée"</li>
            </ul>
        </>,
    }*/
]

const Actualites = () => {

    const [actus, setActus] = useState(news);

    if(!actus) return null;

    return (
        <Panel title="Actualités" contentClassName='gap-2'>
            {actus.map(actu => <Alert key={actu.title} variant={actu.type} className='mb-0'>
                <Alert.Heading className='mb-3'>{actu.title}</Alert.Heading>
                {actu.body}
            </Alert>)}
        </Panel>
    );
}

export default Actualites;