import { Page, Text, View, Image, StyleSheet } from '@react-pdf/renderer';
import { CIVILITES } from '@constantes';
import LettreBottom from './lettre-bottom';

const styles = StyleSheet.create({
    texte: {
        marginBottom: '4mm',
        textAlign: 'justify',
        textIndent: '25mm',
    }
});

const LettreDefavorable = ({ visite, meuble, inspecteur, contacts }) => {

    const logotypeUrl = inspecteur.organisme_logo || "/wp-content/uploads/logotype-clevacances.png";
    const { demandeur } = visite;
    const initiales = `${inspecteur.first_name.charAt(0)}${inspecteur.last_name.charAt(0)}`;
    const dateVisiteLong = new Date(visite.date_visite).toLocaleDateString('fr-FR', { day: 'numeric', month: 'long', year: 'numeric' });
    const { adresse1, adresse2, code_postal, commune } = meuble;

    const proprietaire = contacts.loueur;
    const isPersonnePhysique = CIVILITES.includes(proprietaire.civilite.trim()) && proprietaire.id !== demandeur.id;

    return (
        <Page size="A4" style={{ padding: '10mm 10mm 20mm 10mm', fontFamily: 'Lato', fontSize: '12pt' }}>

            <View style={{ maxWidth: '65mm', maxHeight: '35mm' }}>
                <Image src={logotypeUrl} style={{ objectFit: 'contain', maxHeight: '35mm', width: '100%', height: '100%' }} />
            </View>

            <View style={{ paddingLeft: '55%', marginBottom: '15mm' }}>
                <View>
                    <View>
                        <Text style={{fontWeight: 'bold'}}>{demandeur.civilite} {demandeur.prenom} {demandeur.nom}</Text>
                        {(isPersonnePhysique && demandeur.nom !== proprietaire.nom) && <Text>{proprietaire.civilite} {proprietaire.prenom} {proprietaire.nom}</Text>}
                    </View>
                    {demandeur.adresse1 ? <Text>{demandeur.adresse1}</Text> : null}
                    {demandeur.adresse2 ? <Text>{demandeur.adresse2}</Text> : null}
                    {demandeur.adresse3 ? <Text>{demandeur.adresse3}</Text> : null}
                    <Text>{demandeur.code_postal} {demandeur.commune}</Text>
                    <Text>{demandeur.pays}</Text>
                </View>
            </View>

            <View style={{ textAlign: 'center', marginBottom: '10mm' }}>
                <Text>{inspecteur.organisme_commune}, le {new Date().toLocaleDateString('fr-FR', { day: 'numeric', month: 'long', year: 'numeric' })}</Text>
            </View>

            <View style={{ marginBottom: '10mm', fontSize: '11pt' }}>
                <Text>Nos réf. : Clévacances/{initiales}</Text>
            </View>

            <View style={{ textAlign: 'left', marginBottom: '5mm' }}>
                <Text style={styles.texte}>Madame, Monsieur,</Text>
                <Text style={styles.texte}>Nous sommes venus visiter votre (vos) location(s) le {dateVisiteLong}, nous avons émis un rapport défavorable suite au non-respect des normes fixées par l’arrêté ministériel du 2 août 2010 modifié le 24 Novembre 2021, pour le(s) meublé(s) situé(s) au {adresse1}{adresse2 ? ` ${adresse2}` : ''}, {code_postal} {commune}. Sans nouvelle demande de classement de votre part depuis cet avis défavorable, nous sommes obligés de classer votre dossier sans suite.</Text>
                <Text style={styles.texte}>De plus, vous devez enlever les panonceaux de classement de vos Meublés de Tourisme, afin d’éviter tous risques de réclamation auprès de la Répression des Fraudes. Si votre location a toujours une vocation saisonnière, je vous rappelle également l’obligation de déclaration en Mairie de votre ou vos Meublé(s). En effet suite à la loi N°2009-888 du 22 juillet 2009 de développement et de modernisation des services touristiques et en application de l’article L324-1-1 du Code de Tourisme qui précise que toute personne qui offre à la location un meublé de tourisme doit en avoir préalablement fait la déclaration auprès du maire de la commune où est situé le meublé. En cas de non-respect de l’obligation de déclaration, un dispositif de sanctions sous la forme de contraventions de troisième classe est applicable.</Text>
                <Text style={styles.texte}>Je reste à votre disposition pour tout renseignement complémentaire et vous prie d’agréer, Madame, Monsieur, l’expression de mes salutations distinguées.</Text>
            </View>

            <View style={{ paddingLeft: '90mm' }}>
                <Text>{inspecteur.civilite} {inspecteur.first_name} {inspecteur.last_name}</Text>
                <Text>Chargé du classement des meublés</Text>
                {(inspecteur.file_signature !== '') && <View style={{height: "35mm"}}>
                    <Image style={{width: "100%", height: "100%", objectFit: "contain", objectPosition: 'top left'}} src={inspecteur.file_signature} />
                </View>}
            </View>

            <LettreBottom inspecteur={inspecteur} visite={visite} />
        </Page>
    )
}

export default LettreDefavorable;