import { useMemo, useState, useEffect, useRef, forwardRef, useImperativeHandle } from '@wordpress/element';
import { createColumnHelper } from "@tanstack/react-table";
import { useFilters } from '@hooks';
import { useFiltersDispatch } from '@context';
import { Classement, TableEntite, Cell, CellVisites } from '@composants';
import { CheckboxSelection } from '@composants/form';
import { ReactComponent as MaisonIcon } from '@images/maison.svg';
import { ReactComponent as AppartementIcon } from '@images/appartement.svg';
import { ButtonMeubleActions } from '@composants/button';

const columnHelper = createColumnHelper();
const { classementFilter, dateRangeFilter } = useFilters();

const TableMeubles = forwardRef(({ meubles, handler }, ref) => {

    const [rowSelection, setRowSelection] = useState({});
    const { setRowCount } = useFiltersDispatch();

    const columns = useMemo(() => [
        {
            id: 'select',
            header: ({ table }) => (
                <CheckboxSelection {...{
                    checked: table.getIsAllRowsSelected(),
                    indeterminate: table.getIsSomeRowsSelected(),
                    onChange: table.getToggleAllRowsSelectedHandler(),
                }} />
            ),
            cell: ({ row }) => (
              <Cell className="cell-select">
                <CheckboxSelection {...{
                    checked: row.getIsSelected(),
                    indeterminate: row.getIsSomeSelected(),
                    onChange: row.getToggleSelectedHandler(),
                }} />
              </Cell>
            )
        },
        columnHelper.display({
            id: 'vignette',
            cell: props => <Cell className="vignette meuble" link={`/meubles/meuble-voir?id=${props.row.original.id}`}>
                {props.row.original.vignette_url ? 
                    <img src={props.row.original.vignette_url} /> :
                    props.row.original.type === 'Maison' ? <MaisonIcon /> : <AppartementIcon />
                }
            </Cell>
        }),
        columnHelper.accessor(row => `${row.titre} ${row.identifiant} ${row.id}`, {
            id: 'identification',
            header: 'Identification',
            cell: props => {
                const original = props.row.original;
                return <Cell link={`/meubles/meuble-voir?id=${original.id}`}>
                    <span className='text-nowrap'>{original.identifiant?original.identifiant:original.id}</span>
                    <strong className='fst-italic'>{original.titre}</strong>
                </Cell>
            }
        }),
        columnHelper.accessor('classement', {
            header: 'Classement',
            cell: props => {
                const data = props.row.original;
                let date_classement = "";
                if( new Date(props.row.original.date_classement) != 'Invalid Date' ){
                    date_classement = new Date(props.row.original.date_classement).toLocaleDateString();
                }

                return <Cell link={`/meubles/meuble-voir?id=${data.id}`}>
                    <Classement count={data.classement} />
                    {date_classement.length > 0 ? date_classement:''}
                </Cell>
            },
            filterFn: (row, columnId, value) => classementFilter(row, columnId, value)
        }),
        columnHelper.accessor(row => `${row.search}`, {
            header: 'Contact',
            cell: props => {
                const url = `/meubles/meuble-voir?id=${props.row.original.id}`;
                const contacts = props.row.original.contacts;
                const children = contacts && contacts.length > 0 ? 
                    <div className='d-flex flex-column'>{contacts.map(c => <span key={c.nom}>{c.civilite} {c.prenom} {c.nom} ({c.contact_type})</span>)}</div> : 
                    <span>Ce meublé n'a pas de contact</span>;
                return <Cell link={url} className='cell-contact'><span className='line-clamp'>{children}</span></Cell>
            }
        }),
        columnHelper.accessor(row => `${row.adresse.rue} ${row.adresse.complement} ${row.adresse.cp} ${row.adresse.commune}`, {
            header: 'Adresse',
            cell: props => {
                const original = props.row.original.adresse;
                return <Cell className='cell-adresse' link={`/meubles/meuble-voir?id=${props.row.original.id}`}>
                    <span className='caption text-nowrap'>{original.rue}</span>
                    {original.complement && <span className='caption'>{original.complement}</span>}
                    <span className='caption text-nowrap'>{original.cp} {original.commune}</span>
                </Cell>
            }
        }),
        columnHelper.accessor('date_update', {
            header: 'Date édition',
            cell: props => {
                const data = props.row.original;
                const dateString = data.date_update.toLocaleDateString();
                const userName = data.user_update_name;
                return <Cell className='cell-inspecteur caption'>
                    <div className='d-flex flex-column'>
                        <span>Le {dateString}</span>
                        {userName ? <span>par {userName}</span> : null}
                    </div>
                </Cell>
            },
            filterFn: (row, columnId, value) => dateRangeFilter(row, columnId, value)
        }),
        columnHelper.display({
            id: 'actions',
            cell: props => {
                const data = props.row.original;
                return <CellVisites visites={data.visites} />
            }
        }),
        columnHelper.display({
            id: 'actions2',
            cell: props => {
                return <div className='cell cell-actions cell-inner'>
                    <ButtonMeubleActions meuble={props.row.original} />
                </div>
            }
        }),
    ]);

    const selectedRowsCount = useRef(0);

    useImperativeHandle(ref, () => ({
        reset() {
            setRowSelection({});
        }
    }));

    useEffect(() => {
        const keys = Object.keys(rowSelection);
        if(keys.length !== selectedRowsCount.current){
            selectedRowsCount.current = keys.length;
            const meublesToAction = keys.map(key => meubles[key].id);
            handler(meublesToAction);
        }
    }, [rowSelection, selectedRowsCount]);

    useEffect(() => {
        setRowCount(meubles.length);
    }, [meubles]);

    return(
        <TableEntite data={meubles} columns={columns} rowSelection={rowSelection} selectionHandler={setRowSelection} className="hover" />
    );
});

export default TableMeubles;