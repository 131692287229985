import Visites from "./visites";
import VisiteVoir from "./visite-voir";
import VisiteEdit from "./visite-edit";
import PageVisites from './page-visites';

export {
    Visites,
    VisiteVoir,
    VisiteEdit, 
    PageVisites, 
}