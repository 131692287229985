import { useState, useContext } from '@wordpress/element';
import Modal from 'react-bootstrap/Modal';
import { useFetch, useDB } from "@hooks";
import { UserContext, useToastContext } from "@context";
import { Button } from '@composants/button';
import { ReactComponent as IconClose } from '@images/close.svg';
import config from "@config";

const ModaleDuplicate = ({ show, setShow, uid }) => {

    const { duplicateEntity } = useFetch();
    const { user } = useContext(UserContext);
    const { addToast } = useToastContext();

    const [loading, setLoading] = useState(false);
    const url = config.api('', uid).get.meuble_duplicate;

    const onDuplicate = async () => {

        console.log(url);

        return await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Cache-Control': 'no-cache',
            },
            //body: new URLSearchParams(data)
        })
            .then(response => response.json())
            .then(response => {
                    console.log(response);
                if (response.success) {
                    addToast({
                        title: "Dupplication du meublé.",
                        message: response.data.message,
                        type: 'success'
                    });
                    setTimeout(() => {
                        window.location = '/meubles/'
                    }, 1000);
                }else {
                    addToast({
                        title: "Dupplication du meublé.",
                        message: duplicateRes.data.message,
                        type: 'danger'
                    });
                    setShow(false);
                }
            });


  /*      setLoading(true);
        const duplicateRes = await duplicateEntity(user.id, uid, "meuble");
        if( duplicateRes.success ){
            await db.meubles.duplicate(uid);
            addToast({
                title: "Suppression d'un meublé.",
                message: duplicateRes.data.message,
                type: 'success'
            });
            setTimeout(() => {
                window.location = '/meubles/'
            }, 1000);
        } else {
            addToast({
                title: "Suppression d'un meublé.",
                message: duplicateRes.data.message,
                type: 'danger'
            });
            setShow(false);
        }

        setLoading(false);*/
    }

    return(
        <Modal show={show} onHide={() => setShow(false)} scrollable>
            <Modal.Header>
                <Modal.Title>Dupliquer ce meublé {uid}</Modal.Title>
                <button className='btn-close' data-bs-dismiss="modal" aria-label="Close">
                    <IconClose onClick={() => setShow(false)} />
                </button>
            </Modal.Header>
            <Modal.Body className='text-center'>
                <p><strong>Êtes-vous sur de vouloir dupliquer ce meublé ?</strong></p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline" name="cancel" onClick={() => setShow(false)}>Annuler</Button>
                <Button name="save" onClick={onDuplicate} disabled={loading}>Dupliquer</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModaleDuplicate;