import { useEffect, useState, useRef } from '@wordpress/element';
import ToastMessage from './toast-message';

const ToastManager = ({ data }) => {

    const [toasts, setToasts] = useState([]);
    const currentIndex = useRef(0);

    useEffect(() => {

        const interval = setInterval(() => {

            if(toasts.length < data.length){
                const newItem = data[currentIndex.current];
                const nextToasts = [...toasts, newItem];
                currentIndex.current += 1;
                setToasts(nextToasts);
            } else {
                clearInterval(interval);
            }

        }, 1000);

        return () => clearInterval(interval);

    }, [data, toasts]);

    return (
        <>
            {toasts.map((item, index) => <ToastMessage key={`${item?.message}-${index}`} data={item} />)}
        </>
    );
}

export default ToastManager;