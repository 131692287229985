import { DataProvider, UserProvider, ToastContextProvider } from "@context";
import { PageMeubles } from "@meuble";

const Meubles = () => {

    return(
        <UserProvider>
            <ToastContextProvider>
                <DataProvider sync={true}>
                    <PageMeubles />
                </DataProvider>
            </ToastContextProvider>
        </UserProvider>
    );
}

export default Meubles;