import { useState, useEffect } from '@wordpress/element';
import { DataProvider, useVisite } from '@context';
import { useDB, useVisiteValide } from '@hooks';
import { OverlayTrigger, Tooltip, Form } from 'react-bootstrap';
import { ButtonDownloadPDF, Modale } from '@composants';
import { DOCUMENTS_DECISION } from '@constantes';

const ButtonDecision = () => {

    const { visite, inspecteur, contactsMeuble } = useVisite();
    const { getVisiteErrors } = useVisiteValide();
    const { db } = useDB();

    const [decision, setDecision] = useState(false);
    const [btnError, setBtnError] = useState();
    const [showModal, setShowModal] = useState(false);
    const [documents, setDocuments] = useState(DOCUMENTS_DECISION);

    /** Déterminer si la décision est favorable */
    const scores = visite.infos.scores;
    const ONC = visite.grille.filter(critere => critere.statut === 'ONC' && critere.checked);
    const favorable = (scores.c >= scores.b && ONC.length === 5) && (scores.h >= scores.g);
    
    const handleDecision = async () => {

        /** La date de décision de classement */
        let dateDecision = new Date();
        if(visite.scelle) {
            const dateValide = visite.date_decision ? visite.date_decision : visite.date_update;
            dateDecision = new Date(dateValide);
        }
        const dateString = dateDecision.toLocaleString('sv-SE');

        await db.visites.put({...visite, date_decision: dateString, date_update: dateString});
        visite.date_decision = dateString;
        setDecision(true);
    }
    
    const renderTooltip = (props) => (
        <Tooltip id="decision-tooltip" {...props}>
            <div className='d-flex flex-column gap-3 py-3'>
                {btnError.map(error => <span key={error}>{error}</span>)}
            </div>
        </Tooltip>
    );

    const renderBtnDecision = () => {
        
        if(btnError && btnError.length){
            return (
                <OverlayTrigger placement='auto' overlay={renderTooltip}>
                    <button key={btnError} className='btn outline text-nowrap' disabled={true}>Générer la décision de classement…</button>
                </OverlayTrigger>
            );
        }

        if(visite.scelle){
            return (
                <button className='btn outline text-nowrap' onClick={() => setShowModal(true)}>Générer la décision de classement…</button>
            );
        }

        const tooltip = <Tooltip id="tooltip">Date de décision actualisée au clic, car visite non encore scellée !</Tooltip>;
        return(
            <OverlayTrigger placement="left" overlay={tooltip}>
                <button className='btn outline text-nowrap' onClick={() => setShowModal(true)}>Générer la décision de classement…</button>
            </OverlayTrigger>
        );
    }

    const onHideModale = async (event) => {
        if(event?.target?.name === 'save'){
            await handleDecision();
        }
        setShowModal(false);
    }

    const onDocument = (document) => {
        const nextDocuments = [...documents];
        const nextDocument = nextDocuments.find(doc => doc.id === document.id);
        nextDocument.selected = !nextDocument.selected;
        setDocuments(nextDocuments);
    }

    const resetDecision = () => {
        setTimeout(() => {
            setDecision(false);
        }, 500);
    }

    useEffect(() => {

        if(visite && inspecteur) {
            getVisiteErrors({ visite, inspecteur, contactsMeuble })
            .then(visiteErrors => {
                setBtnError(visiteErrors);
            });

            if(!visite.infos.comment || visite.infos.comment === ''){
                const compteRendu = documents.find(item => item.id === 'compte-rendu');
                compteRendu.selected = false;
            }
        }

    }, [visite, inspecteur, contactsMeuble]);

    return (
        <DataProvider>
            {!decision ? renderBtnDecision() : <ButtonDownloadPDF documents={documents} callback={resetDecision} />}
            <Modale title="Décision de classement" show={showModal} hideHandler={onHideModale} save={true}>
                <div className='d-flex flex-column gap-3'>
                    <p className='body-2'>Choisir les documents à télécharger.</p>
                    <div className='d-flex flex-column gap-3'>
                        {documents.map(document => <DecisionFormCheck key={document.id} document={document} visite={visite} favorable={favorable} changeCallback={onDocument} />)}
                    </div>
                </div>
            </Modale>
        </DataProvider>
    )
}

const DecisionFormCheck = ({ document, visite, favorable, changeCallback }) => {

    let isDisabled = false;

    if(
        document.id === 'annexe-3' && !favorable || 
        document.id === 'compte-rendu' && (!visite.infos.comment || visite.infos.comment?.length === 0) ||
        document.id === 'surfaces' && visite.infos.superficies?.length === 0
    ) {
        isDisabled = true;
    }
    
    const renderErrorTooltip = (props) => {

        let message = '';
        
        if(document.id === 'annexe-3' && !favorable) message = "L'avis est défavorable";
        if(document.id === 'compte-rendu' && (!visite.infos.comment || visite.infos.comment?.length === 0)) message = "Vous n'avez pas saisi de commentaire pour cette visite";
        if(document.id === 'surfaces' && visite.infos.superficies?.length === 0) message = "Vous n'avez pas saisi de superficies";
        
        return (
            <Tooltip id="decision-tooltip" {...props}>
                <div className='d-flex flex-column gap-3 py-3'>
                    {message}
                </div>
            </Tooltip>
        );
    };

    if(isDisabled) {
        return(
            <OverlayTrigger placement='auto' overlay={renderErrorTooltip}>
                <span style={{width: 'fit-content'}}>
                    <Form.Check 
                        key={document.id} 
                        type='switch' 
                        id={document.id} 
                        label={document.label} 
                        checked={isDisabled ? !isDisabled : document.selected} 
                        disabled={isDisabled} 
                        onChange={() => changeCallback(document)}
                    />
                </span>
            </OverlayTrigger>
        );
    }
    
    return(
        <Form.Check 
            key={document.id} 
            type='switch' 
            id={document.id} 
            label={document.label} 
            checked={isDisabled ? !isDisabled : document.selected} 
            disabled={isDisabled} 
            onChange={() => changeCallback(document)}
        />
    )
}

export default ButtonDecision;