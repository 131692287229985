import { ReactComponent as Icon } from '@images/arrow-down.svg';
import { ButtonHelp } from '@composants/button/';

const SelectInput = ({ className = '', size='', values, value, name, label, empty, handler, disabled, aide=null }) => {

    return(
        <div className={`form-input ${className} ${size}`.trim()}>
            {label && <label className='form-input-label'>{label}</label>}
            <div className='d-flex gap-1 align-items-center'>
                <div className='select-input-container'>
                    <select className='select-input' name={name} onChange={handler} value={value ? value : ''} disabled={disabled}>
                        {empty && <option value={''}>{empty}</option>}
                        {values && values.map(item => <option key={item.label} value={item.value}>{item.label}</option>)}
                    </select>
                    <Icon className="select-input-arrow" />
                </div>
                {aide && <ButtonHelp help={aide} />}
            </div>
        </div>
    )
}

export default SelectInput;