import { createContext } from '@wordpress/element';
import { useData } from "../hooks";

const DataContext = createContext(null);

const DataProvider = ({ children, sync }) => {
    
    const { contacts, meubles, visites, isLoading } = useData(sync);

    return(
        <DataContext.Provider value={{contacts, meubles, visites, isLoading}}>
            {children}
        </DataContext.Provider>
    )
}

export {
    DataContext, 
    DataProvider, 
};