import { ReactComponent as Icon } from '@images/check.svg';

const Checkbox = ({ className, name, value, label, help, helpVariant, handler, tabindex = -1 }) => {
    return(
        <div className={`checkbox-input-wrapper ${className}`.trim()}>
            {label && <label className='form-input-label checkbox-input-label'>
                <input className="checkbox-input" type="checkbox" name={name} checked={value} onChange={handler} tabIndex={tabindex} />
                <div className='d-flex flex-column'>
                    <span className='text-wrap'>{label}</span>
                    {help && <div className={`form-text ${helpVariant}`}>{help}</div>}
                </div>
                <Icon className="check-mark" />
            </label>}
        </div>
    )
}

export default Checkbox;