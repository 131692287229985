import { useState, useContext } from '@wordpress/element';
import Modal from 'react-bootstrap/Modal';
import { UserContext } from '@context';
import { useVisitesActions, useData } from '@hooks';
import { Button } from '@composants/button';
import { MeublesSelector } from '@composants/entites';
import { ReactComponent as IconClose } from '@images/close.svg';

const ModaleMeubles = ({ show, setShow, uid }) => {

    const { duplicateVisite } = useVisitesActions();
    const { user } = useContext(UserContext);
    const { meubles, contacts } = useData();

    const [selection, setSelection] = useState();

    const onMeuble = (m) => {
        setSelection(m);
    }

    const onDuplicate = async () => {
        const copy = await duplicateVisite(user, uid, selection.id);
        window.location = `/visites/visite-voir/?id=${copy.uid}`;
    }

    return(
        <Modal show={show} onHide={() => setShow(false)} scrollable>
            <Modal.Header>
                <Modal.Title>Dupliquer cette visite…</Modal.Title>
                <button className='btn-close' data-bs-dismiss="modal" aria-label="Close">
                    <IconClose onClick={() => setShow(false)} />
                </button>
            </Modal.Header>
            <Modal.Body className='p-0'>
                {meubles ? 
                    <MeublesSelector meubles={meubles} contacts={contacts} handler={onMeuble} /> : 
                    <div className='p-3 text-center'>Chargement des meublés…</div>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline" name="cancel" onClick={() => setShow(false)}>Annuler</Button>
                <Button name="save" onClick={onDuplicate} disabled={!selection}>Dupliquer</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModaleMeubles;