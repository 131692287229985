import { useState, useEffect, useRef, useContext } from '@wordpress/element';
import { UserContext } from '@context';
import { useOnlineStatus } from '@hooks';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { ModaleCommentaire, ModaleMeubles, ModaleDelete, ModaleRevision } from '@composants';
import { ButtonAction } from '@composants/button';
import { ReactComponent as IconAction } from '@images/more.svg';

const ButtonVisiteActions = ({ uid, scelle, comment, user_create }) => {

    const [show, setShow] = useState(false);
    const [showCommentModal, setShowCommentModal] = useState(false);
    const [showRevisionModal, setShowRevisionModal] = useState(false);
    const [showCopyModal, setShowCopyModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const isOnline = useOnlineStatus();

    const { user } = useContext(UserContext);

    /* Autorisations */
    const canEdit = user && (parseInt(user_create) === parseInt(user.id));
    //const canDelete = !scelle && canEdit && isOnline;
    const canDelete = isOnline;

    const btnRef = useRef();

    const onActions = () => {
        setShow(!show);
    };

    const onDeleteAction = () => {
        if(canDelete){
            setShowDeleteModal(!showDeleteModal);
        }
    }

    const onCommentAction = () => {
        if(comment){
            setShowCommentModal(!showCommentModal);
        }
    }

    const onRevisionAction = () => {
        setShowRevisionModal(!showRevisionModal);
    }

    useEffect(() => {

        const onDocumentClick = (event) => {
            if(btnRef.current && !btnRef.current.contains(event.target)){
                setShow(false);
            }
        }

        document.addEventListener('click', onDocumentClick);

        return () => {
            document.removeEventListener('click', onDocumentClick);
        }

    }, []);

    const actionsOverlay = (props) => (
        <Popover id={`popover-${uid}`} className='popover-actions' {...props}>
            <Popover.Body>
                <div className='d-flex'>
                    <ButtonAction key={`edit-${uid}`} action="edit" url={`/visites/visite-edit/?id=${uid}`} isDisabled={!canEdit} />
                    <ButtonAction key={`comment-read-${uid}`} action="comment-read" handler={onCommentAction} isDisabled={!comment} />
                    <ButtonAction key={`copy-${uid}`} action="copy" handler={() => setShowCopyModal(!showCopyModal)} />
                    <ButtonAction key={`revision_read-${uid}`} action="revision_read" handler={onRevisionAction} isDisabled={!isOnline} />
                    <ButtonAction key={`delete-${uid}`} action="delete" handler={onDeleteAction} isDisabled={!canDelete} />
                </div>
            </Popover.Body>
        </Popover>
    )

    return (
        <>
            <OverlayTrigger key={`${uid}-overlay`} placement='top' show={show} overlay={actionsOverlay} rootClose={true}>
                <button key={`${uid}-button`} ref={btnRef} className={`btn btn-icon-only btn-transparent btn-lg`} onClick={onActions}>
                    <IconAction className="icon" />
                </button>
            </OverlayTrigger>
            {showCommentModal && <ModaleCommentaire show={showCommentModal} setShow={setShowCommentModal} comment={comment} uid={uid} edit={false} />}
            {showRevisionModal && <ModaleRevision show={showRevisionModal} setShow={setShowRevisionModal} uid={uid} entite='visite' />}
            {showCopyModal && <ModaleMeubles show={showCopyModal} setShow={setShowCopyModal} uid={uid} />}
            {showDeleteModal && <ModaleDelete show={showDeleteModal} setShow={setShowDeleteModal} uid={uid} />}
        </>
    );
}

export default ButtonVisiteActions;