import { Page, Text, View, Image } from '@react-pdf/renderer';
import { CIVILITES } from '@constantes';
import UneEtoile from '@images/panonceau-1-etoile.png';
import DeuxEtoiles from '@images/panonceau-2-etoiles.png';
import TroisEtoiles from '@images/panonceau-3-etoiles.png';
import QuatreEtoiles from '@images/panonceau-4-etoiles.png';
import CinqEtoiles from '@images/panonceau-5-etoiles.png';

const filigrane = {
    position: 'absolute', 
    fontSize: '40pt', 
    color: '#fcd3dd',
    top: '50%',
    left: '50%',
    whiteSpace: 'nowrap', 
    transform: 'translate(-70%,-50%) rotate(-45deg)', 
    textAlign: 'center', 
    textTransform: 'uppercase', 
}

const Annexe3 = ({ visite, meuble, contacts, inspecteur }) => {

    const { date_update, date_decision, scelle } = visite;
    const { classement_obtenu, capacite } = visite.infos;
    const { loueur, mandataire } = contacts;

    const getImgSrc = () => {
        switch(classement_obtenu){
            case 1:
                return UneEtoile;
            case 2:
                return DeuxEtoiles;
            case 3:
                return TroisEtoiles;
            case 4:
                return QuatreEtoiles;
            case 5:
                return CinqEtoiles;
        }
    };

    const getDateDecision = () => {
        if(scelle){
            if(date_decision){
                return new Date(date_decision).toLocaleDateString('fr-FR');
            } else {
                return new Date(date_update).toLocaleDateString('fr-FR');
            }
        } else {
            return new Date().toLocaleDateString('fr-FR');
        }
    };

    const isLoueurSociete = loueur && loueur.civilite && !CIVILITES.includes(loueur.civilite.trim());
    const isMandataireSociete = mandataire && mandataire.civilite && !CIVILITES.includes(mandataire.civilite.trim());

    return(
        <Page size="A4" style={{ padding: '10mm 22mm 20mm 22mm', fontFamily: 'Lato', position: 'relative' }}>

            {!scelle && <View style={filigrane}>
                <Text>Proposition de classement</Text>
            </View>}

            <View style={{textAlign: 'center', marginBottom: '8mm', position: 'relative'}}>
                <View style={{position: 'absolute', left: '0', top: '1mm'}}><Image src={getImgSrc} style={{width: '32mm', height: '32mm'}} /></View>
                <View style={{fontSize: '18pt', fontWeight: 'bold', marginBottom: '12mm'}}><Text>Annexe III</Text></View>
                <View style={{fontSize: '12pt'}}>
                    <View style={{fontWeight: 'bold'}}><Text>{visite.scelle ? "DÉCISION DE CLASSEMENT" : "PROPOSITION DE CLASSEMENT"}</Text></View>
                    <Text>dans la catégorie des meublés de tourisme</Text>
                </View>
            </View>

            <View style={{fontSize: '12pt'}}>
                
                <View style={{border: '1pt solid #000', padding: '1mm', marginBottom: '8mm'}}>
                    <View style={{fontWeight: 'bold', marginBottom: '4mm'}}>
                        <Text>Le meublé identifié ci-dessous est classé {classement_obtenu} {classement_obtenu > 1 ? 'étoiles' : 'étoile'} pour une capacité de {capacite} personne(s).</Text>
                    </View>
                    <View style={{marginBottom: '4mm'}}>
                        <View>
                            <Text>Dénomination commerciale, le cas échéant : {meuble.titre}</Text>
                        </View>
                        <View>
                            <Text>Adresse du meublé : {meuble.adresse1} {meuble.adresse2}</Text>
                        </View>
                    </View>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={{width: '30%'}}>
                            <Text>Code postal : {meuble.code_postal}</Text>
                        </View>
                        <View style={{width: '70%'}}>
                            <Text>Ville : {meuble.commune.toUpperCase()}</Text>
                        </View>
                    </View>
                </View>

                <View style={{border: '1pt solid #000', padding: '1mm', marginBottom: '6mm'}}>
                    
                    <View>
                        <View style={{fontWeight: 'bold', marginBottom: '4mm'}}>
                            <Text>Identification du loueur :</Text>
                        </View>
                        <View style={{display: 'flex', flexDirection: 'row', marginBottom: '4mm'}}>
                            <View style={{width: '40%'}}>
                                {loueur.nom && <Text>{isLoueurSociete ? 'Raison sociale' : 'Nom'} : {isLoueurSociete ? `${loueur.civilite} ${loueur.nom}`: loueur.nom}</Text>}
                            </View>
                            <View>
                                {!isLoueurSociete && <Text>Prénom : {loueur.prenom && loueur.prenom}</Text>}
                            </View>
                        </View>
                        <View>
                            {(isLoueurSociete && loueur?.nom_resp_societe) && <Text>Nom / Prénom : {loueur?.nom_resp_societe && loueur?.nom_resp_societe} {loueur?.prenom_resp_societe && loueur?.prenom_resp_societe}</Text>}
                        </View>
                        <View style={{marginBottom: '4mm'}}>
                            <Text>Adresse : {loueur?.adresse1} {loueur?.adresse2} {loueur?.adresse3}</Text>
                        </View>
                        <View style={{display: 'flex', flexDirection: 'row'}}>
                            <View style={{width: '40%'}}>
                                <Text>Code postal : {loueur?.code_postal}</Text>
                            </View>
                            <View>
                                <Text>Ville : {loueur?.commune.toUpperCase()}{loueur?.pays && loueur?.pays !== 'France' && `, ${loueur?.pays.toUpperCase()}`}</Text>
                            </View>
                        </View>
                        <View style={{display: 'flex', flexDirection: 'row'}}>
                            <View style={{width: '40%'}}>
                                <Text>Tél. 1 : {loueur?.telephone}</Text>
                            </View>
                            <View>
                                <Text>Tél. 2 : {loueur?.telephone2}</Text>
                            </View>
                        </View>
                        <View style={{marginBottom: '4mm'}}>
                            <Text>Courriel : {loueur?.email}</Text>
                        </View>
                    </View>

                    <View>
                        <View style={{fontWeight: 'bold', marginBottom: '4mm'}}>
                            <Text>Identification du mandataire, le cas échéant :</Text>
                        </View>
                        <View style={{display: 'flex', flexDirection: 'row', gap: "10mm", marginBottom: '4mm'}}>
                            <View>
                                <Text>{isMandataireSociete ? 'Raison sociale' : 'Nom'} : {isMandataireSociete ? `${mandataire?.civilite} ${mandataire?.nom}`: mandataire?.nom}</Text>
                            </View>
                            <View>
                                {!isMandataireSociete && <Text>Prénom : {mandataire?.prenom && mandataire?.prenom}</Text>}
                            </View>
                        </View>
                        <View>
                            {(isMandataireSociete && mandataire?.nom_resp_societe) && <Text>Nom / Prénom : {mandataire?.nom_resp_societe && mandataire?.nom_resp_societe} {mandataire?.prenom_resp_societe && mandataire?.prenom_resp_societe}</Text>}
                        </View>
                        <View style={{marginBottom: '4mm'}}>
                            <Text>Adresse : {mandataire?.adresse1} {mandataire?.adresse2} {mandataire?.adresse3}</Text>
                        </View>
                        <View style={{display: 'flex', flexDirection: 'row'}}>
                            <View style={{width: '40%'}}>
                                <Text>Code postal :  {mandataire?.code_postal}</Text>
                            </View>
                            <View>
                                <Text>Ville : {mandataire?.commune.toUpperCase()}{mandataire?.pays && mandataire?.pays !== 'France' && `, ${mandataire?.pays.toUpperCase()}`}</Text>
                            </View>
                        </View>
                        <View style={{display: 'flex', flexDirection: 'row'}}>
                            <View style={{width: '40%'}}>
                                <Text>Tél. 1 :  {mandataire?.telephone}</Text>
                            </View>
                            <View>
                                <Text>Tél. 2 :  {mandataire?.telephone2}</Text>
                            </View>
                        </View>
                        <View>
                            <Text>Courriel :  {mandataire?.email}</Text>
                        </View>
                    </View>

                </View>

                <View style={{marginBottom: '4mm'}}>
                    <Text>Le présent classement est délivré pour cinq ans.</Text>
                </View>

                <View style={{display: 'flex', flexDirection: 'row', marginBottom: '4mm'}}>
                    <View style={{width: '60%'}}>
                        <Text>Fait à : {meuble.commune.toUpperCase()}</Text>
                    </View>
                    <View style={{width: '40%'}}>
                        <Text>Le : {getDateDecision()}</Text>
                    </View>
                </View>

                <View style={{display: 'flex', flexDirection: 'row', marginBottom: '4mm'}}>
                    <View style={{width: '50%'}}>
                        <View style={{marginBottom: '3mm'}}><Text>Timbre de l'organisme évaluateur</Text></View>
                        <View style={{maxHeight: '30mm'}}><Image src={inspecteur.file_tampon} style={{objectFit: 'contain'}} /></View>
                    </View>
                    <View style={{width: '50%', textAlign: 'center'}}>
                        <View><Text>Nom et signature du représentant</Text></View>
                        <View><Text>de l'organisme évaluateur</Text></View>
                        <View style={{marginBottom: '1mm'}}><Text>{inspecteur.civilite} {inspecteur.first_name} {inspecteur.last_name}</Text></View>
                        <View style={{maxHeight: '30mm'}}><Image src={inspecteur.file_signature} style={{objectFit: 'contain'}} /></View>
                    </View>
                </View>

            </View>

            <View style={{fontSize: '10pt', fontWeight: 'bold', position: 'absolute', bottom: '12mm', left: '22mm', right: '22mm'}} fixed>
                <Text><Text style={{textDecoration: 'underline'}}>Avertissement :</Text> Au terme de l'article D. 324-4 du code du tourisme, le loueur ou son mandataire dispose d'un délai de quinze jours à réception de la présente proposition pour refuser le classement. À l'expiration de ce délai et en l'absence de refus, le classement est acquis.</Text>
            </View>
        </Page>
    );
}

export default Annexe3