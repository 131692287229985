import { Classement } from '@composants';
import './visite-sub-header.scss';

const VisiteSubHeader = ({ infos, edit }) => {

    const { scores, surfaces, capacite, criteres_non_applicables, criteres_non_compensables, classement_cible, classement_obtenu } = infos;
    const nonApplicables = criteres_non_applicables?.join(', ');
    const nonCompensables = criteres_non_compensables?.join(', ');
    const surfaceTotale = parseFloat(surfaces?.totalHabitable).toLocaleString('fr-FR');
    const surfaceMajoree = parseFloat(surfaces?.majorees).toLocaleString('fr-FR');
    const bestDiganostic = infos.diagnostic ? Math.max(...infos.diagnostic) : 0;

    return(
        <div className='visite-sub-header py-2 d-flex flex-column text-moyen'>
            <div className='d-flex flex-wrap gap-3 py-2'>
                <div className='sub-header-item d-flex flex-column ps-2'>
                    <span>Surface totale minimum</span>
                    <strong className="subheader-item-value">{surfaceTotale} m<sup>2</sup> (min. {surfaces.minimum} m<sup>2</sup>)</strong>
                </div>
                <div className='sub-header-item d-flex flex-column ps-2'>
                    <span>Surface totale majorée</span>
                    <strong className="subheader-item-value">{surfaceMajoree} m<sup>2</sup></strong>
                </div>
                <div className='sub-header-item d-flex flex-column ps-2'>
                    <span>Points obligatoires</span>
                    <strong className='subheader-item-value text-orange'>{scores?.c} / {scores?.a} (min. {scores.b})</strong>
                </div>
                <div className='sub-header-item d-flex flex-column ps-2'>
                    <span>Points à la carte</span>
                    <strong className="subheader-item-value">{scores?.h} / {scores?.e} (min. {scores.g})</strong>
                </div>
                <div className='sub-header-item d-flex flex-column ps-2'>
                    <span>Critères non-applicables</span>
                    <strong className="subheader-item-value">{(nonApplicables?.length > 0) ? nonApplicables : "Aucun critère"}</strong>
                </div>
                <div className='sub-header-item d-flex flex-column ps-2'>
                    <span>Critères non-compensables</span>
                    <strong className='subheader-item-value text-orange'>{(nonCompensables?.length > 0) ? nonCompensables : "Aucun critère"}</strong>
                </div>
                <div className='sub-header-item d-flex flex-column ps-2'>
                    <span>Capacité d'accueil</span>
                    <strong className="subheader-item-value">{capacite} {capacite > 1 ? "personnes" : "personne"}</strong>
                </div>
                <div className='sub-header-item d-flex flex-column ps-2'>
                    <span>Classement recherché</span>
                    <strong className="subheader-item-value"><Classement count={classement_cible} /></strong>
                </div>
                <div className='sub-header-item d-flex flex-column ps-2'>
                    {edit ?
                        <span>{classement_cible === 0 ? "Classement possible" : "Classement obtenu"}</span> :
                        <span>Classement obtenu</span>
                    }
                    <strong className="subheader-item-value">
                        {edit ? 
                            <Classement count={classement_cible !== 0 ? classement_obtenu : bestDiganostic} /> :
                            <Classement count={classement_obtenu} />
                        }
                    </strong>
                </div>
            </div>
        </div>
    )
}

export default VisiteSubHeader;