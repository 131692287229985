import { useDB, useUtiles } from '@hooks';

const useMeubles = () => {

    const { getNombreDeMois } = useUtiles();
    const { db } = useDB();

    /**
     * 
     * @param {Object} contact 
     * @param {Array} meubles 
     * @returns Array Liste des meublés liés à un contact
     * 
     */
    const getContactMeubles = (contact, meubles) => {
        return contact.meubles.reduce((prev, current) => {
            const meuble = meubles.find(m => parseInt(m.id) === parseInt(current.id));
            if(meuble) prev.push(meuble);
            return prev;
        }, []);
    }

    /**
     * 
     * @param {Array} meubles 
     * @returns La liste des meblés qui n'ont pas de classement ni de date de classement
     * 
     */
    const getMeublesSansClassement = (meubles) => {
        return meubles.filter(meuble => meuble.date_classement === '0000-00-00 00:00:00' && meuble.classement === '0');
    }
    
    /**
     * 
     * @param {Array} meubles 
     * @returns La liste des meublés qui ont une date de classement
     * 
     */
    const getMeublesAvecClassement = (meubles) => {
        return meubles.filter(meuble => meuble.date_classement !== '0000-00-00 00:00:00');
    }

    /**
     * 
     * @param {Array} meubles 
     * @param {Array} visites 
     * @returns La liste des meublés qui ne sont liés à aucune visite
     * 
     */
    const getMeublesSansVisites = (meubles, visites) => {
        return meubles.reduce((result, meuble) => {
            const foundVisite = visites.find(visite => visite.meuble_id === meuble.id);
            if(!foundVisite) result.push(meuble);
            return result;
        }, []);
    }

    /**
     * 
     * @param {Array} meubles 
     * @returns La liste des meublés qui ont une date de classement et dont cette date sera obsolète dans les six mois à venir
     */
    const getMeublesRevisiterSixmois = (meubles) => {
        return meubles.reduce((result, meuble) => {
            if(!meuble.date_classement || meuble.date_classement === '0000-00-00 00:00:00') return result;
            const mois = getNombreDeMois(new Date(meuble.date_classement));
            if(mois > 54 && mois < 60) result.push(meuble); // 54 mois est égal à 4 ans et demi. 60 mois est égal à 5 ans.
            return result;
        }, []);
    }

    /**
     * 
     * @param {Array} meubles 
     * @returns La liste des meublés qui ont une date de classement et dont cette date est supérieure ou égale à cinq ans
     */
    const getMeublesPlusDeCinqAns = (meubles) => {
        return meubles.reduce((result, meuble) => {
            if(!meuble.date_classement || meuble.date_classement === '0000-00-00 00:00:00') return result;
            const mois = getNombreDeMois(new Date(meuble.date_classement));
            if(mois >= 60) result.push(meuble); // 60 mois est égal à 5 ans.
            return result;
        }, []);
    }

    /**
     * 
     * @param {Array} ids ids des meublés à exporter
     * @returns {Array} tableau des lignes du fichier csv
     */
    const getMeublesCSVData = async (ids) => {

        if(!ids.length) return [];
        
        const meubles = await db.meubles.bulkGet(ids);
        return [];
        // const visitesAvecNumClassement = selectedMeubles.filter(visite => visite.infos.meuble && visite.infos?.meuble.num_classement && visite.infos?.meuble.num_classement !== "");
        // const visitesAvecProprio = await visitesAvecNumClassement.reduce(async (memo, visite) => {
            
        //     const contactsVisite = await db.contacts.filter(contact => {
        //         return contact.meubles.find(meuble => meuble.id === visite.infos.meuble.id);
        //     }).toArray();

        //     const proprio = contactsVisite.find(contact => {
        //         return contact.meubles.find(meuble => meuble.id === visite.infos.meuble.id && meuble.contact_type === 'Propriétaire') !== undefined;
        //     });

        //     if(proprio) (await memo).push(visite);
        //     return (await memo);

        // }, []);

        // const getVisiteRow = async visite => {

        //     const meuble = visite.infos.meuble;
        
        //     visite.contacts = await db.contacts.filter(contact => {
        //         const meubleContact = contact.meubles.find(m => m.id === meuble.id);
        //         return meubleContact !== undefined;
        //     }).toArray();

        //     const proprio = visite.contacts.find(contact => {
        //         const meubleProprio = contact.meubles.find(m => m.id === meuble.id);
        //         return meubleProprio.contact_type === "Propriétaire"
        //     });

        //     const mandataire = visite.contacts.find(contact => {
        //         const meubleMandataire = contact.meubles.find(m => m.id === meuble.id);
        //         return meubleMandataire.contact_type === "Mandataire"
        //     });

        //     const row = [
        //         normalizeCivilite(proprio?.civilite) || '', 
        //         proprio?.nom || '', 
        //         proprio?.prenom || '', 
        //         proprio?.raison_sociale || '', 
        //         proprio?.adresse1 || '', 
        //         proprio?.adresse2 || '', 
        //         proprio?.adresse3 || '', 
        //         proprio?.code_postal || '', 
        //         proprio?.commune || '', 
        //         proprio?.pays || '', 
        //         proprio?.telephone || proprio?.telephone2 || '', 
        //         proprio?.email || '', 
        //         proprio?.fax || '', 
        //         '', // proprio?.site_web || '', 
        //         mandataire?.civilite || '', 
        //         mandataire?.nom || '', 
        //         mandataire?.prenom || '', 
        //         mandataire?.raison_sociale || '', 
        //         mandataire?.adresse1 || '', 
        //         mandataire?.adresse2 || '', 
        //         mandataire?.adresse3 || '', 
        //         mandataire?.code_postal || '', 
        //         mandataire?.commune || '',  
        //         mandataire?.pays || '', 
        //         mandataire?.telephone || '', 
        //         mandataire?.email || '', 
        //         mandataire?.fax || '', 
        //         '', // mandataire?.site_web || '', 
        //         meuble?.titre || '', 
        //         meuble?.adresse1 || '', 
        //         meuble?.adresse2 || '', 
        //         meuble?.code_postal || '', 
        //         meuble?.code_insee || '', 
        //         meuble?.commune || '', 
        //         visite.infos.classement_obtenu.toString(), 
        //         visite.infos.capacite || '', 
        //         new Date(visite.date_visite).toLocaleDateString('fr-FR'),
        //         meuble?.num_classement || '', 
        //         meuble?.num_classement || ''
        //     ];

        //     return Promise.resolve(row);
        // }

        // const csvRows = async () => {
        //     return Promise.all(
        //         visitesAvecProprio.map(async visite => {
        //             return await getVisiteRow(visite);
        //         })
        //     );
        // }

        // const rows = await csvRows();

        // const csvData = [
        //     CLASS_CSV_HEADER,
        //     ...rows
        // ]
        
        // return csvData;
    }

    return {
        getContactMeubles, 
        getMeublesSansClassement, 
        getMeublesAvecClassement, 
        getMeublesRevisiterSixmois, 
        getMeublesPlusDeCinqAns, 
        getMeublesSansVisites, 
        getMeublesCSVData, 
    }
}

export default useMeubles;