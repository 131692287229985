import { DataProvider, UserProvider, ToastContextProvider } from "@context";
import PageMeubleVoir from "@pages/meuble-voir/page-meuble-voir";

const MeubleVoir = () => {
    return(
        <ToastContextProvider>
            <UserProvider>
                <DataProvider>
                    <PageMeubleVoir />
                </DataProvider>
            </UserProvider>
        </ToastContextProvider>
    );
}

export default MeubleVoir;