import { useContext } from '@wordpress/element';
import Modal from 'react-bootstrap/Modal';
import { useVisitesActions } from '@hooks';
import { UserContext, useToastContext } from '@context';
import { Button } from '@composants/button';
import { ReactComponent as IconClose } from '@images/close.svg';

const ModaleDelete = ({ show, setShow, uid }) => {

    const { deleteVisite } = useVisitesActions();
    const { user } = useContext(UserContext);
    const { addToast } = useToastContext();

    const onDelete = async () => {
        const response = await deleteVisite(uid, user.id);
        if(response.success){
            addToast({
                title: 'Visite supprimée',
                message: response.data.message,
                type: 'success',
            });
        } else {
            addToast({
                title: 'Erreur',
                message: response.data.message,
                type: 'danger',
            });
        }
        setTimeout(() => {
            window.location = '/visites';
        }, 500);
    }

    return(
        <Modal show={show} onHide={() => setShow(false)} scrollable>
            <Modal.Header>
                <Modal.Title>Supprimer la visite {uid}</Modal.Title>
                <button className='btn-close' data-bs-dismiss="modal" aria-label="Close">
                    <IconClose onClick={() => setShow(false)} />
                </button>
            </Modal.Header>
            <Modal.Body className='text-center'>
                Êtes-vous sur de vouloir supprimer cette visite ?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline" name="cancel" onClick={() => setShow(false)}>Annuler</Button>
                <Button name="save" onClick={onDelete}>Supprimer</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModaleDelete;