import { Document, Font } from '@react-pdf/renderer';
import { Annexe1, Annexe2, Annexe3, LettreFavorable, LettreDefavorable, CompteRendu, ReleveSurfaces } from '@composants';

Font.register({ family: 'Lato', fonts: [
    {src: '/wp-content/themes/nomade/fonts/Lato/Lato-Regular.ttf', fontStyle: 'normal'},
    {src: '/wp-content/themes/nomade/fonts/Lato/Lato-Bold.ttf', fontStyle: 'normal', fontWeight: 'bold'},
    {src: '/wp-content/themes/nomade/fonts/Lato/Lato-Italic.ttf', fontStyle: 'italic', fontWeight: 'normal'},
]});

Font.registerHyphenationCallback(word => [word]);

const DecisionDeClassement = ({ documents, visite, meuble, contacts, inspecteur }) => {

    // Les points A, B, C, D, E, F, G et H obtenus pour la visite
    const scores = visite.infos.scores;

    // Les critères ONC (obligatoires et non compensables)
    const ONC = visite.grille.filter(critere => critere.statut === 'ONC' && critere.checked);

    // Selon les scores, la décision de classsement est favorable ou pas
    const favorable = (scores.c >= scores.b && ONC.length === 5) && (scores.h >= scores.g);

    const hasLettre = documents.find(item => item === 'lettre') !== undefined;
    const hasAnnexe1 = documents.find(item => item === 'annexe-1') !== undefined;
    const hasAnnexe2 = documents.find(item => item === 'annexe-2') !== undefined;
    const hasAnnexe3 = documents.find(item => item === 'annexe-3') !== undefined;
    const hasCompteRendu = documents.find(item => item === 'compte-rendu') !== undefined;
    const hasSurfaces = documents.find(item => item === 'surfaces') !== undefined;

    return(
        <Document>
            {(hasLettre && favorable) && <LettreFavorable visite={visite} inspecteur={inspecteur} contacts={contacts} />}
            {(hasLettre && !favorable) && <LettreDefavorable visite={visite} meuble={meuble} inspecteur={inspecteur} contacts={contacts} />}
            {hasAnnexe1 && <Annexe1 visite={visite} meuble={meuble} contacts={contacts} inspecteur={inspecteur} />}
            {hasAnnexe2 && <Annexe2 visite={visite} meuble={meuble} contacts={contacts} inspecteur={inspecteur} />}
            {(hasAnnexe3 && favorable) && <Annexe3 visite={visite} meuble={meuble} contacts={contacts} inspecteur={inspecteur} />}
            {(hasCompteRendu && visite.infos.comment?.length > 1) && <CompteRendu visite={visite} inspecteur={inspecteur} />}
            {(hasSurfaces && visite.infos.superficies?.length > 0) && <ReleveSurfaces visite={visite} inspecteur={inspecteur} />}
        </Document>
    );
}

export default DecisionDeClassement;