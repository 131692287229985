
import { useState, useContext } from '@wordpress/element';
import { useVisitesActions } from '@hooks';
import { UserContext } from '@context';

const ButtonNewVisite = ({ meuble, className='' }) => {

    const { user } = useContext(UserContext);
    const { getNewVisite } = useVisitesActions();
    const [isLoading, setIsLoading] = useState(false);

    const onNewVisite = async (event) => {
        
        event.preventDefault();
        setIsLoading(true);

        const newVisite = await getNewVisite(meuble.id, user);
        if(newVisite){
            window.location = `/visites/visite-edit?id=${newVisite.uid}`;
        }

        setIsLoading(false);
    }

    return(
        <a className={`btn btn-new-visite outline ${className}`.trim()} href={`/visites/visite-edit`} onClick={(e) => onNewVisite(e)} title="Nouvelle visite">Nouvelle visite</a>
    )
}

export default ButtonNewVisite;