import { useVisiteDispatch, useVisite } from '@context/';
import { classementValues } from '@constantes';
import { Classement } from '@composants';
import { SelectInput } from '@composants/form';

export const GrilleHeader = ({ status, uncheckedsHandler, selectionHandler, isOpen }) => {

    const { visite } = useVisite();
    const dispatch = useVisiteDispatch();

    const { uncheckeds, selection } = status;

    const onClassement = (e) => {
        const nextClassement = e.target.value !== '' ? parseInt(e.target.value) : 0;
        dispatch({type: 'classement_cible', value: nextClassement});
    }

    const onSelection = (e) => {
        selectionHandler(e);
    }

    const onUncheckeds = (e) => {
        uncheckedsHandler(!uncheckeds);
    }

    return (
        <div className='grille-header'>
            <div className="d-flex justify-content-between align-items-center">
                <div className='d-flex align-items-center gap-3'>
                    <SelectInput values={classementValues} size="md" value={visite.infos.classement_cible} name="classement_cible" label="" handler={onClassement} />
                    {isOpen && <div className='d-flex align-items-center gap-1'>
                        <span className='font-body-2 text-moyen'>Classement obtenu</span>
                        <Classement count={visite.infos.classement_obtenu} />
                    </div>}
                </div>
                <div className='d-flex gap-4'>
                    <div className="action d-flex gap-3">
                        <div className="form-check form-switch mb-0">
                            <input className="form-check-input" type="checkbox" checked={uncheckeds} id="uncheckeds" onChange={onUncheckeds} />
                            <label className="form-check-label caption text-moyen" htmlFor="uncheckeds">Critères non-validés</label>
                        </div>
                        <div className="form-check form-switch mb-0">
                            <input className="form-check-input" type="checkbox" checked={selection === 'obligatoires'} id="obligatoires" name="obligatoires" onChange={onSelection} />
                            <label className="form-check-label caption text-moyen" htmlFor="obligatoires">Obligatoires</label>
                        </div>
                        <div className="form-check form-switch mb-0">
                            <input className="form-check-input" type="checkbox" checked={selection === 'optionnels'} id="optionnels" name="optionnels" onChange={onSelection} />
                            <label className="form-check-label caption text-moyen" htmlFor="optionnels">Optionnels</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}