import { Page, Text, View, Image, StyleSheet } from '@react-pdf/renderer';
import { CIVILITES } from '@constantes';
import LettreBottom from './lettre-bottom';

const styles = StyleSheet.create({
    texte: {
        marginBottom: '5mm',
        textAlign: 'justify',
        textIndent: '25mm',
    }
});

const LettreFavorable = ({ visite, inspecteur, contacts }) => {

    const logotypeUrl = inspecteur.file_logo || "/wp-content/uploads/logotype-clevacances.png";
    const { demandeur } = visite;
    const dateVisiteString = new Date(visite.date_visite).toLocaleDateString('fr-FR');
    const classement = visite.infos.classement_obtenu;
    const capacite = visite.infos.capacite;

    const proprietaire = contacts.loueur;
    const isPersonnePhysique = CIVILITES.includes(proprietaire.civilite.trim());

    return(
        <Page size="A4" style={{ padding: '10mm 15mm 10mm 15mm', fontFamily: 'Lato', fontSize: '12pt' }}>

            <View style={{maxWidth: '65mm', maxHeight: '35mm'}}>
                <Image src={logotypeUrl} style={{objectFit: 'contain', maxHeight: '35mm', width: '100%', height: '100%'}} />
            </View>
            
            <View style={{paddingLeft: '55%', marginBottom: '15mm'}}>
                <View>
                    <View>
                        <Text style={{fontWeight: 'bold'}}>{demandeur.civilite} {demandeur.prenom} {demandeur.nom}</Text>
                        {(isPersonnePhysique && demandeur.nom !== proprietaire.nom) && <Text>{proprietaire.civilite} {proprietaire.prenom} {proprietaire.nom}</Text>}
                    </View>
                    {demandeur.adresse1 && <Text>{demandeur.adresse1}</Text>}
                    {demandeur.adresse2 && <Text>{demandeur.adresse2}</Text>}
                    {demandeur.adresse3 && <Text>{demandeur.adresse3}</Text>}
                    <Text>{demandeur.code_postal} {demandeur.commune}</Text>
                    <Text>{demandeur.pays}</Text>
                </View>
            </View>

            <View style={{textAlign: 'center', marginBottom: '10mm'}}>
                <Text>{inspecteur.organisme_commune}, le {new Date().toLocaleDateString('fr-FR', {day: 'numeric', month: 'long', year: 'numeric'})}</Text>
            </View>

            <View style={{marginBottom: '10mm', fontSize: '11pt'}}>
                {visite.scelle ? 
                    <Text>P.J. : Décision(s) de classement à conserver.</Text> : 
                    <Text>P.J. : Proposition de classement.</Text>
                }
            </View>

            <View style={{textAlign: 'left', marginBottom: '5mm'}}>
                <Text style={styles.texte}>Madame, Monsieur,</Text>
                <Text style={styles.texte}>Pour donner suite à ma visite du {dateVisiteString} concernant le classement de votre (vos) meublé(s), nous vous informons qu’il(s) remplit (remplissent) les critères d'attribution d’un classement en {classement} {classement > 1 ? 'étoiles' : 'étoile'} pour une capacité d’hébergement de {capacite} personnes.</Text>
                <Text style={styles.texte}>J’ai le plaisir de vous faire parvenir la décision qui atteste du classement, qui est à apposer dans votre livret de votre location, ainsi que le rapport détaillé d’inspection.</Text>
                <Text style={styles.texte}>Cette décision de classement (Annexe III) sert de justificatif pour votre centre des impôts, ANCV, Office de Tourisme, comités d’entreprise, plateformes de commercialisation et sites tiers…</Text>
                <Text style={styles.texte}>Vous remerciant par avance de l’intérêt que vous portez au développement touristique et à l'accueil que vous accorderez à nos voyageurs.</Text>
                <Text style={styles.texte}>Nous vous prions d’agréer, Madame, Monsieur, l’expression de nos salutations distinguées.</Text>
            </View>

            <View style={{paddingLeft: '90mm', marginBottom: '15mm'}}>
                <Text>{inspecteur.civilite} {inspecteur.first_name} {inspecteur.last_name}</Text>
                <Text>Responsable de l'inspection</Text>
                {(inspecteur.file_signature !== '') && <View style={{height: "35mm"}}>
                    <Image style={{width: "100%", height: "100%", objectFit: "contain", objectPosition: 'top left'}} src={inspecteur.file_signature} />
                </View>}
            </View>

            <LettreBottom inspecteur={inspecteur} visite={visite} />

        </Page>
    )
}

export default LettreFavorable;