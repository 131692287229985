
import ButtonVisiteActions from '@composants/button/button-visite-actions';
import Classement from '@composants/classement/classement';
import { Vignette } from '@composants';
import { ReactComponent as IconLock } from '@images/lock.svg';

import './visite-item.scss';

const VisiteItem = ({ visite }) => {

    const meuble = visite.infos.meuble;

    const onVisite = () => {
        window.location = `/visites/visite-voir/?id=${visite.uid}`;
    }

    return (
        <div className="entite-item visite-item">
            <div className="d-flex">
                <div onClick={onVisite}>
                    <Vignette className='rond' variant={meuble?.type === 'Appartement' ? 'appartement' : 'maison'}>
                        {(meuble && meuble.vignette_url) && <img src={meuble.vignette_url} />}
                    </Vignette>
                </div>
                <div className="body ps-2 pe-0" onClick={onVisite}>
                    <div className="identification d-flex flex-column justify-content-center gap-1 h-100">
                        <div className='d-flex align-items-center gap-2'>
                            {visite.scelle && <IconLock className="icon-scelle" />}
                            <div className='d-flex flex-column gap-1'>
                                {meuble && <strong>{meuble?.titre ? meuble?.titre : meuble?.id}</strong>}
                                <span>Visite {visite.uid} du&nbsp;{new Date(visite.date_visite).toLocaleDateString()}</span>
                            </div>
                        </div>
                    </div>
                    {visite.extra && <span className='rounded-pill bg-warning px-3 py-2 text-dark'>{visite.extra}</span>}
                    <Classement count={visite.infos.classement_obtenu} />
                </div>
                <div className='actions d-flex align-items-center px-2'>
                    <ButtonVisiteActions uid={visite.uid} scelle={visite.scelle} comment={visite.infos.comment} user_create={visite.user_create} />
                </div>
            </div>
        </div>
    )
}

export default VisiteItem;