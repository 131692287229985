import ReactPaginate from 'react-paginate';
import { ReactComponent as ArrowPrev } from '@images/arrow-prev.svg';
import { ReactComponent as ArrowNext } from  '@images/arrow-next.svg';

const Pagination = ({ handler, count, index }) => {
    return(
        <div className='pagination-container'>
            {count > 0 && <ReactPaginate
                breakLabel="..."
                onPageChange={handler}
                pageRangeDisplayed={1}
                pageCount={count}
                nextLabel={<ArrowNext />}
                renderOnZeroPageCount={null} 
                forcePage={index}
                previousLabel={<ArrowPrev />}
                pageLinkClassName='page-item' 
                disabledLinkClassName='page-item page-item-disabled' 
                activeLinkClassName='page-item page-item-active' 
                breakLinkClassName='page-item'
            />}
        </div>
    );
}

export default Pagination;