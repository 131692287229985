import { useContext } from '@wordpress/element';
import { useVisite, useVisiteDispatch, UserContext } from '@context';
import { useFetch } from '@hooks';
import { DocumentsUpload } from '@composants';

const VisiteDocuments = () => {

    const { user } = useContext(UserContext);
    const { visite } = useVisite();
    const dispatch = useVisiteDispatch();
    const { deleteFile } = useFetch();
    const { uid, documents, infos } = visite;

    const onFileUploaded = async (uploadInfo) => {
        console.log(uploadInfo);
        if(uploadInfo.success){
            await dispatch({type: 'file-upload', value: uploadInfo});
        }
    }
    
    const onFileDelete = async (file) => {
        const result = await deleteFile(user.id, file.id);
        if(result.success || result.data.message === "Document non trouvé."){
            dispatch({type: 'file-delete', value: file});
        }
    }

    if(!visite) return <div></div>;

    if(!infos.saved) return <div>
        <p className="text-muted text-center p-4">Cette visite n'est pas encore enregistrée sur le serveur.</p>
    </div>

    return(
        <DocumentsUpload uid={uid} files={documents} onUpload={onFileUploaded} onDelete={onFileDelete} />
    )
}

export default VisiteDocuments;