import { useState } from '@wordpress/element';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import Calendar from 'react-calendar'
import { ReactComponent as IconCalendar } from '@images/calendar.svg';
import { ReactComponent as IconClose } from '@images/close.svg';

import './date-range-input.scss';

const DateRangeInput = ({ label, handler }) => {

    const [datesLabel, setDatesLabel] = useState(null);

    const onDateRange = (value) => {
        setDatesLabel(`Du ${value[0].toLocaleDateString()} au ${value[1].toLocaleDateString()}`);
        handler(value);
        document.getElementById('page').click();
    }

    const clearDateRange = () => {
        handler(undefined);
        setDatesLabel(null);
    }

    const calendarPopover = (props) => (
        <Popover id={`calendar-popover`} className='calendar-popover' {...props}>
            <Popover.Body className='p-3'>
                <Calendar 
                    returnValue='range' 
                    selectRange={true} 
                    locale='fr-FR' 
                    onChange={onDateRange} 
                />
            </Popover.Body>
        </Popover>
    );

    return(
        <div className={`form-input date-range-input`}>
            {label && <label className='form-input-label'>{label}</label>}
            <div className='date-range-wrapper'>
                <OverlayTrigger key={`date-range-overlay`} trigger="click" placement="auto" overlay={calendarPopover} rootClose={true}>
                    <div key={`date-range-btn`} className='d-flex align-items-center gap-1'>
                        <IconCalendar className="icon calendar-icon" role="button" />
                        <div className='date-range-text'>{datesLabel ? datesLabel : 'Du -- au --'}</div>
                    </div>
                </OverlayTrigger>
                {datesLabel && <IconClose className="icon clear-icon" onClick={clearDateRange} role="button" />}
            </div>
        </div>
    );
}

export default DateRangeInput;