import { OverlayTrigger, Popover } from "react-bootstrap";
import { ReactComponent as Icon } from '@images/help.svg';

const ButtonHelp = ({ help, className='' }) => {

    const renderPopover = (props) => (
        <Popover {...props}>
            <Popover.Body>
                <div className="p-3" dangerouslySetInnerHTML={{ __html: `${help}` }} />
            </Popover.Body>
        </Popover>
    );

    return(
        <OverlayTrigger overlay={renderPopover} trigger="focus">
            <button key={help} className={`btn btn-help ${className}`.trim()} tabIndex={-1} onClick={(e) => e.stopPropagation()}><Icon /></button>
        </OverlayTrigger>
    );
}

export default ButtonHelp;
