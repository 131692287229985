import { useContext } from '@wordpress/element';
import { DataContext } from '@context';

const useContacts = () => {

    const { contacts } = useContext(DataContext);

    /**
     * 
     * @param {string} id 
     * @returns Object contact
     */
    const getContact = (id) => {
        return contacts.find(contact => contact.id === id);
    }

    /**
     * 
     * @param {object} meuble 
     * @returns Array liste des contacts liés à un meublé
     */
    const getMeubleContacts = (meuble) => {
        if( !contacts ) return false;
        return contacts.reduce((prev, current) => {
            const foundContact = current.meubles.find(m => parseInt(m.id) === parseInt(meuble.id));
            if (foundContact) {
                current.contact_type = foundContact.contact_type;
                prev.push({...current});
            }
            return prev;
        }, []);
    }

    /**
     * 
     * @param {Object} meuble 
     * @returns Object contact de type Propriétaire
     */
    const getMeubleProprio = (meuble) => {
        const meubleContacts = getMeubleContacts(meuble);
        const proprio = meubleContacts.find(contact => {
            return contact.meubles.find(m => m.id === meuble.id && m.contact_type === 'Propriétaire');
        });
        return proprio;
    }

    /**
     * 
     * @param {Object} meuble 
     * @param {string} role 
     * @returns Object contact de type {role}
     */
    const getMeubleContactByRole = (meuble, role) => {
        const meublesContact = getMeubleContacts(meuble);
        if(contacts) {
            return meublesContact.find(contact => contact.contact_type === role);
        }
    }

    return {
        contacts, 
        getContact, 
        getMeubleContacts, 
        getMeubleProprio, 
        getMeubleContactByRole, 
    }
}

export default useContacts;