import { DataProvider, UserProvider, ToastContextProvider } from '@context';
import { PageContactVoir } from '@pages/contact-voir';

const ContactVoir = () => {

    return(
        <ToastContextProvider>
            <UserProvider>
                <DataProvider>
                    <PageContactVoir />
                </DataProvider>
            </UserProvider>
        </ToastContextProvider>
    );
}

export default ContactVoir;