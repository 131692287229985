import ButtonIconOnly from '@composants/button/button-icon-only';
import { ReactComponent as Icon } from '@images/copy.svg';

const ButtonIconCopy = ({ action }) => {
    return(
        <ButtonIconOnly action={action}>
            <Icon />
        </ButtonIconOnly>
    )
}

export default ButtonIconCopy;