import { ReactComponent as Icon } from '@images/arrow-up.svg';

const ButtonCollapse = ({ statut, variant, handler, tabindex=-1 }) => {
    return (
        <button className={`btn btn-collapse ${statut} ${variant}`} onClick={handler} tabIndex={tabindex}>
            <Icon />
        </button>
    )
}

export default ButtonCollapse;