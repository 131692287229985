import './button.scss';

const Button = ({ variant='', name='', children, onClick, disabled }) => {

    return(
        <button 
            name={name} 
            className={`btn ${variant}`.trim()} 
            onClick={onClick} 
            disabled={disabled}
        >{children}</button>
    );
}

export default Button;