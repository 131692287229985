import { createContext, useState, useContext } from '@wordpress/element';

const FilterContext = createContext(null);
const FilterDispatchContext = createContext(null);

const FilterProvider = ({ children }) => {

    const [rowCount, setRowCount] = useState(0);

    return(
        <FilterContext.Provider value={{ rowCount }}>
            <FilterDispatchContext.Provider value={{ setRowCount }}>
                {children}
            </FilterDispatchContext.Provider>
        </FilterContext.Provider>
    )
}

const useFilters = () => {
    return useContext(FilterContext);
}

const useFiltersDispatch = () => {
    return useContext(FilterDispatchContext);
}

export {
    FilterContext, 
    FilterProvider, 
    useFilters, 
    useFiltersDispatch, 
}