import { ReactComponent as Etoile } from '@images/etoile.svg';
import { ReactComponent as Non } from '@images/non.svg';

import './classement.scss';

const Classement = ({ className='', count }) => {
    
    if( !count ){
        return <div className={`classement ${className}`.trim()}><span className="etoiles-non"><Non /></span></div>;
    }

    const etoiles = new Array(parseInt(count)).fill('etoile');

    return(
        <div className={`classement ${className}`.trim()}>
            {etoiles.length > 0 ?
                <span className="etoiles">{etoiles.map((etoile, index) => <Etoile key={`etoile-${index}`} />)}</span> :
                <span className="etoiles-non"><Non /></span>
            }
        </div>
    )
}

export default Classement;