import { Vignette } from '@composants';
import { ReactComponent as Icon } from '@images/close.svg';
import { ReactComponent as Docicon } from '@images/document.svg';

import './document.scss';

const Document = ({ file, deleteHandler=null }) => {

    const isImage = file.mime_type?.indexOf('image') !== -1;

    const openDocument = () => {
        window.open(file.url, '_blank');
    }

    return(
        <div className='document d-flex flex-column gap-2 align-items-center'>
            <Vignette className={isImage ? "vignette-document" : "vignette-document vignette-fichier"}>
                {isImage && <img className='image' src={file.url} title={file.titre} onClick={openDocument} />}
                {!isImage && <Docicon onClick={openDocument} />}
                {deleteHandler && <button className='btn btn-delete btn-delette-vignette' onClick={() => deleteHandler(file)}>
                    <Icon />
                </button>}
            </Vignette>
            <div className='file-infos d-flex flex-column'>
                <span className='file-title'>{file.titre}</span>
                <span className='caption text-moyen'>{file.mime_type}</span>
            </div>
        </div>
    );
};

export default Document;