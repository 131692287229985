import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import './badge.scss';

const Badge = ({ size='', variant='', className='', children, onClick=null, tooltip }) => {

    const classString = `badge d-flex align-items-center justify-content-center ${variant} ${size} ${className}`.trim();

    const BadgeTooltip = ({ id, children, title }) => (
        <OverlayTrigger overlay={<Tooltip id={id}>{title}</Tooltip>}>
            {children}
        </OverlayTrigger>
    )

    if(tooltip) return(
        <BadgeTooltip id={tooltip} title={tooltip}>
            <div key={Math.random()} className={classString} onClick={onClick}>{children}</div>
        </BadgeTooltip>
    );

    return(
        <div className={classString} onClick={onClick}>{children}</div>
    );
}

export default Badge;