import { useDB, useUtiles } from '@hooks';
import { CIVILITES } from '@constantes';

const useExports = () => {

    const { db } = useDB();
    const { getProp } = useUtiles();

    /**
     * 
     * @param {string} entity L'entité dont on va extraire les données.
     * @param {Array} ids La liste des id à extraire.
     * @param {Array} cols La liste des colonnes sélectionnées.
     * 
     * @returns Un tableau avec toutes les données sélectionnées.
     */
    const getEntityData = async (entity, ids, cols) => {

        const loadedData = await db[entity].bulkGet(ids);
        let data = [];
        
        if(entity === 'meubles'){
            
            const contactsData = await db.contacts.toArray();
            const visitesData = await db.visites.toArray();

            data = loadedData.map(meuble => {
                const contacts = contactsData.filter(contact => contact.meubles.find(m => m.id === meuble.id));
                const proprietaire = contacts.find(contact => contact.meubles.find(m => m.id === meuble.id && m.contact_type === 'Propriétaire'));
                const mandataire = contacts.find(contact => contact.meubles.find(m => m.id === meuble.id && m.contact_type === 'Mandataire'));
                const visites = visitesData.filter(visite => visite.meuble_id === meuble.id);
                const lastVisite = visites.reduce((a, b) => {
                    return new Date(a.date_visite) > new Date(b.date_visite) ? a : b;
                }, visites[0]);

                const hasNewLine = meuble.description.indexOf('\r\n') > -1;
                const nextDescription = hasNewLine ? meuble.description.replace('\r\n', '. ') : meuble.description;
                
                const nextMeuble = {
                    ...meuble,
                    'description': nextDescription, 
                    'civilite_proprietaire': null, 
                    'nom_proprietaire': null,
                    'prenom_proprietaire': null,
                    'nom_mandataire': null,
                    'prenom_mandataire': null,
                    'status': null,
                    'adresse_proprietaire': null,
                    'cp_proprietaire': null, 
                    'commune_proprietaire': null,
                    'tel_proprietaire': null, 
                    'portable_proprietaire': null, 
                    'email_proprietaire': null, 
                    'web_proprietaire': null, 
                    'date_decision': lastVisite ? lastVisite.date_decision : null, 
                    'date_visite': lastVisite ? lastVisite.date_visite : null, 
                };

                if(proprietaire){
                    
                    const { civilite, nom, prenom, adresse1, adresse2, adresse3, code_postal, commune, pays, telephone, telephone2, email, site_web } = proprietaire;

                    const isSociete = civilite && !CIVILITES.includes(civilite.trim());

                    nextMeuble.civilite_proprietaire = isSociete ? '' : `${civilite}`;
                    nextMeuble.nom_proprietaire = `${nom ? nom : ''}`;
                    nextMeuble.prenom_proprietaire = `${prenom ? prenom : ''}`;
                    nextMeuble.status = isSociete ? `${civilite}` : '';
                    nextMeuble.adresse_proprietaire = `${adresse1 ? adresse1 : ''} ${adresse2 ? adresse2 : ''} ${adresse3 ? adresse3 : ''}`.replaceAll('\n', ' ').trim();
                    nextMeuble.cp_proprietaire = `${code_postal ? code_postal : ''}`;
                    nextMeuble.commune_proprietaire = `${commune ? commune : ''} ${(pays && pays !== '' && pays !== 'France') ? '- ' + pays : ''}`;
                    nextMeuble.tel_proprietaire = `${telephone ? telephone : ''}`;
                    nextMeuble.portable_proprietaire = `${telephone2 ? telephone2 : ''}`;
                    nextMeuble.email_proprietaire = `${email ? email : ''}`;
                    nextMeuble.web_proprietaire = `${site_web ? site_web : ''}`;
                }
                if(mandataire){
                    nextMeuble.nom_mandataire = `${mandataire.nom ? mandataire.nom : ''}`;
                    nextMeuble.prenom_mandataire = `${mandataire.prenom ? mandataire.prenom : ''}`;
                    const mandataireIsSociete = mandataire.civilite && !CIVILITES.includes(mandataire.civilite.trim());
                    nextMeuble.status = (nextMeuble.status === '' && mandataireIsSociete) ? `${mandataire?.civilite}` : '';
                }
                return nextMeuble;
            });
        } else {
            data = loadedData;
        }

        const rows = data.reduce((result, item) => {

            const row = [];
            
            cols.forEach(col => {
                if(!col.selected) return;
                const colValue = col.key.reduce((string, key, index) => {
                    let value = getProp(item, key.split('.'));
                    if(col.type === 'date' && value){
                        if(value === '0000-00-00 00:00:00'){
                            value = '';
                        } else {
                            const newDate = new Date(value);
                            value = newDate.toLocaleDateString('fr-FR', {day: 'numeric', month: 'long', year: 'numeric'});
                        }
                    }
                    if(col.type === 'classement') {
                        if(value && value !== '0') {
                            value = `${value} ${parseInt(value) > 1 ? 'étoiles' : 'étoile'}`;
                        } else {
                            value = 'non classé';
                        }
                    }
                    if(col.type === 'texte-long' && value){
                        value = `${value.replace(/\n/g,' ')}`;
                    }
                    return string += `${(index > 0 && value !== '') ? ' ' : ''}${value ? value : ''}`;
                }, '');
                row.push(colValue);
            });

            result.push(row);
            
            return result;

        }, []);

        const headerRow = cols.reduce((result, col) => {
            if(col.selected) {
                result.push(col.label);
            }
            return result;
        }, []);

        const csvData = [
            headerRow,
            ...rows
        ]

        return csvData;
    }

    return {
        getEntityData, 
    }
}

export default useExports;