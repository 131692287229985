import { useState, useEffect, useRef, useContext } from '@wordpress/element';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { UserContext } from '@context';
import { useVisitesActions, useOnlineStatus } from '@hooks';
import ModaleDuplicate  from "@meuble/modale-duplicate";
import { ModaleArchivageAction, ModaleRevision } from '@composants';
import { ButtonAction } from '@composants/button';
import { ReactComponent as IconAction } from '@images/more.svg';

const ButtonMeubleActions = ({ meuble }) => {

    const { id: uid, visites, user_create } = meuble;
    const { user } = useContext(UserContext);
    const { getNewVisite } = useVisitesActions();
    const isOnline = useOnlineStatus();

    /* Autorisations */
    //const canEdit = parseInt(user_create) === parseInt(user.id) && isOnline; //tous les collegues peuvent editer
    const canEdit = isOnline;
    const canDelete = visites.length < 1 && canEdit && isOnline;
    const canDuplicate = isOnline;
    let title_delete = "";
    if( !canDelete ){
        title_delete = "Suppression impossible si au moins une visite est liée à ce meublé";
    }

    const [show, setShow] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showDuplicateModal, setShowDuplicateModal] = useState(false);
    const [showRevisionModal, setShowRevisionModal] = useState(false);

    const btnRef = useRef();

    const onActions = () => {
        setShow(!show);
    };

    const onNewVisite = async (event) => {
        event.preventDefault();
        const newVisite = await getNewVisite(uid, user);
        if(newVisite){
            window.location = `/visites/visite-edit?id=${newVisite.uid}`;
        }
    }

    const onDelete = () => {
        if(canDelete) setShowDeleteModal(!showDeleteModal);
    }

    const onRevisionAction = () => {
        setShowRevisionModal(!showRevisionModal);
    }

    useEffect(() => {

        const onDocumentClick = (event) => {
            if(btnRef.current && !btnRef.current.contains(event.target)){
                setShow(false);
            }
        }

        document.addEventListener('click', onDocumentClick);

        return () => {
            document.removeEventListener('click', onDocumentClick);
        }

    }, []);

    const actionsOverlay = (props) => (
        <Popover className='popover-actions' {...props}>
            <Popover.Body>
                <div className='d-flex'>
                    <ButtonAction action="new_visite" handler={onNewVisite} />
                    <ButtonAction action="edit_meuble" url={`/meubles/meuble-edit/?id=${uid}`} isDisabled={!canEdit} />
                    <ButtonAction action="copy_meuble" handler={() => setShowDuplicateModal(!showDuplicateModal)} isDisabled={!canDuplicate} />
                    <ButtonAction key={`revision_read-${uid}`} action="revision_read" handler={onRevisionAction} isDisabled={!isOnline} />
                    {<ButtonAction action="delete_meuble" handler={onDelete} isDisabled={!canDelete} title={title_delete} />}
                </div>
            </Popover.Body>
        </Popover>
    );

    return (
        <>
            <OverlayTrigger placement='top' show={show} overlay={actionsOverlay} rootClose={true}>
                <button key={`btn-${meuble.id}`} ref={btnRef} className={`btn btn-icon-only btn-transparent btn-lg`} onClick={onActions}>
                    <IconAction className="icon" />
                </button>
            </OverlayTrigger>
            {showDuplicateModal && <ModaleDuplicate show={showDuplicateModal} setShow={setShowDuplicateModal} uid={uid}  type='meublé' />}
            {showRevisionModal && <ModaleRevision show={showRevisionModal} setShow={setShowRevisionModal} uid={uid} entite='meuble' />}
            {showDeleteModal && <ModaleArchivageAction
                show='jeter'
                setShow='jeter'
                selectionEntities={[meuble.id]}
                action='jeter'
                entity='meubles'
                show={showDeleteModal} setShow={setShowDeleteModal} uid={uid}
            />}
        </>
    )
}

export default ButtonMeubleActions;