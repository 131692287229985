import { useCallback, useContext, useState } from '@wordpress/element';
import { UserContext } from '@context';
import { useFetch, useOnlineStatus } from '@hooks';
import { useDropzone } from 'react-dropzone';
import { Document } from '@composants';

import './documents-upload.scss';

const DocumentsUpload = ({ uid, files, onUpload, onDelete }) => {

    const { user } = useContext(UserContext);
    const { postVisiteFile } = useFetch();
    const isOnline = useOnlineStatus();

    const [uploading, setUploading] = useState(false);

    const onDrop = useCallback((acceptedFiles) => {
        setUploading(true);
        acceptedFiles.forEach(async (file) => {
            const result = await postVisiteFile(user.id, uid, file);
            await onUpload(result);
            setUploading(false);
        });
    });

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop, 
    });

    const renderFiles = () => {
        return files.map(file => <Document key={file.url} file={file} deleteHandler={onDelete} />);
    }

    return(
        <div className='documents-wrapper'>
            
            {files && <div className='documents d-flex flex-wrap gap-3 mb-3'>
                {renderFiles()}
            </div>}
            
            {isOnline ?
                <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className={`dropzone${uploading ? ' upload' : ''}`}>
                        <span className='text-moyen'>Pour ajouter un document</span>
                        <strong className='text-bleu'>Cliquer pour choisir un fichier</strong>
                        <span className='caption text-moyen'>Accepte les formats png, jpg, jpeg, pdf</span>
                    </div>
                </div> : 
                <div className='dropzone'>
                    <span className='caption text-moyen'>Téléchargement momentanément impossible.</span>
                </div>
            }

        </div>
    );
};

export default DocumentsUpload;