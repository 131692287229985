import { useState } from '@wordpress/element';
import { useVisiteDispatch } from '@context/';
import { Switch, Badge } from '@composants';
import { Checkbox } from '@composants/form/';
import { ButtonHelp, ButtonIconComment } from '@composants/button/';
import { GRILLE_DEFAULTS, GRILLE_TEXTES } from '@/grille-classement';

import './critere.scss';

export const Critere = ({ data, editable=false }) => {

    const { id, statut, points, checked, comment } = data;
    const { na, auto } = GRILLE_DEFAULTS[id];
    const { title, aide } = GRILLE_TEXTES[id];

    const dispatch = useVisiteDispatch();

    const [isFocused, setIsFocused] = useState(false);

    const onComment = (texte) => {
        dispatch({ type: 'comment-critere', value: { id: id, comment: texte } });
    }

    const onCritere = (checked) => {
        dispatch({ type: 'critere', value: {id: id, checked: checked} });
    }

    const onNA = (event) => {
        dispatch({ type: 'NA', value: {id: id, checked: event.target.checked} });
    }

    const focusHandler = (event) => {
        setIsFocused(event.type === 'focus');
    }

    const obligatoire = statut.indexOf('X') !== -1 || statut.indexOf('ONC') !== -1;
    const disabled = statut === 'NA' || auto || data.auto;
    
    return(
        <div className={`critere${(checked) ? " checked" : ''}${(statut === 'NA') ? " na" : ""}${(statut === 'X' || statut === 'ONC') ? ' obligatoire' : ''}${statut === 'O' ? ' optionnel' : ''}${disabled ? ' disabled' : ''}${isFocused ? ' focus' : ''}`.trim()} tabIndex={id === 1 ? 0 : -1}>
            <div className='id-container'>
                <Badge variant={obligatoire ? "orange" : "moyen"}>{id}</Badge>
            </div>
            <div className='label-container flex-grow-1' dangerouslySetInnerHTML={{ __html: title}} />
            {editable ?
                <div className='critere-actions'>
                    {na && <Checkbox label="NA" handler={onNA} value={statut === 'NA'} />}
                    <div className='help'>
                        {aide.length > 0 && <ButtonHelp help={aide} />}
                    </div>
                    <ButtonIconComment action={onComment} comment={comment} edit={editable} label="Commenter ce critère" />
                    <Switch tabindex={id} value={points} checked={checked} disabled={disabled} handler={onCritere} focusHandler={focusHandler} />
                </div> : <div className='critere-actions'>
                    {comment && <ButtonIconComment action={onComment} comment={comment} edit={editable} />}
                    <Badge variant={checked ? "vert" : "disabled"}>{points}</Badge>
                </div>
            }
        </div>
    )
}
