const Cell = (props) => {

    const { children, className='', link } = props;

    const onLink = () => {
        window.location.href = link;
    }

    return (
        <>
        {link ? <div className={`cell cell-inner cell-link ${className}`.trim()} role="button" onClick={onLink}>
            {children}
        </div> : 
        <div className={`cell cell-inner ${className}`.trim()}>
            {children}
        </div>}
        </>
    )
}

export default Cell;