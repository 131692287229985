import { useState, useContext } from '@wordpress/element';
import { useVisiteDispatch, UserContext } from '@context/';
import { useOnlineStatus } from '@hooks';
import { ButtonIconComment, ButtonAction } from '@composants/button';
import { ModaleMeubles, ModaleDelete, ModaleSceller } from '@composants';

import './visite-actions-buttons-group.scss';

const VisiteActionsButtonGroup = ({ visite, edit }) => {

    const { user } = useContext(UserContext);
    const comment = visite.infos.comment ? visite.infos.comment : '';
    const dispatch = useVisiteDispatch();
    const isOnline = useOnlineStatus();
    
    /* Autorisations */
    const canEdit = (parseInt(user.id) === parseInt(visite.user_create)) && !visite.scelle;
    const canDelete = canEdit && isOnline;
    const canScelle = canEdit && visite.demandeur != null;

    const [showCopyModal, setShowCopyModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showScellerModal, setShowScellerModal] = useState(false);

    const onEdit = () => {
        window.location = `/visites/visite-edit/?id=${visite.uid}`;
    }

    const onComment = (texte) => {
        dispatch({ type: 'comment-visite', value: texte })
    }

    const onCopy = () => {
        setShowCopyModal(!showCopyModal);
    }

    const onLockVisite = () => {
        setShowScellerModal(!showScellerModal);
    }

    return(
        <div className='actions actions-btn-group d-flex gap-1'>
            {(canEdit && !edit) && <ButtonAction size="sm" className="normal" action='edit' handler={onEdit} />}
            <ButtonAction size="sm" className="normal" action='copy' handler={onCopy} />
            <ButtonIconComment action={onComment} comment={comment} edit={true} />

            {!canScelle && <ButtonAction size="sm" className="normal" action='lock' handler={onLockVisite} isDisabled="true" title="Pour sceller vous devez : - choisir un demandeur - être l'inspecteur ayant créé cette visite - Avoir une connexion internet " />}
            {canScelle && <ButtonAction size="sm" className="normal" action='lock' handler={onLockVisite} />}

            {canEdit && <ButtonAction size="sm" className="normal" action='delete' handler={() => setShowDeleteModal(!showDeleteModal)} isDisabled={!canDelete} />}
            {showCopyModal && <ModaleMeubles show={showCopyModal} setShow={setShowCopyModal} uid={visite.uid} />}
            {showDeleteModal && <ModaleDelete show={showDeleteModal} setShow={setShowDeleteModal} uid={visite.uid} />}
            {showScellerModal && <ModaleSceller show={showScellerModal} setShow={setShowScellerModal} uid={visite.uid} />}
        </div>
    )
}

export default VisiteActionsButtonGroup