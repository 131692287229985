import { useMemo, useState, useEffect } from '@wordpress/element';
import { createColumnHelper } from "@tanstack/react-table";
import { useFilters } from '@hooks';
import { Classement, TableEntite, Cell } from '@composants';
import { CheckboxSelection } from '@composants/form';
import { ButtonVisiteActions, ButtonHelp } from '@composants/button';
import { ReactComponent as Maison } from '@images/maison.svg';
import { ReactComponent as Appartement } from '@images/maison.svg';
import { ReactComponent as IconLock } from '@images/lock.svg';

const columnHelper = createColumnHelper();
const { dateRangeFilter, classementFilter, scelleFilter } = useFilters();

const TableVisites = ({ visites, selectionHandler }) => {

    visites.sort((a,b) => b.date_update - a.date_update);

    const [rowSelection, setRowSelection] = useState({});
    const meuble_notexist = "Ce meublé est introuvable dans Nomade. Il peut-être est archivé ou supprimé. Cependant il est possible qu'une partie de ses informations soient stockées directement dans la visite.";
    const meuble_sans_class = "Cette visite n'a pas de N° de classement, vous ne pouvez donc pas l'exporter pour le classement. Veuillez vérifier si le meublé a bien un code insée. Ensuite dans l'édition de la visite veuillez cliquer sur 'Générer la décision de classement' pour lancer la génération du N°. Revenez ensuite sur cette liste et constatez si le N° est bien créé.";

    const columns = useMemo(() => [
        {
            id: 'select',
            header: ({ table }) => (
                <CheckboxSelection {...{
                    checked: table.getIsAllRowsSelected(),
                    indeterminate: table.getIsSomeRowsSelected(),
                    onChange: table.getToggleAllRowsSelectedHandler(),
                }} />
            ),
            cell: ({ row }) => (
              <Cell className="cell-select">
                <CheckboxSelection {...{
                    checked: row.getIsSelected(),
                    indeterminate: row.getIsSomeSelected(),
                    onChange: row.getToggleSelectedHandler(),
                }} />
              </Cell>
            )
        },
        columnHelper.display({
            id: 'vignette',
            cell: props => {
                const meuble = props.row.original.meuble;
                return <Cell className={`vignette vignette-meuble`} link={`/visites/visite-voir?id=${props.row.original.uid}`}>
                    {meuble?.vignette_url ? <img src={meuble?.vignette_url} /> :
                        meuble?.type === 'Maison' ? <Maison /> : <Appartement />
                    }
                </Cell>
            }
        }),
        columnHelper.accessor(row => `${row.identification.uid} ${row.identification.titre}`, {
            id: 'identification',
            header: 'Identification',
            cell: props => {
                return <Cell link={`/visites/visite-voir?id=${props.row.original.uid}`}>
                    <div className='d-flex gap-1'>
                        {props.row.original.scelle && <IconLock width="24" className="icon-scelle" />}
                        <div className='d-flex flex-column'>
                            <span className='text-overflow'>{props.row.original.identification.uid}</span>
                            {props.row.original.meuble && (props.row.original.meuble.titre ? props.row.original.meuble.titre : props.row.original.meuble.id)}
                            {!props.row.original.meuble && <div className='d-flex align-items-center ps-n2'>
                                <ButtonHelp help={meuble_notexist} className="orange" />
                                <strong className="text-orange body-small">Meublé inexistant</strong>
                            </div>}
                        </div>
                    </div>
                </Cell>
            }
        }),
        columnHelper.accessor(row => `${row.meuble?.otsi}`, {
            header: 'OTSI',
            cell: props => {
                return <Cell><span className='text-center'>{props.row.original.meuble?.otsi}</span></Cell>
            },
        }),
        columnHelper.accessor(row => `${row.meuble?.num_classement}`, {
            header: 'N°Classement',
            cell: props => {
                return <Cell>
                    { props.row.original.meuble &&  props.row.original.meuble.num_classement && <span className='text-center body-small'>{props.row.original.meuble.num_classement}</span> }
                    {!props.row.original.meuble || !props.row.original.meuble?.num_classement && <div className='d-flex align-items-center ps-n2'>
                        <ButtonHelp help={meuble_sans_class} className="orange" />
                        <strong className="text-orange body-small">Pas de N°</strong>
                    </div>}

                </Cell>
            },
        }),
        columnHelper.accessor('contact', {
            header: 'Contact',
            cell: props => {
                const data = props.row.original;
                const contact = data.contact;
                return <Cell link={`/visites/visite-voir?id=${data.uid}`}>
                    {contact ? contact : <span className='text-muted'>Aucun contact</span>}
                    {data.hasLoueur ? null : <span className='text-orange body-small'>Sans propriétaire</span>}
                </Cell>
            }
        }),
        columnHelper.accessor('date_visite', {
            header: 'Date visite',
            cell: props => {
                const data = props.row.original;
                return <Cell link={`/visites/visite-voir?id=${data.uid}`}>{data.date_visite.toLocaleDateString()}</Cell>
            },
            filterFn: (row, columnId, value) => dateRangeFilter(row, columnId, value)
        }),
        columnHelper.accessor(row => `${row.commune.cp} ${row.commune.nom}`, {
            header: 'Commune',
            cell: props => {
                const data = props.row.original;
                return <Cell link={`/visites/visite-voir?id=${data.uid}`}>
                    <span className='text- center'>{data.commune.cp}</span>
                    <span className='text- center'>{data.commune.nom}</span>
                </Cell>
            },
        }),
        columnHelper.accessor('scelle', {
            header: 'Scellé',
            cell: props => {
                const data = props.row.original;
                return <Cell link={`/visites/visite-voir?id=${data.uid}`}>
                    <span className='text-center'>{data.scelle ? 'oui' : 'non'}</span>
                </Cell>
            },
            filterFn: (row, columnId, value) => scelleFilter(row, columnId, value)
        }),
        columnHelper.accessor(row => `${row.capacite}`, {
            header: 'Capacité',
            cell: props => {
                const data = props.row.original;
                return <Cell link={`/visites/visite-voir?id=${data.uid}`}>
                    <div className='text-center'>{data.capacite}</div>
                </Cell>
            },
            filterFn: (row, columnId, value) => classementFilter(row, columnId, value)
        }),
        columnHelper.accessor('classement', {
            header: 'Classement',
            cell: props => {
                const data = props.row.original;
                return <Cell link={`/visites/visite-voir?id=${data.uid}`}>
                    <Classement count={data.classement} />
                </Cell>
            },
            filterFn: (row, columnId, value) => classementFilter(row, columnId, value)
        }),
        columnHelper.display({
            id: 'actions',
            cell: props => {
                const data = props.row.original;
                return <Cell>
                    <ButtonVisiteActions uid={data.uid} scelle={data.scelle} comment={data.comment} user_create={data.user_create} />
                </Cell>
            }
        }),
    ]);

    useEffect(() => {
        const visitesToExport = Object.keys(rowSelection).map(key => visites[key].uid);
        selectionHandler(visitesToExport);
    }, [rowSelection]);

    return(
        <TableEntite data={visites} columns={columns} rowSelection={rowSelection} selectionHandler={setRowSelection} className="hover" />
    )
}

export default TableVisites;