import { useState, useContext } from '@wordpress/element';
import { Alert, Modal } from 'react-bootstrap';
import { UserContext } from '@context';
import { useFetch } from '@hooks';
import { WORDING_ACTIONS_ENTITIES } from '@constantes';
import { Button } from '@composants/button';
import { Checkbox, TextInput } from '@composants/form';
import { ReactComponent as IconClose } from '@images/close.svg';

const WORDING = WORDING_ACTIONS_ENTITIES;

const ModaleArchivageAction = ({ show, setShow, selectionEntities, action, entity }) => {

    const { user } = useContext(UserContext);
    const { actionEntities } = useFetch();

    const [actionMode, setActionMode] = useState('etendu');
    const [actionRaison, setActionRaison] = useState();
    const [messages, setMessages] = useState();

    const closeModale = () => {
        window.location.reload();
    }

    const doAction = async () => {

        if(!['restaurer', 'jeter', 'archiver'].includes(action)) return;

        const response = await actionEntities(user.id, selectionEntities, entity.substring(0, entity.length-1), actionMode, actionRaison, action);
        if(response.success){
            setMessages(response.data.message);
        }
    };
    
    return (
        <Modal show={show} onHide={() => setShow(false)} size='lg'>
            <Modal.Header>
                <Modal.Title>{WORDING[action][entity].titre}</Modal.Title>
                <button className='btn-close' data-bs-dismiss="modal" aria-label="Close" onClick={closeModale}>
                    <IconClose />
                </button>
            </Modal.Header>
            <Modal.Body>
            {messages &&
                <div className='d-flex flex-column gap-2'>
                    {messages.map(message => <Alert className='mb-0 p-2' variant={message.type} key={message.texte}>
                        <div className='d-flex flex-column'>
                            {message.titre && <strong>{message.titre}</strong>}
                            <span>{message.texte}</span>
                        </div>
                    </Alert>)}
                </div>
            }
            {!messages && <>
                <Checkbox
                    className='mb-3'
                    value={actionMode === 'simple'}
                    label={WORDING[action].labelSimple}
                    help={WORDING[action][entity].infoSimple ? WORDING[action][entity].infoSimple : null}
                    handler={() => setActionMode('simple')}
                />
                <Checkbox
                    className='mb-3'
                    value={actionMode === 'etendu'}
                    label={WORDING[action].labelEtendu}
                    help={WORDING[action][entity].infoEtendu ? WORDING[action][entity].infoEtendu.info : null}
                    helpVariant = {WORDING[action][entity].infoEtendu ? WORDING[action][entity].infoEtendu.variant : null}
                    handler={() => setActionMode('etendu')}
                />
                {action!=='jeter' &&
                    <TextInput
                        label={action === 'archiver' ? `Commentaire (obligatoire)`: "Commentaire"}
                        multiline={true}
                        handler={(e) => setActionRaison(e.target.value)}
                    />
                }
            </>}
        </Modal.Body>
            <Modal.Footer>
            <Button variant="outline" name="cancel" onClick={closeModale}>{messages ? 'Fermer' : 'Annuler'}</Button>
            {!messages && <Button
                type="submit"
                onClick={doAction}
                disabled={action === 'archiver' && (!actionRaison || actionRaison.length < 1)}
            >
                {WORDING[action].labelAction}
            </Button>}
        </Modal.Footer>
        </Modal>
    );
};

export default ModaleArchivageAction;