import React from 'react';
import { ReactComponent as Icon } from '@images/arrow-down.svg';

const ButtonDropdown = React.forwardRef(({ variant, children, onClick }, ref) => {
    return (
        <button ref={ref} className={`btn dropdown ${variant}`} onClick={onClick}>
            <span className="label">{children}</span>
            <Icon />
        </button>
    );
});

export default ButtonDropdown;