import { useVisite, useUser, useVisiteDispatch } from '@context';
import { useFetch } from '@hooks';
import { Button } from '@composants/button';
import './visite-edit-footer.scss';

const pages = ['infos', 'grille', 'resultats'];

const VisiteEditFooter = ({ page, handler }) => {

    const { visite } = useVisite();
    const { user } = useUser();
    const { postVisite } = useFetch();
    const dispatch = useVisiteDispatch();

    const currentIndex = pages.indexOf(page);

    const onRetour = () => {
        if(currentIndex > 0){
            handler(pages[currentIndex - 1])
        }
    }

    const onSuivant = () => {
        if(currentIndex < 2){
            handler(pages[currentIndex + 1]);
        }
    }

    const onSave = async () => {
        if(window.navigator.onLine && user && visite){
            const result = await postVisite(user.id, visite);
            if(result.success && !visite.saved){
                dispatch({ type: 'saved', value: true });
            }
            window.location = `/visites/visite-voir?id=${visite.uid}`;
        } else {
            window.location = `/visites/visite-voir?id=${visite.uid}`;
        }
    }

    return(
        <footer className="visite-edit-footer">
            <div className='d-flex justify-content-end align-items-center gap-3 p-3'>
                <div className='d-flex gap-3'>
                    {page !== 'infos' && <Button variant={'outline'} onClick={onRetour}>Retour</Button>}
                    {page !== 'resultats' && <Button onClick={onSuivant}>Continuer</Button>}
                    {page === 'resultats' && <Button onClick={onSave}>Enregistrer</Button>}
                </div>
            </div>
        </footer>
    );
}

export default VisiteEditFooter;