import { useState } from '@wordpress/element';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Modale } from '@composants';

import { ReactComponent as Icon } from '@images/comment.svg';

const ButtonIconComment = ({ action, comment='', edit, label }) => {

    const [showModal, setShowModal] = useState(false);
    const [commentaire, setCommentaire] = useState(comment);

    const onComment = (event) => {
        setCommentaire(event.target.value);
    }

    const onHide = (event) => {
        if(event && event.target.name === 'save'){
            action(commentaire);
            setTimeout(() => {
                setShowModal(false);
            }, 300)
        } else {
            setCommentaire(comment);
            setShowModal(false);
        }
    }

    return(
        <>
            <OverlayTrigger placement='top' overlay={<Tooltip>{edit ? `${label ? label : 'Commenter cette visite'}` : "Lire le commentaire"}</Tooltip>}>
                <a key={`${action}`} className={`btn btn-icon-only btn-action btn-comment sm normal ${commentaire ? "active" : ""}`.trim()} onClick={() => setShowModal(true)} tabIndex={-1}>
                    <Icon />
                </a>
            </OverlayTrigger>
            <Modale show={showModal} hideHandler={onHide} title="Saisie d'un commentaire" size="lg" save={edit}>
                {edit ? 
                    <textarea className='form-control' rows="10" value={commentaire} onChange={onComment} /> :
                    <div className='p-3'>{commentaire ? 
                        <span dangerouslySetInnerHTML={{__html: commentaire.replace(/\n/g, "<br />")}} /> : 
                        <span className='text-center'>Aucun commentaire pour cette visite.</span>
                    }</div>
                }
            </Modale>
        </>
    );
};

export default ButtonIconComment;