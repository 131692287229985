import { useContext, useState, useEffect } from '@wordpress/element';
import { VisitesContext } from '@context';
import { useOnlineStatus } from '@hooks';
import { Actualites } from '@composants';
import { VisitesListe, ContactsListe, StatsVisites, VisitesFinValidite, StatsMeubles } from '@composants/entites';

import './page-accueil.scss';


const PageAccueil = () => {
    
    const { isLoading, visites, contacts, meubles } = useContext(VisitesContext);
    const isOnline = useOnlineStatus();

    const [lastVisites, setLastVisites] = useState();
    const [lastContacts, setLastContacts] = useState();

    useEffect(() => {

        if(visites){
            const sortedVisites = visites.sort((a, b) => new Date(b.date_update) - new Date(a.date_update));
            setLastVisites(sortedVisites);
        }

        if(contacts){
            const sortedContacts = contacts.sort((a,b) => new Date(b.date_update) - new Date(a.date_update));
            setLastContacts(sortedContacts.slice(0,3));
        }

    }, [visites, contacts]);

    if(isLoading) return <div className='text-center p-5'>Chargement des données…</div>;
    if(!visites) return <div className='text-center p-5'>Chargement des visites…</div>;

    return(
        <div className="content-wrapper">
            <header className="entry-header">
                <div className="d-flex align-items-center justify-content-between w-100">
                    <h3 className="h3">Mon tableau de bord</h3>
                    <div className="actions">
                        <button className="btn" title="Importer" onClick={() => window.location = "/import"} disabled={!isOnline}>Importer</button>
                    </div>

                </div>
            </header>
            <div className="entry-content">
                
                <Actualites />
                
                {isLoading && <div className="d-flex flex-column gap-4 p-3">Chargement…</div>}

                {!isLoading && 

                    <div className="d-flex flex-column gap-4 p-3">

                        <div className="row-2-cols">
                            <div className="d-flex flex-grow-1 gap-4">
                                <div className='w-100'>
                                    {lastVisites && <VisitesListe visites={lastVisites} />}
                                </div>
                            </div>
                            <div className="d-flex flex-grow-1 gap-4">
                                <div className='w-100'>
                                    <h4>Nouveaux contacts</h4>
                                    {lastContacts && <ContactsListe contacts={lastContacts} />}
                                </div>
                            </div>
                        </div>

                        <StatsMeubles meubles={meubles} visites={visites} />

                        <VisitesFinValidite meubles={meubles} visites={visites} />

                        {visites && <StatsVisites visites={visites} />}

                    </div>
                }
            </div>
        </div>
    )
}

export default PageAccueil;