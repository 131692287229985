import { useEffect, useState } from '@wordpress/element';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import VisiteItem from '../visite-item/visite-item';
import { Badge } from '@composants';

const VisitesListe = ({ visites }) => {

    const [visiteListe, setVisiteListe] = useState();
    const [departements, setDepartements] = useState();
    const [selection, setSelection] = useState('tous');

    if(visites.length < 1){
        return <div className='text-moyen pt-3'>Aucune visite</div>
    }

    useEffect(() => {
        if(visites){

            /** Ajouter un attribut département à chaque visite */
            const visitesUpdated = visites.map(visite => {
                visite.departement = (visite.infos.meuble && visite.infos.meuble.dep) ? visite.infos.meuble.dep : 'sans';
                return visite;
            });

            /** trier les visites par département */
            const visitesParDepartement = visitesUpdated.reduce((result, visite) => {
                if(!result[visite.departement]){
                    result[visite.departement] = [];
                }
                result[visite.departement].push(visite);
                return result;
            }, {});

            visitesParDepartement['tous'] = visitesUpdated;

            /** Extraire les départements seuls */
            setDepartements(Object.keys(visitesParDepartement).sort((a,b) => {
                if(!isNaN(parseInt(a)) && !isNaN(parseInt(b))) return a - b;
                if(!isNaN(parseInt(a)) && isNaN(parseInt(b))) return 1;
                if(isNaN(parseInt(a)) && !isNaN(parseInt(b))) return -1;
                return b.localeCompare(a);
            }));

            setVisiteListe(visitesParDepartement);

        }
    }, [visites]);

    return(
        <div className="visites-liste">
            <div className='d-flex justify-content-between mb-2 flex-wrap'>
                <h4>Dernières visites</h4>
                <div className='d-flex gap-2 flex-wrap'>
                    {departements && departements.map(departement => 
                        <Badge 
                            onClick={() => setSelection(departement)}
                            variant={selection === departement ? 'bleu' : 'outline'} 
                            size='sm' 
                            className='square px-2 link' 
                            key={departement}
                            tooltip={departement === 'sans' ? 'Sans département' : false}
                        >
                                {departement === 'sans' ? '?' : departement === 'tous' ? 'Tous' : departement}
                        </Badge>)}
                </div>
            </div>
            <div className="d-flex flex-column gap-2">
                {visiteListe && visiteListe[selection].slice(0,3).map(visite => <VisiteItem key={visite.uid} visite={visite} />)}
            </div>
        </div>
    );
};

export default VisitesListe;