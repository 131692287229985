import { createContext, useContext, useEffect, useState, useRef } from '@wordpress/element';
import { userIdCookieName } from '@constantes';
import { useDB, useOnlineStatus } from '@hooks';

const UserContext = createContext(null);
const UserDispatchContext = createContext(null);

const UserProvider = ({ children }) => {

    const { db } = useDB();
    const isOnline = useOnlineStatus();
    const [user, setUser] = useState();
    
    const logoutRef = useRef(document.querySelector('.page-content-header .user-info'));
    const usernameRef = useRef(document.querySelector('.page-content-header .user-info .user-name'));

    useEffect(() => {

        const clearAllData = async () => {
            await db.users.clear();
            await db.contacts.clear();
            await db.meubles.clear();
            await db.visites.clear();
        }

        const getNomadeCookie = () => {
            const cookies = document.cookie;
            const cookiesArray = cookies.split(';');
            const nomadeCookie = cookiesArray.find(item => item.indexOf(userIdCookieName) !== -1);
            return nomadeCookie;
        }

        const getCookieUserId = () => {

            const nomadeCookie = getNomadeCookie();
            
            if(nomadeCookie){
                const lastLoggedUserId = nomadeCookie.split('=')[1];
                return parseInt(lastLoggedUserId);
            }

            return null;
        }

        const updateUser = async () => {

            // Chargement des utilisateurs enregistrés
            const dbUsers = await db.users.toArray();

            if(isOnline){
    
                if(window.cUser && window.cUser.id !== '') {

                    const loggedUser = dbUsers.find(user => user.id === parseInt(window.cUser.id));

                    if(!loggedUser){

                        console.warn("cUser n'est pas dans la base de données locale");
                        return;

                    } else {

                        const lastSavedUserId = localStorage.getItem('userid');
                        
                        if(lastSavedUserId && loggedUser.id !== parseInt(lastSavedUserId)){
                            await clearAllData();
                            console.warn("Changement d'utilisateur");
                            console.info("Données supprimées");
                        }
                        
                        localStorage.setItem('userid', loggedUser.id);
                        loggedUser.loggedin = true;
                    }
                    
                    setUser(loggedUser);
        
                } else {

                    console.warn("Pas de cUser");
                    return;

                }
    
            } else {

                const nomadeCookie = getNomadeCookie();
                console.log("Pas de cookie nomade");
                if(!nomadeCookie) return;

                const lastLoggedUserId = getCookieUserId();
                const lastLoggedUser = dbUsers.find(user => user.id === lastLoggedUserId);

                console.log("Aucun utilisateur précédement enregistré");
                if(!lastLoggedUser) return;
                
                localStorage.setItem('userid', lastLoggedUser.id);
                lastLoggedUser.loggedin = true;
                
                setUser(lastLoggedUser);
            }
        }

        updateUser();

    }, []);

    useEffect(() => {

        if(logoutRef.current){

            // Affichage ou pas du lien /profil si hors-ligne ou pas
            if(!isOnline){
                logoutRef.current.classList.add('d-none');
            } else {
                logoutRef.current.classList.remove('d-none');
            }
        }

    }, [isOnline, logoutRef]);
            
    // Correction affichage du user si dans cache enregistré avec warning php
    if(usernameRef.current && user){
        usernameRef.current.textContent = `${user.first_name} ${user.last_name}`;
    }
    
    const getUserById = async (id) => {
        return await db.users.get(id);
    }

    return (
        <UserContext.Provider value={{ user, getUserById }}>
            {children}
        </UserContext.Provider>
    );
}

const useUser = () => {
    return useContext(UserContext);
}

const useUserDispatch = () => {
    return useContext(UserDispatchContext);
}

export {
    UserContext, 
    UserProvider, 
    useUserDispatch, 
    useUser, 
};