import { Page, Text, View, Image, StyleSheet } from '@react-pdf/renderer';
import LettreBottom from './lettre-bottom';

const styles = StyleSheet.create({
    texte: {
        marginBottom: '5mm',
        textAlign: 'justify',
        // textIndent: '15mm',
    }
});

const CompteRendu = ({ visite, inspecteur }) => {

    const { demandeur, date_decision, date_visite, scelle } = visite;
    const { comment, classement_cible, classement_obtenu, meuble, surfaces, capacite } = visite.infos;
    const logotypeUrl = inspecteur.file_logo || "/wp-content/uploads/logotype-clevacances.png";
    const dateDecision = new Date(date_decision ? date_decision : Date.now()).toLocaleDateString('fr-FR', {day: 'numeric', month: 'long', year: 'numeric'});
    const dateVisite = new Date(date_visite).toLocaleDateString('fr-FR', {day: 'numeric', month: 'long', year: 'numeric'});
    const dateDebutValidite = new Date(date_decision ? date_decision : Date.now());
    const dateValidite = dateDebutValidite.setFullYear(dateDebutValidite.getFullYear() + 5);
    const dateValiditeLabel = new Date(dateValidite).toLocaleDateString('fr-FR', {day: 'numeric', month: 'long', year: 'numeric'});

    return(
        <Page size="A4" style={{ padding: '10mm 15mm 30mm 15mm', fontFamily: 'Lato', fontSize: '12pt' }}>

            <View style={{maxWidth: '65mm', maxHeight: '35mm'}}>
                <Image src={logotypeUrl} style={{objectFit: 'contain', maxHeight: '35mm', width: '100%', height: '100%'}} />
            </View>
            
            <View style={{width: '100%', marginBottom: '10mm', display: 'flex', flexDirection: 'row', fontSize: '10pt'}}>
                <View style={{width: '60%'}}>
                    <View style={{fontWeight: 'bold'}}>
                        <Text>{inspecteur.civilite} {inspecteur.first_name} {inspecteur.last_name}</Text>
                    </View>
                    {inspecteur.organisme_email && <Text>{inspecteur.organisme_email}</Text>}
                    {inspecteur.organisme_tel && <Text>{inspecteur.organisme_tel}</Text>}
                </View>
                <View style={{width: '40%'}}>
                    <View style={{fontWeight: 'bold'}}>
                        <Text>{demandeur.civilite} {demandeur.prenom} {demandeur.nom}</Text>
                    </View>
                    {demandeur.adresse1 ? <Text>{demandeur.adresse1}</Text> : null}
                    {demandeur.adresse2 ? <Text>{demandeur.adresse2}</Text> : null}
                    {demandeur.adresse3 ? <Text>{demandeur.adresse3}</Text> : null}
                    <Text>{demandeur.code_postal} {demandeur.commune}</Text>
                    <Text style={{marginBottom: '3mm'}}>{demandeur.pays}</Text>
                    <Text>{inspecteur.organisme_commune}, le {dateDecision}</Text>
                </View>
            </View>

            <View style={{marginBottom: '6mm', fontSize: '11pt', fontWeight: 'bold', textAlign: 'center', padding: '5mm', border: '1pt solid #000'}}>
                <Text>Compte-rendu de visite de classement {classement_cible} {classement_cible > 1 ? 'étoiles' : 'étoile'}</Text>
            </View>

            <View style={{textAlign: 'left', marginBottom: '6mm', paddingBottom: '6mm', borderBottom: '1px solid black'}}>
                <Text style={styles.texte}>{demandeur.civilite === 'M.' ? 'Monsieur' : demandeur.civilite === 'Mme' ? 'Madadame' : 'Madame, Monsieur'},</Text>
                <Text style={styles.texte}>Nous avons le plaisir de vous communiquer ci-après, le compte-rendu de la visite de classement de votre hébergement.</Text>
                <Text style={{...styles.texte, marginBottom: '0', textIndent: '0'}}>
                    <Text style={{fontWeight: 'bold'}}>Visite effectuée le : </Text>
                    <Text>{dateVisite} par {inspecteur.civilite} {inspecteur.first_name} {inspecteur.last_name}{inspecteur.organisme_titre ? ' - ' + inspecteur.organisme_titre : ''}.</Text>
                </Text>
                <Text style={{...styles.texte, marginBottom: '0', textIndent: '0'}}>
                    <Text style={{fontWeight: 'bold'}}>Hébergement situé à : </Text>
                    <Text>{meuble.adresse1}{meuble.adresse2 !== '' ? ' ' + meuble.adresse2 : ''}, {meuble.code_postal} {meuble.commune}.</Text>
                </Text>
                <Text style={{...styles.texte, marginBottom: '0', textIndent: '0'}}>
                    <Text style={{fontWeight: 'bold'}}>Superficie : </Text><Text>{parseFloat(surfaces.total).toFixed(2)} m².</Text>
                </Text>
                <Text style={{...styles.texte, marginBottom: '0', textIndent: '0'}}>
                    <Text style={{fontWeight: 'bold'}}>{scelle ? `Classement : `: `Proposition de classement : ` }</Text>
                    <Text>{classement_obtenu} {classement_obtenu > 1 ? 'étoiles' : 'étoile'} pour {capacite} {capacite > 1 ? 'personnes' : 'personne'}{scelle ? `, valable jusqu'au ${dateValiditeLabel}.` : '.'}</Text>
                </Text>
            </View>

            <View style={{textAlign: 'left', marginBottom: '0'}}>
                <Text style={styles.texte}>{comment}</Text>
            </View>

            <View style={{paddingLeft: '90mm', marginBottom: '0'}} wrap={false}>
                <Text>{inspecteur.civilite} {inspecteur.first_name} {inspecteur.last_name}</Text>
                <Text>Responsable de l'inspection</Text>
                {(inspecteur.file_signature !== '') && <View style={{height: "35mm"}}>
                    <Image style={{width: "100%", height: "100%", objectFit: "contain", objectPosition: 'top left'}} src={inspecteur.file_signature} />
                </View>}
            </View>

            <LettreBottom inspecteur={inspecteur} visite={visite} />

        </Page>
    );
}

export default CompteRendu;