import { useFilters } from '@context';

const PageTitle = ({ exportIds, entite }) => {

    const { rowCount } = useFilters();

    const singulier = entite === 'visite' ? 'visite' : entite === 'meuble' ? 'meublé' : 'contact';
    const pluriel = entite === 'visite' ? 'visites' : entite === 'meuble' ? 'meublés' : 'contacts';

    return (
        <h1>
            {exportIds.length > 0 && <span className='text-moyen'>{exportIds.length} / </span>}
            {rowCount} {rowCount > 1 ? pluriel : singulier}
        </h1>
    );
}

export default PageTitle;