import { Page, Text, View } from '@react-pdf/renderer';
import { STRUCTURE, GRILLE_DEFAULTS, GRILLE_TEXTES } from '@/grille-classement';
import { SURFACES_MIN, SURFACES_MIN_CHAMBRE } from '@constantes';

const PageGrille = ({ visite, meuble, contacts, inspecteur }) => {

    const contact = contacts.loueur ? contacts.loueur : contacts.mandataire ? contacts.mandataire : null;
    const allNA = visite.grille.filter(critere => critere.statut === 'NA');
    const allNAString = allNA.map(critere => critere.id).join(', ');
    const allONC = visite.grille.filter(critere => critere.statut === 'ONC' && !critere.checked);
    const allONCString = allONC.length ? allONC.map(critere => critere.id).join(', ') : 'aucun critère.';

    return(
        <Page size="A4" style={{ padding: '10mm', fontFamily: 'Lato' }}>

            <View style={{fontSize: '5.6pt', textAlign: 'center', marginBottom: '3mm'}}>
                <View><Text>Tableau de classement des meublés de tourisme</Text></View>
                <View style={{fontStyle: 'italic'}}><Text>(Source : arrêté du 24 novembre 2021 modifiant l'arrêté du 17 août 2010 fixant les normes et la procédure de classement des meublés de tourisme)</Text></View>
            </View>

            <View style={{fontSize: '9pt', textAlign: 'center', marginBottom: '2mm'}}>
                <Text>RAPPORT DETAILLÉ RENSEIGNÉ PAR L'ORGANISME VISÉ AU 1° OU AU 2° DU CODE DU TOURISME</Text>
            </View>

            <View style={{display: 'flex', flexDirection: 'row', fontSize: '5.6pt', marginBottom: '2mm'}}>
                <View style={{width: '50%'}}>
                    <View><Text>Nom de l'organisme évaluateur : {inspecteur.organisme_titre}</Text></View>
                    <View><Text>Adresse : {inspecteur.organisme_adresse}</Text></View>
                    <View><Text>Code postal / Ville : {inspecteur.organisme_cp} {inspecteur.organisme_commune}</Text></View>
                    <View><Text>Tél. : {inspecteur.organisme_tel}  E-mail : {inspecteur.email}</Text></View>
                    <View><Text>Site internet : {inspecteur.organisme_url ? inspecteur.organisme_url : '/'}  Référent dossier : {inspecteur.civilite} {inspecteur.first_name} {inspecteur.last_name}</Text></View>
                </View>
                <View style={{width: '50%'}}>
                    <View style={{fontWeight: 'bold'}}><Text>Meublé visité : {meuble.titre}</Text></View>
                    <View><Text>Nom / Prénom : {contact.civilite} {contact.nom} {contact.prenom}</Text></View>
                    <View><Text>Adresse : {meuble.adresse1} {meuble.adresse2} {meuble.code_postal} {meuble.commune}</Text></View>
                </View>
            </View>

            <View style={{marginBottom: "2mm"}}>
                <View style={{fontSize: '8.4pt', marginBottom: '1mm'}}><Text>Synthèse de la visite d'inspection</Text></View>
                <View style={{fontSize: '5.6pt'}}>
                    <View style={{marginBottom: '1mm'}}>
                        <Text>Critères "non applicables" pour le meublé entraînant un changement des totaux obligatoires et "à la carte" à atteindre : {allNAString}</Text>
                    </View>
                    <View style={{marginBottom: '1mm'}}>
                        <Text>Critères « obligatoires non compensables » non respectés : {allONCString}</Text>
                    </View>
                </View>
            </View>

            <View style={{fontSize: '5.2pt', marginBottom: '1mm'}}>
                <Text><View style={{fontWeight: 'bold'}}><Text>Légende :</Text></View> X = critère obligatoire - O = critère optionnel - NA = critère non applicable - X ONC = critère obligatoire non compensable</Text>
            </View>

            <View style={{marginBottom: '5mm'}}>
                <Grille grille={visite.grille} visite={visite} />
            </View>

            <View wrap={false}>
                <View style={{marginBottom: '5mm', padding: '2mm', backgroundColor: '#eee', fontSize: '6.6pt', fontWeight: 'bold'}}>
                    <Text>Les résultats</Text>
                </View>

                <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <View>
                        <View style={{marginBottom: '2mm'}}>
                            <Resultats visite={visite} />
                        </View>
                        <View style={{display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: '6.6pt'}}>
                            <View style={{marginRight: '1mm'}}>
                                <Text>Capacité maximale du meublé</Text>
                            </View>
                            <View style={{padding: '1mm', border: '0.5pt solid #000', textAlign: 'center', width: '20mm'}}>
                                <Text>{visite.infos.capacite}</Text>
                            </View>
                        </View>
                    </View>
                    <View style={{border: '0.5pt solid #000', width: '48mm', height: '38mm', padding: '1mm', fontSize: '5.6pt'}}>
                        <Text>Signature du propriétaire ou mandataire, précédé de la mention "Lu et Approuvé " :</Text>
                    </View>
                </View>
            </View>

        </Page>
    )
}

export default PageGrille;

const Grille = ({grille, visite}) => {

    const surfaces = visite.infos.surfaces;
    const classement = visite.infos.classement_cible;
    const index = classement > 0 ? classement - 1 : 0;

    const textePrerequis = `Un logement meublé d’une pièce d’habitation destiné à accueillir une ou deux personnes doit avoir une surface minimale de 9m² lorsque la cuisine est séparée ou d'au moins 12m² lorsqu’il existe un coin cuisine`;

    const surfacesDefault = (<>
        <View style={{fontSize: '5.2pt', display: 'flex', flexDirection: 'row', gap: '1mm', border: '0.5pt solid #eee', marginTop: '-0.5pt'}} wrap={false}>
            <View style={{padding: '1pt 2pt', width: '5mm', borderRight: '0.5pt solid #eee', textAlign: 'right'}}><Text> </Text></View>
            <View style={{padding: '1pt 2pt', width: '60mm', borderRight: '0.5pt solid #eee'}}>
                <Text>Surface de base minimum d'un logement composé d'une seule pièce d'habitation pour une ou deux personnes (studio ou T1)</Text>
            </View>
            <View style={{padding: '1pt 2pt', width: '9mm', borderRight: '0.5pt solid #eee', textAlign: 'center'}}><Text>{`${SURFACES_MIN[index]}m²`}</Text></View>
            <View style={{padding: '1pt 2pt', width: '9mm', borderRight: '0.5pt solid #eee', textAlign: 'center'}}><Text> </Text></View>
            <View style={{padding: '1pt 2pt', width: '9mm', borderRight: '0.5pt solid #eee', textAlign: 'center'}}><Text> </Text></View>
            <View style={{padding: '1pt 2pt', width: '9mm', borderRight: '0.5pt solid #eee', textAlign: 'center'}}><Text> </Text></View>
            <View style={{padding: '1pt 2pt', width: '12mm', borderRight: '0.5pt solid #eee', textAlign: 'center'}}><Text> </Text></View>
            <View style={{padding: '2pt', width: '60mm'}}><Text> </Text></View>
        </View>
        <View style={{fontSize: '5.2pt', display: 'flex', flexDirection: 'row', gap: '1mm', border: '0.5pt solid #eee', marginTop: '-0.5pt'}} wrap={false}>
            <View style={{padding: '1pt 2pt', width: '5mm', borderRight: '0.5pt solid #eee', textAlign: 'right'}}><Text> </Text></View>
            <View style={{padding: '1pt 2pt', width: '60mm', borderRight: '0.5pt solid #eee'}}>
                <Text>Surface moyenne minimum de la (des) chambre(s) supplémentaire(s) à additionner avec la ligne du dessus</Text>
            </View>
            <View style={{padding: '1pt 2pt', width: '9mm', borderRight: '0.5pt solid #eee', textAlign: 'center'}}><Text>{classement < 4 ? `${SURFACES_MIN_CHAMBRE[index]}m²` : 'NA'}</Text></View>
            <View style={{padding: '1pt 2pt', width: '9mm', borderRight: '0.5pt solid #eee', textAlign: 'center'}}><Text> </Text></View>
            <View style={{padding: '1pt 2pt', width: '9mm', borderRight: '0.5pt solid #eee', textAlign: 'center'}}><Text> </Text></View>
            <View style={{padding: '1pt 2pt', width: '9mm', borderRight: '0.5pt solid #eee', textAlign: 'center'}}><Text> </Text></View>
            <View style={{padding: '1pt 2pt', width: '12mm', borderRight: '0.5pt solid #eee', textAlign: 'center'}}><Text> </Text></View>
            <View style={{padding: '2pt', width: '60mm'}}><Text> </Text></View>
        </View>
        <View style={{fontSize: '5.2pt', display: 'flex', flexDirection: 'row', gap: '1mm', border: '0.5pt solid #eee', marginTop: '-0.5pt'}} wrap={false}>
            <View style={{padding: '1pt 2pt', width: '5mm', borderRight: '0.5pt solid #eee', textAlign: 'right'}}><Text> </Text></View>
            <View style={{padding: '1pt 2pt', width: '60mm', borderRight: '0.5pt solid #eee'}}>
                <Text>Surface minimum de la (des) chambre(s) supplémentaire(s) à additionner avec la ligne du dessus</Text>
            </View>
            <View style={{padding: '1pt 2pt', width: '9mm', borderRight: '0.5pt solid #eee', textAlign: 'center'}}><Text>{classement > 3 ? `${SURFACES_MIN_CHAMBRE[index]}m²` : 'NA'}</Text></View>
            <View style={{padding: '1pt 2pt', width: '9mm', borderRight: '0.5pt solid #eee', textAlign: 'center'}}><Text> </Text></View>
            <View style={{padding: '1pt 2pt', width: '9mm', borderRight: '0.5pt solid #eee', textAlign: 'center'}}><Text> </Text></View>
            <View style={{padding: '1pt 2pt', width: '9mm', borderRight: '0.5pt solid #eee', textAlign: 'center'}}><Text> </Text></View>
            <View style={{padding: '1pt 2pt', width: '12mm', borderRight: '0.5pt solid #eee', textAlign: 'center'}}><Text> </Text></View>
            <View style={{padding: '2pt', width: '60mm'}}><Text> </Text></View>
        </View>
        <View style={{fontSize: '5.2pt', display: 'flex', flexDirection: 'row', gap: '1mm', border: '0.5pt solid #eee', marginTop: '-0.5pt'}} wrap={false}>
            <View style={{padding: '1pt 2pt', width: '5mm', borderRight: '0.5pt solid #eee', textAlign: 'right'}}><Text> </Text></View>
            <View style={{padding: '1pt 2pt', width: '60mm', borderRight: '0.5pt solid #eee'}}>
                <Text>Surface additionnelle de la pièce d'habitation par personne supplémentaire (au-delà des deux premières personnes par pièce d'habitation, dans la limite de 4 personnes de 1* à 4* et dans la limite de 3 personnes en 5*)</Text>
            </View>
            <View style={{padding: '1pt 2pt', width: '9mm', borderRight: '0.5pt solid #eee', textAlign: 'center'}}><Text>3m²</Text></View>
            <View style={{padding: '1pt 2pt', width: '9mm', borderRight: '0.5pt solid #eee', textAlign: 'center'}}><Text> </Text></View>
            <View style={{padding: '1pt 2pt', width: '9mm', borderRight: '0.5pt solid #eee', textAlign: 'center'}}><Text> </Text></View>
            <View style={{padding: '1pt 2pt', width: '9mm', borderRight: '0.5pt solid #eee', textAlign: 'center'}}><Text> </Text></View>
            <View style={{padding: '1pt 2pt', width: '12mm', borderRight: '0.5pt solid #eee', textAlign: 'center'}}><Text> </Text></View>
            <View style={{padding: '2pt', width: '60mm'}}><Text> </Text></View>
        </View>
    </>
    );

    return(
        <View style={{border: '1pt solid #000'}}>
            <View style={{fontSize: '5.2pt', display: 'flex', flexDirection: 'row', gap: '1mm', border: '0.5pt solid #eee', marginTop: '-0.5pt'}} wrap={false}>
                <View style={{padding: '1pt 2pt', width: '5mm', borderRight: '0.5pt solid #eee', textAlign: 'center'}}><Text>ID</Text></View>
                <View style={{padding: '1pt 2pt', width: '60mm', borderRight: '0.5pt solid #eee'}}><Text>Intitulé du critère</Text></View>
                <View style={{padding: '1pt 2pt', width: '9mm', borderRight: '0.5pt solid #eee', textAlign: 'center'}}><Text>Statut pour la catégorie {visite.infos.classement_cible}*</Text></View>
                <View style={{padding: '1pt 2pt', width: '9mm', borderRight: '0.5pt solid #eee', textAlign: 'center'}}><Text>Points affectés</Text></View>
                <View style={{padding: '1pt 2pt', width: '9mm', borderRight: '0.5pt solid #eee', textAlign: 'center'}}><Text>Validation</Text></View>
                <View style={{padding: '1pt 2pt', width: '9mm', borderRight: '0.5pt solid #eee', textAlign: 'center'}}><Text>Points obtenus</Text></View>
                <View style={{padding: '1pt 2pt', width: '12mm', borderRight: '0.5pt solid #eee', textAlign: 'center'}}>
                    <Text>Surfaces relevées</Text>
                </View>
                <View style={{padding: '2pt', width: '60mm'}}><Text wrap={true}>Commentaires</Text></View>
            </View>
            <View>
                <View style={{fontSize: '6.6pt', fontWeight: 'bold', backgroundColor: '#000', color: "#fff", padding: '2pt'}}>
                    <Text>Prérequis</Text>
                </View>
                <View style={{fontSize: '5.2pt', display: 'flex', flexDirection: 'row', gap: '1mm', border: '0.5pt solid #eee', marginTop: '-0.5pt'}} wrap={false}>
                    <View style={{padding: '1pt 2pt', width: '5mm', borderRight: '0.5pt solid #eee'}}><Text> </Text></View>
                    <View style={{padding: '1pt 2pt', width: '60mm', borderRight: '0.5pt solid #eee', textAlign: 'left'}}><Text>{textePrerequis}</Text></View>
                    <View style={{padding: '1pt 2pt', width: '9mm', borderRight: '0.5pt solid #eee', textAlign: 'center'}}><Text>PRQ</Text></View>
                    <View style={{padding: '1pt 2pt', width: '9mm', borderRight: '0.5pt solid #eee', textAlign: 'center'}}><Text>/</Text></View>
                    <View style={{padding: '1pt 2pt', width: '9mm', borderRight: '0.5pt solid #eee', textAlign: 'center'}}><Text> </Text></View>
                    <View style={{padding: '1pt 2pt', width: '9mm', borderRight: '0.5pt solid #eee', textAlign: 'center'}}><Text> </Text></View>
                    <View style={{padding: '1pt 2pt', width: '12mm', borderRight: '0.5pt solid #eee', textAlign: 'right'}}><Text> </Text></View>
                    <View style={{padding: '2pt', width: '60mm'}}><Text wrap={true}> </Text></View>
                </View>
            </View>
            {STRUCTURE.map(chapitre => 
                <View key={chapitre.id}>
                    <View style={{fontSize: '6.6pt', fontWeight: 'bold', backgroundColor: '#000', color: "#fff", padding: '2pt'}}>
                        <Text>{chapitre.title}</Text>
                    </View>
                    {chapitre.sections && chapitre.sections.map(section => 
                        <View key={section.id}>
                            <View style={{fontSize: '5.6pt', fontWeight: 'bold', backgroundColor: '#ccc', padding: '2pt'}}>
                                <Text>{section.id} {section.title}</Text>
                            </View>
                            {section.groupes && section.groupes.map(groupe => 
                                <View key={groupe.title}>
                                    <View style={{fontSize: '5.2pt', fontWeight: 'bold', backgroundColor: '#eee', padding: '2pt'}}>
                                        <Text>{groupe.title}</Text>
                                    </View>
                                    {groupe.title === "Surfaces de l'habitation" && surfacesDefault}
                                    {groupe.extra && <View>
                                        {groupe.extra.map(extra => {
                                            return <Extra key={extra.title} extra={extra} index={index} />
                                        })}
                                    </View>}
                                    {groupe.criteres && 
                                        <View>
                                            {groupe.criteres.map(critereId => {
                                                const critere = grille.find(item => item.id === critereId);
                                                return <Critere key={critere.id} critere={critere} surfaces={surfaces} />
                                            }
                                            )}
                                        </View>
                                    }
                                </View>
                            )}
                            {section.criteres && <View>
                                {section.criteres.map(critereId => {
                                    const critere = grille.find(item => item.id === critereId);
                                    return <Critere key={critere.id} critere={critere} surfaces={surfaces} />
                                })}
                            </View>}
                        </View>
                    )}
                </View>
            )}
        </View>
    )
}

const Critere = ({ critere, surfaces }) => {

    const { id, statut, points, checked, comment } = critere;
    const { na, auto } = GRILLE_DEFAULTS[id];
    const { title, aide } = GRILLE_TEXTES[id];

    return(
        <View style={{fontSize: '5.2pt', display: 'flex', flexDirection: 'row', gap: '1mm', border: '0.5pt solid #eee', marginTop: '-0.5pt'}} wrap={false}>
            <View style={{padding: '1pt 2pt', width: '5mm', borderRight: '0.5pt solid #eee', textAlign: 'right'}}><Text>{id}</Text></View>
            <View style={{padding: '1pt 2pt', width: '60mm', borderRight: '0.5pt solid #eee'}}><Text>{title}</Text></View>
            <View style={{padding: '1pt 2pt', width: '9mm', borderRight: '0.5pt solid #eee', textAlign: 'center'}}><Text>{statut}</Text></View>
            <View style={{padding: '1pt 2pt', width: '9mm', borderRight: '0.5pt solid #eee', textAlign: 'center'}}><Text>{points}</Text></View>
            <View style={{padding: '1pt 2pt', width: '9mm', borderRight: '0.5pt solid #eee', textAlign: 'center'}}><Text>{checked ? 'OUI' : statut === 'NA' ? 'NA' : 'NON'}</Text></View>
            <View style={{padding: '1pt 2pt', width: '9mm', borderRight: '0.5pt solid #eee', textAlign: 'center'}}><Text>{checked ? points : '0'}</Text></View>
            <View style={{padding: '1pt 2pt', width: '12mm', borderRight: '0.5pt solid #eee', textAlign: 'center'}}>
                <Text>{(id === 1 || id === 2) ? id === 1 ? `${parseFloat(surfaces.totalHabitable).toFixed(2)} m²` : `${parseFloat(surfaces.majorees).toFixed(2)} m²` : ''}</Text>
            </View>
            <View style={{padding: '2pt', width: '60mm'}}><Text wrap={true}>{comment}</Text></View>
        </View>
    )
}

const Extra = ({ extra, index }) => {

    const { title, value } = extra;

    return(
        <View style={{fontSize: '5.2pt', display: 'flex', flexDirection: 'row', gap: '1mm', border: '0.5pt solid #eee', marginTop: '-0.5pt'}} wrap={false}>
            <View style={{padding: '1pt 2pt', width: '5mm', borderRight: '0.5pt solid #eee', textAlign: 'right'}}><Text> </Text></View>
            <View style={{padding: '1pt 2pt', width: '60mm', borderRight: '0.5pt solid #eee'}}><Text>{title}</Text></View>
            <View style={{padding: '1pt 2pt', width: '9mm', borderRight: '0.5pt solid #eee', textAlign: 'center'}}><Text>{value && value[index]}</Text></View>
            <View style={{padding: '1pt 2pt', width: '9mm', borderRight: '0.5pt solid #eee', textAlign: 'center'}}><Text> </Text></View>
            <View style={{padding: '1pt 2pt', width: '9mm', borderRight: '0.5pt solid #eee', textAlign: 'center'}}><Text></Text></View>
            <View style={{padding: '1pt 2pt', width: '9mm', borderRight: '0.5pt solid #eee', textAlign: 'center'}}><Text> </Text></View>
            <View style={{padding: '1pt 2pt', width: '12mm', borderRight: '0.5pt solid #eee', textAlign: 'center'}}><Text> </Text></View>
            <View style={{padding: '2pt', width: '60mm'}}><Text> </Text></View>
        </View>
    )
}

const Resultats = ({ visite }) => {

    const ONC = visite.grille.filter(critere => critere.statut === 'ONC' && critere.checked);
    const scores = visite.infos.scores;
    const { a, b, c, d, e, f, g, h } = scores;
    const seuilAtteint = scores.c >= scores.b && ONC.length === 5;

    return(
        <View style={{fontSize: '6.6pt', width: '110mm'}} wrap={false}>
            <View style={{padding: '2pt', fontWeight: 'bold', border: '0.5pt solid #000', backgroundColor: '#000', color: '#fff'}}>
                <Text>Points obligatoires</Text>
            </View>
            <View style={{display: 'flex', flexDirection: 'row', border: '0.5pt solid #000', marginTop: '-0.5pt'}}>
                <View style={{padding: '2pt', width: '100mm'}}>
                    <Text>A. Nombre total de points obligatoires à respecter pour le meublé dans la catégorie demandée (tenant compte des cas particuliers et des critères non applicables)</Text>
                </View>
                <View style={{padding: '2pt', width: '10mm', borderLeft: '0.5pt solid #000', textAlign: 'center'}}>
                    <Text>{a}</Text>
                </View>
            </View>
            <View style={{display: 'flex', flexDirection: 'row', border: '0.5pt solid #000', marginTop: '-0.5pt'}}>
                <View style={{padding: '2pt', width: '100mm'}}>
                    <Text>B. Nombre minimal de points obligatoires à respecter (95% de A)</Text>
                </View>
                <View style={{padding: '2pt', width: '10mm', borderLeft: '0.5pt solid #000', textAlign: 'center'}}>
                    <Text>{b}</Text>
                </View>
            </View>
            <View style={{display: 'flex', flexDirection: 'row', border: '0.5pt solid #000', marginTop: '-0.5pt', fontWeight: 'bold', backgroundColor: '#eee'}}>
                <View style={{padding: '2pt', width: '100mm'}}>
                    <Text>C. Nombre de points obligatoires atteints</Text>
                </View>
                <View style={{padding: '2pt', width: '10mm', borderLeft: '0.5pt solid #000', textAlign: 'center'}}>
                    <Text>{c}</Text>
                </View>
            </View>
            <View style={{display: 'flex', flexDirection: 'row', border: '0.5pt solid #000', marginTop: '-0.5pt', fontWeight: 'bold', backgroundColor: '#eee'}}>
                <View style={{padding: '2pt', width: '100mm'}}>
                    <Text>Respect du seuil de nombre de points obligatoires à atteindre</Text>
                </View>
                <View style={{padding: '2pt', width: '10mm', borderLeft: '0.5pt solid #000', textAlign: 'center'}}>
                    <Text>{seuilAtteint ? 'OUI' : 'NON'}</Text>
                </View>
            </View>
            <View style={{display: 'flex', flexDirection: 'row', border: '0.5pt solid #000', marginTop: '-0.5pt'}}>
                <View style={{padding: '2pt', width: '100mm'}}>
                    <Text>D. Nombre de points obligatoires à compenser - dans la limite de 5% du total des points obligatoires à respecter (coefficient 3), soit (a)-(c)*3</Text>
                </View>
                <View style={{padding: '2pt', width: '10mm', borderLeft: '0.5pt solid #000', textAlign: 'center'}}>
                    <Text>{d}</Text>
                </View>
            </View>
            <View style={{padding: '2pt', fontWeight: 'bold', border: '0.5pt solid #000', marginTop: '-0.5pt', backgroundColor: '#000', color: '#fff'}}>
                <Text>Points à la carte</Text>
            </View>
            <View style={{display: 'flex', flexDirection: 'row', border: '0.5pt solid #000', marginTop: '-0.5pt'}}>
                <View style={{padding: '2pt', width: '100mm'}}>
                    <Text>E. Nombre total de points à la carte disponibles pour le meublé dans la catégorie demandée</Text>
                </View>
                <View style={{padding: '2pt', width: '10mm', borderLeft: '0.5pt solid #000', textAlign: 'center'}}>
                    <Text>{e}</Text>
                </View>
            </View>
            <View style={{display: 'flex', flexDirection: 'row', border: '0.5pt solid #000', marginTop: '-0.5pt'}}>
                <View style={{padding: '2pt', width: '100mm'}}>
                    <Text>F. Nombre de points à la carte à respecter, soit 5%, 10%, 20%, 30%, 40% respectivement pour les catégories 1, 2, 3, 4, 5*</Text>
                </View>
                <View style={{padding: '2pt', width: '10mm', borderLeft: '0.5pt solid #000', textAlign: 'center'}}>
                    <Text>{f}</Text>
                </View>
            </View>
            <View style={{display: 'flex', flexDirection: 'row', border: '0.5pt solid #000', marginTop: '-0.5pt'}}>
                <View style={{padding: '2pt', width: '100mm'}}>
                    <Text>G. Nombre de points à la carte à atteindre : (d) + (f)</Text>
                </View>
                <View style={{padding: '2pt', width: '10mm', borderLeft: '0.5pt solid #000', textAlign: 'center'}}>
                    <Text>{g}</Text>
                </View>
            </View>
            <View style={{display: 'flex', flexDirection: 'row', border: '0.5pt solid #000', marginTop: '-0.5pt', fontWeight: 'bold', backgroundColor: '#eee'}}>
                <View style={{padding: '2pt', width: '100mm'}}>
                    <Text>H. Nombre de points à la carte atteints</Text>
                </View>
                <View style={{padding: '2pt', width: '10mm', borderLeft: '0.5pt solid #000', textAlign: 'center'}}>
                    <Text>{h}</Text>
                </View>
            </View>
            <View style={{display: 'flex', flexDirection: 'row', border: '0.5pt solid #000', marginTop: '-0.5pt', fontWeight: 'bold', backgroundColor: '#eee'}}>
                <View style={{padding: '2pt', width: '100mm'}}>
                    <Text>Respect du seuil de nombre de points à la carte à atteindre</Text>
                </View>
                <View style={{padding: '2pt', width: '10mm', borderLeft: '0.5pt solid #000', textAlign: 'center'}}>
                    <Text>{h >= g ? 'OUI' : 'NON'}</Text>
                </View>
            </View>
        </View>
    )
}