import { ReactComponent as SearchIcon } from '@images/search.svg';
import { ReactComponent as CloseIcon } from '@images/close.svg';

const SearchInput = ({ className='', size='', value='', placeholder="Rechercher…", handler }) => {

    const handleClear = (event) => {
        event.preventDefault();
        event.target.value = '';
        handler(event);
    }

    return(
        <div className={`form-input search-input ${className} ${size}`.trim()}>
            <input type='text' value={value} onChange={handler} autoComplete="off" placeholder={placeholder} />
            {(value.length === 0) && <span className='icon'><SearchIcon /></span>}
            {(value.length > 0) && <a className='icon' href="clear" rel="nofollow" onClick={handleClear}><CloseIcon /></a>}
        </div>
    )
}

export default SearchInput;