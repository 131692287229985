import { flexRender } from "@tanstack/react-table";
import { TableFilters } from '@composants/table-entite';
import { ReactComponent as IconUp } from '@images/arrow_drop_up.svg';
import { ReactComponent as IconDown } from '@images/arrow_drop_down.svg';

const TableHeader = ({ table }) => {

    const groupes = table.getHeaderGroups();

    return (
        <thead>
            {groupes.map(headerGroup => (
                <tr key={headerGroup.id}>
                    {
                        headerGroup.headers.map(header => (
                            <th key={header.id} colSpan={header.colSpan} className={`th-${header.column.id}`}>
                                {header.isPlaceholder ? null : (
                                    <>
                                        <div
                                            {...{
                                                className: header.column.getCanSort() ? 'cursor-pointer select-none' : '',
                                                onClick: header.column.getToggleSortingHandler(),
                                            }}
                                        >
                                            {flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                            {{
                                                asc: <IconUp />,
                                                desc: <IconDown />,
                                            }[header.column.getIsSorted()] ?? null}
                                        </div>
                                        {header.column.getCanFilter() ? (
                                            <TableFilters column={header.column} table={table} />
                                        ) : null}
                                    </>
                                )}
                            </th>
                        ))
                    }
                </tr>
            ))}
        </thead>
    );

}

export default TableHeader;