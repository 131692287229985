import { Vignette } from '@composants';
import Classement from '@composants/classement/classement';
import { ButtonNewVisite } from '@composants/button';

import './meuble-item.scss';

const MeubleItem = ({ meuble, action, handler }) => {

    const { type, identifiant, titre, classement, role, adresse1, adresse2, code_postal, commune, active, vignette_url } = meuble;

    const handleClick = () => {
        if(handler){
            handler(meuble);
        } else {
            window.location = `/meubles/meuble-voir/?id=${meuble.id}`;
        }
    }

    return (
        <div className={`meuble-item ${action}${active ? ' active' : ''}`} onClick={handleClick}>
            <Vignette variant={`${type === 'Appartement' ? 'appartement' : 'maison'}`}>
                {vignette_url && <img src={vignette_url} />}
            </Vignette>
            <div className='identification d-flex flex-column justify-content-center body-2'>
                <span className='caption'>ID:{identifiant}</span>
                <strong>{titre}</strong>
            </div>
            {action === 'liste' && <div className='classement-wrapper px-2 text-center'>
                <Classement count={classement} />
            </div>}
            {role && <div className='role body-2 text-center'>{role}</div>}
            <div className='adresse d-flex flex-column justify-content-center caption'>
                <ContactMeuble contacts={meuble.contacts} />
                <span>{adresse1}</span>
                {adresse2 && <span>{adresse2}</span>}
                <span>{code_postal} {commune}</span>
            </div>
            <div className='actions px-2'>
                {action === 'liste' && <ButtonNewVisite meuble={meuble} />}
            </div>
        </div>
    )
}

export default MeubleItem;

const ContactMeuble = ({contacts}) => {

    if(!contacts || !contacts.length) return;

    if(contacts[0].nom) {
        return <strong>{contacts[0].prenom && contacts[0].prenom} {contacts[0].nom}</strong>
    } else if(contacts[1].nom) {
        return <strong>{contacts[1].prenom && contacts[1].prenom} {contacts[1].nom}</strong>
    } else {
        return;
    }
}