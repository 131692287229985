import { useState } from '@wordpress/element';
import { Panel } from "@composants";
import { ButtonCollapse } from "@composants/button";

import './chapitre.scss';

export const Chapitre = ({ title, children }) => {

    const [closed, setClosed] = useState(false);

    const toggleChapitre = () => {
        setClosed(!closed);
    }

    return(
        <Panel className="p-0 gap-0 mb-3" contentClassName="p-0 gap-0">
            <div className="chapitre-header px-3 pt-1 pb-2">
                <div className="d-flex justify-content-between">
                    <h3 className="h3 mb-0">{title}</h3>
                    <ButtonCollapse statut={closed ? 'collapsed' : ''} variant="light" handler={toggleChapitre} />
                </div>
            </div>
            <div className={`chapitre-body${closed ? ' collapsed' : ''}`}>
                {children}
            </div>
        </Panel>
    )
}