import { useContext } from '@wordpress/element';
import { useVisite, useVisiteDispatch, UserContext } from '@context';
import { useFetch } from '@hooks';
import { Panel, DocumentsUpload } from "@composants";
import { SelectInput, NumberInput, DateInput } from '@composants/form/';
import { classementValues, etagesValues, typesVisites, ASCENSEUR_VALUES, AIDE_CRITERE_78 } from '@constantes';
import { VisiteSuperficies } from '@pages/visite-edit/composants';
import { Alert } from 'react-bootstrap';

const VisiteInfos = () => {

    const { user } = useContext(UserContext);
    const { visite, meuble, contactsMeuble } = useVisite();
    const dispatch = useVisiteDispatch();
    const { deleteFile } = useFetch();
    
    // Définition de la date de visite maximale.
    const now = new Date();
    const demain = now.setDate(now.getDate() + 1);

    const onClassement = (e) => {
        const nextClassement = e.target.value !== '' ? parseInt(e.target.value) : 0;
        dispatch({type: 'classement_cible', value: nextClassement});
    }
    
    const onCapacite = (e) => {
        dispatch({ type: 'capacite', value: e.target.value });
    }

    const onEtage = (e) => {
        dispatch({type: 'etage', value: parseInt(e.target.value)});
    }

    const onDateVisite = (e) => {
        const nextDate = new Date(e);
        dispatch({type: 'date_visite', value: nextDate.toLocaleString('sv-SE')});
    }

    const onTypeVisite = (e) => {
        dispatch({type: e.target.name, value: e.target.value});
    }

    const onFileUploaded = async (uploadInfo) => {
        if(uploadInfo.success){
            await dispatch({type: 'file-upload', value: uploadInfo});
        }
    }
    
    const onFileDelete = async (file) => {
        const result = await deleteFile(user.id, file.id);
        if(result.success || result.data.message === "Document non trouvé."){
            dispatch({type: 'file-delete', value: file});
        }
    }

    const onDemandeur = async (e) => {
        dispatch({type: 'demandeur-selection', value: {id: e.target.value, contact: contactsMeuble.find(c => c.id === e.target.value)}});
    }

    const onAscenseur = async (e) => {
        dispatch({type: 'ascenseur', value: e.target.value});
    }

    const getContactsItems = () => {
        const contactsItems = contactsMeuble.reduce((result, contact) => {
            const lienMeuble = contact.meubles.find(m => m.id === meuble.id);
            if(["Propriétaire", "Mandataire"].indexOf(lienMeuble.contact_type) !== -1){
                result.push({
                    value: contact.id,
                    label: `${contact.civilite}${contact.prenom ? ' ' + contact.prenom : ''} ${contact.nom} (${lienMeuble.contact_type})`
                });
            }
            return result;
        }, []);
        return [{value: '', label: 'Choisir un demandeur'}, ...contactsItems];
    }

    if(!visite) return null;
    
    const { infos, date_visite, type_visite, documents, demandeur } = visite;

    return(
        <>
            <Panel>
                <h4 className='h4'>Informations générales</h4>
                <div className='d-flex gap-4'>
                    <SelectInput values={classementValues} value={infos.classement_cible} name="classement_cible" label="Classement recherché" handler={onClassement} />
                    <NumberInput value={infos.accueil_max} name="accueil_max" label="Accueil maximum" handler={onCapacite} />
                    <SelectInput values={etagesValues} value={infos.etage} name="etage" label="Étage" handler={onEtage} />
                    <SelectInput values={ASCENSEUR_VALUES} value={infos.ascenseur} name="ascenseur" label="Ascenseur" handler={onAscenseur} aide={AIDE_CRITERE_78} />
                </div>
                <div className='d-flex gap-4'>
                    <DateInput value={date_visite} handler={onDateVisite} label="Date de la visite" max={demain} />
                    <SelectInput values={typesVisites} value={type_visite ? type_visite : ''} name="type_visite" label="Type de visite" handler={onTypeVisite} />
                    {(contactsMeuble.length > 0) ? <SelectInput values={getContactsItems()} value={(demandeur?.id) ? demandeur.id : ''} name="demandeur" label="Demandeur" handler={onDemandeur} /> :
                    <Alert className='p-2' variant='warning'>
                        <h4 className='fs-6 fw-bold mb-1'>Demandeur</h4>
                        <p className='mb-0 caption'>Aucun contact (propriétaire ou mandataire) n'est lié à ce meublé.</p>
                        <p className='mb-0 caption'>Veuillez <a href="/contacts">associer un contact de ce type</a> à ce meublé.</p>
                    </Alert>
                    }
                </div>
            </Panel>
            <VisiteSuperficies data={visite.infos.superficies} classement={visite.infos.classement_cible} />
            <Panel>
                <h4 className='h4'>Documents</h4>
                {visite.infos.saved ? 
                    <DocumentsUpload uid={visite.uid} files={documents} onUpload={onFileUploaded} onDelete={onFileDelete} /> :
                    <p className="text-muted text-center p-4">Cette visite n'est pas encore enregistrée sur le serveur.</p>
                }
            </Panel>
        </>
    );
}

export default VisiteInfos;