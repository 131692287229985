import { useState, useEffect } from '@wordpress/element';
import { useVisite } from '@context';
import { STRUCTURE } from '@/grille-classement';

import { Chapitre } from "@visite-edit/grille/chapitre/chapitre";
import { Groupe } from '@visite-edit/grille/groupe/groupe';
import { Critere } from "@visite-edit/grille/critere/critere";
import { Section } from '@visite-edit/grille/section/section';
import { GrilleHeader } from '@visite-edit/grille/grille-header/grille-header';

import './visite-grille.scss';

const VisiteGrille = ({ editable, isOpen }) => {

    const { visite } = useVisite();

    const [uncheckeds, setUncheckeds] = useState(false);
    const [selection, setSelection] = useState(null);

    const handleSelection = (event) => {
        if(event.target.name === 'obligatoires'){
            if(selection === 'obligatoires'){
                setSelection(null);
            } else {
                setSelection('obligatoires');
            }
        }
        if(event.target.name === 'optionnels'){
            if(selection === 'optionnels'){
                setSelection(null);
            } else {
                setSelection('optionnels');
            }
        }
    }

    useEffect(() => {

        const selectFirstSwitch = () => {
            const firstSwitch = document.querySelector('.switch');
            if(firstSwitch){
                firstSwitch.contentEditable = true;
                firstSwitch.focus();
                firstSwitch.contentEditable = false;
            }
        }

        const onTabKey = (event) => {
            if(event.keyCode === 9){
                const activeElement = document.activeElement;
                if(!activeElement.classList.contains('switch')){
                    selectFirstSwitch();
                }
            }
        }

        if(editable) {
            document.addEventListener('keyup', onTabKey);
            selectFirstSwitch();
        }

        return () => {
            if(editable){
                document.removeEventListener('keyup', onTabKey);
            }
        }
    }, [editable]);

    return(
        <div className={`grille${uncheckeds ? ' uncheckeds' : ''} ${selection ? selection : ''}`.trim()}>

            {editable && <GrilleHeader 
                status={{uncheckeds: uncheckeds, selection: selection}} 
                uncheckedsHandler={setUncheckeds} 
                selectionHandler={handleSelection} 
                isOpen={isOpen}
            />}
            

            <div className={`grille-body`.trim()}>
                {selection ? <div className='d-flex flex-column gap-2'>
                    {visite.grille.map(item => <Critere key={item.id} data={item} editable={true} />)}
                </div> : 
                    STRUCTURE.map(chapitre => 
                        <Chapitre key={chapitre.id} title={chapitre.title}>
                            {chapitre.sections && chapitre.sections.map(section => 
                                <Section key={section.id} id={section.id} title={section.title}>
                                        {section.groupes && section.groupes.map(groupe => 
                                            <Groupe key={groupe.title} groupe={groupe} classement={visite.infos.classement_cible} status={selection || uncheckeds}>
                                                {groupe.criteres && <div className='d-flex flex-column gap-2'>
                                                    {groupe.criteres.map(critereId => {
                                                        const critere = visite.grille.find(item => item.id === critereId);
                                                        return <Critere key={critere.id} data={critere} editable={editable} />
                                                    })}
                                                </div>}
                                            </Groupe>
                                        )}
                                        {section.criteres && <div className='d-flex flex-column gap-2'>
                                            {section.criteres.map(critereId => {
                                                const critere = visite.grille.find(item => item.id === critereId);
                                                return <Critere key={critere.id} data={critere} editable={editable} />
                                            })}
                                        </div>}
                                </Section>
                            )}
                        </Chapitre>
                    )
                }
            </div>
        </div>
    );
}

export default VisiteGrille;