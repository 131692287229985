import { useMemo, useEffect , useState } from '@wordpress/element';
import { createColumnHelper } from "@tanstack/react-table";
import { useFilters } from '@hooks';
import { Classement, TableEntite, Cell } from '@composants';
import { CheckboxSelection } from '@composants/form';
import {ButtonArchiveVisiteActions, ButtonHelp} from '@composants/button';
import { ReactComponent as Maison } from '@images/maison.svg';
import { ReactComponent as Appartement } from '@images/maison.svg';
import { ReactComponent as IconLock } from '@images/lock.svg';

const columnHelper = createColumnHelper();
const { dateRangeFilter, classementFilter, scelleFilter } = useFilters();

const TableArchivesVisites = ({ visites, selectionHandler }) => {

    visites.sort((a,b) => b.date_update - a.date_update);

    const [rowSelection, setRowSelection] = useState({});
    const meuble_notexist = "Ce meublé est introuvable dans Nomade. Il peut-être est archivé ou supprimé. Cependant il est possible qu'une partie de ses informations soient stockées directement dans la visite.";
    const meuble_sans_class = "Cette visite n'a pas de N° de classement, vous ne pouvez donc pas l'exporter pour le classement. Veuillez vérifier si le meublé a bien un code insée. Ensuite dans l'édition de la visite veuillez cliquer sur 'Générer la décision de classement' pour lancer la génération du N°. Revenez ensuite sur cette liste et constatez si le N° est bien créé.";

    const columns = useMemo(() => [
        {
            id: 'select',
            header: ({ table }) => (
                <CheckboxSelection {...{
                    checked: table.getIsAllRowsSelected(),
                    indeterminate: table.getIsSomeRowsSelected(),
                    onChange: table.getToggleAllRowsSelectedHandler(),
                }} />
            ),
            cell: ({ row }) => (
                <Cell className="cell-select">
                    <CheckboxSelection {...{
                        checked: row.getIsSelected(),
                        indeterminate: row.getIsSomeSelected(),
                        onChange: row.getToggleSelectedHandler(),
                    }} />
                </Cell>
            )
        },
        columnHelper.display({
            id: 'vignette',
            cell: props => {
                const meuble = props.row.original.meuble;
                return <Cell className={`vignette vignette-meuble`}>
                    {meuble?.vignette_url ? <img src={meuble?.vignette_url} /> :
                        meuble?.type === 'Maison' ? <Maison /> : <Appartement />
                    }
                </Cell>
            }
        }),
        columnHelper.accessor(row => `${row.identification.uid} ${row.identification.titre}`, {
            id: 'identification',
            header: 'Identification',
            cell: props => {
                return <Cell>
                    <div className='d-flex gap-1'>
                        {props.row.original.scelle && <IconLock width="24" className="icon-scelle" />}
                        <div className='d-flex flex-column'>
                            <span className='text-overflow'>{props.row.original.identification.uid}</span>
                            {props.row.original.meuble && (props.row.original.meuble.titre ? <i>{props.row.original.meuble.titre}</i> : props.row.original.meuble.id)}
                            <span className='text-overflow'>{props.row.original.meuble ? props.row.original.meuble.code_postal+' ' + props.row.original.meuble.commune : ''}</span>
                            {!props.row.original.meuble && <div className='d-flex align-items-center ps-n2'>
                                <ButtonHelp help={meuble_notexist} className="orange" />
                                <strong className="text-orange body-small">Meublé inexistant</strong>
                            </div>}
                        </div>
                    </div>
                </Cell>
            }
        }),
        /*columnHelper.accessor(row => `${row.meuble?.otsi}`, {
            header: 'OTSI',
            cell: props => {
                return <Cell><span className='text-center'>{props.row.original.meuble?.otsi}</span></Cell>
            },
        }),*/
        columnHelper.accessor(row => `${row.meuble?.num_classement}`, {
            header: 'N°Classement',
            cell: props => {
                return <Cell>
                    { props.row.original.meuble &&  props.row.original.meuble.num_classement && <span className='text-center body-small'>{props.row.original.meuble.num_classement}</span> }
                    {!props.row.original.meuble || !props.row.original.meuble?.num_classement && <div className='d-flex align-items-center ps-n2'>
                        <ButtonHelp help={meuble_sans_class} className="orange" />
                        <strong className="text-orange body-small">Pas de N°</strong>
                    </div>}

                </Cell>
            },
        }),
        columnHelper.accessor(row => `${row?.proprio.prenom} ${row?.proprio.nom} ${row.demandeur?.prenom} ${row.demandeur?.nom}`, {
            header: 'Contacts',
            cell: props => {
                return <Cell>
                    {props.row.original.proprio ?
                        <span className='text-muted pb-1'>Proprio : {props.row.original.proprio.civilite}{props.row.original.proprio.prenom ? ' ' + props.row.original.proprio.prenom : ''} {props.row.original.proprio.nom}</span> :
                        <span className='text-muted pb-1'>Aucun propriétaire</span>}
                    {props.row.original.demandeur ?
                        <span className='text-muted'>Demandeur : {props.row.original.demandeur.civilite}{props.row.original.demandeur.prenom ? ' ' + props.row.original.demandeur.prenom : ''} {props.row.original.demandeur.nom}</span> :
                        <span className='text-muted'>Aucun demandeur</span>}
                </Cell>
            }
        }),
        columnHelper.accessor('date_visite', {
            header: 'Date visite',
            cell: props => {
                return <Cell>{props.row.original.date_visite.toLocaleDateString()}</Cell>
            },
            filterFn: (row, columnId, value) => dateRangeFilter(row, columnId, value)
        }),

        columnHelper.accessor('scelle', {
            header: 'Scellé',
            cell: props => {
                const data = props.row.original;
                return <Cell>
                    <span className='text-center'>{data.scelle ? 'oui' : 'non'}</span>
                </Cell>
            },
            filterFn: (row, columnId, value) => scelleFilter(row, columnId, value)
        }),
        columnHelper.accessor(row => `${row.capacite}`, {
            header: 'Capacité',
            cell: props => {
                return <Cell>
                    <div className='text-center'>{props.row.original.meuble.capacite}</div>
                </Cell>
            },
            filterFn: (row, columnId, value) => classementFilter(row, columnId, value)
        }),
        columnHelper.accessor('classement', {
            header: 'Classement',
            cell: props => {
                let date_classement = "";
                if( props.row.original.meuble.date_classement !== null && new Date(props.row.original.meuble.date_classement) != 'Invalid Date' ){
                    date_classement = new Date(props.row.original.meuble.date_classement).toLocaleDateString();
                }
                return <Cell>
                    <Classement count={props.row.original.meuble.classement} />
                    {date_classement.length > 0 ? date_classement:''}
                </Cell>
            },
            filterFn: (row, columnId, value) => classementFilter(row, columnId, value)
        }),
        columnHelper.accessor('archivage_date', {
            header: 'Date archivage',
            cell: props => {
                return <Cell className='cell-inspecteur caption'>
                    <div className='d-flex flex-column'>
                        <span>Le {props.row.original.archivage_date.toLocaleDateString()}</span>
                        {props.row.original.archivage_user ? <span>par {props.row.original.archivage_user}</span> : null}
                        <ButtonHelp help={props.row.original.archivage_raison?props.row.original.archivage_raison:'Aucune information'} className="orange" />
                    </div>
                </Cell>
            },
            filterFn: (row, columnId, value) => dateRangeFilter(row, columnId, value)
        }),
        columnHelper.display({
            id: 'actions2',
            cell: props => {
                return <div className='cell cell-actions cell-inner'>
                    <ButtonArchiveVisiteActions visite={props.row.original} />
                </div>
            }
        }),
    ]);

    useEffect(() => {
        const visitesToExport = Object.keys(rowSelection).map(key => visites[key].uid);
        selectionHandler(visitesToExport);
    }, [rowSelection]);

    return(
        <TableEntite data={visites} columns={columns} rowSelection={rowSelection} selectionHandler={setRowSelection} className="hover" />
    )
}

export default TableArchivesVisites;