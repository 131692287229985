import { useSyncExternalStore } from '@wordpress/element';

const useOnlineStatus = () => {
    const isOnline = useSyncExternalStore(subscribe, getSnapshot);
    return isOnline;
}

function getSnapshot() {
    return navigator.onLine;
}

function subscribe(callback) {
    window.addEventListener('online', callback);
    window.addEventListener('offline', callback);
    return () => {
        window.removeEventListener('online', callback);
        window.removeEventListener('offline', callback);
    };
}

export default useOnlineStatus;