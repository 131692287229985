import { PDFDownloadLink } from '@react-pdf/renderer';
import { useVisite } from '@context';
import { CIVILITES } from '@constantes';
import { useContacts, useUtiles } from '@hooks';
import { DecisionDeClassement } from '@composants';

const prefixe = "DecisionMT_";

const ButtonDownloadPDF = ({ documents, callback }) => {

    const { getMeubleContactByRole } = useContacts();
    const { visite, meuble, inspecteur } = useVisite();
    const { cleanTexte } = useUtiles();

    const demandeur = visite.demandeur;
    
    const selectedFiles = documents.filter(doc => doc.selected);
    const outputDocs = selectedFiles.map(file => file.id);
    
    /** Génère le nom du fichier selon les documents à produire */
    const getFileName = () => {

        // chaines pour constituer le nom du fichier
        const nomDemandeur = demandeur.nom.substring(0,9);
        const prenomDemandeur = (demandeur.prenom && demandeur.prenom !== '') ? `_${demandeur.prenom.substring(0,2)}`: '';
        const raisonSociale = !CIVILITES.includes(demandeur.civilite.trim()) ? `_${cleanTexte(demandeur.civilite.trim())}` : '';

        // Nom du fichier par défaut
        const defaultFileName = cleanTexte(`${prefixe}${nomDemandeur}${prenomDemandeur}${raisonSociale}`);

        // La visite a-t-elle un compte-rendu ?
        const hasCompteRendu = visite.infos.comment && visite.infos.comment !== '';

        // Nombre total de fichiers selon compte-rendu ou pas
        const totalFilesLength = hasCompteRendu ? 6 : 5;

        // Si le nombre de fichiers sélectionnés est inférieur au nombre total de fichiers
        // C'est qu'on ne génère qu'un sous-groupe de documents
        if(selectedFiles.length < totalFilesLength) {
            const suffixe = selectedFiles.map(file => `_${file.shortname}`);
            return `${defaultFileName}${suffixe.join('')}`;
        } else {
            return defaultFileName;
        }
    }

    const nomFichier = getFileName();

    if(!visite || !meuble) return null;

    // Les contacts de la visite
    const contacts = {
        loueur: getMeubleContactByRole(meuble, 'Propriétaire'),
        mandataire: getMeubleContactByRole(meuble, 'Mandataire'), 
    };

    // L'inspecteur qui a fait la visite

    if(!inspecteur) return <div></div>;

    if(!meuble) return <div>Cette visite n'est liée à aucun meublé</div>;

    return (
        <PDFDownloadLink onClick={(e) => callback()}
            document={<DecisionDeClassement 
                documents={outputDocs} 
                visite={visite} 
                meuble={meuble} 
                contacts={contacts} 
                inspecteur={inspecteur} 
            />} fileName={nomFichier}>
            {({ blob, url, loading, error }) =>
                loading ? 'Chargement...' : <button className='btn orange'>Télécharger la décision de classement</button>
            }
        </PDFDownloadLink>
    )
}

export default ButtonDownloadPDF;