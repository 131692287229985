import { useState, useEffect, useRef } from '@wordpress/element';
import { flexRender, getCoreRowModel, getFilteredRowModel, getSortedRowModel, useReactTable, getPaginationRowModel } from "@tanstack/react-table";
import { Table } from 'react-bootstrap';
import debounce from 'lodash.debounce';
import { useFiltersDispatch } from '@context';
import Pagination from '@composants/pagination';
import { TableHeader } from '@composants/table-entite';

import './table-entite.scss';

const TableEntite = ({ data, columns, className='', rowSelection, selectionHandler }) => {

    const [sorting, setSorting] = useState([]);
    const [columnFilters, setColumnFilters] = useState([]);
    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10});
    const { setRowCount } = useFiltersDispatch();

    /**
     * Référence sur le container de la grille pour calculer le nombre d'items par page
     */
    const containerRef = useRef();

    /**
     * Ressources pour la table
     */
    const table = useReactTable({ 
        data: data, 
        columns,
        state: {
            sorting,
            columnFilters,
            pagination, 
            rowSelection, 
        },
        enableRowSelection: true,
        onRowSelectionChange: selectionHandler,
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),
        onColumnFiltersChange: setColumnFilters,
        getFilteredRowModel: getFilteredRowModel(),
        getCoreRowModel: getCoreRowModel(), 
        getPaginationRowModel: getPaginationRowModel(), 
        // debugTable: true, 
    });

    /**
     * 
     * @param {object} event 
     * 
     * gestion des actions sur les boutons de la pagination
     */
    const handlePaginationClick = (event) => {
        setPagination({...pagination, pageIndex: event.selected})
    }
    
    /**
     * Pagination :
     * Calcule le nombre d'items par page en fonction 
     * de la taille de la zone affichée
     */
    useEffect(() => {

        const countItemsVisibles = () => {
            if(containerRef.current){
                const totalHeight = containerRef.current.offsetHeight;
                const availableSpace = totalHeight - 116; // 128 = hauteur de la pagination + hauteur du header du tableau
                const itemsCount = Math.round(availableSpace / 74);
                setPagination({...pagination, pageSize: itemsCount})
            }
        }

        countItemsVisibles();

        window.addEventListener('resize', debounce(countItemsVisibles, 300));

        return () => {
            window.removeEventListener('resize', debounce(countItemsVisibles, 300));
        }
    }, []);

    /**
     * Pagination :
     * remettre la pagination à 1 lors du filtrage de la liste
     * Sélection :
     * Annuler la sélection courante
     */
    useEffect(() => {

        if(columnFilters.length){
            setPagination({...pagination, pageIndex: 0});
            selectionHandler({});
        }

    }, [columnFilters]);

    /**
     * Mise à jour du total de lignes selon filtrage
     */
    const totalRowsCount = table.getFilteredRowModel().rows.length;
    useEffect(() => {
        setRowCount(totalRowsCount);
    }, [totalRowsCount]);
    
    /* Calcul du nombre de pages pour la pagination */
    const pageCount = Math.ceil(table.getFilteredRowModel().rows.length / pagination.pageSize);


    return (
        <div ref={containerRef} className={`table-container`}>
            <Table responsive className={`'-highlight ${className}`.trim()}>
                <TableHeader table={table} />
                <tbody>
                    {table.getRowModel().rows.map(row => {
                        return <tr key={row.id}>
                            {row.getVisibleCells().map(cell => (
                                <td key={cell.id} className={cell.column.id}>
                                    {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext()
                                    )}
                                </td>
                            ))}
                        </tr>
                    })}
                </tbody>
            </Table>
            {pageCount > 1 && <Pagination handler={handlePaginationClick} count={pageCount} index={pagination.pageIndex} />}
            {/* <pre>{JSON.stringify(sorting, null, 2)}</pre> */}
            {/* <pre>{JSON.stringify(table.getState(), null, 2)}</pre> */}
            {(table.getFilteredRowModel().rows.length === 0) && <div className='p-3 text-center h4 text-moyen'>Aucun élément ne correspond aux critères</div>}
        </div>
    );

}

export default TableEntite;