import React, { useEffect , useState } from 'react';
import config from "@config";
import { UserProvider, FilterProvider } from '@context';
import PageTitle from '@pages/page-title';
import TableArchivesVisites from './table-visites';
import ButtonArchiveActionsGroups from "@composants/button/button-archive-actions-groups";

const getArchivesVisites = async (idUser) => {

    const url = config.api(idUser).get.archives_visites;

    return await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Cache-Control': 'no-cache',
        },
    })
        .then(response => response.json())
        .then(response => {
            if (response.success) {
                //console.log(response.visites);
                if(response.data.visites) {
                    return [...response.data.visites];
                }
            }
        });
}

const PageArchivesVisites = (idUser) => {

    const [visites, setVisites] = useState([]);
    const [exportIds, setExportIds] = useState([]);

    useEffect(() => {
        getArchivesVisites(idUser.idUser).then(result => {
            setVisites(result);
        });
    }, [])

    const formatedVisites = [...visites].reduce((result, visite) => {

        const meuble = visite.meuble;
        const proprio = visite.proprio;
        const demandeur = visite.demandeur ? visite.demandeur : proprio ? proprio : null;

        result.push({
            uid: visite.uid,
            nom: meuble?.titre,
            meuble: meuble,
            demandeur: visite?.demandeur,
            proprio: visite?.proprio,
            type: meuble?.type,
            num_classement: meuble?.num_classement,
            user_create: visite.user_create,
            vignette: meuble?.vignette_url,
            comment: visite.infos.comment,
            identification: {uid: visite.uid, titre: meuble?.titre, date_scelle: visite.date_scelle},
            date_visite: new Date(visite.date_visite),
            date_update: new Date(visite.date_update),
            archivage_date: new Date(visite.archivage_date),
            archivage_user: visite.archivage_user,
            archivage_raison: visite.archivage_raison,
            commune: {nom: meuble?.commune, cp: meuble?.code_postal},
            classement: visite.infos.classement_obtenu ? visite.infos.classement_obtenu : 0,
            scelle: visite.scelle,
            capacite: visite.infos.capacite,
            hasLoueur: demandeur?.role === 'Propriétaire' || !!proprio,
        });
        return result;
    }, []);

    const onSelection = async (selection) => {
        setExportIds(selection);
    }

    return(
        <UserProvider>
            <FilterProvider>
                <div className='content-wrapper'>
                    <header className="entry-header">
                        <div className='d-flex w-100 justify-content-between'>
                            <PageTitle exportIds={exportIds} entite='visite' />
                            <div className='d-flex align-items-center gap-3'>
                                <ButtonArchiveActionsGroups selectionEntities={exportIds} entity="visites" status="archive" />
                            </div>
                        </div>
                    </header>
                    <div className="entry-content">
                        <TableArchivesVisites visites={formatedVisites} selectionHandler={onSelection} />
                    </div>
                </div>
            </FilterProvider>
        </UserProvider>
    )
}

export default PageArchivesVisites;