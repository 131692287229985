import VisiteInfos from './visite-infos';
import VisiteGrille from './visite-grille';
import VisiteResultats from './visite-resultats';

import './visite-edit-body.scss';

const VisiteEditBody = ({ page, isOpen }) => {

    return(
        <div className={`visite-body visite-edit-body${page === 'grille' ? ' grille' : ''}`.trim()}>
            {page === 'infos' && <VisiteInfos />} 
            {page === 'grille' && <VisiteGrille editable={true} isOpen={isOpen} />} 
            {page === 'resultats' && <VisiteResultats />}
        </div>
    )
}

export default VisiteEditBody;