import { useState } from '@wordpress/element';
import { ButtonCollapse } from "@composants/button";
import './panel.scss';

export const Panel = ({ className='', contentClassName='', title=undefined, header, children, footer, scrollable=false, collapsible=false }) => {

    const [closed, setClosed] = useState(false);

    const togglePanel = () => {
        setClosed(!closed);
    }

    return(
        <div className={`panel ${className}`.trim()}>
            {!header && <div className='panel-title d-flex justify-content-between align-items-center'>
                {title && <span>{title}</span>}
                {collapsible && <ButtonCollapse statut={closed ? 'collapsed' : ''} variant="light" handler={togglePanel} />}
            </div>}
            {header}
            <div className={`panel-content ${contentClassName}${closed ? ' collapsed' : ''}${scrollable ? ' scrollable' : ''}`.trim()}>{children}</div>
            {footer && <div className='panel-footer'>{footer}</div>}
        </div>
    )
}
