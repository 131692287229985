import { useState, useEffect, useRef, useContext } from '@wordpress/element';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { UserContext } from '@context';
import { useOnlineStatus } from '@hooks';
import { ModaleArchivageAction, ModaleRevision } from '@composants';
import { ButtonAction } from '@composants/button';
import { ReactComponent as IconAction } from '@images/more.svg';
import ModaleDuplicate from "@contact/modale-duplicate";

const buttonContactActions = ({ contact }) => {

    const { id: uid, meubles, user_create } = contact;

    const { user } = useContext(UserContext);
    const isOnline = useOnlineStatus();

    /* Autorisations */
    //const canEdit = parseInt(user_create) === parseInt(user.id) && isOnline; //tous les collegues peuvent editer
    const canEdit = isOnline;
    //const canDelete = meubles.length < 1 && canEdit && isOnline;
    const canDelete = canEdit && isOnline;
    let title_delete = "";
    if( !canDelete ){
        title_delete = "Suppression impossible si un meublé est lié à ce contact ou si pas de connexion internet.";
    }
    const canDuplicate = isOnline;

    const [show, setShow] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showDuplicateModal, setShowDuplicateModal] = useState(false);
    const [showRevisionModal, setShowRevisionModal] = useState(false);

    const btnRef = useRef();

    const onActions = () => {
        setShow(!show);
    };

    const onDelete = () => {
        if(canDelete) setShowDeleteModal(!showDeleteModal);
    }
    const onRevisionAction = () => {
        setShowRevisionModal(!showRevisionModal);
    }

    useEffect(() => {

        const onDocumentClick = (event) => {
            if(btnRef.current && !btnRef.current.contains(event.target)){
                setShow(false);
            }
        }

        document.addEventListener('click', onDocumentClick);return () => {
            document.removeEventListener('click', onDocumentClick);
        }
    }, []);

    const actionsOverlay = (props) => (
        <Popover className='popover-actions' {...props}>
            <Popover.Body>
                <div className='d-flex'>
                    <ButtonAction action="edit_contact" url={`/contacts/contact-edit/?id=${uid}`} isDisabled={!canEdit} />
                    <ButtonAction action="copy_contact" handler={() => setShowDuplicateModal(!showDuplicateModal)} isDisabled={!canDuplicate} />
                    <ButtonAction key={`revision_read-${uid}`} action="revision_read" handler={onRevisionAction} isDisabled={!isOnline} />
                    <ButtonAction action="delete_contact" handler={onDelete} isDisabled={!canDelete} title={title_delete} />
                </div>
            </Popover.Body>
        </Popover>
    );

    return (
        <>
            <OverlayTrigger placement='top' show={show} overlay={actionsOverlay} rootClose={true}>
                <button key={`${contact.id}-btn`} ref={btnRef} className={`btn btn-icon-only btn-transparent btn-lg`} onClick={onActions}>
                    <IconAction className="icon" />
                </button>
            </OverlayTrigger>
            {showDeleteModal && <ModaleArchivageAction
                show='jeter'
                setShow='jeter'
                selectionEntities={[contact.id]}
                action='jeter'
                entity='contacts'
                show={showDeleteModal} setShow={setShowDeleteModal} uid={uid}
            />}
            {showRevisionModal && <ModaleRevision show={showRevisionModal} setShow={setShowRevisionModal} uid={uid} entite='contact' />}
            {showDuplicateModal && <ModaleDuplicate show={showDuplicateModal} setShow={setShowDuplicateModal} uid={uid} type='contact' />}
        </>
    )
}

export default buttonContactActions;