import { ReactComponent as IconUp } from '@images/arrow-up.svg';
import { ReactComponent as IconDown } from '@images/arrow-down.svg';

const NumberInput = ({ className = '', size = '', value, name, label, min=0, pas=1, error, handler }) => {

    const handleUp = () => {
        handler({ target: { name, value: parseFloat(value) + pas } });
    };

    const handleDown = () => {
        const nextValue = (parseFloat(value) - pas) > min ? (parseFloat(value) - pas) : min;
        handler({ target: { name, value: nextValue } });
    };

    return(
        <div className={`form-input ${className} ${size}`}>
            {label && <label className='form-input-label'>{label}</label>}
            <div className='number-input-container'>
                <input type="number" name={name} min={min} className="number-input" step="any" onChange={handler} value={value} />
                <div className='input-nav d-flex flex-column'>
                    <IconUp className="number-input-arrow number-input-arrow-up" onClick={handleUp} />
                    <IconDown className="number-input-arrow number-input-arrow-down" onClick={handleDown} />
                </div>
            </div>
            {error && <span className='error'>{error}</span>}
        </div>
    )
}

export default NumberInput;