const useFilters = () => {

    /**
     * 
     * @param {Object} row 
     * @param {number} columnId 
     * @param {Array} value 
     * @returns {boolean} pour chaque ligne du tableau
     */
    const dateRangeFilter = (row, columnId, value) => {
        const date = row.getValue(columnId);
        if(!value) return undefined;
        const [start, end] = value ?? null;
        if((start || end) && !date) return false;
        if(start && !end){
            return date.getTime() >= start.getTime()
        } else if(!start && end){
            return date.getTime() <= end.getTime()
        } else if (start && end) {
            return date.getTime() >= start.getTime() && date.getTime() <= end.getTime()
        } else {
            return true;
        }
    }

    /**
     * 
     * @param {Object} row 
     * @param {number} columnId 
     * @param {Array} value 
     * @returns {boolean} pour chaque ligne du tableau
     */
    const classementFilter = (row, columnId, value) => {
        if(!value) return false;
        const itemClassement = parseInt(row.getValue(columnId));
        const filterValue = parseInt(value);
        return itemClassement === filterValue;
    }

    /**
     * 
     * @param {object} row 
     * @param {number} columnId 
     * @param {string} value 
     * @returns {boolean} pour chaque ligne du tableau
     */
    const scelleFilter = (row, columnId, value) => {
        return value === row.getValue(columnId).toString();
    }

    return {
        dateRangeFilter, 
        classementFilter, 
        scelleFilter, 
    }
}

export default useFilters;