import { useState, useEffect } from '@wordpress/element';
import { useUser } from '@context';
import { useDB, useFetch, useUtiles, useOnlineStatus } from '@hooks';

/**
 *
 * @param {boolean} sync synchroniser ou pas les données avec le serveur
 * @param {boolean} update Met à jour le statut scellé des visites si true
 * @returns {Object}
 isLoading,  // état du chargement des données
 contacts,   // La liste des contacts
 meubles,    // La liste des meublés
 visites,    // La liste des visites
 *
 */
const useData = (sync = false, update = false) => {

    // Initialisation et connexion à la base de données locale
    const { db } = useDB();
    const { user } = useUser();
    const { countDays } = useUtiles();
    const isOnline = useOnlineStatus();

    // Méthodes pour les requêtes sur l'API
    const { fetchEntities, postEntities, fetchData, fetchAllData, fetchComparableEntities } =  useFetch();

    // Données locales 

    const [localData, setLocalData] = useState();

    // État du chargement des données
    const [isLoading, setIsLoading] = useState(true);

    const [users, setUsers] = useState();
    const [contacts, setContacts] = useState();
    const [meubles, setMeubles] = useState();
    const [visites, setVisites] = useState();

    /**
     *
     * @param {Array} diffs Tableau des différences
     * @param {string} entity Entité à synchroniser
     * @param {string} key Identifiant utilisé (id pour contact et meublé et uid pour visite)
     *
     * Synchronise les données locales avec celles
     * du serveur selon un tableau de différences
     *
     */
    const _updateEntityData = async (diffs, entity, key) => {

        const userId = user.role === "administrator" ? 'all' : user.id;

        /**
         * Liste des données à télécharger (new ou update)
         */
        const dataToGet = diffs.filter(item => item.action === 'get');
        if (dataToGet.length > 0) {

            const getIds = dataToGet.map(item => item[key]);

            // Téléchargment des nouvelles entités
            const loadedData = await fetchEntities(userId, entity, getIds);

            // Enregistrement des entités reçues dans la base de données
            if(loadedData && loadedData.length){
                // traitement des données pour les visites
                if(entity === 'visite') {
                    loadedData.map(visite => {
                        if(visite){
                            if (visite.id){
                                delete visite.id;
                            }
                            const parsedInfos = JSON.parse(visite.infos);
                            const parsedGrille = JSON.parse(visite.grille);
                            const parsedDemandeur = JSON.parse(visite.demandeur);
                            visite.infos = parsedInfos;
                            visite.infos.saved = true;
                            visite.grille = parsedGrille;
                            visite.demandeur = parsedDemandeur;
                            visite.scelle = visite.scelle === "0" ? false : true;
                            delete visite.id;
                            return visite;
                        }
                    });
                }

                loadedData.forEach(async result => {
                    if(result) {

                        await db[`${entity}s`].put({ ...result });

                        // Si entité est contact, chercher les visites dont le demandeur est ce contact et le mettre à jour
                        if(entity === 'contact'){
                            const visitesToUpdate = localData.visites.filter(visite => visite.demandeur && visite.demandeur.id === result.id);
                            visitesToUpdate.forEach(visite => {

                                // Ne pas modifier le contact d'une visite scellée
                                if(visite.scelle) return;

                                // Le meublé du contact lié à la visite
                                const meuble = result.meubles.find(meuble => meuble.id === visite.meuble_id);
                                // Le rôle du contact en tant que demandeur
                                const role = meuble ? meuble.contact_type : null;
                                // Mise à jour des informations du demandeur
                                const isRoleValide = ['Propriétaire', 'Mandataire'].indexOf(role) !== -1;
                                const nextInfos  = {...visite.infos};
                                if(isRoleValide){
                                    visite.demandeur = {...result, role: role};
                                    nextInfos.demandeur_id = result.id;
                                } else {
                                    visite.demandeur = null;
                                    nextInfos.demandeur_id = null;
                                }
                                // Mise à jour de la visite
                                db.visites.put({...visite, infos: nextInfos});
                            });
                        }

                        // Si entité est meuble, chercher les visites dont le meublé est à mettre à jour
                        if(entity === 'meuble'){

                            const nextMeuble = result;
                            const visitesToUpdate = localData.visites.filter(visite => visite.meuble_id === result.id);


                            visitesToUpdate.forEach(visite => {

                                const nextInfos = {...visite.infos};

                                // Ne pas modifier le meublé d'une visite scellée sauf si le meublé n'avait pas de numéro de classement
                                if(visite.scelle) {
                                    const meubleNumClassement = nextInfos.meuble.num_classement;
                                    const nextNumClassement = nextMeuble.num_classement;
                                    if((!meubleNumClassement || meubleNumClassement === '') && nextNumClassement && nextNumClassement.length > 0){
                                        nextInfos.meuble = {...nextInfos.meuble, num_classement: nextNumClassement};
                                        db.visites.put({...visite, infos: nextInfos});
                                    } else {
                                        return;
                                    }
                                } else {
                                    nextInfos.meuble = nextMeuble;
                                    db.visites.put({...visite, infos: nextInfos});
                                }
                            });
                        }
                    }
                });

            }
        }

        /**
         * Liste des données à téléverser
         */
        const dataToPost = diffs.filter(item => item.action === 'post');
        if (dataToPost && dataToPost.length > 0) {
            const postIds = dataToPost.map(item => item.data[key]);
            if(postIds && postIds.length){
                const postsToUpload = await db[`${entity}s`].bulkGet(postIds);
                if(postsToUpload.length && postsToUpload.length > 0){
                    await postEntities(user.id, entity, postsToUpload);
                }
            }
        }

    }

    /**
     * Charger les données de comparaison et synchronise
     * les données locales avec celles du serveur.
     */
    const compareAndUpdateEntities = async () => {

        if(!user) return;

        setIsLoading(true);

        const userId = user.role === "administrator" ? 'all' : user.id;

        if(!userId) {
            setIsLoading(false);
            return;
        }

        await fetchComparableEntities(userId).then(async response => {

            if(!response) return;
            
            /** Les données reçues du serveur */
            const serverContacts = response.contacts.contacts;
            const serverMeubles = response.meubles.meubles;
            const serverVisites = response.visites.visites;
            
            /**
             * Suppression des contacts locaux qui ne sont plus sur le serveur
            */
           const contactsLocal = await db.contacts.toArray();
           const contactsLocalIds = contactsLocal.map(contact => contact.id);
           const contactsServerIds = serverContacts.map(contact => contact.id);
           const contactsToDeleteIds = contactsLocalIds.filter(id => contactsServerIds.indexOf(id) === -1);
           if(contactsToDeleteIds.length) await db.contacts.bulkDelete(contactsToDeleteIds);

            /**
             * Suppression des meublés locaux qui ne sont plus sur le serveur
             */
            const meublesLocal = await db.meubles.toArray();
            const meublesLocalIds = meublesLocal.map(meuble => meuble.id);
            const meublesServerIds = serverMeubles.map(meuble => meuble.id);
            const meublesToDeleteIds = meublesLocalIds.filter(id => meublesServerIds.indexOf(id) === -1);
            if(meublesToDeleteIds.length) await db.meubles.bulkDelete(meublesToDeleteIds);

            /**
             * Suppression des visites locales absentes du serveur
             * et dont l'attribut infos.saved === true
             */
            const visitesServerIds = serverVisites.map(visite => visite.uid);
            const localVisites = await db.visites.toArray();
            const visitesToDeleteIds = localVisites.reduce((result, visite) => {
                if(visitesServerIds.indexOf(visite.uid) === -1 && visite.infos.saved){
                    result.push(visite.uid);
                }
                return result;
            }, []);
            if(visitesToDeleteIds.length) await db.visites.bulkDelete(visitesToDeleteIds);

            /**
             * Comparer les contacts et construire une liste des contacts
             * à télécharger (get) ou à envoyer vers le serveur (post)
             */
            const contactsDiffs = serverContacts.reduce((prev, contact) => {

                const localContact = localData.contacts.find(c => c.id === contact.id);

                if (localContact) {

                    const serverContactDate = new Date(parseInt(contact.du) * 1000);
                    const localContactDate = new Date(localContact.date_update);

                    const serverIsMostRecent = serverContactDate.getTime() > localContactDate.getTime();
                    const localIsMostRecent = localContactDate.getTime() > serverContactDate.getTime();

                    if (serverIsMostRecent) {
                        prev.push({ action: 'get', entite: 'contacts', id: contact.id });
                    }

                    if (localIsMostRecent) {
                        prev.push({ action: 'post', entite: 'contacts', data: localContact });
                    }

                } else {
                    prev.push({ action: 'get', entite: 'contacts', id: contact.id });
                }

                return prev;

            }, []);

            /**
             * Comparer les meubles et construire une liste des meublés
             * à télécharger (get) ou à envoyer vers le serveur (post)
             */
            const meublesDiffs = serverMeubles.reduce((prev, meuble) => {

                const localMeuble = localData.meubles.find(m => m.id === meuble.id);

                if (localMeuble) {

                    const serverMeubleDate = new Date(parseInt(meuble.du) * 1000);
                    const localMeubleDate = new Date(localMeuble.date_update);

                    const serverIsMostRecent = serverMeubleDate.getTime() > localMeubleDate.getTime();
                    const localIsMostRecent = localMeubleDate.getTime() > serverMeubleDate.getTime();

                    if (serverIsMostRecent) {
                        prev.push({ action: 'get', entite: 'meubles', id: meuble.id });
                    }

                    if (localIsMostRecent) {
                        prev.push({ action: 'post', entite: 'meubles', data: localMeuble });
                    }
                } else {
                    prev.push({ action: 'get', entite: 'meubles', id: meuble.id });
                }

                return prev;

            }, []);

            /**
             * Comparer les visites et construire une liste des visites
             * à télécharger (get) ou à envoyer vers le serveur (post)
             */
            const serverVisitesDiffs = serverVisites.reduce((prev, visite) => {

                const localVisite = localData.visites.find(v => v.uid === visite.uid);

                if (localVisite) {

                    const serverVisiteDate = new Date(parseInt(visite.du) * 1000);
                    const localVisiteDate = new Date(localVisite.date_update);

                    const serverIsMostRecent = serverVisiteDate.getTime() > localVisiteDate.getTime();
                    const localIsMostRecent = localVisiteDate.getTime() > serverVisiteDate.getTime();

                    if (serverIsMostRecent) {
                        prev.push({ action: 'get', entite: 'visites', uid: visite.uid });
                    }

                    if (localIsMostRecent) {
                        prev.push({ action: 'post', entite: 'visites', data: localVisite });
                    }

                } else {
                    prev.push({ action: 'get', entite: 'visites', uid: visite.uid });
                }

                return prev;

            }, []);

            /**
             * Lister les nouvelles visites à pousser qui ne sont pas sur le serveur
             */
            const localVisitesDiffs = localData.visites.reduce((prev, visite) => {

                const serverVisite = serverVisites.find(v => v.uid === visite.uid);
                if(!serverVisite){
                    prev.push({ action: 'post', entite: 'visites', data: visite })
                }

                return prev;

            }, []);

            const visitesDiffs = serverVisitesDiffs.concat(localVisitesDiffs);
            
            if (!contactsDiffs.length) setContacts(localData.contacts);
            if (!meublesDiffs.length) setMeubles(localData.meubles);
            if (!visitesDiffs.length) setVisites(localData.visites);

            if (contactsDiffs.length > 0) await _updateEntityData(contactsDiffs, 'contact', 'id');
            if (meublesDiffs.length > 0) await _updateEntityData(meublesDiffs, 'meuble', 'id');
            if (visitesDiffs.length > 0) await _updateEntityData(visitesDiffs, 'visite', 'uid');
            
            /**
             * Mise à jour des donnée en mémoire
            */
            const nextContacts = await db.contacts.toArray();
            const nextMeubles = await db.meubles.toArray();
            const nextVisites = await db.visites.toArray();

            setContacts(nextContacts);
            setMeubles(nextMeubles);
            setVisites(nextVisites);

            setIsLoading(false);

        });
    }

    const updateStatusVisites = async (visites) => {
        const visitesNonScellees = visites.filter(visite => visite.scelle !== true);
        visitesNonScellees.map(async visite => {
            const delta = countDays(visite.date_visite, new Date().getTime());
            if(delta < 0) {
                const date = new Date().toLocaleString('sv-SE');
                visite.scelle = true;
                visite.date_update = date;
            } else {
                visite.scelle = false;
            }
            await db.visites.put(visite);
            return visite;
        });

        return await db.visites.toArray();
    }

    /**
     * Chargement des données locales
     */
    useEffect(() => {

        /** Synchronisation des meublés avec ceux des visites */
        const syncMeublesVisites = async (visites, meubles) => {

            meubles.forEach(meuble => {

                const visitesMeuble = visites.filter(visite => visite.infos.meuble && visite.infos.meuble.id === meuble.id);

                visitesMeuble.forEach(async visite => {

                    const nextInfos = {...visite.infos};

                    // Ne pas modifier le meublé d'une visite scellée sauf si le meublé n'avait pas de numéro de classement
                    if(visite.scelle) {

                        const visiteNumClassement = nextInfos.meuble.num_classement;
                        const meubleNumClassement = meuble.num_classement;

                        if(!meubleNumClassement || meubleNumClassement === '') return;

                        if((!visiteNumClassement || visiteNumClassement === '')){
                            nextInfos.meuble = {...nextInfos.meuble, num_classement: meubleNumClassement};
                            await db.visites.put({...visite, infos: nextInfos});
                        }
                    } else {
                        nextInfos.meuble = meuble;
                        await db.visites.put({...visite, infos: nextInfos});
                    }
                });
            });

            return await db.visites.toArray();
        }

        const loadLocalData = async () => {

            const nextUsers = await db.users.toArray();
            const nextContacts = await db.contacts.toArray();
            const nextMeubles = await db.meubles.toArray();
            const nextVisite = await db.visites.toArray();

            /** Mise à jour du status scellé des visites selon la date et si update === true */
            const updatedVisites = update ? await updateStatusVisites(nextVisite) : nextVisite;

            /** Mise à jour des meublés des visites */
            const meublesUpdatedVisites = await syncMeublesVisites(updatedVisites, nextMeubles);

            setLocalData({
                users: nextUsers,
                contacts: nextContacts,
                meubles: nextMeubles,
                visites: meublesUpdatedVisites
            });
        }

        if(!localData) {
            loadLocalData();
        }

    }, [localData]);

    /**
     * Initialisation des données
     */
    useEffect(() => {

        const user = window.cUser;

        if(!user) {
            return;
        }

        /**
         * Identifiant de l'utilisateur pour les appels d'api
         */
        const userId = user.level === 'administrator' ? 'all' : user.id;

        /**
         * Chargement de toutes les données
         * liées à l'utilisateur courant
         */
        const firstInstall = async() => {

            await fetchAllData(userId).then( async result => {
                await db.users.bulkPut(result.users).then(() => {
                    setUsers(result.users)
                });
                await db.contacts.bulkPut(result.contacts).then(() => setContacts(result.contacts));
                await db.meubles.bulkPut(result.meubles).then(() => setMeubles(result.meubles));
                const nextVisites = result.visites;
                const savedVisites = nextVisites.map(visite => {
                    const nextInfos = visite.infos;
                    nextInfos.saved = true;
                    return {...visite, infos: nextInfos};
                });
                await db.visites.bulkPut(savedVisites).then(() => setVisites(savedVisites));

            });
        }

        if(localData){

            const localUsers = localData.users;
            const localContacts = localData.contacts;
            const localMeubles = localData.meubles;
            const localVisites = localData.visites;

            if(localUsers.length === 0 && localContacts.length === 0 && localMeubles.length === 0 && localVisites.length === 0){
                firstInstall();
            } else {
                if(sync && isOnline) {
                    // chargement des users
                    fetchData(userId, 'users').then(nextUsers => {
                        db.users.bulkPut(nextUsers);
                        setUsers(nextUsers);
                    });
                    compareAndUpdateEntities();
                } else {
                    setUsers(localUsers);
                    setContacts(localContacts);
                    setMeubles(localMeubles);
                    setVisites(localVisites);
                }
            }

        } else {
            // ERREUR si pas d'utilisateur
            if(!user) throw new Error('Aucun utilisateur identifié');
        }

    }, [localData]);

    useEffect(() => {
        
        if(users && contacts && meubles && visites) {
            setIsLoading(false)
        };
    }, [users, contacts, meubles, visites]);

    return {
        isLoading,
        users,
        contacts,
        meubles,
        visites,
    }
}

export default useData;