import { createRoot } from "react-dom";
import { InseeInput } from '@composants/form';

const MeubleEdit = () => {

    const btnDeletePhoto = document.querySelector('.delete_meuble_photo');

    if( btnDeletePhoto ){
        btnDeletePhoto.addEventListener('click', (e) => {
            if( confirm("Voulez-vous vraiment supprimer cette photo du meuble ?")) {
                e.preventDefault();
                const ajaxurl = e.target.dataset.ajaxurl;

                const data = {
                    action: e.target.dataset.action,
                    nonce:  e.target.dataset.nonce,
                    id_meuble: e.target.dataset.id_meuble
                }
                //console.log(data);

                fetch(ajaxurl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Cache-Control': 'no-cache',
                    },
                    body: new URLSearchParams(data),
                })
                    .then(response => response.json())
                    .then(response => {
                        if(response.success == true){
                            window.location = window.location.href;
                        }
                        else if(response.data.message){
                            alert(response.data.message);
                        }
                    });
            }
        })
    }

    /**
     * Sélection auto du département selon code postal
     */
    const cpInput = document.getElementById('code_postal');
    if(cpInput){
        cpInput.addEventListener('blur', (event) => {
            const depSelect = document.getElementById('dep');
            const cp = event.target.value;
            if(depSelect){
                let depNum = cp.substring(0,2);
                if(depNum > 96){
                    depNum = parseInt(cp.substring(0,3));
                }
                depSelect.value = depNum;
            }
        });
    }


    const inseeContainer = document.getElementById('insee-container');

    if(inseeContainer){
        const root = createRoot(inseeContainer);
        root.render(<InseeInput codeInsee={codeInsee} />);
    }

    const residenceInputs = document.getElementsByName('residence');
    if(residenceInputs){
        residenceInputs.forEach((elem) => {
            elem.addEventListener("click", function(event){
                const date_recepisse_mairie =  document.querySelector('#date_recepisse_mairie');
                if( event.target.value === "secondaire" ) {
                    date_recepisse_mairie.disabled = false;
                }
                else {
                    date_recepisse_mairie.disabled = true;
                    date_recepisse_mairie.value = "";
                }
            });
        });
    }

    function compareChainsIgnoreCaseAndAccents(str1, str2) {
        const regex = /[\u00C0-\u017Fa-zA-Z]/gmu; // Expression régulière prenant en compte les lettres accentuées et non accentuées
        const normalizedStr1 = str1.toLowerCase().replace(regex, match => String.fromCodePoint(match.codePointAt(0)));
        const normalizedStr2 = str2.toLowerCase().replace(regex, match => String.fromCodePoint(match.codePointAt(0)));

        return normalizedStr1 === normalizedStr2;
    }
}

export default MeubleEdit;
