import { useContext, useState } from '@wordpress/element';
import { DataContext, FilterProvider } from '@context';
import { useOnlineStatus } from '@hooks';
import TableContacts from './table-contacts';
import PageTitle from '@pages/page-title';
import { ToastContextProvider } from '../src/context';
import ButtonArchiveActionsGroups from "@composants/button/button-archive-actions-groups";

const PageContacts = () => {

    const { isLoading, contacts } = useContext(DataContext);
    const isOnline = useOnlineStatus();
    const [exportIds, setExportIds] = useState([]);

    if(isLoading) return <div className='text-center p-5'><h3 className='h3 text-moyen'>Chargement des contacts…</h3></div>;
    if(!contacts) return <div className='text-center p-5'>Aucun contact ne correspond à cet identifiant</div>;
    
    const contactsFormated = contacts.map(contact => {
        return {...contact, date_update: new Date(contact.date_update)}
    });

    const onSelection = async (selection) => {
        setExportIds(selection);
    }

    return(
        <ToastContextProvider>
            <FilterProvider>
                <div className='content-wrapper'>
                    <header className="entry-header">
                        <div className='d-flex w-100 justify-content-between'>
                            <PageTitle exportIds={exportIds} entite='contact' />
                            {/* <h1>
                                {exportIds.length > 0 && <span className='text-moyen'>{exportIds.length} / </span>}
                                {contacts.length} {contacts.length > 1 ? 'contacts' : 'contact'}
                            </h1> */}
                            <div className='actions d-flex align-items-center gap-3'>
                                <ButtonArchiveActionsGroups selectionEntities={exportIds} entity="contacts" status="publie" />
                                <button className='btn' title='Nouveau contact'  onClick={() => window.location = '/contact/contact-edit/'} disabled={!isOnline}>Nouveau contact</button>
                            </div>
                        </div>
                    </header>
                    <div className="entry-content">
                        <TableContacts contacts={contactsFormated} selectionHandler={onSelection} />
                    </div>
                </div>
            </FilterProvider>
        </ToastContextProvider>
    )

}

export default PageContacts;