import { useState, useEffect, useMemo } from '@wordpress/element';
import { useUtiles } from '@hooks';
import { SearchInput } from '@composants/form';
import { MeubleItem } from '@composants/entites';

import './meubles-selector.scss';

const MeublesSelector = ({ meubles, contacts, handler }) => {

    const { removeAccents } = useUtiles();

    // Ajouter une chaîne de recherche sur chaque meublé
    const searchableMeubles = useMemo(() => {
        return meubles.map(meuble => {

            const { titre, adresse1, adresse2, code_postal, commune } = meuble;
            
            const stitre = titre ? removeAccents(titre).toLowerCase() : '';
            const sadresse = removeAccents(`${adresse1} ${adresse2}`).toLowerCase();
            const scommune = commune ? removeAccents(commune).toLowerCase() : '';
            const contactsMeuble = contacts.filter(contact => contact.meubles.find(m => m.id === meuble.id));

            // Ajouter les noms et prénoms des contacts du meublé
            const contactsSearch = contactsMeuble.reduce((result, contact) => {
                const nom = contact.nom ? removeAccents(contact.nom).toLowerCase() : null;
                const prenom = contact.prenom ? removeAccents(contact.prenom).toLowerCase() : null;
                if(nom){
                    if(prenom) return `${result}${nom} ${prenom} `;
                    return `${result}${nom} `;
                }
                return result;
                
            }, '');

            meuble.search = `${stitre} ${sadresse} ${scommune} ${code_postal} ${contactsSearch}`;
            meuble.active = false;
            meuble.contacts = contactsMeuble;
            return meuble;
        });
    }, [meubles]);

    const [filtered, setFiltered] = useState(searchableMeubles);
    const [selected, setSelected] = useState();
    const [search, setSearch] = useState('');

    const onSearch = (event) => {
        setSearch(event.target.value);
    }

    useEffect(() => {

        const getMeublesFiltered = () => {
            return searchableMeubles.filter(meuble => meuble.search.indexOf(removeAccents(search).toLowerCase()) !== -1);
        }

        if(search !== ''){
            setFiltered(getMeublesFiltered());
        } else {
            setFiltered(searchableMeubles);
        }

    }, [search]);

    useEffect(() => {
        if(selected){
            const nextMeubles = filtered.map(m => {
                m.active = m.id === selected.id;
                return m;
            });
            setFiltered(nextMeubles);
            handler(selected);
        }
    }, [selected]);

    return(
        <div className="meubles-selector">
            <div className='search-wrapper'>
                <SearchInput value={search} handler={onSearch} />
            </div>
            <div className='list-wrapper'>
                <div className="d-flex flex-column gap-2">
                    {!filtered.length && <p className='text-center'>Aucun meublé ne correspond à votre recherche</p>}
                    {filtered.map(meuble => <MeubleItem key={meuble.id} meuble={meuble} action="copy" handler={setSelected} />)}
                </div>
            </div>
        </div>
    );
};

export default MeublesSelector;