import ButtonVisiteActions from '@composants/button/button-visite-actions';

const CellVisiteActions = (props) => {

    return (
        <div className='cell cell-actions cell-inner'>
            <ButtonVisiteActions {...props} />
        </div>
    )
}

export default CellVisiteActions;