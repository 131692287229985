import { useState } from '@wordpress/element';
import { SelectInput } from '@composants/form';
import { ModaleArchivageAction, ModaleExport } from "@composants";
import { useOnlineStatus } from '@hooks';
import { EXPORT_MEUBLE_COLONNES, EXPORT_VISITE_COLONNES } from'@constantes';

const ButtonArchiveActionsGroups = ({ selectionEntities, entity, status, titre, exporter }) => {

    const isOnline = useOnlineStatus();

    const [selectedOption, setSelectedOption] = useState(null);
    const exportColonnes = entity === 'meubles' ? EXPORT_MEUBLE_COLONNES : entity === 'visites' ? EXPORT_VISITE_COLONNES : null;

    const options = [];
    
    if(exporter){
        options.push({value: 'export', label: 'Exporter'});
    }
    if(status === "publie" || status === "") {
        options.push({value: 'archiver', label: 'Archiver'});
    } else {
        options.push({value: 'restaurer', label: 'Restaurer'});
    }
    options.push({ value: 'jeter', label: 'Supprimer' });

    const handleSelectChange = (event) => {
        // Mettre à jour l'état avec la nouvelle sélection
        setSelectedOption(event.target.value);
    };

    return (
        <div>
            <SelectInput
                value={selectedOption} 
                values={options} 
                empty={titre ? titre : "Actions groupées"} 
                handler={handleSelectChange} 
                size='md' 
                disabled={!isOnline || !selectionEntities?.length} 
            />
            {(selectedOption && selectedOption !== 'export') && <ModaleArchivageAction
                show={selectedOption}
                setShow={setSelectedOption}
                selectionEntities={selectionEntities}
                action={selectedOption}
                entity={entity}
            />}
            {(exporter && selectedOption && selectedOption === 'export') && <ModaleExport 
                show={selectedOption} 
                setShow={setSelectedOption} 
                ids={selectionEntities} 
                entity={entity} 
                defaultCols={exportColonnes} 
                ajax={exporter === 'ajax'}
            />}
        </div>
    );
}

export default ButtonArchiveActionsGroups;
