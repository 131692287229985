import { useContext } from '@wordpress/element';
import { VisiteContext, DataProvider } from '@context';
import { useUtiles } from '@hooks';
import { VisiteActionsButtonGroup, VisiteSubHeader } from '@visite-edit/';
import { VisiteUserDates } from '@pages/visite-voir';
import { DropDownContacts } from "@composants/dropdown";

import './visite-header.scss';

const { countDays } = useUtiles();

const VisiteHeader = ({ edit }) => {

    const { visite, meuble } = useContext(VisiteContext);
    
    if(!visite) return null;

    if(!meuble) {
        // window.location = '/visites/';
        return null;
    }

    if(visite.scelle && edit) {
        window.location = `/visites/visite-voir?id=${visite.uid}`;
        return false;
    }

    const dateVisite = new Date(visite.date_visite).toLocaleDateString();
    const delaiRestant = countDays(visite.date_visite, new Date().getTime()); // Le délai avant scellage de la visite

    return(
        <header className='visite-header d-flex flex-column'>

            <div className='d-flex justify-content-between p-3'>
                <div className='d-flex align-items-center gap-2'>

                    <div className='resume d-flex flex-column gap-1'>
                        <div className='visite-identification d-flex gap-2 align-items-center'>
                            <span className='visite-uid'>{visite.uid}</span>
                            <strong className='visite-meuble'>
                                {meuble.id ?
                                    <a className='btn btn-sm outline' role='button' href={`/meubles/meuble-voir/?id=${meuble.id}`}>{meuble.titre?meuble.titre:meuble.id}</a> :
                                    <span>{meuble.titre?meuble.titre:meuble.id}</span>
                                }
                            </strong>
                            {!edit && <>
                                <strong className="caption">du {dateVisite}</strong>
                                {visite.scelle ? 
                                    <strong className="caption text-orange">Visite scellée</strong> :
                                    (delaiRestant > 0) && <strong className="caption text-orange">Scellée dans {delaiRestant} {delaiRestant > 1 ? 'jours' : 'jour'}</strong>
                                }
                                <DataProvider><DropDownContacts meuble={meuble} /></DataProvider>
                            </>}
                        </div>
                        {!edit && <VisiteUserDates visite={visite} />}
                    </div>

                </div>
                <div className='d-flex gap-3 align-items-center'>
                    {edit && <a href={`/visites/visite-voir/?id=${visite.uid}`} className='btn outline'>Retour</a>}
                    {visite && <VisiteActionsButtonGroup visite={visite} edit={edit} />}
                </div>
            </div>

            <VisiteSubHeader infos={visite.infos} edit={edit} />

        </header>
    );

}

export default VisiteHeader;