import { useRef, useEffect } from '@wordpress/element';
import './switch.scss';

const Switch = ({ value, checked, disabled, handler, tabindex, focusHandler }) => {

    const switchRef = useRef();

    const handleClick = () => {
        if(!disabled){
            handler(!checked);
        }
    }

    useEffect(() => {

        const onEnterKey = (event) => {
            if (event.key === "Enter" && !disabled) {
                if(document.activeElement === switchRef.current){
                    handler(!checked);
                }
            }
        }

        if(switchRef.current){
            switchRef.current.addEventListener('keypress', onEnterKey)
        }

        return () => {
            if(switchRef.current){
                switchRef.current.removeEventListener('keypress', onEnterKey);
            }
        }

    }, [checked]);

    return(
        <div 
            ref={switchRef} 
            role="checkbox" 
            tabIndex={tabindex} 
            className={`switch${checked ? " on" : ""}${disabled ? " disabled" : ""}`} 
            onClick={handleClick} 
            onFocus={focusHandler} 
            onBlur={focusHandler} 
        >
            <div className="track"></div>
            <div className="handle">
                {value}
            </div>
        </div>
    );
}

export default Switch;