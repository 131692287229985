import { Page, Text, View, Image } from '@react-pdf/renderer';
import { useUtiles } from '@hooks';

const Annexe1 = ({ visite, meuble, contacts, inspecteur }) => {

    const { demandeur } = visite;
    const { classement_cible } = visite.infos;
    const { numberStringToPhone, getLabelClassementActuel } = useUtiles();

    const dateVisite = new Date(visite.date_visite).toLocaleDateString();
    const dateDecision = new Date(visite.date_decision).toLocaleDateString();
    const dateUpdate = new Date(visite.date_update).toLocaleDateString();
    const dateEnregistrementOrganisme = new Date(inspecteur.organisme_date_agrement).toLocaleDateString('fr-FR');

    const classementActuel = getLabelClassementActuel(meuble);

    const scores = visite.infos.scores;
    const ONC = visite.grille.filter(critere => critere.statut === 'ONC' && critere.checked);
    const labelONC = (5 - ONC.length) === 0 ? 'aucun' : 5 - ONC.length;
    const seuilAtteint = scores.c >= scores.b && ONC.length === 5;
    const alaCarteAtteints = scores.h >= scores.g ? 'OUI' : 'NON';

    const getDemandeurPhones = () => {

        const phone1 = numberStringToPhone(demandeur.telephone);
        const phone2 = numberStringToPhone(demandeur.telephone2);
        
        let output = "Téléphone : ";
        
        if(phone1){
            output += phone1;
            if(phone2){
                output += ', Téléphone 2 : ';
                output += phone2;
            }
        } else if(phone2){
            output += phone2;
        }
        
        return output;
    }

    const loueur = contacts.loueur;
    const mandataire = contacts.mandataire;

    return(
        <Page size="A4" style={{ padding: '8mm 10mm 8mm 10mm', fontFamily: 'Lato' }}>

            <View style={{ textAlign: 'center', fontSize: '18pt', fontWeight: 'bold', marginBottom: '2mm' }}>
                <Text>Annexe I</Text>
            </View>
            <View style={{ textAlign: 'center', fontSize: '12pt', textTransform: 'uppercase' }}>
                <Text>Attestation de visite</Text>
            </View>
            
            <View style={{ border: '1pt solid #000', padding: '2mm', fontSize: '12pt', marginBottom: '2mm' }}>
                
                <View style={{ marginBottom: '2mm' }}>
                    <View style={{ fontWeight: 'bold' }}>
                        <View style={{ marginBottom: '2mm', textDecoration: 'underline' }}><Text>Identification du loueur : </Text></View>
                        <Text>Nom et prénom : {loueur?.civilite} {loueur?.nom} {loueur?.prenom}</Text>
                    </View>
                    <View>
                        <Text>Adresse : {loueur.adresse1} {loueur.adresse2}  {loueur.adresse3}</Text>
                        <Text>Code postal : {loueur?.code_postal} Ville et pays : {loueur?.commune.toUpperCase()}{loueur?.pays ? ' – ' : ' '}{loueur?.pays}</Text>
                        <Text>Téléphone : {numberStringToPhone(loueur?.telephone)}, Téléphone 2 : {numberStringToPhone(loueur?.telephone2)}</Text>
                        <Text>Courriel : {loueur?.email}</Text>
                    </View>
                </View>
                
                <View style={{ marginBottom: '2mm' }}>
                    <View style={{ fontWeight: 'bold' }}>
                        <View style={{ marginBottom: '2mm', textDecoration: 'underline' }}><Text>Identification du mandataire (le cas échéant) : </Text></View>
                        <Text>Nom et prénom : {mandataire?.civilite} {mandataire?.nom} {mandataire?.prenom}</Text>
                    </View>
                    <View>
                        <Text>Adresse : {mandataire?.adresse1} {mandataire?.adresse} {mandataire?.adresse3}</Text>
                        <Text>Code postal : {mandataire?.code_postal} Ville et pays : {mandataire?.commune.toUpperCase()}{mandataire?.pays ? ' – ' : ' '}{mandataire?.pays}</Text>
                        <Text>Téléphone : {numberStringToPhone(mandataire?.telephone)}, Téléphone 2 : {numberStringToPhone(mandataire?.telephone2)}</Text>
                        <Text>Courriel : {mandataire?.email}</Text>
                    </View>
                </View>
                
                {meuble && <View style={{ marginBottom: '2mm' }}>
                    <View style={{ fontWeight: 'bold' }}>
                        <View style={{ marginBottom: '2mm', textDecoration: 'underline' }}><Text>Identification du meublé : </Text></View>
                    </View>
                    <View>
                        <Text>Dénomination commerciale : {meuble.titre ? meuble.titre : ''}</Text>
                        <Text>Adresse : {meuble?.adresse1} {meuble?.adresse2}</Text>
                        <Text>Code postal : {meuble?.code_postal} Ville : {meuble?.commune.toUpperCase()}</Text>
                        <Text>Téléphone : {numberStringToPhone(meuble?.tel)}</Text>
                        <Text>Classement actuel : {classementActuel}</Text>
                        <Text>Catégorie de classement demandé : {classement_cible} {classement_cible > 1 ? "étoiles" : "étoile"}</Text>
                        <Text>Capacité demandée : {visite.infos.accueil_max} {visite.infos.accueil_max > 1 ? "personnes" : "personne"}</Text>
                    </View>
                </View>}

                <View style={{ marginBottom: '2mm' }}>
                    <View style={{ fontWeight: 'bold' }}>
                        <Text>Organisme évaluateur accrédité visé au 1° de l'article L.324-1 du code du tourisme : /</Text>
                    </View>
                    <View>
                        <Text>Date de délivrance de l'accréditation d'inspection pour le classement des meublés de tourisme : /</Text>
                    </View>
                </View>

                <View style={{ marginBottom: '2mm' }}>
                    <View style={{ fontWeight: 'bold' }}>
                        <Text>Organisme visé au 2° de l'article L.324-1 du code du tourisme : {inspecteur.organisme_titre}</Text>
                    </View>
                    <View>
                        <Text>Date de délivrance de l’attestation de certification : {dateEnregistrementOrganisme}</Text>
                    </View>
                </View>

            </View>

            <View style={{fontSize: '12pt', marginBottom: '3mm'}}>
                <View style={{ marginBottom: 5 }}>
                    <Text>Rapport édité le {visite.scelle ? dateDecision : dateUpdate}. Inspection  réalisée le {dateVisite}.</Text>
                </View>
                <View style={{ marginBottom: 5 }}>
                    <Text>Nombre de points obligatoires atteints {scores.c}, soit {Math.floor((scores.c/scores.a)*100)} % du total à atteindre.</Text>
                </View>
                <View style={{ marginBottom: 5 }}>
                    <Text>Conformité du nombre de points total à atteindre : {seuilAtteint ? 'OUI' : 'NON'}.</Text>
                </View>
                <View style={{ marginBottom: 5 }}>
                    <Text>Critères Obligatoires Non Compensables non respectés : {labelONC}.</Text>
                </View>
                <View style={{ marginBottom: 5 }}>
                    <Text>Nombre de points à la carte atteints : {scores.h}.</Text>
                </View>
                <View style={{ marginBottom: 5 }}>
                    <Text>Conformité du nombre de points à la carte à atteindre : {alaCarteAtteints}.</Text>
                </View>
            </View>

            <View style={{ border: '1pt solid #000', padding: '2mm', fontSize: '12pt', textAlign: 'center', marginBottom: '3mm' }}>
                <View style={{ marginBottom: 5 }}>
                    <Text>Avis de l'organisme pour la catégorie de classement et la capacité demandées :</Text>
                </View>
                <View style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>
                    <Text>{(seuilAtteint && scores.h >= scores.g) ? 'FAVORABLE' : 'DÉFAVORABLE'} </Text>
                </View>
            </View>

            <View style={{ fontSize: '12pt' }}>
                <View style={{ marginBottom: "2mm", fontWeight: 'bold'}}>
                    <Text>Nom de l'organisme :&nbsp;{inspecteur.organisme_titre}</Text>
                </View>
                <View style={{ fontStyle: 'italic' }}>
                    <Text>Déclare ces informations justes et sincères</Text>
                </View>
                <View style={{ marginBottom: '2mm' }}>
                    <Text>Date : {dateUpdate}</Text>
                </View>
                <View style={{ display: "flex", flexDirection: "row" }}>
                    <View style={{ width: '50%'}}>
                        <Text>Nom et signature du responsable de l'inspection :</Text>
                        <Text>{inspecteur.civilite} {inspecteur.first_name} {inspecteur.last_name}</Text>
                    </View>
                    <View style={{ width: '50%'}}>
                        <View style={{border: '1pt solid #000', padding: '2mm', width: "90mm", height: "25mm"}}>
                            {(inspecteur.file_signature !== '') && <Image style={{width: "100%", height: "100%", objectFit: "contain"}} src={inspecteur.file_signature} />}
                        </View>
                    </View>
                </View>
            </View>

        </Page>
    );
}

export default Annexe1