import { UserProvider, VisitesProvider, ToastContextProvider } from "@context";
import { PageVisites } from '@visite';

const Visites = () => {

    return(
        <ToastContextProvider>
            <UserProvider>
                <VisitesProvider sync={true}>
                    <PageVisites />
                </VisitesProvider>
            </UserProvider>
        </ToastContextProvider>
    );
}

export default Visites;