const jwt = "def50200fe01e6df500fc39eca4e7d934490d14a4f1fbab3c860a553e68ec07001a02b54662acef843632f9b59ed9d7c12b4ef3a430acb8a3dfeda9c6374765693e7697fc94997ec314266ca381710da16a3f3a26d1aa80adb696ecd2a6a32a6dd37";

const config = {

    // Base de données locale
    db: {
        version: 2.6,
        tables: {
            users: '++id, email, login, jspwd, civilite, first_name, last_name, display_name, level, file_signature, file_tampon, file_logo, organisme_titre, organisme_siret, organisme_date_agrement, organisme_adresse, organisme_adresse2, organisme_commune, organisme_cp, organisme_tel, organisme_url, organisme_email, collegues',
            contacts: '++id, civilite, nom, prenom, email, telephone, telephone2, fax, site_web, adresse1, adresse2, adresse3, code_postal, commune, dep, pays, user_create, user_create_name, user_update, user_update_name, date_create, date_update, meubles, url_vignette',
            meubles: '++id, identifiant, titre, capacite, classement, num_classement, date_classement, type, site_web, tel, description, adresse1, adresse2, code_insee, code_postal, commune, dep, user_create, user_create_name, user_update, user_update_name, date_create, date_update, url_vignette',
            visites: '++uid, date_visite, date_create, date_update, date_decision, user_create, user_create_name, user_update, user_update_name, type_visite, meuble_id, scelle, grille, infos, documents, demandeur',
        }
    },

    // API
    api: (userid, id, idsString, params) => {

        return {

            get: {
                contacts: `/wp-json/nomade-api/v1/contacts?iduser=${userid}&NJWT=${jwt}`, 
                contactsBulk: `/wp-json/nomade-api/v1/contacts/${idsString}?iduser=${userid}&NJWT=${jwt}`,
                contact_duplicate: `/wp-json/nomade-api/v1/contact_duplicate/${id}?NJWT=${jwt}`,
                contact: `/wp-json/nomade-api/v1/contact/${id}/?iduser=${userid}&NJWT=${jwt}`,
                
                meuble: `/wp-json/nomade-api/v1/meuble/${id}/?iduser=${userid}&NJWT=${jwt}`,
                meubles: `/wp-json/nomade-api/v1/meubles?iduser=${userid}&NJWT=${jwt}`,
                meublesBulk: `/wp-json/nomade-api/v1/meubles/${idsString}?iduser=${userid}&NJWT=${jwt}`,
                meuble_duplicate: `/wp-json/nomade-api/v1/meuble_duplicate/${id}?NJWT=${jwt}`,
                meuble_num_classement: `/wp-json/nomade-api/v1/meuble-num-classement/${id}/?iduser=${userid}&NJWT=${jwt}`,
                meubles_export: `/wp-json/nomade-api/v1/meubles/export?iduser=${userid}&ids=${params?.ids}&champs=${params?.champs}&NJWT=${jwt}`,

                contact_meubles: `/wp-json/nomade-api/v1/contact-meubles/${id}?NJWT=${jwt}&deps=all`,
                meuble_contacts: `/wp-json/nomade-api/v1/meuble-contacts/${id}?NJWT=${jwt}&deps=all`,

                visites: `/wp-json/nomade-api/v1/visites?iduser=${userid}&NJWT=${jwt}`, 
                visite: `/wp-json/nomade-api/v1/visite/${id}/?iduser=${userid}&NJWT=${jwt}`, 

                users: `/wp-json/nomade-api/v1/users/?iduser=${userid}&NJWT=${jwt}`,

                archives_meubles: `/wp-json/nomade-api/v1/archives-meubles/?iduser=${userid}&NJWT=${jwt}`,
                archives_contacts: `/wp-json/nomade-api/v1/archives-contacts/?iduser=${userid}&NJWT=${jwt}`,
                archives_visites: `/wp-json/nomade-api/v1/archives-visites/?iduser=${userid}&NJWT=${jwt}`,

                revisions: `/wp-json/nomade-api/v1/revisions/?iduser=${userid}&NJWT=${jwt}`,
            },

            post: {
                visite: `/wp-json/nomade-api/v1/visite?NJWT=${jwt}`,
                visite_file_upload: `/wp-json/nomade-api/v1/visite-file-upload?iduser=${userid}&uid=${id}&NJWT=${jwt}`,
            },

            comparableEntities : {
                contacts: `/wp-json/nomade-api/v1/comparable-entities?entite=contacts&iduser=${userid}&NJWT=${jwt}`,
                meubles: `/wp-json/nomade-api/v1/comparable-entities?entite=meubles&iduser=${userid}&NJWT=${jwt}`,
                visites: `/wp-json/nomade-api/v1/comparable-entities?entite=visites&iduser=${userid}&NJWT=${jwt}`,
            },

            archivage : {
                archiver: `/wp-json/nomade-api/v1/archiver?iduser=${userid}&NJWT=${jwt}`,
                restaurer: `/wp-json/nomade-api/v1/restaurer?iduser=${userid}&NJWT=${jwt}`,
                jeter: `/wp-json/nomade-api/v1/jeter?iduser=${userid}&NJWT=${jwt}`,

            },

            del: {
                visite_file_delete: `/wp-json/nomade-api/v1/visite-file-delete?iduser=${userid}&iddoc=${id}&NJWT=${jwt}`, 
                visite_delete: `/wp-json/nomade-api/v1/visite/${id}/?iduser=${userid}&NJWT=${jwt}`, 
                meuble_delete: `/wp-json/nomade-api/v1/meuble/${id}/?iduser=${userid}&NJWT=${jwt}`,
                contact_delete: `/wp-json/nomade-api/v1/contact/${id}/?iduser=${userid}&NJWT=${jwt}`,
            }
        }
    },
    version : "1.4.0"
}

export default config;