import { ReactComponent as MaisonIcon } from '@images/maison.svg';
import { ReactComponent as AppartIcon } from '@images/appartement.svg';
import { ReactComponent as ContactIcon } from '@images/person.svg';

import './vignette.scss';

const Vignette = ({ variant='', className='', children }) => {
    return(
        <div className={`vignette ${className}`}>
            {children ? children :
                <>
                    {variant === 'contact' && <ContactIcon />}
                    {variant === 'maison' && <MaisonIcon />}
                    {variant === 'appartement' && <AppartIcon />}
                </>
            }
        </div>
    )
}

export default Vignette;