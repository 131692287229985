import { useState, useEffect, useReducer, useContext, createContext } from '@wordpress/element';
import { SURFACES_MIN, SURFACES_MIN_CHAMBRE, DOMTOM } from '@constantes';
import { GRILLE_DEFAULTS } from '@/grille-classement';
import { useData, useDB, useUtiles } from '@hooks';

const VisiteContext = createContext(null);
const VisiteDispatchContext = createContext(null);
const { db } = useDB();
const { countDays } = useUtiles();

/**
 * 
 * @param {Array} grille 
 * @param {int} classement 
 * @returns {Array} grille mise à jour
 * 
 * Met à jour du statut de chaque critère selon le classement recherché
 */
const updateStatusCriteres = (grille, infos) => {

    const classement = infos.classement_cible;
    const index = classement > 0 ? classement - 1 : 0;
    const isDomTom = DOMTOM.includes(infos.meuble.dep);
    
    return grille.map(critere => {

        const defauts = GRILLE_DEFAULTS[critere.id];
        const isNA = critere.statut === 'NA' && GRILLE_DEFAULTS[critere.id].na;

        // Attribuer au critere le statut correspondant au classemenet
        if(!isNA){
            const nextStatus = defauts.classement[index];
            critere.statut = nextStatus;
        }

        switch(critere.id){
            case 2: // attribuer les points selon surfaces majorées
                const { totalHabitable, majorees, majoreeSeule } = infos.surfaces;
                let points = 0;
                if(totalHabitable >= majoreeSeule * 1.2) points += 1;
                if(totalHabitable >= majoreeSeule * 1.4) points += 1;
                if(totalHabitable >= majoreeSeule * 1.6) points += 1;
                if(totalHabitable >= majoreeSeule * 1.8) points += 1;
                if(totalHabitable >= majoreeSeule * 2) points += 1;
                critere.points = points;
                critere.checked = points > 0;
                break;
            case 16: // Non applicables si DROM-TOM
                if(isDomTom){
                    critere.statut = 'NA',
                    critere.checked = false;
                } else {
                    critere.statut = defauts.classement[index];
                }
                break;
            case 18: // Non applicables si capacité < 4
                if(infos.capacite < 4){
                    critere.statut = 'NA';
                    critere.checked = false;
                } else {
                    critere.statut = defauts.classement[index];
                }
                break;
            case 19: // Non applicables si capacité < 6 ou DOM-TOM
                if(infos.capacite < 6 || isDomTom){
                    critere.statut = 'NA';
                    critere.checked = false;
                } else {
                    critere.statut = defauts.classement[index];
                }
                break;
            case 27: // Non applicables si studio
                if(infos.meuble.type === 'Studio'){
                    critere.statut = 'NA';
                    critere.checked = false;
                } else {
                    critere.statut = defauts.classement[index];
                }
                break;
            case 43: // Non applicables si capacité < 7 jusqu'à 4 étoiles et jusqu'à 5 pour 5 étoiles
            case 44:
            case 45:
                if((infos.capacite < 7 && classement < 5) || (infos.capacite < 5 && classement === 5)){
                    critere.statut = 'NA';
                    critere.checked = false;
                }
                break;
            case 73:
                if(infos.capacite < 4){
                    critere.statut = 'NA';
                    critere.checked = false;
                } else {
                    critere.statut = defauts.classement[index];
                }
                break;
            case 77: // modifier le statut selon l'étage et la présence d'un asenceur
                if(infos.etage === 0){
                    critere.statut = 'NA';
                    critere.checked = false;
                } else {
                    if(infos.etage < 4 || infos.ascenseur === 'NA') {
                        critere.statut = 'NA';
                    } else {
                        critere.statut =  classement < 3 ? 'X' : 'NA';
                    }
                    critere.checked = classement < 3 ? infos.ascenseur === 'NA' ? false : infos.ascenseur : false;
                }
                break;
            case 78: // modifier le statut selon l'étage et la présence d'un asenceur
                if(infos.etage === 0){
                    critere.statut = 'NA';
                    critere.checked = false;
                } else {
                    if(infos.etage < 3 || infos.ascenseur === 'NA') {
                        critere.statut = 'NA';
                    } else {
                        critere.statut = classement < 3 ? 'O' : 'X';
                    }
                    critere.checked = infos.ascenseur === 'NA' ? false : infos.ascenseur;
                }
                break;
            case 113: // Si le meublé est lié a un site web
                critere.checked = infos.meuble && infos.meuble.site_web ? true : false;
                break;
            case 114: 
                critere.auto = infos.meuble && infos.meuble.site_web ? false : true;
                if(critere.auto) delete critere.checked;
                break;
        }

        return {...critere};
    });
}

/**
 * 
 * @param {Array} grille 
 * @param {int} classement 
 * @returns {Object} scores
 * 
 * Calcule les scores d'une visite
 */
const updateScores = (grille, classement) => {

    const scores = {};
    
    // Liste des critères obligatoires
    const obligatoires = grille.filter(critere => critere.statut === "X" || critere.statut === 'ONC');
    // Liste des critères obligatoires validés
    const obligatoiresValides = obligatoires.filter(critere => critere.checked);

    // Liste des critères optionnels
    const optionnels = grille.filter(critere => critere.statut === "O");
    // Liste des points optionnels validés
    const optionnelsValides = optionnels.filter(critere => critere.checked);
    
    // A - Nombre total de points obligatoires à atteindre
    scores.a = obligatoires.reduce((total, critere) => {
        return total + critere.points;
    }, 0);
    
    // B - Nombre minimal de points obligatoires à atteindre
    scores.b = Math.ceil(scores.a * 0.95);

    // C - Nombre de points obligatoires atteints
    scores.c = obligatoiresValides.reduce((total, critere) => {
        return total + critere.points;
    }, 0);

    // D - Nombre de points obligatoires à compenser dans la limite de 5% du total des points obligatoires à respecter
    scores.d = scores.c >= scores.b ? (scores.a - scores.c) * 3 : 0;

    // E - Nombre de points à la carte
    scores.e = optionnels.reduce((total, critere) => {
        return total + critere.points;
    }, 0);
    
    // F - Nombre de points à la carte à respecter
    const ratios = {0: 0, 1: 0.05, 2: 0.1, 3: 0.2, 4: 0.3, 5: 0.4};
    scores.f = Math.round(scores.e * ratios[classement]);

    // G - Nombre de points à la carte à atteindre
    scores.g = scores.d + scores.f;

    // H - Nombre de points à la carte atteints
    scores.h = optionnelsValides.reduce((total, critere) => {
        return total + critere.points;
    }, 0);

    return scores;
}

/**
 * 
 * @param {Array} pieces 
 * @param {int} index 
 * @returns {int} un entier représentant la surface minimum légale du logement
 * 
 * Ce nombre dépend du niveau de classement recherché, 
 * du nombre de couchages supplémentaires 
 * et du nombre de pièces d'habitation
 */
const getSurfaceMin = (pieces, index, classement) => {

    // Pièces d'habitation
    const habitations = pieces.filter(piece => piece.habitation === true);
    
    // Nombre de personnes déclarées
    const personnes = pieces.reduce((total, piece) => total + piece.personnes, 0);
    
    // Nombre de pièces supplémentaires
    const piecesSup = habitations.length > 1 ? habitations.length - 1 : 0;

    // Nombre de personnes minimum selon le nombre de pièces d'habitation (2 personnes par pièce d'habitation)
    const couchagesMin = habitations.length * 2;
    
    // Nombre de personnes supplémentaires
    const personnesSup = personnes > couchagesMin ? personnes - couchagesMin : 0;

    // Surface minimum selon le nombre de pièces et le nombre de personnes supplémentaires
    const surfaceMin = SURFACES_MIN[index] + (piecesSup * SURFACES_MIN_CHAMBRE[index]) + (personnesSup * 3);

    // Tolérance de 10 % pour les classements su^érieurs à 2 étoiles
    const tolerance  = classement > 2 ? SURFACES_MIN[index] * 0.1 : 0;

    return surfaceMin - tolerance;
}

/**
 * 
 * @param {Array} pieces 
 * @param {int} classement 
 * @returns {Object} surfaces totales et majorées
 */
const updateSurfaces = (pieces, classement) => {

    // Calcul du total des surfaces saisies
    const totalSurfaces = pieces.reduce((total, piece) => {
        return total + parseFloat(piece.surface);
    }, 0);

    // Pièces habitables
    const piecesHabitables = pieces.filter(piece => piece.habitable === true);

    // Calcul de la surface habitable du meublé
    const surfaceHabitable = piecesHabitables.reduce((total, piece) => {
        return total + parseFloat(piece.surface);
    }, 0);

    // Index selon classement pour accéder aux tableaux de constantes
    const index = classement > 0 ? classement - 1 : 0;

    // Personnes supplémentaires
    const personnesSupplementaires = pieces.reduce((total, piece) => {
        if(piece.personnes > 2){
            const sups = piece.personnes - 2;
            return total + sups;
        }
        return total;
    }, 0);

    // Calcul de la surface minimum selon le nombre de pièces et le nombre de personnes supplémentaires
    const surfaceMin = getSurfaceMin(piecesHabitables, index, classement);

    // Pièces d'habitation
    const habitations = pieces.filter(piece => piece.habitation === true && piece.surface >= 7);
    // Surface ajoutée pour les personnes supplémentaires
    const surfacePersonnes = personnesSupplementaires * 3; // 3 étant la surface minimum d'une pièce d'habitation pour une personne
    // Surface totale des pièces d'habitation supplémentaires au-delà de la première pièce d'habitation
    const surfaceHabitationsSup = habitations.length > 0 ? (habitations.length - 1) * SURFACES_MIN_CHAMBRE[index] : 0;
    // Surface majoree sans appliquer le taux de bonification
    const surfaceMajoreeSeule = SURFACES_MIN[index] + surfaceHabitationsSup + surfacePersonnes;
    // La surface majorée avec aplication des taux de bonification
    const surfacesMajorees = 
        surfaceHabitable >= (surfaceMajoreeSeule * 2) ? surfaceMajoreeSeule * 2 : 
            surfaceHabitable >= (surfaceMajoreeSeule * 1.8) ? surfaceMajoreeSeule * 1.8 :
                surfaceHabitable >= (surfaceMajoreeSeule * 1.6) ? surfaceMajoreeSeule * 1.6 :
                    surfaceHabitable >= (surfaceMajoreeSeule * 1.4) ? surfaceMajoreeSeule * 1.4 :
                        surfaceHabitable >= (surfaceMajoreeSeule * 1.2) ? surfaceMajoreeSeule * 1.2 : 
                            surfaceHabitable;

    return {
        total: totalSurfaces,
        totalHabitable: surfaceHabitable, 
        majoreeSeule: surfaceMajoreeSeule, 
        majorees: surfacesMajorees,
        minimum: surfaceMin, 
    };

}

/**
 * 
 * @param {Array} grille 
 * @returns {Array} La liste des critères non compensables, non validés
 */
const getONC = (grille) => {
    const allONC = grille.filter(item => item.statut === 'ONC' && !item.checked);
    return allONC.map(onc => onc.id);
}

/**
 * 
 * @param {Array} grille 
 * @returns {Array} La liste des critères non applicables, validés
 */
const getAllNA = (grille) => {
    const allNa = grille.filter(item => item.statut === 'NA');
    return allNa.map(na => na.id);
}

const getClassement = (infos) => {
    if(
        infos.scores.c >= infos.scores.b && // Critères obligatoires
        infos.scores.h >= infos.scores.g && // Critères à la carte
        infos.criteres_non_compensables.length === 0 // Critères non compensables
    ) {
        return infos.classement_cible;
    } else {
        return 0;
    }
}

const getDiagnostic = (infos, grille) => {

    const nextInfos = structuredClone(infos);
    const nextGrille = structuredClone(grille);

    function getDiag(cible){
        nextInfos.classement_cible = cible;
        const grilleUpdate = updateStatusCriteres([...nextGrille], {...nextInfos});
        const nextScores = updateScores(grilleUpdate, cible);
        nextInfos.scores = nextScores;
        const result = getClassement({...nextInfos});
        return result;
    }

    return [getDiag(1), getDiag(2), getDiag(3), getDiag(4), getDiag(5)];
}

const updateVisite = (visite) => {

    if(visite.scelle) {
        return visite;
    }

    const nextVisite = {...visite};

    // Mise à jour des infos
    const nextInfos = {...nextVisite.infos};

    // Calcul des surfaces majorées
    nextInfos.surfaces = updateSurfaces(nextInfos.superficies, nextInfos.classement_cible);

    // Mise à jour de la capacité d'accueil selon pièces saisies
    nextInfos.capacite = nextInfos.superficies.reduce((total, current) => {
        return total + parseInt(current.personnes);
    }, 0);

    // Mise à jour de la grille
    const nextGrille = updateStatusCriteres([...nextVisite.grille], {...nextInfos});
            
    // Mise à jour de la liste des critères non compensables non validés
    nextInfos.criteres_non_compensables = getONC(nextGrille);

    // Mise à jour de la liste des critères non-applicables sélectionnés
    nextInfos.criteres_non_applicables = getAllNA(nextGrille);
    
    // Calcul des scores
    const nextScores = updateScores(nextGrille, nextInfos.classement_cible);
    nextInfos.scores = nextScores;
    
    // Mise à jour du classement obtenu selon les scores
    nextInfos.classement_obtenu = getClassement(nextInfos);

    // Enregistrement des classemnt possibles pour le mode diagnostic
    nextInfos.diagnostic = getDiagnostic(nextInfos, nextGrille);

    // Si la visite est scellée, la date de mise à jour n'est pas modifiée
    const dateUpdate = visite.scelle ? visite.date_update : new Date().toLocaleString('sv-SE');

    // Contrôle de la date_visite pour sceller la visite
    const delta = countDays(visite.date_visite, new Date().getTime());
    const visiteScelle = nextVisite.scelle ? nextVisite.scelle : delta < 1;

    // Enregistrement de la visite en base de données
    const visiteUpdated = { ...nextVisite, grille: nextGrille, infos: nextInfos, date_update: dateUpdate, scelle: visiteScelle };
    db.visites.put(visiteUpdated);

    return visiteUpdated;
}

const visiteReducer = (visite, action) => {
    switch (action.type){
        case 'init': {
            return {...action.visite};
        }
        case 'classement_cible': {
            const nextInfos = {...visite.infos};

            // désélection critère 28 (dimensions des lits) si passage à un classement supérieur à 3 étoiles.
            const critere28 = visite.grille.find(critere => critere.id === 28);
            if(action.value > 3 && nextInfos.classement_cible <= 3){
                critere28.checked = false;
            }
            
            // désélection du critère 30 (oreillers) si passage à un classement supérieur à 2 étoiles.
            const critere30 = visite.grille.find(critere => critere.id === 30);
            if(action.value > 2 && nextInfos.classement_cible <= 2){
                critere30.checked = false;
            }
            
            // désélection du critère 57 (nombre de foyers) si passage à un classement supérieur à 2 étoiles.
            const critere57 = visite.grille.find(critere => critere.id === 57);
            if(action.value > 2 && nextInfos.classement_cible <= 2){
                critere57.checked = false;
            }

            // Passer le nombre de personnes par pièce d'habitation à 3 si classement > 4 étoiles
            const nextSuperficies = nextInfos.superficies;
            if(parseInt(action.value) > 4){
                nextSuperficies.map(piece => {
                    if(piece.personnes > 3) piece.personnes = 3;
                });
                nextInfos.superficies = nextSuperficies;
            }

            nextInfos.classement_cible = parseInt(action.value);
            return updateVisite({ ...visite, infos: nextInfos });
        }
        case 'capacite': {
            const nextInfos = {...visite.infos}
            nextInfos.accueil_max = parseInt(action.value);
            return updateVisite({ ...visite, infos: nextInfos });
        }
        case 'etage': {
            const nextInfos = {...visite.infos}
            nextInfos.etage = parseInt(action.value);
            return updateVisite({ ...visite, infos: nextInfos });
        }
        case 'ascenseur': {
            const nextInfos = {...visite.infos}
            nextInfos.ascenseur = action.value === 'true' ? true : action.value === 'false' ? false : action.value;
            return updateVisite({ ...visite, infos: nextInfos });
        }
        case 'superficies': {
            const nextInfos = {...visite.infos};
            nextInfos.superficies = action.value;
            return updateVisite({ ...visite, infos: nextInfos });
        }
        case 'critere': {
            const nextGrille = [...visite.grille];
            const critere = nextGrille.find(item => item.id === action.value.id);
            critere.checked = action.value.checked;
            return updateVisite({ ...visite, grille: nextGrille });
        }
        case 'comment-critere': {
            const nextGrille = [...visite.grille];
            const critere = nextGrille.find(item => item.id === action.value.id);
            critere.comment = action.value.comment;
            return updateVisite({ ...visite, grille: nextGrille });
        }
        case 'comment-visite': {
            const nextInfos = {...visite.infos};
            nextInfos.comment = action.value;

            /* Enregistrement de la visite même si scellée */
            if(visite.scelle) {
                db.visites.put({ ...visite, infos: nextInfos });
            }

            return updateVisite({ ...visite, infos: nextInfos });
        }
        case 'NA': {
            // Mise à jour de la grille
            const nextGrille = [...visite.grille];
            const critere = nextGrille.find(item => item.id === action.value.id);
            const index = visite.classement_cible > 0 ? visite.classement_cible - 1 : 0;
            critere.statut = action.value.checked ? 'NA' : GRILLE_DEFAULTS[critere.id].classement[index];
            critere.checked = false;

            return updateVisite({ ...visite, grille: nextGrille });
        }
        case 'date_visite': {
            const nextVisite = {...visite, date_visite: action.value, date_update: new Date().toLocaleString('sv-SE')};
            return updateVisite(nextVisite);
        }
        case 'type_visite': {
            const nextVisite = {...visite, type_visite: action.value, date_update: new Date().toLocaleString('sv-SE')};
            return updateVisite(nextVisite);
        }
        case 'file-upload': {
            const nextFileData = action.value;
            delete nextFileData.success;
            delete nextFileData.message;
            const nextDocs = visite.documents ? [...visite.documents] : [];
            nextDocs.push(nextFileData);
            const nextVisite = {...visite, documents: [...nextDocs]};

            /* Enregistrement de la visite même si scellée */
            if(visite.scelle) {
                db.visites.put({ ...nextVisite });
            }

            return updateVisite(nextVisite);
        }
        case 'file-delete': {
            const nextDocs = [...visite.documents].filter(file => file.url !== action.value.url);

            /* Enregistrement de la visite même si scellée */
            if(visite.scelle) {
                db.visites.put({...visite, documents: nextDocs});
            }
            
            return updateVisite({...visite, documents: nextDocs});
        }
        case 'lock-visite': {
            const dateUpdate = new Date().toLocaleString('sv-SE');
            const dateDecision = visite.date_decision ? visite.date_decision : dateUpdate;
            const nextVisite = {...visite, scelle: true, date_update: dateUpdate, date_decision: dateDecision};
            db.visites.put(nextVisite);
            return nextVisite;
        }
        case 'saved': {
            console.log("is visite saved ? ", visite.infos.saved);
            const nextInfos = {...visite.infos};
            nextInfos.saved = action.value;
            return updateVisite({ ...visite, infos: nextInfos });
        }
        case 'demandeur-selection': {
            const nextInfos = {...visite.infos};
            nextInfos.demandeur_id = action.value.id;
            return updateVisite({ ...visite, infos: nextInfos, demandeur: action.value.contact });
        }
        default: {
            throw Error('Unknown action: ' + action.type);
        }
    }
}

const VisiteProvider = ({ children }) => {

    const { isLoading, visites, meubles, contacts, users } = useData();
    
    const [visite, dispatch] = useReducer(visiteReducer, null);
    const [contactsMeuble, setContactsMeuble] = useState();
    const [meuble, setMeuble] = useState();
    const [inspecteur, setInspecteur] = useState(); // L'inspecteur qui a créé la visite

    useEffect(() => {

        // Quand les données sont disponibles
        if(visites && meubles && users){
            
            // Trouver l'id de la visite dans l'url
            const searchParams = new URLSearchParams(window.location.search);
            const uid = searchParams.get('id');
            
            // Sélection de la visite dans la base locale
            const nextVisite = visites.find(v => v.uid === uid);
            
            if(nextVisite){

                /** Meublé dans indexedDB */
                const dbMeuble = meubles.find(m => m.id === nextVisite.meuble_id);

                /** Meublé lié à la visite */
                const visiteMeuble = nextVisite.infos.meuble;

                if(nextVisite.infos.meuble) {
                    setMeuble(nextVisite.infos.meuble);
                } else {
                    if(dbMeuble){
                        nextVisite.infos.meuble = dbMeuble;
                        setMeuble({...dbMeuble, original: true});
                    }
                }
    
                if(nextVisite.infos.meuble){
                    const nextContacts = contacts.reduce((prev, contact) => {
                        const foundMeubleLien = contact.meubles.find(m => parseInt(m.id) === parseInt(nextVisite.infos.meuble.id));
                        if (foundMeubleLien) {
                            prev.push({...contact, role: foundMeubleLien.contact_type});
                        }
                        return prev;
                    }, []);

                    setContactsMeuble(nextContacts);
                    dispatch({ type: 'init', visite: nextVisite });
                }

                // Trouver l'inspecteur qui a créé la visite
                const nextInspecteur = users.find(i => i.id === parseInt(nextVisite.user_create));
                if(nextInspecteur) setInspecteur(nextInspecteur);
            }

        }

    }, [visites, meubles]);

    return(
        <VisiteContext.Provider value={{ isLoading, visite, meuble, inspecteur, contactsMeuble }}>
            <VisiteDispatchContext.Provider value={dispatch}>
                {children}
            </VisiteDispatchContext.Provider>
        </VisiteContext.Provider>
    );
}

const useVisite = () => {
    return useContext(VisiteContext);
}

const useVisiteDispatch = () => {
    return useContext(VisiteDispatchContext);
}

export {
    VisiteContext, 
    VisiteProvider, 
    useVisiteDispatch, 
    useVisite, 
};