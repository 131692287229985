import { Vignette } from '@composants';

const ContactItem = ({ contact }) => {

    const contactUrl = `/contacts/contact-voir/?id=${contact.id}`;

    return (
        <div className="entite-item contact-item" onClick={ () => window.location=contactUrl }>
            <div className="d-flex">
                <Vignette variant="contact" className="rond">
                    {contact.vignette_url && <img src={contact.vignette_url} />}
                </Vignette>
                <div className="body">
                    <div className='identification d-flex flex-column justify-content-center h-100'>
                        <strong className='nom'>{contact.civilite} {contact.prenom} {contact.nom}</strong>
                        <span>{contact.adresse1} {contact.adresse2} – {contact.code_postal} {contact.commune}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactItem;