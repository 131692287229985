import { useState, useEffect, useRef } from '@wordpress/element';
import { Panel } from '@composants';
import Select from 'react-select';
import config from '@config';


//Liste tous les contacts d'un meuble
const getMeubleContacts = async (meuble) => {

    const url = config.api('', meuble.id).get.meuble_contacts;

    return await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Cache-Control': 'no-cache',
        },
        //body: new URLSearchParams(data)
    })
        .then(response => response.json())
        .then(response => {
            if (response.success) {
                //console.log(response.meubles);
                if(response.data.contacts) {
                    return [...response.data.contacts];
                }
            }
        });
}

const MeubleLiaisonContact = () => {
    const selectContact = useRef(null);
    const [meubleContacts, setMeubleContacts] = useState([]);
    const [contactsToSelect, setContactsToSelect] = useState();

    function associer() {

        const contactSelected = selectContact.current ? selectContact.current.state.selectValue[0].value : null;

        const contactTypeSelected = document.getElementById("contact_type").value;

        const data = {
            action: 'associer_contact_meuble',
            nonce: document.getElementById('nonce_associer_contact_meuble').value,
            id_contact: contactSelected,
            id_meuble: meuble.id,
            contact_type: contactTypeSelected
        }

        fetch('/wp-admin/admin-ajax.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Cache-Control': 'no-cache',
            },
            body: new URLSearchParams(data)
        })
            .then(response => response.json())
            .then(response => {
                console.log(response);
                if (response.success) {

                    getMeubleContacts(meuble).then(contacts => {
                        setMeubleContacts(contacts);
                        //rafraichi le select choix des contacts à associer
                        //chargeMeublesSaufContactType();
                        const $select = document.querySelector('#contact_type');
                        $select.value = '';
                        document.querySelector('#block_select_association').style.visibility = 'hidden';
                    });
                }
            });
    }


    function dissocier(id_contact, id_meuble, contact_type) {

        if ( ! window.confirm('Souhaitez vous dissocier ce contact de ce meublé ?') ) {
            return false;
        }

        const data = {
            action: 'dissocier_contact_meuble',
            nonce: document.getElementById('nonce_dissocier_contact_meuble').value,
            id_contact: id_contact,
            id_meuble: id_meuble,
            contact_type: contact_type
        }
        //console.log("dissocier data : ", data);
        fetch('/wp-admin/admin-ajax.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Cache-Control': 'no-cache',
            },
            body: new URLSearchParams(data)
        })
            .then(response => response.json())
            .then(response => {
                //console.log(response)
                if (response.success) {
                    getMeubleContacts(meuble).then(contacts => {
                        setMeubleContacts(contacts);

                        //rafraichi le select choix des contacts à associer
                        const $select = document.querySelector('#contact_type');
                        $select.value = '';
                        //cache le block des contacts, il sera mis à jour lors du changement du contact_type
                        document.querySelector('#block_select_association').style.visibility = 'hidden';
                    });
                }
            });
    }


    function chargeMeublesSaufContactType()
    {
        const contactTypeSelected = document.getElementById("contact_type").value;
        if( contactTypeSelected == "" ){
            return false;
        }
        const id_meuble = document.getElementById("id").value;

        const data = {
            action: 'liste_contacts_4meuble_sauf_profil',
            contact_type: contactTypeSelected,
            id_meuble: id_meuble
        }
        fetch('/wp-admin/admin-ajax.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Cache-Control': 'no-cache',
            },
            body: new URLSearchParams(data)
        })
        .then(response => response.json())
        .then(response => {
            if (response.success) {

                const options = [];
                response.data.contacts.map( contact => {
                        if(contact.civilite && contact.nom) {
                            options.push({
                                value: contact.id,
                                label: (contact.civilite.length ? contact.civilite + ' ' : '') + (contact.nom.length ? contact.nom + ' ' : '') + (contact.prenom ? contact.prenom : '')
                            });
                        }
                    }
                );
                setContactsToSelect(options);

                //si le block est caché on l'affiche
                const $block_select = document.querySelector('#block_select_association');
                if($block_select) {
                    $block_select.style.visibility = 'visible';
                }
            }
        });
    }

    useEffect(() => {
        getMeubleContacts(meuble).then(result => {
            setMeubleContacts(result);
        });
    }, [])

    const customStyles = {
        container: provided => ({
            ...provided,
            width: 450
        })
    };

    return (
        <Panel title="Contacts associés à ce meublé">

            <div className='table-responsive'>
                <table className="table table-hover align-middle">
                    <thead>
                        <tr>
                            <th className='py-3'>Identification</th>
                            <th className='py-3'>Email</th>
                            <th className='py-3'>Type de liaison</th>
                            <th className='py-3'>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {meubleContacts && meubleContacts.map(contact =>
                            <tr key={contact.id + contact.contact_type}>
                                <th scope="row"><a href={`/contact-edit?id=${contact.id}`}>{contact.civilite} {contact.prenom} {contact.nom}</a></th>
                                <td>{contact.email}</td>
                                <td>{contact.contact_type}</td>
                                <td>
                                    <button className='btn' type="button" onClick={() => dissocier(contact.id, meuble.id, contact.contact_type)}>Dissocier</button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            <div className='d-flex flex-column gap-5'>
                <h4 className='h4'>Associer un contact à ce meublé</h4>
                <div className="d-flex gap-3 pb-5">
                    <div>
                        <label htmlFor="type" className="form-label">1. Rôle</label>
                        <select className='form-control' id="contact_type" onChange={() => chargeMeublesSaufContactType()}>
                            <option value="">1 - Choisir le rôle</option>
                            {contactTypes.map(type => {
                                    const valeurExiste = (meubleContacts)?meubleContacts.some(objet => objet.contact_type === type):false;
                                    return <option key={type} value={type} disabled={valeurExiste}>{type}</option>
                                }
                            )}
                        </select>
                    </div>

                    {contactsToSelect && <div className='d-flex gap-3 align-items-end' id="block_select_association">
                        <div id="div_select_contact">
                            <label htmlFor="contacts" className="form-label">2. Choisir un contact</label>
                            <Select
                                options={contactsToSelect} id={"select_contacts"}
                                ref={selectContact}
                                placeholder="Sélectionnez un contact"
                                isClearable={true}
                                isSearchable={true}
                                className="form-control"
                                menuPlacement="top"
                            styles={customStyles} />
                        </div>

                        <div className="col" id="div_associer_button">
                            <button className='btn' id='associerAction' type="button" onClick={() => associer()}>Associer</button>
                        </div>
                    </div>}

                </div>
            </div>

        </Panel>
    );
}

export default MeubleLiaisonContact;