import { useState } from '@wordpress/element';
import { UserProvider, VisiteProvider, ToastContextProvider } from '@context';
import { VisiteHeader } from '@pages/visite-edit/';
import { VisiteVoirBody } from '@/pages/visite-voir';
import { ButtonIconOnly } from '@composants/button';

import './visite-voir.scss';

const VisiteVoir = () => {

    const [fsopen, setFsopen] = useState(false);

    const toggleFS = () => {
        setFsopen(!fsopen);
    }

    return(
        <UserProvider>
            <ToastContextProvider>
                <VisiteProvider>
                    <div className={`visite visite-voir position-relative ${fsopen ? 'fsopen' : ''}`.trim()}>
                        <div className='position-absolute fs-toggler'>
                            <ButtonIconOnly className="btn-fs btn-fs-open" variant='fs-open' action={toggleFS} />
                            <ButtonIconOnly className="btn-fs btn-fs-close" variant='fs-close' action={toggleFS} />
                        </div>
                        <VisiteHeader edit={false} />
                        <VisiteVoirBody />
                    </div>
                </VisiteProvider>
            </ToastContextProvider>
        </UserProvider>
    );
}

export default VisiteVoir;