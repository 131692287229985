import Dexie from "dexie";
import config from '../config';

const useDB = () => {

    const db = new Dexie('nomade-classement');
    db.version(config.db.version).stores(config.db.tables);

    return {
        db, 
    }
}

export default useDB;