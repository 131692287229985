import { SearchInput, DateRangeInput } from '@composants/form';
import { SelectInput } from '@composants/form';
import { classementValues } from '@constantes';

const TableFilters = ({ column, table }) => {

    const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id);

    const isDate = firstValue instanceof Date;
    const isClassement = column.id === 'classement';
    const isScelle = column.id === 'scelle';

    const columnFilterValue = column.getFilterValue();

    if(isDate){
        return(
            <div className='date-container'>
                <DateRangeInput
                    value={columnFilterValue ?? [null, null]} 
                    handler={ e => column.setFilterValue(e) } 
                />
            </div>
        );
    }

    if(isClassement){
        return(
            <div className='classement-container'>
                <SelectInput 
                    size='sm' 
                    className='filtre' 
                    values={[{value: '', label: 'Tous'}, ...classementValues]} 
                    value={columnFilterValue ?? null} 
                    handler={ e => column.setFilterValue(e.target.value !== '' ? e.target.value : undefined) } 
                />
            </div>
        )
    }

    if(isScelle){
        return(
            <div className='scelle-container'>
                <SelectInput 
                    size='sm' 
                    className='filtre' 
                    values={[{value: '', label: 'Tous'}, {value: false, label: 'Visites non-scellées'},{value: true, label: 'Visites scellées'}]} 
                    value={columnFilterValue ?? null} 
                    handler={ e => column.setFilterValue(e.target.value !== '' ? e.target.value : undefined) } 
                />
            </div>
        )
    }

    return(
        <div>
            <SearchInput 
                value={columnFilterValue ?? ''} 
                handler={e => column.setFilterValue(e.target.value)}
                className="filter" 
                size='sm' 
            />
        </div>
    )
}

export default TableFilters;