const useVisitesComparator = () => {

    const getComparator = (column) => {
        switch (column) {
            case 'titre':
                case 'contact':
            case 'date_visite':
            case 'departement':
            case 'classement':
                return (a, b) => {
                    return a[column].props.search.localeCompare(b[column].props.search);
                };
            default:
                throw new Error(`unsupported column: "${column}"`);
        }
    }

    return {
        getComparator
    }
    
}

export default useVisitesComparator;