import { useState, useEffect } from '@wordpress/element';
import { ReactComponent as Icon } from '@images/close.svg';

const TextInput  = ({ className = '', size = '', value, name, multiline, label, handler }) => {

    const [text, setText] = useState(value);

    useEffect(() => {
        setText(value);
    }, [value]);

    const clear = () => {
        setText('');
        handler({ target: { name, value: '' } });
    }

    return(
        <div className={`form-input ${className} ${size}`}>
            {label && <label className='form-input-label'>{label}</label>}
            <div className='text-input-container'>
                {multiline ? 
                    <textarea type="text" name={name} className="text-input" onChange={handler}>{text}</textarea> :
                    <input type="text" name={name} className="text-input" onChange={handler} value={text} />
                }
                {text?.length > 0 && <Icon className="icon text-clear" onClick={clear} />}
            </div>
        </div>
    )
}

export default TextInput;