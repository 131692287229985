import { ButtonDropdown } from "@composants/button"
import Dropdown from 'react-bootstrap/Dropdown';

const DropDown = (props) => {

    const { label, children } = props;
    return(
        <Dropdown>
            <ButtonDropdown label={label} variant="outline" />
            {children}
        </Dropdown>
    )
}

export default DropDown;