import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

import { ButtonDropdown } from '@composants/button';

const CellVisites = ({ visites }) => {

    /**
     * 
     * @param {string} uid 
     * 
     * Ouvre le lien vers une visite
     */
    const onVisite = (uid) => {
        window.location.href = `/visites/visite-voir/?id=${uid}`;
    }
    
    /**
     * 
     * @param {Object} props 
     * @returns <Popover />
     */
    const renderPopover = (props) => (
        <Popover className='popover-meubles' {...props}>
            <Popover.Body>
                {visites.map(visite => {
                    const { uid, date_visite } = visite;
                    return(
                        <div className='cell-item d-flex flex-column gap-1 p-3' key={uid} onClick={() => onVisite(uid)}>
                            <strong className='call-id'>{uid}</strong>
                            <span className='call-titre'>visite du {new Date(date_visite).toLocaleDateString('fr-FR')}</span>
                        </div>
                    )
                })}
            </Popover.Body>
        </Popover>
    );

    if(visites.length < 1) return(<div className="cell cell-actions cell-inner text-moyen text-center">Aucune visite</div>)

    return(
        <div className="cell cell-actions cell-inner cell-dropdown">
            <OverlayTrigger placement='auto' trigger='click' overlay={renderPopover} rootClose={true}>
                <ButtonDropdown key={`btn-cell-visites`} variant="outline">Visites</ButtonDropdown>
            </OverlayTrigger>
        </div>
    )
}

export default CellVisites;