import { useOnlineStatus, useFetch } from '@hooks';

const useVisiteValide = () => {

    const isOnline = useOnlineStatus();
    const { getNumClassement } = useFetch();

    /**
     * 
     * @param {object} visite La visite à valider
     * @param {object} inspecteur L'inspecteur qui a créé la visite
     * 
     * @returns {array} Un tableau de chaines pour chaque erreur trouvée dans la visite, sinon un tableau vide 
     */
    const getVisiteErrors = async ({ visite, inspecteur, contactsMeuble }) => {

        /** Tableau des erreurs */
        const errors = [];

        if(!isOnline) {
            errors.push("Vous n'êtes pas connecté à internet");
        } else {
            if(visite && inspecteur){

                if(contactsMeuble){
                    const proprietaire = contactsMeuble.find(contact => contact.role === 'Propriétaire');
                    if(!proprietaire) errors.push("Ce meublé n'a pas de propriétaire");
                }

                /** Si les informations du meublé sont incomplètes */
                const meuble = visite.infos.meuble;
                if(
                    !meuble.adresse1 ||
                    !meuble.code_postal ||
                    !meuble.commune ||
                    meuble.adresse1 === "" ||
                    meuble.code_postal === "" ||
                    meuble.commune === ""
                ) errors.push("Veuillez compléter les informations du meublé.");

                /** Si pas de demandeur */
                if(!visite.demandeur) errors.push("Veuillez sélectionner un demandeur pour cette visite.");

                /** Si visite en mode diagnostic */
                if(visite.infos.classement_cible < 1) errors.push("Veuillez choisir un classement pour cette visite.");

                /** Si pas de pièces */
                if(visite.infos.superficies.length < 1) {
                    errors.push("Veuillez renseigner les superficies du meublé.");
                } else {
                    /** Si pas de surfaces pour les pièces */
                    const sansSurface = visite.infos.superficies.reduce((result, current) => {
                        if(parseFloat(current.surface) === 0) result.push(current);
                        return result;
                    }, []);
                    if(sansSurface.length > 0) errors.push("Veuillez saisir une surface pour chaque pièce du meublé.");
                }

                /** Si pas de capacité pour les pièces d'habitation */
                if(!visite.infos.capacite) errors.push("Veuillez saisir le nombre de personnes par pièce d'habitation.");

                /** Si fichiers de l'inspecteur manquants (signature, logo, tampon) */
                const hasFiles = inspecteur.file_logo.length > 0 && inspecteur.file_signature.length > 0 && inspecteur.file_tampon.length > 0;
                if(!hasFiles) errors.push("Le profil de l'inspecteur est incomplet.");

                /** Si le numéro de classement n'existe pas */
                if(!meuble.num_classement || meuble.num_classement === ''){
                     const result = await getNumClassement(inspecteur.id, meuble.id);
                     if(!result.success){
                         errors.push(result.data.error);
                     }
                }

            }
        }

        return errors;
    }

    return {
        getVisiteErrors
    }
}

export default useVisiteValide;