import { SelectInput, TextInput, NumberInput } from "@composants/form";
import { typePieces } from "@constantes";
import { ButtonIconDelete } from "@composants/button";

import './piece-form.scss';

const minSurfaceError = <>Min. 7 m<sup>2</sup></>;

const PieceForm = ({ data, handler, deleteHandler }) => {

    const { type, nom, largeur, longueur, surface, personnes, habitation } = data;
    
    const surfaceError = (surface < 7 && habitation) ? minSurfaceError : null;

    const fixedSurface = surface;

    return(
        <div className="superficie d-flex gap-3">
            <SelectInput values={typePieces} name="type" value={type} label="Type de pièce" handler={handler} />
            <TextInput value={nom} name="nom" label="Nom" handler={handler} />
            <NumberInput className="surface" value={largeur} name="largeur" label="Largeur" pas={0.5} handler={handler} />
            <NumberInput className="surface" value={longueur} name="longueur" label="Longueur" pas={0.5} handler={handler} />
            <NumberInput className="surface" value={fixedSurface} name="surface" label="Surface (m2)" pas={0.5} error={surfaceError} handler={handler} />
            {habitation && <NumberInput className="surface" value={personnes} name="personnes" label="Personnes" min={0} handler={handler} />}
            <div className="pt-4">
                <ButtonIconDelete variant="orange" action={deleteHandler} />
            </div>
        </div>
    )
}

export default PieceForm;