import { useEffect, useState, createPortal } from '@wordpress/element';
import { useDB, useFetch } from '@hooks';
import { ToastManager } from "@composants";

//permet de lancer la synchro Bulk au retour du serveur
const ImportFile = () => {

    const { db } = useDB();
    const { bulkFetch } = useFetch();
    const [logs, setLogs] = useState();
    const [toasts, setToasts] = useState([]);

    useEffect(() => {

        const btnDeleteImport = document.getElementById('btnDeleteLastImport');

        const deleteLastImport = async (event) => {

            if( confirm("Voulez-vous vraiment supprimer cet import de données ?")) {
                event.preventDefault();
                const ajaxurl = event.target.dataset.ajaxurl;
                const data = {
                    action: event.target.dataset.action,
                    nonce:  event.target.dataset.nonce
                }

                const request = await fetch(ajaxurl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Cache-Control': 'no-cache',
                    },
                    body: new URLSearchParams(data),
                });

                const response = await request.json();

                if(response.success){

                    if(response.data.contacts){
                        await db.contacts.bulkDelete(response.data.contacts);
                    }

                    if(response.data.meubles){
                        await db.meubles.bulkDelete(response.data.meubles);
                    }

                    setToasts([{
                        title: "Suppression dernier import",
                        type: "success",
                        message: response.data.message,
                    }]);

                } else {
                    setToasts([{
                        title: "Suppression dernier import",
                        type: "danger",
                        message: response.data.message,
                    }]);
                }

                btnDeleteImport.style.display = 'none';

            }
        }

        const initDelete = () => {
            if(btnDeleteImport) {
                btnDeleteImport.addEventListener('click', deleteLastImport);
            }
        }

        const loadEntity = async (entity, liste) => {
            const data = await bulkFetch(window.cUser.id, entity, liste.toString());
            await db[entity].bulkPut(data);
        }

        const updateData = async () => {
            await loadEntity('meubles', window.meubles);
            await loadEntity('contacts', window.contacts);
            const logsGrouped = window.logs.reduce((result, current) => {
                if(!result[current.ligne]){
                    result[current.ligne] = [];
                }
                result[current.ligne].push(current);
                return result;
            }, {});
            setLogs(logsGrouped);
        }

        if(window.meubles !== undefined && window.contacts !== undefined && window.logs !== undefined) {
            updateData();
        }

        initDelete();

        return () => {
            if(btnDeleteImport){
                btnDeleteImport.removeEventListener('click', deleteLastImport);
            }
        }

    }, []);

    const renderLogItem = (item) => {
        switch(item.action){
            case 'add':
                if(item.type === 'meuble'){
                    return <span>Meublé {item.name} à {item.commune} ajouté.</span>;
                } else {
                    return <span>Contact {item.name} ajouté.</span>;
                }
            case 'skip':
                const type = item.type === 'meuble' ? 'Meublé' : 'Contact';
                return <span>{type} {item.name} NON ajouté (déjà dans Nomade).</span>;
            case 'linknew':
                return <span>Association : meublé {item.meuble} au propriétaire {item.contact}.</span>;
            case 'linkold':
                return <span>Association déjà existant : meublé {item.meuble} au propriétaire {item.contact}.</span>;
        }
    }

    if(toasts.length > 0){
        return(<>
            {createPortal(
                <ToastManager data={toasts} />, 
                document.querySelector('.toast-container')
            )}
        </>)
    }

    if(logs) {
        return (
            <>
            {createPortal(
                Object.keys(logs).map(ligne => <div className='card mb-3' key={ligne} style={{backgroundColor: 'var(--body)', borderRadius: '0.5rem'}}>
                    <div className='card-body d-flex flex-column gap-1' style={{padding: '1rem'}}>
                        <p className='mb-2'><strong>Ligne {ligne}</strong></p>
                        {logs[ligne].map((item, index) => <div key={index}>
                            {renderLogItem(item)}
                        </div>)}
                    </div>
                </div>),
                window.typeImport === 'CLASS' ? document.getElementById('result-class') : document.getElementById('result-excel')
            )}
            </>
        );
    }
}

export default ImportFile;

jQuery(document).ready(function () {

    jQuery('input[type="file"]').on('change', function(e) {
        let that = e.currentTarget;
        if (that.files && that.files[0]) {
            let uploadBtn = 'upload_' + e.target.name;
            blink("input[name="+uploadBtn+"]");
        }
    });

});

function blink(e){
    jQuery(e).fadeOut('slow', function(){
        jQuery(this).fadeIn('slow', function(){
            blink(this);
        });
    });
}