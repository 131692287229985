const menuEtoiles = [{
    value: "",
    label: "Tous"
},
{
    value: "1",
    label: "1 étoile"
},
{
    value: "2",
    label: "2 étoiles"
},
{
    value: "3",
    label: "3 étoiles"
},
{
    value: "4",
    label: "4 étoiles"
},
{
    value: "5",
    label: "5 étoiles"
}];

const fakeDepartements = [{
    value: '31',
    label: '31'
}, {
    value: '82', 
    label: '82'
}, {
    value: '34', 
    label: '34'
}, {
    value: '09', 
    label: '09'
}];

const classementValues = [{
    value: 0, 
    label: 'Diagnostic'
},{
    value: 1,
    label: 'Une étoile'
},{
    value: 2,
    label: 'Deux étoiles'
},{
    value: 3,
    label: 'Trois étoiles'
},{
    value: 4,
    label: 'Quatre étoiles'
},{
    value: 5,
    label: 'Cinq étoiles'
}];

const CATEGORIES = [
    {
        label: 'Sans classement',
        id: "0",
    }, {
        label: '1 étoile',
        id: "1",
    }, {
        label: '2 étoiles',
        id: "2",
    }, {
        label: '3 étoiles',
        id: "3",
    }, {
        label: '4 étoiles',
        id: "4",
    }, {
        label: '5 étoiles',
        id: "5",
    }
];

const etagesValues = [{
    value: 0,
    label: 'Rez-de-chaussée'
},{
    value: 1,
    label: '1er étage'
},{
    value: 2,
    label: '2e étage'
},{
    value: 3,
    label: '3e étage'
},{
    value: 4,
    label: '4e étage ou plus'
}];

const typesVisites = [{
    value: 'visite',
    label: 'Visite'
},{
    value: 'visite-quinquennale',
    label: 'Visite quinquennale'
},{
    value: 'contre-visite',
    label: 'Contre-visite'
}];

const typePieces = [{
    value: 'chambre',
    label: 'Chambre',
    habitable: true,
    habitation: true
}, {
    value: 'cuisine-piece',
    label: 'Cuisine, pièce de vie',
    habitable: true,
    habitation: true
}, {
    value: 'cuisine',
    label: 'Cuisine',
    habitable: true,
    habitation: false
}, {
    value: 'sejour',
    label: 'Séjour',
    habitable: true,
    habitation: true
}, {
    value: 'salon',
    label: 'Salon',
    habitable: true,
    habitation: true
}, {
    value: 'mezzanine',
    label: 'Mezzanine',
    habitable: true,
    habitation: true
}, {
    value: 'couloirs',
    label: 'Dégagements et couloirs',
    habitable: true,
    habitation: false
}, {
    value: 'non-habitable',
    label: 'Autre pièce',
    habitable: false,
    habitation: false
}];

const NEW_VISITE = {
    uid: null,
    date_visite: null, 
    date_create: null, 
    date_update: null,
    date_decision: null,
    user_create: null, 
    user_create_name: null, 
    user_update: null,
    user_update_name: null,
    type_visite: "visite", 
    meuble_id: null, 
    scelle: false, 
    grille: null,
    infos: {
        saved: false,
        capacite: 0, 
        accueil_max: 0,
        etage: 0, 
        ascenseur: false, 
        comment: null, 
        classement_cible: 0, 
        classement_obtenu: 0, 
        criteres_non_applicables: [],
        criteres_non_compensables: [],
        demandeur_id: null,
        diagnostic: [0,0,0,0,0],
        scores: {
            a: 0,
            b: 0,
            c: 0,
            d: 0,
            e: 0,
            f: 0,
            g: 0,
            h: 0
        },
        surfaces: {
            total: 0,
            majorees: 0
        }, 
        superficies: [], 
    }, 
    documents: [], 
    demandeur: null, 
}

const CLASS_CSV_HEADER = ["TITRE-L","NOM-L","PRE-L","RS-L","ADR1-L","ADR2-L","ADR3-L","CP-L","COMM-L","PAYS-L","TEL-L","MAIL-L","FAX-L","WEB-L","TITRE-MA","NOM-MA","PRE-MA","RS-MA","ADR1-MA","ADR2-MA","ADR3-MA","CP-MA","COMM-MA","PAYS-MA","TEL-MA","MAIL-MA","FAX-MA","WEB-MA","DEC-M","ADR1-M","ADR2-M","CP-M","INSEE-M","COMM-M","CL-M","CAP-M","DATE-M","NUM-D","NUM-M"];

const SURFACES_MIN = [12,14,18,20,26];
const SURFACES_MIN_CHAMBRE = [7,8,9,10,12];

const userIdCookieName = 'last_user_id';

const RESSOURCES_URLS = [{
    title: 'Guide de contrôle meublés de tourisme 2022',
    url: '/wp-content/themes/nomade/build/ressources/Guide-de-controle-meubles-de-tourisme-2022-MAJ-le-190122.pdf'
}, {
    title: 'Référentiel de classement des meublés de tourisme 2022',
    url: '/wp-content/themes/nomade/build/ressources/Referentiel-de-classement-des-meubles-de-tourisme-2022-V2.pdf'
}, {
    title: 'FAQ Critères de classement',
    url: '/wp-content/themes/nomade/build/ressources/faq-criteres-de-classement.xlsx'
}];

const DOMTOM = ["971", "972", "973", "974", "975", "976", "977", "978", "984", "986", "987", "988", "989"];

/**
 * Les colonnes par défaut pour l'export des visites
 */
const EXPORT_VISITE_COLONNES = [
    {
        id: 'id', 
        label: 'ID', 
        selected: false,
        key: ['uid'], 
        type: 'texte'
    },{
        id: 'civilite', 
        label: 'Titre, civilité du demandeur',
        selected: true,
        key: ['demandeur.civilite'], 
        type: 'texte'
    },{
        id: 'prenom-nom',
        label: 'Nom prénom du demandeur',
        selected: true,
        key: ['demandeur.prenom', 'demandeur.nom'], 
        type: 'texte'
    },{
        id: 'telephone2',
        label: 'Téléphone 1 du demandeur',
        selected: true,
        key: ['demandeur.telephone2'],
        type: 'telephone'
    },{
        id: 'telephone',
        label: 'Téléphone 2 du demandeur',
        selected: true,
        key: ['demandeur.telephone'],
        type: 'telephone'
    },{
        id: 'email',
        label: 'Adresse e-mail du demandeur',
        selected: true,
        key: ['demandeur.email'], 
        type: 'texte' 
    },{
        id: 'demandeur-rue',
        label: 'Adresse du demandeur',
        selected: false,
        key: ['demandeur.adresse1', 'demandeur.adresse2'],
        type: 'texte'
    },{
        id: 'demandeur-code-postal',
        label: 'Code postal du demandeur',
        selected: false,
        key: ['demandeur.code_postal'],
        type: 'texte'
    },{
        id: 'demandeur-dep',
        label: 'Département du demandeur',
        selected: false,
        key: ['demandeur.dep'],
        type: 'texte'
    },{
        id: 'demandeur-commune',
        label: 'Commune du demandeur',
        selected: false,
        key: ['demandeur.commune'],
        type: 'texte'
    },{
        id: 'demandeur-pays',
        label: 'Pays du demandeur',
        selected: false,
        key: ['demandeur.pays'],
        type: 'texte'
    },{
        id: 'meuble-titre',
        label: 'Dénomination commerciale du meublé',
        selected: false,
        key: ['infos.meuble.titre'],
        type: 'texte'
    },{
        id: 'meuble-type',
        label: 'Type du meublé',
        selected: false,
        key: ['infos.meuble.type'],
        type: 'texte'
    },{
        id: 'meuble-rue',
        label: 'Adresse du meublé',
        selected: true,
        key: ['infos.meuble.adresse1', 'infos.meuble.adresse2'],
        type: 'texte'
    },{
        id: 'meuble-code-postal',
        label: 'Code postal du meublé',
        selected: false,
        key: ['infos.meuble.code_postal'],
        type: 'texte'
    },{
        id: 'meuble-code-insee',
        label: 'Code insee du meublé',
        selected: false,
        key: ['infos.meuble.code_insee'],
        type: 'texte'
    },{
        id: 'meuble-dep',
        label: 'Département du meublé',
        selected: false,
        key: ['infos.meuble.dep'],
        type: 'texte'
    },{
        id: 'meuble-commune',
        label: 'Commune du meublé',
        selected: true,
        key: ['infos.meuble.commune'],
        type: 'texte'
    },{
        id: 'date-decision',
        label: 'Date de décision de classement', 
        selected: true, 
        key: ['date_decision'],  
        type: 'date'
    },{
        id: 'classement',
        label: 'Classement', 
        selected: true, 
        key: ['infos.classement_obtenu'],
        type: 'classement'
    },{
        id: 'num_classement',
        label: 'N° classement',
        selected: false,
        key: ['infos.meuble.num_classement'],
        type: 'texte'
    },{
        id: 'num_enregistrement',
        label: 'N° d\'enregistrement en mairie',
        selected: false,
        key: ['infos.meuble.num_enregistrement'],
        type: 'texte'
    },{
        id: 'date_recepisse_mairie',
        label: 'Date récépissé mairie',
        selected: false,
        key: ['infos.meuble.date_recepisse_mairie'],
        type: 'date'
    },{
        id: 'residence',
        label: 'Résidence',
        selected: false,
        key: ['infos.meuble.residence'],
        type: 'texte'
    },{
        id: 'capacite',
        label: "Capacité d'accueil", 
        selected: true,
        key: ['infos.capacite'],  
        type: 'entier'
    },{
        id: 'meuble-otsi',
        label: 'OTSI',
        selected: false,
        key: ['infos.meuble.otsi'],
        type: 'texte'
    },{
        id: 'meuble-site-internet',
        label: 'Site internet du meublé',
        selected: false,
        key: ['infos.meuble.site_web'],
        type: 'texte'
    },{
        id: 'comment',
        label: 'Info complémentaire',
        selected: false,
        key: ['infos.comment'],
        type: 'texte-long'
    },
];

/**
 * Les colonnes par défaut pour l'export des meublés
 */
const EXPORT_MEUBLE_COLONNES = [
    {
        id: 'id',
        label: 'ID',
        selected: true,
        key: ['id'],
        type: 'texte'
    }, {
        id: 'civilite_proprietaire',
        label: 'Titre / Civilité',
        selected: true,
        key: ['civilite_proprietaire'],
        type: 'texte'
    },
    {
        id: 'nom_proprietaire', 
        label: 'Nom du propriétaire ou de la société', 
        selected: true,
        key: ['nom_proprietaire'], 
        type: 'texte'
    }, 
    {
        id: 'prenom_proprietaire', 
        label: 'Prénom du propriétaire', 
        selected: true,
        key: ['prenom_proprietaire'], 
        type: 'texte'
    }, 
    {
        id: 'entite_juridique', 
        label: 'Entité juridique', 
        selected: false,
        key: ['status'], 
        type: 'texte'
    }, 
    {
        id: 'nom_mandataire', 
        label: 'Nom du mandataire / gestionnaire', 
        selected: true,
        key: ['nom_mandataire'], 
        type: 'texte'
    }, 
    {
        id: 'prenom_mandataire', 
        label: 'Prénom du mandataire / Gestionnaire', 
        selected: true,
        key: ['prenom_mandataire'], 
        type: 'texte'
    }, 
    {
        id: 'adresse_proprietaire', 
        label: 'Adresse du propriétaire ou de la société', 
        selected: true,
        key: ['adresse_proprietaire'], 
        type: 'texte'
    }, 
    {
        id: 'cp_proprietaire', 
        label: "Code postal", 
        selected: true,
        key: ['cp_proprietaire'], 
        type: 'texte'
    }, 
    {
        id: 'commune_proprietaire', 
        label: "Ville propriétaire / société et pays", 
        selected: true,
        key: ['commune_proprietaire'], 
        type: 'texte'
    }, 
    {
        id: 'tel_proprietaire', 
        label: "Téléphone propriétaire", 
        selected: false,
        key: ['tel_proprietaire'], 
        type: 'texte'
    },
    {
        id: 'portable_proprietaire',
        label: "Portable propriétaire",
        selected: false,
        key: ['portable_proprietaire'],
        type: 'texte'
    },
    {
        id: 'email_proprietaire',
        label: "Courriel",
        selected: false,
        key: ['email_proprietaire'],
        type: 'texte'
    },
    {
        id: 'web_proprietaire',
        label: "Site internet propriétaire",
        selected: false,
        key: ['web_proprietaire'],
        type: 'texte'
    },{
        id: 'site_web',
        label: "Site internet meublé",
        selected: false,
        key: ['site_web'],
        type: 'texte'
    },
    {
        id: 'titre_meuble',
        label: "Dénomination commerciale",
        selected: true,
        key: ['titre'],
        type: 'texte'
    },
    {
        id: 'adresse_meuble',
        label: "Adresse du meublé",
        selected: true,
        key: ['adresse1', 'adresse2'],
        type: 'texte'
    },
    {
        id: 'cp_meuble',
        label: "Code postal du meublé",
        selected: true,
        key: ['code_postal'],
        type: 'texte'
    }, 
    {
        id: 'commune_meuble', 
        label: "Ville du meublé", 
        selected: true,
        key: ['commune'], 
        type: 'texte'
    }, 
    {
        id: 'tel',
        label: "Téléphone du meublé", 
        selected: false,
        key: ['tel'], 
        type: 'texte'
    }, 
    {
        id: 'date_mairie', 
        label: "Date de déclaration en mairie", 
        selected: false,
        key: ['date_recepisse_mairie'], 
        type: 'date'
    }, 
    {
        id: 'type_meuble', 
        label: "Type de résidence", 
        selected: false,
        key: ['residence'], 
        type: 'texte'
    }, 
    {
        id: 'date_decision', 
        label: "Date de décision de classement", 
        selected: false,
        key: ['date_classement'], 
        type: 'date'
    }, 
    {
        id: 'no_enregistrement', 
        label: "Numéro d'enregistrement", 
        selected: false,
        key: ['num_enregistrement'], 
        type: 'texte'
    }, 
    {
        id: 'classement', 
        label: "Étoiles", 
        selected: true,
        key: ['classement'], 
        type: 'classement'
    }, 
    {
        id: 'capacite', 
        label: "Personnes", 
        selected: true,
        key: ['capacite'], 
        type: 'texte'
    }, 
    {
        id: 'date_visite', 
        label: "Date visite", 
        selected: false,
        key: ['date_visite'], 
        type: 'date'
    }, 
    {
        id: 'otsi', 
        label: "OTSI Rattaché", 
        selected: false,
        key: ['otsi'], 
        type: 'texte'
    }, 
    {
        id: 'description', 
        label: "Informations complémentaires", 
        selected: false,
        key: ['description'], 
        type: 'texte'
    },
    {
        id: 'archivage_raison',
        label: "Archivage commentaire",
        selected: true,
        key: ['archivage_raison'],
        type: 'texte'
    },
    {
        id: 'archivage_date',
        label: "Archivage date",
        selected: true,
        key: ['archivage_date'],
        type: 'date'
    }
];

const DOCUMENTS_DECISION = [
    {
        id: 'lettre',
        label: 'Lettre de décision de classement',
        shortname: 'L',
        selected: true, 
    },
    {
        id:'annexe-1',
        label: "Annexe I",
        shortname: 'AI',
        selected: true, 
    },
    {
        id:'annexe-2',
        label: "Annexe II",
        shortname: 'AII',
        selected: true, 
    },
    {
        id:'annexe-3',
        label: "Annexe III",
        shortname: 'AIII',
        selected: true, 
    },
    {
        id:'compte-rendu',
        label: "Compte-rendu de visite",
        shortname: 'CR',
        selected: true, 
    },
    {
        id:'surfaces',
        label: "Relevé de surfaces",
        shortname: 'RS',
        selected: true, 
    }
];

const ASCENSEUR_VALUES = [{
    value: false, 
    label: 'Sans ascenseur'
}, {
    value: true, 
    label: 'Avec ascenseur'
}, {
    value: 'NA', 
    label: 'Non-applicable'
}];

const AIDE_CRITERE_78 = "Les contraintes locales ou architecturales peuvent rendre le critère non applicable sur présentation d’un justificatif officiel (exemple : bâtiment classé).";

const CIVILITES = ['Mme', 'M.', 'Mme, M.', 'M. et Mme', 'Mme & M.', 'M. & Mme', 'Mme&M.', 'Mme et M', 'Madame', 'Monsieur', 'Madame, Monsieur', 'Madame et Monsieur', 'Monsieur, Madame'];

/** Couleurs pour les graphiques */
const CHARTS_RGB_COLORS = [
    "0,139, 213", 
    "183,213,0", 
    "249,94,36", 
    "204,34,208", 
    "0,187,213", 
    "43,203,107", 
    "255,199,0", 
    "255,153,0", 
    "217,44,44", 
    "157,85,58", 
    "119,91,230", 
    "84,82,159"
];

const WORDING_ACTIONS_ENTITIES = {
    archiver: {
        labelSimple: 'Archivage simple',
        labelEtendu: 'Archivage étendu',
        labelAction: 'Archiver', 
        meubles: {
            titre: 'Archiver les meublés sélectionnés', 
            infoSimple: 'Archivage impossible si une visite est liée à ce meublé.',
            infoEtendu : {
                info: 'Archive aussi les contacts/visites liés à ces meublés.',
                variant: '', 
            }
        },
        contacts: {
            titre: 'Archiver les contacts sélectionnés',
            infoSimple: 'Archivage impossible si un meublé est lié à ce contact.',
            infoEtendu : {
                info: 'Archive aussi les meublés/visites liés à ces contacts.',
                variant: '',
            }
        },
        visites: {
            titre: 'Archiver les visites sélectionnées', 
            infoEtendu : {
                info: 'Archive aussi les contacts/meublés liés à ces visites',
                variant: '',
            }, 
        }
    },
    restaurer: {
        labelSimple: 'Restauration simple',
        labelEtendu: 'Restauration étendue', 
        labelAction: 'Restaurer', 
        meubles: {
            titre: 'Restaurer les meublés sélectionnés', 
        },
        contacts: {
            titre: 'Restaurer les contacts sélectionnés', 
        },
        visites: {
            titre: 'Restaurer les visites sélectionnées', 
        }
    },
    jeter: {
        labelSimple: 'Suppression simple',
        labelEtendu: 'Suppression étendue', 
        labelAction: 'Supprimer', 
        meubles: {
            titre: 'Supprimer le(s) meublé(s) sélectionné(s)',
            infoSimple: 'Suppression impossible si au moins une visite est liée à ce(s) meublé(s)',
            infoEtendu: {
                info: "Supprime également les contacts et les visites liés", 
                variant: '',
            }
        },
        contacts: {
            titre: 'Supprimer le(s) contact(s) sélectionné(s)',
            infoSimple: 'Suppression impossible si un meublé est lié à ce contact', 
            infoEtendu: {
                info: "Supprime également les meublés et les visites liés", 
                variant: '',
            }, 
        },
        visites: {
            titre: 'Supprimer les visites sélectionnées', 
            infoSimple: null, 
            infoEtendu: {
                info: "Supprime également les meublés et les contacts liés", 
                variant: '',
            }, 
        }
    }
};

export {
    menuEtoiles, 
    fakeDepartements, 
    classementValues, 
    CATEGORIES, 
    etagesValues, 
    typesVisites, 
    typePieces, 
    userIdCookieName, 
    NEW_VISITE, 
    SURFACES_MIN, 
    SURFACES_MIN_CHAMBRE, 
    CLASS_CSV_HEADER, 
    RESSOURCES_URLS, 
    DOMTOM, 
    EXPORT_VISITE_COLONNES, 
    EXPORT_MEUBLE_COLONNES, 
    DOCUMENTS_DECISION, 
    ASCENSEUR_VALUES, 
    AIDE_CRITERE_78, 
    CHARTS_RGB_COLORS, 
    CIVILITES, 
    WORDING_ACTIONS_ENTITIES, 
}