import Dropdown from 'react-bootstrap/Dropdown';
import { useContacts } from '@hooks';

const DropDownContacts = ({ meuble }) => {

    const { getMeubleContacts } = useContacts();
    const contacts = getMeubleContacts(meuble);

    return (
        <Dropdown>
            <Dropdown.Toggle className='btn-sm' variant=" outline">Contacts</Dropdown.Toggle>
            <Dropdown.Menu>
                {(contacts && contacts.length > 0) ? contacts.map(contact => <Dropdown.Item key={contact.id} href={`/contacts/contact-voir?id=${contact.id}`}>{contact.contact_type} - {contact.prenom} {contact.nom}</Dropdown.Item>) : <Dropdown.Item>Aucun contact lié à ce meublé...</Dropdown.Item>}
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default DropDownContacts;