import { useState } from '@wordpress/element';
import { Toast } from "react-bootstrap";

const ToastMessage = ({ data }) => {

    const [show, setShow] = useState(true);

    const toggleShow = () => {
        setShow(!show)
    };

    return (
        <Toast bg={data.type} show={show} onClose={toggleShow} delay={10000} autohide>
            <Toast.Header>
                <strong className='me-auto'>{data.title ? data.title : "Message"}</strong>
            </Toast.Header>
            <Toast.Body className={(data.type === 'info' || data.type === 'danger') && 'text-white'}>
                {data.message}
            </Toast.Body>
        </Toast>
    )
}

export default ToastMessage;