import { Table as StatTable } from 'react-bootstrap';
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable, } from '@tanstack/react-table';
import { useVisitesActions } from '@hooks';
import { Classement } from '@composants';
import { useMemo } from '@wordpress/element';

import './stats-visites.scss';

const columnHelper = createColumnHelper();

const StatsVisites = ({ visites }) => {
    
    const { getVisitesCountParAnnee } = useVisitesActions();
    const visitesParAnnee = getVisitesCountParAnnee(visites);

    const getTotalParAnnee = (annee) => {
        return visitesParAnnee.reduce((total, current) => {
            return total + parseInt(current[annee]);
        }, 0);
    }

    // Colonnes pour les années
    const colonnesAnnees = Object.keys(visitesParAnnee[0]).reduce((colonnes, key) => {
        if(key !== 'classement'){
            colonnes.push(columnHelper.accessor(row => row[key], {
                id: key,
                cell: props => <div className='text-center'>{props.row.original[key]}</div>,
                footer: <span className='text-bleu'>{getTotalParAnnee(key)}</span>
            }));
        }
        return colonnes;
    }, []);

    // Définition des colonnes du tableau
    const columns = useMemo(() => [
        columnHelper.accessor(row => row.classement, {
            id: 'classement',
            cell: info => <Classement className='start' count={info.getValue()} />,
            footer: <span className='text-bleu'>Total</span>
        }),
        ...colonnesAnnees,
    ], []);

    const tableVisites = useReactTable({
        data: visitesParAnnee,
        columns, 
        getCoreRowModel: getCoreRowModel(), 
        // debugTable: true, 
    });

    const tableHeaders = tableVisites.getHeaderGroups();
    const tableRows = useMemo(() => tableVisites.getRowModel().rows, []);
    const tableFooters = tableVisites.getFooterGroups();

    return(
        <div className="stats">
            <div className="stats-header d-flex align-items-center justify-content-between">
                <h4>Nombre de visites</h4>
                <div className="filtres d-flex gap-2">
                    {/* <span>Communes</span>
                    <span>Inspecteurs</span>
                    <span>Dates</span> */}
                </div>
            </div>

            { visites.length > 0 ? <div className='stats-body'>
                <StatTable responsive hover>
                    <thead>
                        {tableHeaders.map(headerGroup => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map(header => (
                                    <th key={header.id}>
                                        {
                                            flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )
                                        }
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {tableRows.map(row => (
                            <tr key={row.id}>
                                {row.getVisibleCells().map(cell => (
                                    <td key={cell.id}>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        {tableFooters.map(footerGroup => (
                            <tr key={footerGroup.id}>
                                {footerGroup.headers.map(header => (
                                    <th key={header.id}>
                                        {header.column.columnDef.footer}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </tfoot>
                </StatTable>
            </div> : <div className='text-moyen pt-3'>Aucune visite</div>}
        </div>
    );

};

export default StatsVisites;