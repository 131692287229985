import { useEffect , useState } from '@wordpress/element';
import config from "@config";
import { UserProvider, FilterProvider } from '@context';
import TableRevisions from './table-revisions';
import PageTitle from '@pages/page-title';

const getRevisions = async (idUser) => {

    const url = config.api(idUser).get.revisions;

    return await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Cache-Control': 'no-cache',
        },
    })
        .then(response => response.json())
        .then(response => {
            if (response.success) {
                if(response.data.revisions) {
                    return [...response.data.revisions];
                }
            }
        });
}

const PageRevisions = (idUser) => {

    const [revisions, setRevisions] = useState([]);

    useEffect(() => {
        getRevisions(idUser.idUser).then(result => {
            setRevisions(result);
        });
    }, [])

    const revisionsFormated = revisions.map(revision => {
        return {...revision,
            date_action: new Date(revision.date_action),
        }
    })
console.log("revisionsFormated:", revisionsFormated);
return <div>Coucou</div>;

    /*return(
        <UserProvider>
            <FilterProvider>
                <div className='content-wrapper'>
                    <header className="entry-header">
                        <div className='d-flex w-100 justify-content-between'>
                            <PageTitle entite='revision' />
                        </div>
                    </header>
                    <div className="entry-content">
                        <TableRevisions revisions={revisionsFormated} />
                    </div>
                </div>
            </FilterProvider>
        </UserProvider>
    )*/
}

export default PageRevisions;