const ContactEdit = () => {

    //suppression CONTACT
    const btnDeleteContact = document.querySelector('.delete_contact');

    if( btnDeleteContact )
    {
        //console.log(getContactMeubles()); return false;
        btnDeleteContact.addEventListener('click', (e) => {
            if( confirm("Voulez-vous vraiment supprimer ce contact ?")) {
                e.preventDefault();
                const ajaxurl = e.target.dataset.ajaxurl;
                const data = {
                    action: e.target.dataset.action,
                    nonce:  e.target.dataset.nonce,
                    id_contact: e.target.dataset.id_contact
                }
                //console.log(data);

                fetch(ajaxurl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Cache-Control': 'no-cache',
                    },
                    body: new URLSearchParams(data),
                })
                    .then(response => response.json())
                    .then(response => {
                        console.log(response);
                        if (response.success == true) {
                            window.location.href = "/contacts";
                        }else{
                            alert(response.data.message);
                        }
                    });
            }
        })
    }

    //suppression photo CONTACT
    const btnDeletePhotoContact = document.querySelector('.delete_contact_photo_profil');
    if( btnDeletePhotoContact ){
        btnDeletePhotoContact.addEventListener('click', (e) => {
            if( confirm("Voulez-vous vraiment supprimer cette photo du contact ???")){

                e.preventDefault();
                const ajaxurl = e.target.dataset.ajaxurl;
                console.log("ajaxurl", ajaxurl);

                const data = {
                    action: e.target.dataset.action,
                    nonce:  e.target.dataset.nonce,
                    id_contact: e.target.dataset.id_contact
                }
                console.log(data);

                fetch(ajaxurl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Cache-Control': 'no-cache',
                    },
                    body: new URLSearchParams(data),
                })
                    .then(response => response.json())
                    .then(response => {
                        console.log(response);
                        if(response.success == true){
                            document.querySelector("#photo_profil").classList.add('d-none');
                            document.querySelector("#form_photo_profil").classList.remove('d-none');
                        }
                        if(response.data.message){
                            alert(response.data.message);
                        }
                    });
            }
        })
    }

    /**
     * Sélection auto du département selon code postal si France
     */
    const cpInput = document.getElementById('code_postal');
    if(cpInput){
        cpInput.addEventListener('blur', (event) => {
            const depSelect = document.getElementById('dep');
            const cp = event.target.value;
            if(depSelect){
                let depNum = cp.substring(0,2);
                if(depNum > 96){
                    depNum = parseInt(cp.substring(0,3));
                }
                depSelect.value = depNum;
            }
        });
    }

    const civiliteChoixInput = document.getElementById('civilite_choix');
    if (civiliteChoixInput) {
        civiliteChoixInput.addEventListener('change', (event) => {
            document.getElementById('civilite').value = "";
            if( civiliteChoixInput.value == "Statut juridique"){
                document.getElementById('block_statut_juridique').style.display = "flex";
            }else if(document.getElementById('civilite_choix').value == "Choisir"){
                document.getElementById('block_statut_juridique').style.display = "none";
                document.getElementById('prenom_resp_societe').value = "";
                document.getElementById('nom_resp_societe').value = "";
            }else{
                document.getElementById('block_statut_juridique').style.display = "none";
                document.getElementById('civilite').value = civiliteChoixInput.value;
                document.getElementById('prenom_resp_societe').value = "";
                document.getElementById('nom_resp_societe').value = "";

            }
        });
    }
}

export default ContactEdit;