import { useState, useMemo } from '@wordpress/element';
import MeubleItem from '../../meubles/meuble-item/meuble-item';
import { Panel, Badge } from '@composants';

/**
 * @param {Array} visites La liste de toutes les visites.
 * @returns {Array} Tableau des visites dont le classement arrive en fin de validité (d'avant 6 mois à terme échu).
 */
const VisitesFinValidite = ({ meubles, visites }) => {

    const [visitesARefaire, setVisitesARefaire] = useState();
    const [depsARefaire, setDepsARefaire] = useState();
    const [selectionARefaire, setSelectionARefaire] = useState('tous');
    const [visitesBientot, setVisitesBientot] = useState();
    const [depsBientot, setDepsBientot] = useState();
    const [selectionBientot, setSelectionBientot] = useState('tous');

    /** La date actuelle pour comparaison des dates */
    const dateActuelle = new Date();

    /** Renvoie le nombre de mois écoulés depuis la date ainsi que la date de fin de validité */
    const getMois = (date) => {
        const nombreDeMois = Math.abs((date.getFullYear() - dateActuelle.getFullYear()) * 12 + date.getMonth() - dateActuelle.getMonth());
        let dansCinqAns = new Date(date);
        dansCinqAns.setFullYear(date.getFullYear() + 5);
        // 54 mois pour 4 ans et demi, soit 6 mois avant la fin de validité.
        // 60 mois pour 5 ans.
        return {
            count: nombreDeMois,
            date: new Date(dansCinqAns).toLocaleDateString()
        }
    }

    const meublesARevisiter = useMemo(() => {

        if(!meubles || !visites) return;

        /** Liste des meublés dont la date de décision se termine dans les six mois */
        const meublesBientot = meubles.reduce((result, meuble) => {

            if(meuble.date_classement && meuble.date_classement !== '') {
                const ecartMois = getMois(new Date(meuble.date_classement));
                if(ecartMois.count > 54 && ecartMois.count < 60){
                    result.push({...meuble, role: `Fin de validité le ${ecartMois.date}`, tri: new Date(meuble.date_classement)});
                }
            }
            
            return result;
        }, []);

        /** Liste des meublés dont la date de décision est supérieure à 4,5 ans */
        const meublesAnciens = meubles.reduce((result, meuble) => {

            if(!meuble.date_classement || meuble.date_classement === '') {
                result.push({...meuble, role: `Aucun classement`, tri: null});
            } else {
                const ecartMois = getMois(new Date(meuble.date_classement));
                if(ecartMois.count >= 60){
                    result.push({...meuble, role: `Fin de validité le ${ecartMois.date}`, tri: new Date(meuble.date_classement)});
                }
            }
            
            return result;
        }, []);

        /** Meublés anciens qui n'ont pas de visite ou qui ont des visites trop anciennes */
        const meublesSansVisite = meublesAnciens.reduce((result, meuble) => {

            /** Les visites du meublé */
            const visitesMeuble = visites.filter(visite => visite.infos.meuble && visite.infos.meuble.id === meuble.id);
            
            /** Si pas de visite ajouter le meublé au résultat */
            if(visitesMeuble.length === 0) {
                result.push(meuble);
            } else {
                
                /** Si visites, trouver la plus récente */
                const lastVisite = visitesMeuble.reduce((currentVisite, nextVisite) => {
                    return (new Date(currentVisite.date_visite) > new Date(nextVisite.date_visite) ? currentVisite : nextVisite);
                });
                
                /** Calculer l'age de la visite en mois */
                const ageVisite = getMois(new Date(lastVisite.date_visite));
                
                /** Si l'âge de la visite est supérieur à 4 ans et demi ajouter le meublé au résultat */
                if(ageVisite.count > 54) result.push(meuble);

            }

            return result;
        }, []);

        if(meublesSansVisite.length > 0){
            
            const sansVisiteSorted = meublesSansVisite.sort((a, b) => {
                if (a.tri === null && b.tri === null) {
                    return 0; // Les deux sont égaux
                } else if (a.tri === null) {
                    return -1; // Placer les objets avec date null en premier
                } else if (b.tri === null) {
                    return 1; // Placer les objets avec date null en premier
                } else {
                    return a.tri - b.tri; // Comparaison normale si les deux dates sont définies
                }
            });

            const sansVisiteParDepartement = sansVisiteSorted.reduce((result, meuble) => {
                if(!result[meuble.dep]){
                    result[meuble.dep] = [];
                }
                result[meuble.dep].push(meuble);
                return result;
            }, {});

            sansVisiteParDepartement['tous'] = sansVisiteSorted;

            setDepsARefaire(Object.keys(sansVisiteParDepartement).sort((a,b) => {
                if(!isNaN(parseInt(a)) && !isNaN(parseInt(b))) return a - b;
                if(!isNaN(parseInt(a)) && isNaN(parseInt(b))) return 1;
                if(isNaN(parseInt(a)) && !isNaN(parseInt(b))) return -1;
                return b.localeCompare(a);
            }));

            setVisitesARefaire(sansVisiteParDepartement);

        } else {
            setVisitesARefaire(null);
        }

        if(meublesBientot.length > 0){

            const bientotSorted = meublesBientot.sort((a, b) => {
                if (a.tri === null && b.tri === null) {
                    return 0; // Les deux sont égaux
                } else if (a.tri === null) {
                    return -1; // Placer les objets avec date null en premier
                } else if (b.tri === null) {
                    return 1; // Placer les objets avec date null en premier
                } else {
                    return a.tri - b.tri; // Comparaison normale si les deux dates sont définies
                }
            });

            const bientotParDepartement = bientotSorted.reduce((result, meuble) => {
                if(!result[meuble.dep]){
                    result[meuble.dep] = [];
                }
                result[meuble.dep].push(meuble);
                return result;
            }, {});

            bientotParDepartement['tous'] = bientotSorted;

            setDepsBientot(Object.keys(bientotParDepartement).sort((a,b) => {
                if(!isNaN(parseInt(a)) && !isNaN(parseInt(b))) return a - b;
                if(!isNaN(parseInt(a)) && isNaN(parseInt(b))) return 1;
                if(isNaN(parseInt(a)) && !isNaN(parseInt(b))) return -1;
                return b.localeCompare(a);
            }));

            setVisitesBientot(bientotParDepartement);
        }
    }, [meubles, visites]);

    const departementsARefaire = (
        <div className='d-flex gap-2'>
            {depsARefaire && depsARefaire.map(departement => 
                <Badge 
                    onClick={() => setSelectionARefaire(departement)}
                    variant={selectionARefaire === departement ? 'bleu' : 'outline'} 
                    size='sm' 
                    className='square px-2 link' 
                    tooltip={departement === 'null' ? 'Sans département' : false}
                    key={departement}>
                        {departement === 'null' ? '?' : departement === 'tous' ? 'Tous' : departement}
                </Badge>)}
        </div>
    )

    const headerArefaire = (
        <div className='panel-title d-flex justify-content-between align-items-center pe-3'>
            <div className='d-flex gap-2'>
                <span>Meublés à revisiter (+ de 5 ans)</span>
                {visitesARefaire && <Badge variant="moyen" size="sm">{visitesARefaire[selectionARefaire].length}</Badge>}
            </div>
            {departementsARefaire}
        </div>
    );

    const departementsBientot = (
        <div className='d-flex gap-2'>
            {depsBientot && depsBientot.map(departement => 
                <Badge 
                    onClick={() => setSelectionBientot(departement)}
                    variant={selectionBientot === departement ? 'bleu' : 'outline'} 
                    size='sm' 
                    className='square px-2 link' 
                    tooltip={departement === 'null' ? 'Sans département' : false}
                    key={departement}>
                        {departement === 'null' ? '?' : departement === 'tous' ? 'Tous' : departement}
                </Badge>)}
        </div>
    )

    const headerBientot = (
        <div className='panel-title d-flex justify-content-between align-items-center pe-3'>
            <div className='d-flex gap-2'>
                <span>Meublés à revisiter dans les 6 mois</span>
                {visitesBientot && <Badge variant="moyen" size="sm">{visitesBientot[selectionBientot].length}</Badge>}
            </div>
            {departementsBientot}
        </div>
    );

    return (
        <>
            <Panel title="Meublés à revisiter dans les 6 mois" contentClassName='gap-2' scrollable={true} header={headerBientot}>
                {visitesBientot ? visitesBientot[selectionBientot].map(meuble => <MeubleItem key={meuble.id} meuble={meuble} action='liste' />) : 'Aucun meublé.'}
            </Panel>
            <Panel title="Meublés à revisiter (+ de 5 ans)" contentClassName='gap-2' scrollable={true} header={headerArefaire}>
                {visitesARefaire ? visitesARefaire[selectionARefaire].map(meuble => <MeubleItem key={meuble.id} meuble={meuble} action='liste' />) : 'Aucun meublé.'}
            </Panel>
        </>
    );
}

export default VisitesFinValidite