import { createRoot } from "react-dom";
import { StrictMode } from 'react';
import { FormValidation } from './utiles';
import { UserProvider } from '@context';
import { Contacts, ContactEdit, ContactVoir, ContactLiaisonMeuble } from '@contact';
import { Meubles, MeubleVoir, MeubleEdit, MeubleLiaisonContact } from '@meuble';
import { PageArchivesMeubles, PageArchivesContacts, PageArchivesVisites } from "../archives";
import ProfilEdit from '../user/user-edit';
import { Visites, VisiteEdit, VisiteVoir } from '@visite';
import { ToastManager } from "@composants";
import ImportFile from '../import/import';
import Accueil from '../accueil/accueil';
import ButtonArchiveActionsGroups from "@composants/button/button-archive-actions-groups";
import PageRevisions from "../revisions/page-revisions";

import "./styles/main.scss";

/**
* Écouter l'état de la connexion
*/
const initOfflineStatus = () => {

  const statusElement = document.querySelector('.connection-status');

  if (!statusElement) return false;

  if (!window.navigator.onLine) {
    statusElement.classList.add('offline');
  } else {
    statusElement.classList.remove('offline');
  }

  window.onoffline = (event) => {
    statusElement.classList.add('offline');
    window.location.reload();
  }

  window.ononline = (event) => {
    statusElement.classList.remove('offline');
    window.location.reload();
  }

}

/**
 * Gestion des toasts
 */
const initToast = () => {
  const toasts = document.querySelectorAll('.toast');
  const toastContainer = document.querySelector('.toast-container');
  if (toasts.length && toastContainer) {
    const toastData = [...toasts].map(element => {
      return {
        title: element.dataset.title,
        type: element.dataset.type,
        message: element.dataset.message,
      };
    });
    const toastRoot = createRoot(toastContainer);
    toastRoot.render(<StrictMode><ToastManager data={toastData} /></StrictMode>)
  }
}

/**
 * Démarrage de l'application
 */
const initApp = () => {

  initOfflineStatus();

  /** Gestion des srcripts react selon routes */

  const appContainer = document.getElementById("app-container");

  let root;
  if (appContainer) root = createRoot(appContainer);
  const cleanBodyClasses = (newClass) => {
    document.body.classList.remove('page-template-accueil');
    document.body.classList.remove('page-template-accueilaccueil-php');
    document.body.classList.add(newClass);
  }

  switch (true) {

    case window.location.pathname === '/':
      root.render(<Accueil />);
      break;
    case window.location.pathname === '/identification':
    case window.location.pathname === '/identification/':
      console.log('IDENTIFICATION');
      // loginUser();
      break;
    case window.location.pathname === '/visites':
    case window.location.pathname === '/visites/':
      cleanBodyClasses('page-visite');
      root.render(<Visites />);
      break;
    case window.location.pathname === '/visites/visite-edit/':
    case window.location.pathname === '/visites/visite-edit':
      cleanBodyClasses('page-visite');
      root.render(<VisiteEdit />);
      break;
    case window.location.pathname === '/visites/visite-voir':
    case window.location.pathname === '/visites/visite-voir/':
      cleanBodyClasses('page-visite');
      root.render(<VisiteVoir />);
      break;
    case window.location.pathname === '/meubles':
    case window.location.pathname === '/meubles/':
      cleanBodyClasses('page-meuble');
      root.render(<Meubles />);
      break;
    case window.location.pathname === '/meubles/meuble-voir':
    case window.location.pathname === '/meubles/meuble-voir/':
      cleanBodyClasses('page-meuble');
      root.render(<MeubleVoir />);
      break;
    case window.location.pathname === "/meubles/meuble-edit":
    case window.location.pathname === "/meubles/meuble-edit/":
    case window.location.pathname === "/wp-admin/admin.php?page=meuble-edit&orig=bo":
      cleanBodyClasses('page-meuble');
      FormValidation();
      MeubleEdit();
      const meubleLiaisonsContainer = document.getElementById("meuble-liaison-contact");
      if (meubleLiaisonsContainer) {
        root = createRoot(meubleLiaisonsContainer);
        root.render(<MeubleLiaisonContact />);
      }
      const buttonArchiveActionsGroupsContainer = document.getElementById("button-archive-actions-groups");
      const id_meuble = [document.getElementById("id").value];
      const status_meuble = document.getElementById("status").value;
      if (buttonArchiveActionsGroupsContainer) {
        root = createRoot(buttonArchiveActionsGroupsContainer);
        root.render(<UserProvider>
          <ButtonArchiveActionsGroups selectionEntities={id_meuble} entity='meubles' status={status_meuble} titre='Action' />
        </UserProvider>);
      }

      break;
    case window.location.pathname === '/contacts':
    case window.location.pathname === '/contacts/':
      cleanBodyClasses('page-contact');
      root.render(<Contacts />);
      break;
    case window.location.pathname === '/contacts/contact-edit':
    case window.location.pathname === '/contacts/contact-edit/':
      cleanBodyClasses('page-contact');
      FormValidation();
      ContactEdit();
      const contactLiaisonsContainer = document.getElementById("contact-liaison-meuble");
      if (contactLiaisonsContainer) {
        root = createRoot(contactLiaisonsContainer);
        root.render(<ContactLiaisonMeuble />);
      }
      const buttonArchiveActionsGroupsContainer2 = document.getElementById("button-archive-actions-groups");
      const id_contact = [document.getElementById("id").value];
      const status_contact = document.getElementById("status").value;
      if (buttonArchiveActionsGroupsContainer2) {
        root = createRoot(buttonArchiveActionsGroupsContainer2);
        root.render(<UserProvider>
          <ButtonArchiveActionsGroups selectionEntities={id_contact} entity='contacts' status={status_contact} titre='Action' />
        </UserProvider>);
      }
      break;
    case window.location.pathname === '/contacts/contact-voir':
    case window.location.pathname === '/contacts/contact-voir/':
      cleanBodyClasses('page-contact');
      root.render(<ContactVoir />);
      break;
    case window.location.pathname === "/mon-profil":
    case window.location.pathname === "/mon-profil/":
      ProfilEdit();
      FormValidation();
      break;
    case window.location.pathname === "/import":
    case window.location.pathname === "/import/":
      root.render(<ImportFile />);
      break;
    case window.location.pathname === "/archives/meubles":
      root.render(<PageArchivesMeubles idUser={cUser.id} />);
      break;
    case window.location.pathname === "/archives/contacts":
    case window.location.pathname === "/archives/contacts/":
      root.render(<PageArchivesContacts idUser={cUser.id} />);
      break;
    case window.location.pathname === "/archives/visites":
      root.render(<PageArchivesVisites idUser={cUser.id} />);
      break;
    case window.location.pathname === "/revisions":
    case window.location.pathname === "/revisions/":
      root.render(<PageRevisions idUser={cUser.id} />);
      break;
  };

  initToast();
}

/**
 * Chargement de l'utilisateur
 */
const loadUser = async () => {

  if (!window.navigator.onLine) {

    const cUserId = parseInt(window.localStorage.getItem('userid'));

    if (cUserId) {
      window.cUser = { id: cUserId, level: 'inspecteur' }
      initApp();
    }

  } else {

    const response = await fetch('/wp-admin/admin-ajax.php?action=is_user_logged_in');
    const result = await response.json();

    if (result.success) {

      const { is_user_logged_in, cUser, level } = result.data;

      if (!is_user_logged_in) {
        if (window.location.pathname !== '/identification') {
          window.location = '/identification';
        }
        console.warn('Perte de session');
      } else {
        window.cUser = {
          id: cUser,
          level: level
        }
        console.log("%c Session active", "color:green;");
        initApp();
      }
    }
  }
}

const initSubmenu = () => {

  const collapsibleMenu = document.querySelector('#site-navigation .menu-item-has-children');
  const collapsibleMenuLink = collapsibleMenu.querySelector('a');
  const submenu = collapsibleMenu.querySelector('.sub-menu');

  if(window.location.pathname.indexOf('archives') !== -1) {
    submenu.classList.add('open');
    collapsibleMenu.classList.add('current-menu-item');
  }
  
  collapsibleMenuLink.addEventListener('click', (event) => {
    event.preventDefault();
    submenu.classList.toggle('open');
  });
}

loadUser();
initSubmenu();




