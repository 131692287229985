const ProfilEdit = () => {
    jQuery(document).ready(function () {

        jQuery('input[type="file"]').on('change', function(e) {
            let that = e.currentTarget;
            if (that.files && that.files[0]) {
                let preview = '.img-' + e.target.name;
                let uploadBtn = 'upload_' + e.target.name;
                let reader = new FileReader()
                reader.onload = (e) => {
                    jQuery(preview).attr('src', e.target.result);
                    blink("input[name="+uploadBtn+"]");
                }
                reader.readAsDataURL(that.files[0]);
            }
        });
    });

    function blink(e){
        jQuery(e).fadeOut('slow', function(){
            jQuery(this).fadeIn('slow', function(){
                blink(this);
            });
        });
    }
}

export default ProfilEdit;