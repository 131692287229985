import ContactItem from '../contact-item/contact-item';

const ContactsListe = ({ contacts }) => {

    if(contacts.length < 1) return <div className='text-moyen pt-3'>Aucun contact</div>;

    return(
        <div className="visites-liste">
            <div className="d-flex flex-column gap-2">
                {contacts.map(contact => <ContactItem key={contact.id} contact={contact} />)}
            </div>
        </div>
    );
};

export default ContactsListe;