import { useContext } from '@wordpress/element';
import { DataContext, UserContext } from '@context';
import { useOnlineStatus } from '@hooks';
import { Vignette, Panel } from '@composants';
import { MeubleItem } from '@composants/entites';
import ButtonArchiveActionsGroups from "@composants/button/button-archive-actions-groups";
import {ToastContextProvider} from '@context';

const PageContactVoir = () => {

    const { user } = useContext(UserContext);
    const { isLoading, contacts, meubles } = useContext(DataContext);
    const isOnline = useOnlineStatus();

    if(isLoading) return <div className='text-center p-5'><h3 className='h3 text-moyen'>Chargement des contacts…</h3></div>;

    const searchParams = new URLSearchParams(window.location.search);
    const contact = contacts.find(c => c.id === searchParams.get('id'));
    const { id, civilite, nom, prenom, nom_resp_societe, prenom_resp_societe, adresse1, adresse2, adresse3, code_postal, commune, email, telephone, telephone_type, telephone2, telephone2_type, date_create, date_update, user_create, user_create_name, user_update_name, vignette_url } = contact;
    
    //const canEdit = parseInt(user_create) === parseInt(user.id);
    const canEdit = true;

    const meublesContact = contact.meubles.reduce((result, current) => {
        const found = meubles.find(cm => cm.id === current.id);
        if(found) result.push({...found, role: current.contact_type});
        return result;
    }, []);

    return(
        <div className='content-wrapper'>
            <header className="entry-header">
                <div className='d-flex justify-content-between align-items-center w-100'>
                    <div className='identification d-flex align-items-center gap-3'>
                        <Vignette className="rond" variant='contact'>
                            {vignette_url && <img src={vignette_url} />}
                        </Vignette>
                        <div className='infos d-flex flex-column'>
                            <h3 className='h3'>{civilite} {prenom} {nom}</h3>
                            {nom_resp_societe && <span className="">Responsable sociéte : {nom_resp_societe} {prenom_resp_societe}</span>}
                            {date_create && <span className="caption text-moyen">Créé le {new Date(date_create).toLocaleDateString()}{user_create_name && ` par ${user_create_name}`}</span>}
                            {date_update && <span className="caption text-moyen">Modifié le {new Date(date_update).toLocaleDateString()}{user_update_name && ` par ${user_update_name}`}</span>}
                        </div>
                    </div>
                    <div className='actions d-flex gap-3'>
                        <ToastContextProvider>
                            <ButtonArchiveActionsGroups selectionEntities={[id]} entity='contacts' status='publie' titre='Action' />
                        </ToastContextProvider>
                        <a className="btn outline" href={`/contacts`}>Retour</a>
                        {canEdit && <button className='btn' onClick={() => window.location = `/contacts/contact-edit/?id=${id}`} disabled={!isOnline}>Modifier</button>}
                    </div>
                </div>
            </header>
            <div className="entry-content">
                
                <section className='py-3'>
                    <Panel title="Coordonnées">
                        <div className='d-flex justify-content-between'>
                            <div className='adresse body-2'>
                                <p className='mb-1 body-2'>Adresse postale</p>
                                <address className='mb-0'>
                                    <strong className='d-flex flex-column'>
                                        <span>{adresse1}</span>
                                        <span>{adresse2}</span>
                                        <span>{adresse3}</span>
                                        <span>{code_postal} {commune}</span>
                                    </strong>
                                </address>
                            </div>
                            <div className='emails body-2'>
                                <p className='mb-1 body-2'>Adresse(s) e-mail</p>
                                <p className='body-2 mb-0'>
                                    <a href={`mailto:${email}`} title="Envoyer un e-mail">{email}</a>
                                </p>
                            </div>
                            <div className='telephones body-2'>
                                <p className='mb-1 body-2'>Numero(s) de téléphone</p>
                                {(telephone || telephone2) ? 
                                    <div className='d-flex flex-column'>
                                        {telephone_type && <div>{telephone_type}</div>}{telephone && <a href={`tel:${telephone}`} title="Téléphoner">{telephone}</a>}
                                        {telephone2_type && <div>{telephone2_type}</div>}{telephone2 && <a href={`tel:${telephone2}`} title="Téléphoner">{telephone2}</a>}
                                    </div> : <strong>Aucun numéro.</strong>}
                            </div>
                        </div>
                    </Panel>
                </section>
                
                <section className='py-3'>
                    <Panel title="Meublés associés à ce contact">
                        {(meublesContact && meublesContact.length) ? <div className="d-flex flex-column gap-2">
                            {meublesContact.map((meuble, index) => <MeubleItem key={`${meuble.id}-${index}`} meuble={meuble} action="liste" />)}
                        </div> : <div className='d-flex align-items-center justify-content-center p-4'>
                            Aucun meublé n'est lié à ce contact
                        </div>}
                    </Panel>
                </section>

            </div>
        </div>
    )
}

export default PageContactVoir;