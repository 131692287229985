import { useMemo, useState, useEffect } from '@wordpress/element';
import { createColumnHelper } from "@tanstack/react-table";
import { TableEntite } from '@composants/table-entite';
import { useFilters } from '@hooks';
import { Cell, CellMeubles } from '@composants/table-entite/cell';
import { CheckboxSelection } from '@composants/form';
import { ButtonContactActions } from '@composants/button';
import { ButtonMeubleActions }  from '@composants/button';
import { ReactComponent as ContactIcon } from '@images/person.svg';

const columnHelper = createColumnHelper();
const { dateRangeFilter } = useFilters();

const TableContacts = ({ contacts, selectionHandler }) => {

    // Trier les contacts par date_update
    contacts.sort((a,b) => new Date(b.date_update) - new Date(a.date_update) );
    const [rowSelection, setRowSelection] = useState({});

    const columns = useMemo(() => [
        {
            id: 'select',
            header: ({ table }) => (
                <CheckboxSelection {...{
                    checked: table.getIsAllRowsSelected(),
                    indeterminate: table.getIsSomeRowsSelected(),
                    onChange: table.getToggleAllRowsSelectedHandler(),
                }} />
            ),
            cell: ({ row }) => (
                <Cell className="cell-select">
                    <CheckboxSelection {...{
                        checked: row.getIsSelected(),
                        indeterminate: row.getIsSomeSelected(),
                        onChange: row.getToggleSelectedHandler(),
                    }} />
                </Cell>
            )
        },
        columnHelper.display({
            id: 'vignette',
            cell: props => {
                const vignette = props.row.original.vignette_url;
                return <Cell className="vignette">
                    {vignette ? <img src={vignette} /> : <ContactIcon />}
                </Cell>
            }
        }),
        columnHelper.accessor(row => `${row.prenom} ${row.nom}`, {
            header: 'Contact / Nom',
            cell: props => {
                const data = props.row.original;
                const link = `/contacts/contact-voir/?id=${data.id}`;
                return <Cell link={link}>
                    <span>{data.civilite} {data.prenom} {data.nom}</span>
                </Cell>
            }
        }),
        columnHelper.accessor(row => `${row.adresse1} ${row.adresse2} ${row.adresse3} ${row.code_postal} ${row.commune}`, {
            id: 'adresse',
            header: 'Adresse',
            cell: props => {
                const data = props.row.original;
                const link = `/contacts/contact-voir/?id=${data.id}`;
                return <Cell link={link}>
                    <span>{data.adresse1}</span>
                    {data.adresse2 && <span>{data.adresse2}</span>}
                    {data.adresse3 && <span>{data.adresse3}</span>}
                    <span>{data.code_postal} {data.commune}</span>
                </Cell>
            }
        }),
        columnHelper.accessor(row => `${row.email} ${row.telephone} ${row.telephone2}`, {
            header: 'Coordonnées',
            cell: props => {
                const data = props.row.original;
                return <Cell>
                    <a href={`mailto:${data.email}`} title={`Envoyer un e-mail`}>{data.email}</a>
                    <a href={`tel:${data.telephone}`} title={`Téléphoner`}>{data.telephone}</a>
                </Cell>
            }
        }),
        columnHelper.accessor('date_update', {
            header: 'Date édition',
            cell: props => {
                const data = props.row.original;
                const dateString = data.date_update.toLocaleDateString();
                const userName = data.user_update_name;
                return <Cell className='cell-inspecteur caption'>
                    <div className='d-flex flex-column'>
                    <span>Le {dateString}</span>
                    {userName ? <span>par {userName}</span> : null}
                    </div>
                </Cell>
            },
            filterFn: (row, columnId, value) => dateRangeFilter(row, columnId, value)
        }),

        columnHelper.display({
            id: 'actions',
            cell: props => <CellMeubles contact={props.row.original} />
        }),
        columnHelper.display({
            id: 'actions2',
            cell: props => {
                const data = props.row.original;
                return <div className='cell cell-actions cell-inner'>
                    <ButtonContactActions contact={data} />
                </div>
            }
        }),
    ], []);

    useEffect(() => {
        const contactsToAction = Object.keys(rowSelection).map(key => contacts[key].id);
        selectionHandler(contactsToAction);
    }, [rowSelection]);

    return(
        <TableEntite data={contacts} columns={columns} rowSelection={rowSelection} selectionHandler={setRowSelection} className="hover" />
    )

}

export default TableContacts;