import { createContext, useState, useEffect, useCallback, useContext } from '@wordpress/element';
import { Toast, ToastBody, ToastContainer, ToastHeader } from 'react-bootstrap';

const ToastContext = createContext();

const TOAST_DELAY = 5000;

const ToastContextProvider = ({ children }) => {

    const [toasts, setToasts] = useState([]);

    useEffect(() => {

        if(toasts.length > 0) {
            
            const timer = setTimeout(
                () => setToasts(toasts => toasts.slice(1)), 
                TOAST_DELAY
            );

            return () => clearTimeout(timer);
        };

    }, [toasts]);

    const addToast = useCallback((toast) => {
        setToasts(toasts => [...toasts, toast]);
    }, [setToasts]);

    const onToastClose = (toast) => {
        const nextToasts = [...toasts].filter(item => item.message !== toast.message);
        setToasts(nextToasts);
    }

    return (
        <ToastContext.Provider value={{ addToast }}>
            {children}
            <ToastContainer className='app-toaster' placement='top-end'>
                {toasts.map((toast, index) => (
                    <Toast key={index} bg={toast.type} onClose={() => onToastClose(toast)}>
                        <ToastHeader><strong className='me-auto'>{toast.title}</strong></ToastHeader>
                        <ToastBody className={toast.type === 'danger' && 'text-white'} dangerouslySetInnerHTML={{__html: toast.message}} />
                    </Toast>
                ))}
            </ToastContainer>
        </ToastContext.Provider>
    )

}

const useToastContext = () => {
    return useContext(ToastContext);
}

export {
    ToastContext, 
    ToastContextProvider, 
    useToastContext, 
};