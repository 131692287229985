const useUtiles = () => {

    const removeAccents = (text) => {
        if (text && text.length > 0) {
            const normalized = text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            return normalized;
        } else {
            return text;
        }
    }

    const toPhone = (numberString) => {
        return numberString.replace(/\B(?=(\d{2})+(?!\d))(?<!\+3)|\B(?<=\+33)/g, " ");
    }

    const countDays = (start, end) => {

        const startDate = new Date(start); // Date visite
        const endDate = new Date(end); // Maintenant
        const dateLimite = new Date(startDate.setDate(startDate.getDate() + 30)); // date visite + 30 jours

        const oneDay = 1000 * 60 * 60 * 24;

        const diffInTime = dateLimite.getTime() - endDate.getTime(); // Jours restants

        const diffInDays = Math.round(diffInTime / oneDay);

        return diffInDays;
    }

    const numberStringToPhone = (string) => {
        if (string) {
            return string.replace(/(^\+33)(\d)(\d+)/, '$1 $2 $3').replace(/\B(?=(\d{2})+(?!\d))/g, " ");
        }
    }

    const getLabelClassementActuel = (meuble, entier) => {
        const actuel = parseInt(meuble.classement);
        if (actuel !== 0) {

            if (entier) {
                return actuel;
            }

            if (actuel > 1) {
                return `${actuel} étoiles`;
            } else {
                return `${actuel} étoile`;
            }

        } else {
            return "Non classé";
        }
    }

    const dateUtcToLocal = (dateString) => {

        const dateUtc = `${dateString} UTC`;
        const localDate = new Date(dateUtc);

        return localDate.toLocaleString("sv-SE");
    }

    /*
        format d'entrée : 02/09/2024 14:52:03 et valeur de date UTC
        format de sortie : 02/09/2024 16:52:03 et valeur de date GMT Paris
    */
    const dateUtcToLocalDate = (dateString) => {

        // Créer un objet Date en utilisant la chaîne de date au format français
        const [datePart, timePart] = dateString.split(' ');
        const [day, month, year] = datePart.split('/').map(Number);
        const [hours, minutes, seconds] = timePart.split(':').map(Number);

        // Créer un objet Date en UTC (en supposant que la date initiale est en UTC)
        const utcDate = new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds));

        // Convertir la date en fuseau horaire Europe/Paris et la formater en français
        return utcDate.toLocaleString('fr-FR', { timeZone: 'Europe/Paris', hour12: false });
    }

    /**
     * 
     * @param {string} texte 
     * @returns Retourne la chaine sans espaces, sans points et sans accents.
     */
    const cleanTexte = (texte) => {
        const chaineSansEspaces = texte.replace(/\s/g, '');
        const chaineSansPoints = chaineSansEspaces.replace(/\./g, '');
        const chaineSansAccents = removeAccents(chaineSansPoints);
        return chaineSansAccents;
    }

    /**
     * 
     * Permet de parcourir un objet et trouver la valeur des attributs imbriqués.
     * 
     * @param {object} object l'objet dans lequel trouver la propriété.
     * @param {Array} path tableau des nœuds à parcourir jusqu'à la propriété.
     * @returns la valeur de la propriété recherchée.
     */
    const getProp = (object, path) => {
        if (path.length === 1) return object[path[0]];
        else if (path.length === 0) throw error;
        else {
            if (object[path[0]]) return getProp(object[path[0]], path.slice(1));
            else {
                object[path[0]] = {};
                return getProp(object[path[0]], path.slice(1));
            }
        }
    };

    const normalizeCivilite = (civiliteString) => {

        if(!civiliteString) return '';

        let normalized = '';

        const original = civiliteString.toLowerCase();
        const hasMonsieur = original.indexOf('m.') !== -1 || original.indexOf('mr') !== -1 || original.indexOf('monsieur') !== -1;
        const hasMadame = original.indexOf('mme') !== -1 || original.indexOf('madame') !== -1;

        if (hasMadame) {
            normalized += "Madame"
            if (hasMonsieur) {
                normalized += ", Monsieur"
            }
        } else if (hasMonsieur) {
            normalized = "Monsieur";
        }

        return normalized;
    }

    /**
     * 
     * @param {Date} date 
     * @returns Le nombre de mois entre maintenant et la date passée
     */
    const getNombreDeMois = (date) => {
        const dateActuelle = new Date();
        return Math.abs((date.getFullYear() - dateActuelle.getFullYear()) * 12 + date.getMonth() - dateActuelle.getMonth());
    }

    return {
        removeAccents,
        toPhone,
        countDays,
        numberStringToPhone,
        getLabelClassementActuel,
        dateUtcToLocal,
        dateUtcToLocalDate,
        cleanTexte,
        getProp,
        normalizeCivilite, 
        getNombreDeMois, 
    }
}

export default useUtiles;