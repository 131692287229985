import { useVisite } from '@context';
import './groupe.scss';

export const Groupe = ({ groupe, classement, status, children }) => {

    const { title, replace } = groupe;
    const { visite } = useVisite();

    const criteres = visite.grille.filter(critere => groupe.criteres.includes(critere.id));
    const uncheckeds = criteres.filter(c => (!c.checked && c.statut !== 'NA'));

    let groupTitle = title;
    if(title.indexOf('%') !== -1){
        const index = classement > 0 ? classement - 1 : 0;
        groupTitle = title.replace('%', replace[index]);
    }

    return(
        <div className={`groupe groupe-criteres pb-2 d-flex flex-column${(uncheckeds.length === 0) ? " complete" : " gap-3"}`.trim()}>
            <div className='groupe-header py-2'>
                <span className="font-body-bold">{groupTitle}</span>
            </div>
            <div className='groupe-body'>
                {children}
            </div>
        </div>
    )
}