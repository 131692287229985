import { Page, Text, View } from '@react-pdf/renderer';
import { useUtiles } from '@hooks';
import { CIVILITES } from '@constantes';

const PageInformationsGenerales = ({ visite, meuble, contacts, inspecteur }) => {

    const dateDecision = visite.date_decision ? new Date(visite.date_decision).toLocaleDateString() : new Date().toLocaleDateString();
    const { demandeur } = visite;
    const { loueur, mandataire } = contacts;

    const { getLabelClassementActuel } = useUtiles();
    const classementActuel = getLabelClassementActuel(meuble, true);

    // Si le demandeur est mandataire et qu'il s'agit d'une societe
    const isDemandeurSociete = !CIVILITES.includes(demandeur.civilite.trim());

    // Si le demandeur n'a pas de role définit
    if(!demandeur.role){
        const demandeurMeuble = demandeur.meubles.find(m => m.id === meuble.id);
        if(demandeurMeuble){
            demandeur.role = demandeurMeuble.contact_type === 'Propriétaire' ? 'Loueur' : demandeurMeuble.contact_type;
        } else {
            demandeur.role = 'Loueur';
        }
    }

    return(
        <Page size="A4" style={{ padding: '10mm', fontFamily: 'Lato' }}>
            <View style={{ textAlign: 'center', fontSize: '18pt', fontWeight: 'bold', marginBottom: '7mm' }}>
                <Text>Annexe II</Text>
            </View>
            <View style={{ backgroundColor: '#C0C0C0', marginBottom: '10pt', fontSize: '12pt', padding: '0.5mm'}}>
                <Text>Informations générales</Text>
            </View>

            <View style={{ fontSize: '11pt', marginBottom: '4mm' }}>

                <View style={{display: 'flex', flexDirection: 'row', backgroundColor: "#000000", fontWeight: 'bold'}}>
                    <View style={{padding: '1pt', width: '50%', color: "#ffffff", border: '1pt solid #000'}}><Text>Champs</Text></View>
                    <View style={{padding: '1pt', width: '50%', color: "#ffffff", border: '1pt solid #000'}}><Text>Caractéristiques du champ</Text></View>
                </View>
                
                <View style={{display: 'flex', flexDirection: 'row', marginTop: '-1pt', borderBottom: '1pt solid #000'}}>
                    <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000'}}>
                        <Text>Nature du demandeur (le loueur ou son mandataire) :</Text>
                    </View>
                    <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000', borderRight: '1pt solid #000'}}>
                        <Text>{demandeur.role}</Text>
                    </View>
                </View>

                <View style={{display: 'flex', flexDirection: 'row', marginTop: '-1pt', borderBottom: '1pt solid #000'}}>
                    <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000'}}>
                        <Text>Nom du demandeur ou mandataire :</Text>
                    </View>
                    <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000', borderRight: '1pt solid #000'}}>
                        <Text>{ isDemandeurSociete ? demandeur.nom_resp_societe : demandeur.nom }</Text>
                    </View>
                </View>

                <View style={{display: 'flex', flexDirection: 'row', marginTop: '-1pt', borderBottom: '1pt solid #000'}}>
                    <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000'}}>
                        <Text>Prénom du demandeur ou mandataire :</Text>
                    </View>
                    <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000', borderRight: '1pt solid #000'}}>
                        <Text>{ isDemandeurSociete ? demandeur.prenom_resp_societe : demandeur.prenom }</Text>
                    </View>
                </View>

                <View style={{display: 'flex', flexDirection: 'row', marginTop: '-1pt', borderBottom: '1pt solid #000'}}>
                    <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000'}}>
                        <Text>Civilité du demandeur ou mandataire :</Text>
                    </View>
                    <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000', borderRight: '1pt solid #000'}}>
                        <Text>{ isDemandeurSociete ? 'M. ou Mme' : demandeur.civilite }</Text>
                    </View>
                </View>

                <View style={{display: 'flex', flexDirection: 'row', marginTop: '-1pt', borderBottom: '1pt solid #000'}}>
                    <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000'}}>
                        <Text>Adresse du demandeur ou mandataire :</Text>
                    </View>
                    <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000', borderRight: '1pt solid #000'}}>
                        <Text>{demandeur?.adresse1} {demandeur?.adresse2} {demandeur?.adresse3}</Text>
                    </View>
                </View>

                <View style={{display: 'flex', flexDirection: 'row', marginTop: '-1pt', borderBottom: '1pt solid #000'}}>
                    <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000'}}>
                        <Text>Code postal du demandeur :</Text>
                    </View>
                    <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000', borderRight: '1pt solid #000'}}>
                        <Text>{demandeur?.code_postal}</Text>
                    </View>
                </View>

                <View style={{display: 'flex', flexDirection: 'row', marginTop: '-1pt', borderBottom: '1pt solid #000'}}>
                    <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000'}}>
                        <Text>Commune et pays du demandeur :</Text>
                    </View>
                    <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000', borderRight: '1pt solid #000'}}>
                        <Text>{demandeur?.commune.toUpperCase()}{demandeur.pays ? ` – ${demandeur.pays}`: ''}</Text>
                    </View>
                </View>

                <View style={{display: 'flex', flexDirection: 'row', marginTop: '-1pt', borderBottom: '1pt solid #000'}}>
                    <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000'}}>
                        <Text>Téléphone 1 du demandeur ou mandataire :</Text>
                    </View>
                    <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000', borderRight: '1pt solid #000'}}>
                        <Text>{demandeur?.telephone}</Text>
                    </View>
                </View>

                <View style={{display: 'flex', flexDirection: 'row', marginTop: '-1pt', borderBottom: '1pt solid #000'}}>
                    <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000'}}>
                        <Text>Téléphone 2 du demandeur ou mandataire :</Text>
                    </View>
                    <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000', borderRight: '1pt solid #000'}}>
                        <Text>{demandeur?.telephone2}</Text>
                    </View>
                </View>

                <View style={{display: 'flex', flexDirection: 'row', marginTop: '-1pt', borderBottom: '1pt solid #000'}}>
                    <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000'}}>
                        <Text>Courriel du demandeur :</Text>
                    </View>
                    <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000', borderRight: '1pt solid #000'}}>
                        <Text>{demandeur?.email}</Text>
                    </View>
                </View>

                <View style={{display: 'flex', flexDirection: 'row', marginTop: '-1pt', borderBottom: '1pt solid #000'}}>
                    <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000'}}>
                        <Text>Adresse du logement meublé :</Text>
                    </View>
                    <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000', borderRight: '1pt solid #000'}}>
                        <Text>{meuble?.adresse1} {meuble?.adresse2}</Text>
                    </View>
                </View>

                <View style={{display: 'flex', flexDirection: 'row', marginTop: '-1pt', borderBottom: '1pt solid #000'}}>
                    <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000'}}>
                        <Text>Code postal du logement meublé :</Text>
                    </View>
                    <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000', borderRight: '1pt solid #000'}}>
                        <Text>{meuble?.code_postal}</Text>
                    </View>
                </View>

                <View style={{display: 'flex', flexDirection: 'row', marginTop: '-1pt', borderBottom: '1pt solid #000'}}>
                    <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000'}}>
                        <Text>Commune du logement meublé :</Text>
                    </View>
                    <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000', borderRight: '1pt solid #000'}}>
                        <Text>{meuble?.commune.toUpperCase()}</Text>
                    </View>
                </View>

                <View style={{display: 'flex', flexDirection: 'row', marginTop: '-1pt', borderBottom: '1pt solid #000'}}>
                    <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000'}}>
                        <Text>Dénomination commerciale, le cas échéant :</Text>
                    </View>
                    <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000', borderRight: '1pt solid #000'}}>
                        <Text>{meuble?.titre}</Text>
                    </View>
                </View>

                <View style={{display: 'flex', flexDirection: 'row', marginTop: '-1pt', borderBottom: '1pt solid #000'}}>
                    <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000'}}>
                        <Text>Classement actuel (étoile(s)) : </Text>
                    </View>
                    <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000', borderRight: '1pt solid #000'}}>
                        <Text>{classementActuel}</Text>
                    </View>
                </View>

                <View style={{display: 'flex', flexDirection: 'row', marginTop: '-1pt', borderBottom: '1pt solid #000'}}>
                    <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000'}}>
                        <Text>Classement demandé (étoile(s)) :</Text>
                    </View>
                    <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000', borderRight: '1pt solid #000'}}>
                        <Text>{visite?.infos.classement_cible}</Text>
                    </View>
                </View>

                <View style={{display: 'flex', flexDirection: 'row', marginTop: '-1pt', borderBottom: '1pt solid #000'}}>
                    <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000'}}>
                        <Text>Capacité demandée :</Text>
                    </View>
                    <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000', borderRight: '1pt solid #000'}}>
                        <Text>{visite.infos.capacite}</Text>
                    </View>
                </View>

                <View style={{display: 'flex', flexDirection: 'row', marginTop: '-1pt', borderBottom: '1pt solid #000', fontWeight: 'bold'}}>
                    <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000'}}>
                        <Text>Date de la visite d'inspection :</Text>
                    </View>
                    <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000', borderRight: '1pt solid #000'}}>
                        <Text>{new Date(visite.date_visite).toLocaleDateString()}</Text>
                    </View>
                </View>

                <View style={{display: 'flex', flexDirection: 'row', marginTop: '-1pt', borderBottom: '1pt solid #000', fontWeight: 'bold'}}>
                    <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000'}}>
                        <Text>Date d'émission du document :</Text>
                    </View>
                    <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000', borderRight: '1pt solid #000'}}>
                        <Text>{dateDecision}</Text>
                    </View>
                </View>

            </View>

            <View style={{marginBottom: '4mm'}}>
                <View style={{ fontSize: '11pt', marginBottom: '1mm' }}>
                    <View style={{display: 'flex', flexDirection: 'row', backgroundColor: "#000000", fontWeight: 'bold'}}>
                        <View style={{padding: '1pt', width: '50%', color: "#ffffff", border: '1pt solid #000'}}><Text>Champs</Text></View>
                        <View style={{padding: '1pt', width: '50%', color: "#ffffff", border: '1pt solid #000'}}><Text>Caractéristiques du champ</Text></View>
                    </View>
                    
                    <View style={{display: 'flex', flexDirection: 'row', marginTop: '-1pt', borderBottom: '1pt solid #000'}}>
                        <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000'}}>
                            <Text>Nom de l'organisme qui effectue la visite(*) :</Text>
                        </View>
                        <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000', borderRight: '1pt solid #000'}}>
                            <Text>{inspecteur.organisme_titre}</Text>
                        </View>
                    </View>
                    
                    <View style={{display: 'flex', flexDirection: 'row', marginTop: '-1pt', borderBottom: '1pt solid #000'}}>
                        <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000'}}>
                            <Text>SIRET de l'organisme :</Text>
                        </View>
                        <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000', borderRight: '1pt solid #000'}}>
                            <Text>{inspecteur?.organisme_siret}</Text>
                        </View>
                    </View>
                    
                    <View style={{display: 'flex', flexDirection: 'row', marginTop: '-1pt', borderBottom: '1pt solid #000'}}>
                        <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000'}}>
                            <Text>Adresse 1 de l'organisme :</Text>
                        </View>
                        <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000', borderRight: '1pt solid #000'}}>
                            <Text>{inspecteur?.organisme_adresse}</Text>
                        </View>
                    </View>
                    
                    <View style={{display: 'flex', flexDirection: 'row', marginTop: '-1pt', borderBottom: '1pt solid #000'}}>
                        <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000'}}>
                            <Text>Adresse 2 de l'organisme :</Text>
                        </View>
                        <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000', borderRight: '1pt solid #000'}}>
                            <Text></Text>
                        </View>
                    </View>
                    
                    <View style={{display: 'flex', flexDirection: 'row', marginTop: '-1pt', borderBottom: '1pt solid #000'}}>
                        <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000'}}>
                            <Text>Code postal de l'organisme :</Text>
                        </View>
                        <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000', borderRight: '1pt solid #000'}}>
                            <Text>{inspecteur?.organisme_cp}</Text>
                        </View>
                    </View>
                    
                    <View style={{display: 'flex', flexDirection: 'row', marginTop: '-1pt', borderBottom: '1pt solid #000'}}>
                        <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000'}}>
                            <Text>Commune de l'organisme :</Text>
                        </View>
                        <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000', borderRight: '1pt solid #000'}}>
                            <Text>{inspecteur?.organisme_commune.toUpperCase()}</Text>
                        </View>
                    </View>
                    
                    <View style={{display: 'flex', flexDirection: 'row', marginTop: '-1pt', borderBottom: '1pt solid #000'}}>
                        <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000'}}>
                            <Text>Téléphone de l'organisme :</Text>
                        </View>
                        <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000', borderRight: '1pt solid #000'}}>
                            <Text>{inspecteur?.organisme_tel}</Text>
                        </View>
                    </View>
                    
                    <View style={{display: 'flex', flexDirection: 'row', marginTop: '-1pt', borderBottom: '1pt solid #000'}}>
                        <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000'}}>
                            <Text>Courriel de l'organisme :</Text>
                        </View>
                        <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000', borderRight: '1pt solid #000'}}>
                            <Text>{inspecteur?.organisme_email}</Text>
                        </View>
                    </View>
                    
                    <View style={{display: 'flex', flexDirection: 'row', marginTop: '-1pt', borderBottom: '1pt solid #000'}}>
                        <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000'}}>
                            <Text>Site web de l'organisme :</Text>
                        </View>
                        <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000', borderRight: '1pt solid #000'}}>
                            <Text>{inspecteur?.organisme_url}</Text>
                        </View>
                    </View>
                    
                    <View style={{display: 'flex', flexDirection: 'row', marginTop: '-1pt', borderBottom: '1pt solid #000'}}>
                        <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000'}}>
                            <Text>Civilité et nom de l'inspecteur :</Text>
                        </View>
                        <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000', borderRight: '1pt solid #000'}}>
                            <Text>{inspecteur.civilite} {inspecteur.first_name} {inspecteur.last_name}</Text>
                        </View>
                    </View>
                </View>
                <View style={{fontSize: '8pt'}}>
                    <Text>(*) Données relatives à l'organisme visé au 1° ou au 2° de l'article L.324-1 du code du tourisme</Text>
                </View>
            </View>

            <View style={{ fontSize: '11pt' }}>
                <View style={{display: 'flex', flexDirection: 'row', backgroundColor: "#000000", fontWeight: 'bold'}}>
                    <View style={{padding: '1pt', width: '50%', color: "#ffffff", border: '1pt solid #000'}}><Text>Champs</Text></View>
                    <View style={{padding: '1pt', width: '50%', color: "#ffffff", border: '1pt solid #000'}}><Text>Caractéristiques du champ</Text></View>
                </View>
                
                <View style={{display: 'flex', flexDirection: 'row', marginTop: '-1pt', borderBottom: '1pt solid #000'}}>
                    <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000'}}>
                        <Text>Informations et justifications sur ce qui n'a pas été réalisé par rapport au travail initialement prévu :</Text>
                    </View>
                    <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000', borderRight: '1pt solid #000'}}>
                        <Text>/</Text>
                    </View>
                </View>
                
                <View style={{display: 'flex', flexDirection: 'row', marginTop: '-1pt', borderBottom: '1pt solid #000'}}>
                    <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000'}}>
                        <Text>Tout ou partie du travail d'inspection a-t-il été sous- traité?</Text>
                    </View>
                    <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000', borderRight: '1pt solid #000'}}>
                        <Text>NON</Text>
                    </View>
                </View>
                
                <View style={{display: 'flex', flexDirection: 'row', marginTop: '-1pt', borderBottom: '1pt solid #000'}}>
                    <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000'}}>
                        <Text>Si oui, précisez les motifs :</Text>
                    </View>
                    <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000', borderRight: '1pt solid #000'}}>
                        <Text>/</Text>
                    </View>
                </View>
                
                <View style={{display: 'flex', flexDirection: 'row', marginTop: '-1pt', borderBottom: '1pt solid #000'}}>
                    <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000'}}>
                        <Text>Si oui, précisez le nom du sous-traitant :</Text>
                    </View>
                    <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000', borderRight: '1pt solid #000'}}>
                        <Text>/</Text>
                    </View>
                </View>
                
                <View style={{display: 'flex', flexDirection: 'row', marginTop: '-1pt', borderBottom: '1pt solid #000'}}>
                    <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000'}}>
                        <Text>Si oui, s'agit-il d'une sous-traitance interne :</Text>
                    </View>
                    <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000', borderRight: '1pt solid #000'}}>
                        <Text>/</Text>
                    </View>
                </View>
                
                <View style={{display: 'flex', flexDirection: 'row', marginTop: '-1pt', borderBottom: '1pt solid #000'}}>
                    <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000'}}>
                        <Text>Si oui, s'agit-il d'une sous-traitance externe :</Text>
                    </View>
                    <View style={{padding: '2pt', width: '50%', borderLeft: '1pt solid #000', borderRight: '1pt solid #000'}}>
                        <Text>/</Text>
                    </View>
                </View>
            </View>

        </Page>
    )
}

export default PageInformationsGenerales;