import { useState, useEffect, useContext } from '@wordpress/element';
import { UserContext } from '@context';
import { useExports, useFetch } from '@hooks';
import { CSVLink } from "react-csv";
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { Button } from '@composants/button';
import { ReactComponent as IconClose } from '@images/close.svg';

/**
 * 
 * @param {string} entity L'entité à exporter
 * @param {Array} defaultCols Les colonnes à exporter par défaut 
 * @returns 
 */
const ModaleExport = ({ show, setShow, entity, ids, defaultCols, ajax }) => {

    const { user } = useContext(UserContext);
    const { getEntityData } = useExports();
    const { fetchArchivesMeubles } = useFetch();

    //entites NON ARCHIVE : supprimer de l'export les colonnes archivages
    const isArchive = window.location.pathname.startsWith('/archives/');
    const statusLabel = isArchive ? 'Archives_' : '';

    const [colonnes, setColonnes] = useState(defaultCols);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [exportedData, setExportedData] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const getAllColsSelected = () => {
        const allIsSelected = colonnes.filter(colonne => colonne.selected === true);
        return allIsSelected.length === defaultCols.length;
    }

    const onColonne = (colonne) => {
        const nextCols = [...colonnes];
        const target = nextCols.find(col => col.id === colonne.id);
        target.selected = !target.selected;
        setIsAllSelected(getAllColsSelected());
        setColonnes(nextCols);
    }

    const toggleSelectAll = () => {
        
        const nextCols = [...colonnes];
        
        if(isAllSelected){
            nextCols.map(colonne => colonne.selected = false);
            setIsAllSelected(false);
        } else {
            nextCols.map(colonne => colonne.selected = true);
            setIsAllSelected(true);
        }

        setColonnes(nextCols);
    }

    useEffect(() => {
        if(!isArchive){
            const nextCols = defaultCols.reduce((result, col) => {
                if(!col.id.startsWith('archivage')) result.push(col);
                return result;
            }, []);
            setColonnes(nextCols);
        }
    }, []);

    useEffect(() => {

        const getDataFromServer = async () => {

            const colsIds = colonnes.reduce((result, c) => {
                if(c.selected) result.push(c.id);
                return result;
            }, []);

            const colsLabels = colonnes.reduce((result, col) => {
                if(col.selected){
                    result.push(col.label);
                }
                return result;
            }, []);

            const loadedData = await fetchArchivesMeubles(user.id, ids.join(','), colsIds);
            const rows = loadedData.reduce((result, row) => {
                const nextRow = colsIds.map(col => row[col]);
                result.push(nextRow);
                return result;
            }, []);

            return [
                colsLabels,
                ...rows
            ];
        }

        const loadDataToExport = async() => {
            const csvData = !ajax ? 
                await getEntityData(entity, ids, colonnes) : 
                await getDataFromServer();

            setExportedData(csvData);
            setIsLoading(false);
        }

        loadDataToExport();
        setIsLoading(true);

    }, [colonnes, ids]);

    /** Nom du fichier exporté */
    const date = new Date();
    const jour = date.getDate() > 9 ? date.getDate().toString() : `0${date.getDate().toString()}`;
    const mois = (date.getMonth() + 1) > 9 ? (date.getMonth() + 1).toString() : `0${(date.getMonth() + 1).toString()}`;
    const annee = date.getFullYear().toString().slice(-2);
    const nomFichier = entity === 'visites' ? `ExportBDD_MT_${jour}_${mois}_${annee}.csv` : `Export_${statusLabel}MEUBLES_${jour}_${mois}_${annee}.csv`;
    console.log(nomFichier, jour, mois, annee, entity);

    return(
        <Modal show={show} onHide={() => setShow(false)} scrollable>
            <Modal.Header>
                <Modal.Title>Exporter les visites</Modal.Title>
                <button className='btn-close' data-bs-dismiss="modal" aria-label="Close">
                    <IconClose onClick={() => setShow(false)} />
                </button>
            </Modal.Header>
            <Modal.Body>
                <div className='modal-body-top'>
                    <p className='body-2'>Sélectionnez les colonnes que vous souhaitez exporter</p>
                    <div className='d-flex justify-content-end'>
                        <Form.Check type='switch' name='tout' label={isAllSelected ? "Tout désélectionner" : "Tout sélectionner"} onChange={toggleSelectAll} checked={isAllSelected} reverse />
                    </div>
                </div>
                <div className='modal-body-main'>
                    <div className='d-flex flex-column gap-3'>
                        {colonnes.map(colonne => <Form.Check 
                            key={colonne.id} 
                            id={colonne.id} 
                            type='switch' 
                            name='colonnes' 
                            label={colonne.label} 
                            onChange={() => onColonne(colonne)} 
                            checked={colonne.selected} 
                        />)}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline" name="cancel" onClick={() => setShow(false)}>Annuler</Button>
                {isLoading && <Button variant="disabled">
                    <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                    <span className='ps-2' role="status">Chargement…</span>
                </Button>}
                {(exportedData && !isLoading) && <CSVLink 
                    className={exportedData.length > 1 ? `btn` : `btn disabled`}
                    role="button" 
                    aria-disabled={!exportedData[1]} 
                    data={exportedData} 
                    separator={";"} 
                    enclosingCharacter={``} 
                    filename={nomFichier} 
                    target="_blank" 
                    onClick={e => exportedData.length > 1}
                >Exporter</CSVLink>}
            </Modal.Footer>
        </Modal>
    );
}

export default ModaleExport;