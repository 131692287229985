import { useState, useEffect } from '@wordpress/element';
import { useUser } from '@context';
import Modal from 'react-bootstrap/Modal';
import config from "@config";
import { Table } from 'react-bootstrap';
import {Button} from "@composants/button";
import { ReactComponent as IconClose } from '@images/close.svg';
import { useUtiles } from '@hooks';

const ModaleRevision = ({ show, setShow, uid, entite }) => {

    const [listRevision, setListRevision] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(true);
    const { user } = useUser();
    const { dateUtcToLocalDate } = useUtiles();

    // Fonction à appeler lors de l'ouverture de la modale
    const handleOpenModal = () => {
        const url = config.api(user.id).get.revisions+"&"+entite+"="+uid;
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Cache-Control': 'no-cache',
            },
        })
            .then(response => response.json())
            .then(response => {
                if (response.success) {
                    //console.log(response);
                    if(response.data.revisions) {
                        setListRevision(response.data.revisions);
                    }
                }
            });
    };
    // Utiliser useEffect pour appeler la fonction lors de l'ouverture de la modale
    useEffect(() => {
        if (modalIsOpen) {
            setShow(true);
            handleOpenModal();
        }
    }, [modalIsOpen]);

    return(
        <Modal show={show} onHide={() => setShow(false)} size="lg" scrollable>
            <Modal.Header>
                <Modal.Title>Historique des actions</Modal.Title>
                <button className='btn-close' data-bs-dismiss="modal" aria-label="Close">
                    <IconClose onClick={() => setShow(false)} />
                </button>
            </Modal.Header>
            <Modal.Body className='text-center'>
                <Table responsive className={`'-highlight`}>
                    <thead>
                        <tr>
                            <td>Action</td>
                            <td>Inspecteur</td>
                            <td>Commentaire</td>
                            <td>Date</td>
                        </tr>
                    </thead>
                    <tbody>
                    {listRevision.map(row => {
                        let row_action;
                        switch (row.action) {
                            case 'update':
                                row_action = "modification";
                                break;
                            case 'archive':
                                row_action = "archivage";
                                break;
                            default:
                                row_action = row.action;
                                break;
                        }
                        return <tr key={row.id}>
                            <td className='caption'>{row_action}</td>
                            <td className='caption'>{row.user_name}</td>
                            <td className='caption'><i>{row.raison}</i></td>
                            <td className='caption'>{dateUtcToLocalDate(row.date_action)}</td>
                        </tr>
                    })}
                    </tbody>
                </Table>
            </Modal.Body>
        </Modal>
    );
}

export default ModaleRevision;