import { useState } from '@wordpress/element';
import { useDB } from '@hooks';
import config from '@config';

const useFetch = () => {

    const { db } = useDB();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchData = async (userid, type) => {

        setIsLoading(true);

        const url = config.api(userid).get[type];

        const requestHeaders = new Headers();

        const requestOptions = {
            method: 'GET',
            headers: requestHeaders, 
        }

        try {
            const response = await fetch(url, requestOptions);
            const result = await response.json();
            setIsLoading(false);
            return result.data[type];
        } catch (error) {
            setError(error);
            setIsLoading(false);
            return error;
        }

    }

    const fetchAllData = async (userid) => {

        const urls = {
            users: config.api(userid).get.users, 
            contacts: config.api(userid).get.contacts, 
            meubles: config.api(userid).get.meubles, 
            visites: config.api(userid).get.visites, 
        }

        try {
            const responses = await Promise.all(Object.entries(urls).map(async([ key, url ]) => {
                const result = await fetch(url);
                const json = await result.json();
                if(key === 'visites'){
                    json.data[key] = json.data[key].map(visite => {
                        if(visite.id) delete visite.id;
                        return {
                            ...visite, 
                            infos: JSON.parse(visite.infos), 
                            grille: JSON.parse(visite.grille), 
                            demandeur: JSON.parse(visite.demandeur), 
                            scelle: visite.scelle === '0' ? false : true
                        };
                    })
                }
                return [ key, json.data[key] ];
            }));

            return Object.fromEntries(responses);

        } catch(error) {
            console.log(error);
        }
    }

    const fetchEntities = async (userid, entity, ids) => {

        const urls = ids.map(id => config.api(userid, id).get[entity]);

        try {
            const responses = await Promise.all(urls.map(async(url) => {
                const result = await fetch(url);
                const json = await result.json();
                const data = json.data[entity];
                if(data) return data;
            }));

            return responses.filter(item => item !== undefined);

        } catch(error) {
            console.log(error);
            return false;
        }
    }

    const bulkFetch = async (userid, entity, ids) => {
        
        const url = config.api(userid, null, ids).get[entity];

        try {
            const result = await fetch(url);
            const json = await result.json();
            const data = json.data[entity];
            if(data) return data;
        } catch(error) {
            console.log(error);
            return false;
        }

    }

    const postEntities = async (userid, entity, entitiesData) => {

        if(entity !== 'visite') return;

        const url = config.api(userid).post[entity];
        const reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");

        try {
            
            const response = await Promise.all(entitiesData.map(async(item) => {
                const itemToUpload = {...item};
                delete itemToUpload.documents;
                delete itemToUpload.date_scelle;
                const dataBody = JSON.stringify(itemToUpload);
                const reqOptions = { method: 'POST', headers: reqHeaders, body: dataBody };
                const req = await fetch(url, reqOptions);
                const result = await req.text();
                const parsed = JSON.parse(result);
                if(!item.infos.saved && parsed.success){
                    item.infos.saved = true;
                    await db.visites.put(item);
                }
                return result;
            }));

            return response;

        } catch(error) {
            console.log(error);
        }
    }

    const postVisite = async (userid, visite) => {

        const url = config.api(userid).post.visite;

        const reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");

        const visiteToPost = structuredClone(visite);
        delete visiteToPost.documents;
        delete visiteToPost.date_scelle;
        const dataBody = JSON.stringify(visiteToPost);
        const reqOptions = { method: 'POST', headers: reqHeaders, body: dataBody };
        const req = await fetch(url, reqOptions);
        const result = await req.text();
        const parsed = JSON.parse(result);
        
        return parsed;
    }

    const fetchComparableEntities = async (userid) => {

        const urls = config.api(userid).comparableEntities;

        try {
            
            const responses = await Promise.all(Object.entries(urls).map(async([ key, url ]) => {
                const result = await fetch(url);
                const response = await result.json();
                return [ key, response.data ];
            }));

            return Object.fromEntries(responses);

        } catch(error) {
            console.log(error);
        }

    }

    const postVisiteFile = async (userid, uid, file) => {

        const postUrl = config.api(userid, uid).post.visite_file_upload;

        const fileData = new FormData();
        fileData.append('file', file, `${file.name}`);
        
        const postHeaders = new Headers();
        
        const postOptions = {
            method: 'POST',
            body: fileData,
            headers: postHeaders
        }

        try {
            const response = await fetch(postUrl, postOptions);
            const result = await response.json();
            return result.data;
        } catch(error) {
            console.log(error);
        }
    }

    const deleteFile = async (userid, iddoc) => {
        const deleteUrl = config.api(userid, iddoc).del.visite_file_delete;
        try{
            const response = await fetch(deleteUrl, { method: 'DELETE' });
            const result = await response.json();
            return result;
        } catch(error) {
            console.log(error);
        }
    }
    
    const deleteEntity = async (userid, id, entity) => {

        return actionEntities(userid, [id], entity, 'simple', '', 'jeter');

        // let deleteUrl;
        
        // switch(entity){
        //     case 'meuble':
        //         deleteUrl = config.api(userid, id).del.meuble_delete;
        //         break;
        //     case 'contact':
        //         deleteUrl = config.api(userid, id).del.contact_delete;
        //         break;
        //     case 'visite':
        //         deleteUrl = config.api(userid, id).del.visite_delete;
        //         break;
        // }

        // try{
        //     const response = await fetch(deleteUrl, { method: 'DELETE' });
        //     const result = await response.json();
        //     return result;
        // } catch(error) {
        //     console.log(error);
        // }
    }

    /**
     * 
     * @param {string} userid 
     * @param {Array} ids Tableau des id des entités
     * @param {string} entity Peut être 'visite', 'meuble', 'contact'
     * @param {string} mode Peut être 'simple' ou 'etendu'
     * @param {string} raison Un commentaire sur la raison de l'action
     * @param {string} action Peut être 'jeter', 'archiver', 'restaurer'
     * 
     * @returns {Object} La réponse du serveur avec le tableau des messages pour chaque item
     */
    const actionEntities = async (userid, ids, entity, mode, raison='', action) => {

        const url = config.api(userid).archivage[action];

        const reqHeaders = new Headers();
        reqHeaders.append("Content-Type", 'application/x-www-form-urlencoded');

        const data = {
            entite: entity,
            entites: ids,
            mode: mode,
            raison: raison
        }

        try {
            const reqOptions = { method: 'POST', headers: reqHeaders, body: new URLSearchParams(data) };
            const req = await fetch(url, reqOptions);
            const response = await req.json();
            return response;
        }
        catch(error) {
            console.warn(error);
        }

    }

    /**
     * 
     * Permet de récupérer le numéro de classement du meublé dont l'id est passé en paramètre.
     * 
     * @param {string} userid identifiant de l'utilisateur
     * @param {string} id id du meublé
     * @returns {object} Objet contenant le résultat de la requête
     */
    const getNumClassement = async (userid, id) => {

        const url = config.api(userid, id).get.meuble_num_classement;

        try {
            const response = await fetch(url, { method: 'GET' });
            /**
             * result est un objet avec 2 parametres
             * @param {boolean} success indique si la requête a réussi ou pas.
             * @param {object} data objet retourné par la requête.
             * @param {string} data.num_classement le numéro de classement retourné si success est à true.
             * @param {string} data.error le message d'erreur si success est à false.
             */
            const result = await response.json();
            return result;
        } catch (error) {
            setError(error);
            return error;
        }
    }

    const fetchArchivesMeubles = async (userid, ids, champs) => {

        const url = config.api(userid, '', '', {ids: ids, champs: champs}).get.meubles_export;

        try {
            const response = await fetch(url, { method: 'GET'});
            const result = await response.json();
            return result.data.meubles;
        } catch (error) {
            setError(error);
            return error;
        }
    }

    return {
        fetchData, 
        fetchAllData, 
        fetchEntities, 
        bulkFetch, 
        postEntities, 
        postVisite, 
        fetchComparableEntities, 
        postVisiteFile, 
        deleteFile,
        deleteEntity, 
        actionEntities, 
        getNumClassement, 
        fetchArchivesMeubles, 
        isLoading, 
        error
    };
}

export default useFetch;