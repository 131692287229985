import { useState, useEffect, useContext } from '@wordpress/element';
import { UserContext } from '@context';
import { useVisitesActions, useMeubles } from '@hooks';
import { CSVLink } from "react-csv";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const ButtonExportCsv = ({ selection, type }) => {

    const { user } = useContext(UserContext);
    const { getVisitesCSVData } = useVisitesActions();
    const { getMeublesCSVData } = useMeubles();

    const [csvData, setCsvData] = useState();
    const [errors, setErrors] = useState([]);

    const getTooltip = (showErrors) => {
        return <Tooltip>
            <div className='d-flex flex-column gap-3 py-3'>
            {showErrors ?
                errors.map(error => <span key={error}>{error}</span>) :
                <span>Seules les visites dont le meublé est lié à&nbsp;un <strong>propriétaire</strong> seront exportées</span>
            }
            </div>
        </Tooltip>
    };

    useEffect(() => {

        const nextErrors = [];

        const onSelection = async (ids) => {
            if(type === 'visite'){
                getVisitesCSVData(ids).then(data => setCsvData(data));
            } else {
                getMeublesCSVData(ids).then(data => setCsvData(data));
            }
        }

        if(selection && selection.length){
            onSelection(selection);
        } else {
            setCsvData(null);
            nextErrors.push(type === 'visite' ? "Veuillez sélectionner des visites" : type === 'meuble' ? "Veuillez sélectionner des meublés" : "Veuillez effectuer une sélection");
        }

        if(nextErrors.length) {
            setErrors(nextErrors);
        } else {
            setErrors(null);
        }

    }, [selection, user]);

    if(errors) return(
        <OverlayTrigger key="errors-overlay" overlay={getTooltip(true)} placement='auto'>
            <button key="btn-export" className='btn disabled'>{type === 'visite' ? "Exporter au format CLASS" : "Exporter"}</button>
        </OverlayTrigger>
    );

    if(!csvData && !errors) return(<button key="btn-export" className='btn disabled'>Chargement des données…</button>)

    if(csvData && !errors){
        if(type === 'visite'){
            return(
                <OverlayTrigger key="message-overlay" overlay={getTooltip(false)} placement='auto'>
                    <CSVLink 
                        key="link"
                        className={csvData.length > 1 ? `btn` : `btn disabled`}
                        role="button" 
                        aria-disabled={!csvData[1]} 
                        data={csvData} 
                        separator={";"} 
                        enclosingCharacter={``} 
                        filename={'export-visites-CLASS.csv'} 
                        target="_blank" 
                        onClick={e => csvData.length > 1}
                    >Exporter au format CLASS</CSVLink>
                </OverlayTrigger>
            );
        } else {
            return(
                <CSVLink 
                    key="link"
                    className={csvData.length > 1 ? `btn` : `btn disabled`}
                    role="button" 
                    aria-disabled={!csvData[1]} 
                    data={csvData} 
                    separator={";"} 
                    enclosingCharacter={``} 
                    filename={'export-visites-CLASS.csv'} 
                    target="_blank" 
                    onClick={e => csvData.length > 1}
                >Exporter</CSVLink>
            );
        }
    }
}

export default ButtonExportCsv;