export const STRUCTURE = [
    {
        id: "1",
        title: "Chapitre 1 : équipements et aménagements",
        sections: [
            {
                id: "1.1",
                title: "Aménagement général",
                groupes: [
                    {
                        title: "Surfaces de l'habitation",
                        criteres: [1, 2]
                    },
                    {
                        title: "Équipement électrique de l'habitation",
                        criteres: [3, 4]
                    },
                    {
                        title: "Téléphonie et communication",
                        criteres: [5, 6, 7]
                    },
                    {
                        title: "Télévision et équipement hi-fi",
                        criteres: [8, 9, 10, 11, 12, 13]
                    },
                    {
                        title: "Équipements pour le confort du client",
                        criteres: [14, 15, 16, 17, 18, 19, 20, 21, 22]
                    },
                    {
                        title: "Mobiliers",
                        criteres: [23, 24, 25, 26, 27]
                    }
                ]
            },
            {
                id: "1.2",
                title: "Aménagement des chambres",
                groupes: [
                    {
                        title: "Literie",
                        extra: [
                            {
                                title: "Lit(s) pour une personne :"
                            },
                            {
                                title: '-Largeur',
                                value: ["80cm", "90cm", "90cm", "90cm", "90cm"],
                            },
                            {
                                title: "-Longueur",
                                value: ["190cm", "190cm", "190cm", "200cm", "200cm"]
                            },
                            {
                                title: "Lit(s) pour deux personnes :"
                            },
                            {
                                title: '-Largeur',
                                value: ["140cm", "140cm", "140cm", "160cm", "160cm"],
                            },
                            {
                                title: "-Longueur",
                                value: ["190cm", "190cm", "190cm", "200cm", "200cm"]
                            },
                        ],
                        criteres: [28, 29, 30, 31, 32]
                    },
                    {
                        title: "Équipements et mobiliers (dont électriques)",
                        criteres: [33, 34, 35, 36]
                    }
                ]
            },
            {
                id: "1.3",
                title: "Équipements et aménagement des sanitaires",
                groupes: [
                    {
                        title: "Niveau d'équipements sanitaires requis pour les logements jusqu'à % personnes inclus",
                        replace: ['6', '6', '6', '6', '4'],
                        criteres: [37, 38, 39, 40, 41, 42]
                    },
                    {
                        title: " Niveau d'équipements sanitaires requis pour les logements à partir de % personnes",
                        replace: ['7', '7', '7', '7', '5'],
                        criteres: [43, 44, 45]
                    },
                    {
                        title: "Équipements salle(s) d'eau",
                        criteres: [46, 47, 48, 49, 50, 51, 52, 53, 54, 55]
                    }
                ]
            },
            {
                id: "1.4",
                title: "Équipements et aménagement de la cuisine ou du coin cuisine",
                groupes: [
                    {
                        title: "Bac(s) à laver",
                        criteres: [56]
                    },
                    {
                        title: "Appareils de cuisson",
                        extra: [
                            {
                                title: "Table de cuisson pour logement jusqu'à 4 personnes (inclus)",
                                value: ["2 foyers", "2 foyers", "4 foyers", "4 foyers", "4 foyers"]
                            },
                            {
                                title: "Table de cuisson pour logement à partir de 5 personnes",
                                value: ["4 foyers", "4 foyers", "4 foyers", "4 foyers", "4 foyers"]
                            }
                        ],
                        criteres: [57, 58, 59, 60, 61, 62]
                    },
                    {
                        title: "Vaisselle et matériels de cuisson",
                        criteres: [63, 64, 65, 66, 67]
                    },
                    {
                        title: "Autres matériels",
                        criteres: [68, 69, 70, 71, 72, 73, 74, 75, 76]
                    }
                ]
            },
            {
                id: "1.5",
                title: "Environnement et extérieurs",
                groupes: [
                    {
                        title: "Ascenseurs",
                        criteres: [77, 78]
                    },
                    {
                        title: "Parking voiture",
                        criteres: [79, 80, 81]
                    },
                    {
                        title: "Balcon, loggia, terrasse, jardin",
                        criteres: [82, 83, 84, 85, 86]
                    },
                    {
                        title: "Équipements de loisirs, détente, sports, attachés au logement",
                        criteres: [87, 88, 89, 90, 91]
                    },
                    {
                        title: "Environnement",
                        criteres: [92, 93, 94]
                    }
                ]
            },
            {
                id: "1.6",
                title: "État et propreté des installations et des équipements",
                criteres: [95, 96, 97, 98, 99]
            }
        ]
    },
    {
        id: "2",
        title: "Chapitre 2 : services aux clients",
        sections: [
            {
                id: "2.1",
                title: "Qualité et fiabilité de l'information client",
                criteres: [100, 101]
            },
            {
                id: "2.2",
                title: "Les services proposés",
                criteres: [102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115]
            }
        ]
    },
    {
        id: "3",
        title: "Chapitre 3 : accessibilité et développement durable",
        sections: [
            {
                id: "3.1",
                title: "Accessibilité",
                groupes: [
                    {
                        title: "Information, sensibilisation",
                        criteres: [116]
                    },
                    {
                        title: "Autres services",
                        criteres: [117, 118, 119, 120, 121, 122]
                    }
                ]
            },
            {
                id: "3.2",
                title: "Développement durable",
                criteres: [123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133]
            }
        ]
    }
]

export const GRILLE = [
    {
        id: 1,
        statut: "X",
        points: 5,
    },
    {
        id: 2,
        statut: "O",
        points: 0,
    },
    {
        id: 3,
        statut: "X",
        points: 1,
    },
    {
        id: 4,
        statut: "X",
        points: 3,
    },
    {
        id: 5,
        statut: "O",
        points: 1,
    },
    {
        id: 6,
        statut: "O",
        points: 2,
    },
    {
        id: 7,
        statut: "O",
        points: 2,
    },
    {
        id: 8,
        statut: "O",
        points: 2,
    },
    {
        id: 9,
        statut: "O",
        points: 2,
    },
    {
        id: 10,
        statut: "O",
        points: 1,
    },
    {
        id: 11,
        statut: "O",
        points: 2,
    },
    {
        id: 12,
        statut: "O",
        points: 1,
    },
    {
        id: 13,
        statut: "O",
        points: 2,
    },
    {
        id: 14,
        statut: "X",
        points: 3,
    },
    {
        id: 15,
        statut: "O",
        points: 3,
    },
    {
        id: 16,
        statut: "X",
        points: 5,
    },
    {
        id: 17,
        statut: "O",
        points: 3,
    },
    {
        id: 18,
        statut: "O",
        points: 3,
    },
    {
        id: 19,
        statut: "O",
        points: 2,
    },
    {
        id: 20,
        statut: "X",
        points: 2,
    },
    {
        id: 21,
        statut: "X",
        points: 3,
    },
    {
        id: 22,
        statut: "O",
        points: 2,
    },
    {
        id: 23,
        statut: "X",
        points: 3,
    },
    {
        id: 24,
        statut: "O",
        points: 3,
    },
    {
        id: 25,
        statut: "X",
        points: 4,
    },
    {
        id: 26,
        statut: "X",
        points: 3,
    },
    {
        id: 27,
        statut: "X",
        points: 1,
    },
    {
        id: 28,
        statut: "X",
        points: 4,
    },
    {
        id: 29,
        statut: "O",
        points: 2,
    },
    {
        id: 30,
        statut: "X",
        points: 2,
    },
    {
        id: 31,
        statut: "X",
        points: 2,
    },
    {
        id: 32,
        statut: "X",
        points: 2,
    },
    {
        id: 33,
        statut: "X",
        points: 2,
    },
    {
        id: 34,
        statut: "O",
        points: 2,
    },
    {
        id: 35,
        statut: "O",
        points: 1,
    },
    {
        id: 36,
        statut: "O",
        points: 2,
    },
    {
        id: 37,
        statut: "X",
        points: 2,
    },
    {
        id: 38,
        statut: "X",
        points: 3,
    },
    {
        id: 39,
        statut: "X",
        points: 3,
    },
    {
        id: 40,
        statut: "O",
        points: 2,
    },
    {
        id: 41,
        statut: "X",
        points: 2,
    },
    {
        id: 42,
        statut: "O",
        points: 2,
    },
    {
        id: 43,
        statut: "X",
        points: 5,
    },
    {
        id: 44,
        statut: "X",
        points: 3,
    },
    {
        id: 45,
        statut: "X",
        points: 2,
    },
    {
        id: 46,
        statut: "O",
        points: 2,
    },
    {
        id: 47,
        statut: "O",
        points: 3,
    },
    {
        id: 48,
        statut: "O",
        points: 2,
    },
    {
        id: 49,
        statut: "X",
        points: 1,
    },
    {
        id: 50,
        statut: "O",
        points: 2,
    },
    {
        id: 51,
        statut: "X",
        points: 2,
    },
    {
        id: 52,
        statut: "O",
        points: 2,
    },
    {
        id: 53,
        statut: "X",
        points: 2,
    },
    {
        id: 54,
        statut: "O",
        points: 2,
    },
    {
        id: 55,
        statut: "O",
        points: 1,
    },
    {
        id: 56,
        statut: "X",
        points: 3,
    },
    {
        id: 57,
        statut: "X",
        points: 3,
    },
    {
        id: 58,
        statut: "O",
        points: 2,
    },
    {
        id: 59,
        statut: "X",
        points: 3,
    },
    {
        id: 60,
        statut: "O",
        points: 2,
    },
    {
        id: 61,
        statut: "X",
        points: 4,
    },
    {
        id: 62,
        statut: "O",
        points: 2,
    },
    {
        id: 63,
        statut: "X",
        points: 3,
    },
    {
        id: 64,
        statut: "O",
        points: 1,
    },
    {
        id: 65,
        statut: "X",
        points: 3,
    },
    {
        id: 66,
        statut: "O",
        points: 2,
    },
    {
        id: 67,
        statut: "O",
        points: 3,
    },
    {
        id: 68,
        statut: "X",
        points: 2,
    },
    {
        id: 69,
        statut: "O",
        points: 2,
    },
    {
        id: 70,
        statut: "O",
        points: 1,
    },
    {
        id: 71,
        statut: "O",
        points: 1,
    },
    {
        id: 72,
        statut: "O",
        points: 2,
    },
    {
        id: 73,
        statut: "O",
        points: 2,
    },
    {
        id: 74,
        statut: "X",
        points: 4,
    },
    {
        id: 75,
        statut: "O",
        points: 2,
    },
    {
        id: 76,
        statut: "X",
        points: 1,
    },
    {
        id: 77,
        statut: "X",
        points: 4,
    },
    {
        id: 78,
        statut: "O",
        points: 4,
    },
    {
        id: 79,
        statut: "X",
        points: 4,
    },
    {
        id: 80,
        statut: "O",
        points: 3,
    },
    {
        id: 81,
        statut: "O",
        points: 2,
    },
    {
        id: 82,
        statut: "O",
        points: 2,
    },
    {
        id: 83,
        statut: "O",
        points: 3,
    },
    {
        id: 84,
        statut: "O",
        points: 4,
    },
    {
        id: 85,
        statut: "O",
        points: 2,
    },
    {
        id: 86,
        statut: "O",
        points: 2,
    },
    {
        id: 87,
        statut: "O",
        points: 2,
    },
    {
        id: 88,
        statut: "O",
        points: 2,
    },
    {
        id: 89,
        statut: "O",
        points: 2,
    },
    {
        id: 90,
        statut: "O",
        points: 2,
    },
    {
        id: 91,
        statut: "O",
        points: 1,
    },
    {
        id: 92,
        statut: "O",
        points: 2,
    },
    {
        id: 93,
        statut: "O",
        points: 3,
    },
    {
        id: 94,
        statut: "O",
        points: 3,
    },
    {
        id: 95,
        statut: "ONC",
        points: 5,
    },
    {
        id: 96,
        statut: "ONC",
        points: 5,
    },
    {
        id: 97,
        statut: "ONC",
        points: 5,
    },
    {
        id: 98,
        statut: "ONC",
        points: 5,
    },
    {
        id: 99,
        statut: "ONC",
        points: 5,
    },
    {
        id: 100,
        statut: "X",
        points: 3,
    },
    {
        id: 101,
        statut: "O",
        points: 2,
    },
    {
        id: 102,
        statut: "O",
        points: 3,
    },
    {
        id: 103,
        statut: "O",
        points: 2,
    },
    {
        id: 104,
        statut: "O",
        points: 2,
    },
    {
        id: 105,
        statut: "X",
        points: 2,
    },
    {
        id: 106,
        statut: "X",
        points: 2,
    },
    {
        id: 107,
        statut: "O",
        points: 2,
    },
    {
        id: 108,
        statut: "O",
        points: 2,
    },
    {
        id: 109,
        statut: "O",
        points: 2,
    },
    {
        id: 110,
        statut: "O",
        points: 2,
    },
    {
        id: 111,
        statut: "X",
        points: 2,
    },
    {
        id: 112,
        statut: "O",
        points: 2,
    },
    {
        id: 113,
        statut: "O",
        points: 2,
    },
    {
        id: 114,
        statut: "O",
        points: 1,
    },
    {
        id: 115,
        statut: "O",
        points: 2,
    },
    {
        id: 116,
        statut: "X",
        points: 2,
    },
    {
        id: 117,
        statut: "O",
        points: 2,
    },
    {
        id: 118,
        statut: "O",
        points: 2,
    },
    {
        id: 119,
        statut: "O",
        points: 2,
    },
    {
        id: 120,
        statut: "O",
        points: 2,
    },
    {
        id: 121,
        statut: "X",
        points: 1,
    },
    {
        id: 122,
        statut: "O",
        points: 3,
    },
    {
        id: 123,
        statut: "X",
        points: 3,
    },
    {
        id: 124,
        statut: "O",
        points: 1,
    },
    {
        id: 125,
        statut: "O",
        points: 2,
    },
    {
        id: 126,
        statut: "X",
        points: 3,
    },
    {
        id: 127,
        statut: "O",
        points: 1,
    },
    {
        id: 128,
        statut: "X",
        points: 1,
    },
    {
        id: 129,
        statut: "O",
        points: 1,
    },
    {
        id: 130,
        statut: "X",
        points: 2,
    },
    {
        id: 131,
        statut: "O",
        points: 2,
    },
    {
        id: 132,
        statut: "X",
        points: 1,
    },
    {
        id: 133,
        statut: "O",
        points: 3,
    }
];

export const GRILLE_DEFAULTS = {
    1: {
        classement: ["X", "X", "X", "X", "X"],
        // auto: true,
    },
    2: {
        classement: ["O", "O", "O", "O", "O"],
        auto: true,
    },
    3: {
        classement: ["X", "X", "X", "X", "X"],
    },
    4: {
        classement: ["X", "X", "X", "X", "X"],
    },
    5: {
        classement: ["O", "O", "O", "O", "O"],
        na: true,
    },
    6: {
        classement: ["O", "X", "X", "X", "X"],
        na: true,
    },
    7: {
        classement: ["O", "O", "O", "O", "O"],
        na: true,
    },
    8: {
        classement: ["O", "X", "X", "X", "X"],
    },
    9: {
        classement: ["O", "O", "O", "X", "X"],
    },
    10: {
        classement: ["O", "O", "O", "O", "X"],
    },
    11: {
        classement: ["O", "O", "X", "X", "X"],
    },
    12: {
        classement: ["O", "O", "O", "O", "X"],
    },
    13: {
        classement: ["O", "O", "O", "O", "X"],
    },
    14: {
        classement: ["X", "X", "X", "X", "X"],
    },
    15: {
        classement: ["O", "O", "O", "X", "X"],
        na: true,
    },
    16: {
        classement: ["X", "X", "X", "X", "X"],
        na: true,
    },
    17: {
        classement: ["O", "O", "O", "O", "X"],
        na: true,
    },
    18: {
        classement: ["O", "O", "X", "X", "X"],
        // na: true,
    },
    19: {
        classement: ["O", "O", "O", "X", "X"],
        // na: true,
    },
    20: {
        classement: ["X", "X", "X", "X", "X"],
    },
    21: {
        classement: ["X", "X", "X", "X", "X"],
    },
    22: {
        classement: ["O", "O", "X", "X", "X"],
    },
    23: {
        classement: ["X", "X", "NA", "NA", "NA"],
    },
    24: {
        classement: ["O", "O", "X", "X", "X"],
    },
    25: {
        classement: ["X", "X", "X", "X", "X"],
    },
    26: {
        classement: ["X", "X", "X", "X", "X"],
        na: true,
    },
    27: {
        classement: ["X", "X", "X", "X", "X"],
        // na: true,
    },
    28: {
        classement: ["X", "X", "X", "X", "X"],
    },
    29: {
        classement: ["O", "O", "O", "O", "O"],
    },
    30: {
        classement: ["X", "X", "X", "X", "X"],
    },
    31: {
        classement: ["X", "X", "X", "X", "X"],
    },
    32: {
        classement: ["X", "X", "X", "X", "X"],
    },
    33: {
        classement: ["X", "X", "X", "X", "X"],
    },
    34: {
        classement: ["O", "O", "O", "X", "X"],
    },
    35: {
        classement: ["O", "O", "O", "O", "O"],
    },
    36: {
        classement: ["O", "O", "X", "X", "X"],
    },
    37: {
        classement: ["X", "X", "X", "X", "X"],
    },
    38: {
        classement: ["X", "X", "X", "X", "X"],
    },
    39: {
        classement: ["X", "X", "X", "NA", "NA"],
    },
    40: {
        classement: ["O", "O", "O", "X", "X"],
    },
    41: {
        classement: ["X", "X", "X", "X", "NA"],
    },
    42: {
        classement: ["O", "O", "O", "O", "X"],
    },
    43: {
        classement: ["X", "X", "X", "X", "X"],
        // na: true,
    },
    44: {
        classement: ["X", "X", "X", "X", "X"],
        // na: true,
    },
    45: {
        classement: ["X", "X", "X", "X", "X"],
        // na: true,
    },
    46: {
        classement: ["O", "O", "X", "X", "X"],
    },
    47: {
        classement: ["O", "O", "X", "X", "X"],
    },
    48: {
        classement: ["O", "X", "X", "X", "X"],
    },
    49: {
        classement: ["X", "X", "X", "X", "X"],
    },
    50: {
        classement: ["O", "O", "O", "O", "X"],
    },
    51: {
        classement: ["X", "X", "X", "X", "X"],
    },
    52: {
        classement: ["O", "O", "O", "X", "X"],
    },
    53: {
        classement: ["X", "X", "X", "X", "X"],
    },
    54: {
        classement: ["O", "O", "X", "X", "X"],
    },
    55: {
        classement: ["O", "O", "X", "X", "X"],
    },
    56: {
        classement: ["X", "X", "X", "X", "X"],
    },
    57: {
        classement: ["X", "X", "X", "X", "X"],
    },
    58: {
        classement: ["O", "O", "O", "O", "O"],
    },
    59: {
        classement: ["X", "X", "X", "X", "X"],
    },
    60: {
        classement: ["O", "X", "X", "X", "X"],
    },
    61: {
        classement: ["X", "X", "X", "X", "X"],
    },
    62: {
        classement: ["O", "O", "O", "O", "O"],
    },
    63: {
        classement: ["X", "X", "X", "X", "X"],
    },
    64: {
        classement: ["O", "O", "O", "X", "X"],
    },
    65: {
        classement: ["X", "X", "X", "X", "X"],
    },
    66: {
        classement: ["O", "X", "X", "X", "X"],
    },
    67: {
        classement: ["O", "O", "O", "O", "O"],
    },
    68: {
        classement: ["X", "X", "X", "X", "X"],
    },
    69: {
        classement: ["O", "O", "O", "X", "X"],
    },
    70: {
        classement: ["O", "X", "X", "X", "X"],
    },
    71: {
        classement: ["O", "X", "X", "X", "X"],
    },
    72: {
        classement: ["O", "O", "O", "X", "X"],
    },
    73: {
        classement: ["O", "O", "X", "X", "X"],
        // na: true,
    },
    74: {
        classement: ["X", "X", "X", "X", "X"],
    },
    75: {
        classement: ["O", "O", "X", "X", "X"],
    },
    76: {
        classement: ["X", "X", "X", "X", "X"],
    },
    77: {
        classement: ["X", "X", "NA", "NA", "NA"],
        auto: true,
    },
    78: {
        classement: ["O", "O", "X", "X", "X"],
        auto: true,
    },
    79: {
        classement: ["X", "X", "X", "X", "X"],
        na: true,
    },
    80: {
        classement: ["O", "O", "X", "X", "X"],
        na: true,
    },
    81: {
        classement: ["O", "O", "O", "O", "O"],
    },
    82: {
        classement: ["O", "O", "O", "O", "O"],
    },
    83: {
        classement: ["O", "O", "O", "O", "O"],
    },
    84: {
        classement: ["O", "O", "O", "O", "O"],
    },
    85: {
        classement: ["O", "O", "O", "O", "O"],
    },
    86: {
        classement: ["O", "O", "O", "O", "O"],
    },
    87: {
        classement: ["O", "O", "O", "X", "X"],
    },
    88: {
        classement: ["O", "O", "O", "O", "O"],
    },
    89: {
        classement: ["O", "O", "O", "O", "O"],
    },
    90: {
        classement: ["O", "O", "O", "O", "O"],
    },
    91: {
        classement: ["O", "O", "O", "O", "O"],
        na: true,
    },
    92: {
        classement: ["O", "O", "O", "O", "O"],
    },
    93: {
        classement: ["O", "O", "O", "O", "O"],
    },
    94: {
        classement: ["O", "O", "O", "O", "O"],
    },
    95: {
        classement: ["ONC", "ONC", "ONC", "ONC", "ONC"],
    },
    96: {
        classement: ["ONC", "ONC", "ONC", "ONC", "ONC"],
    },
    97: {
        classement: ["ONC", "ONC", "ONC", "ONC", "ONC"],
    },
    98: {
        classement: ["ONC", "ONC", "ONC", "ONC", "ONC"],
    },
    99: {
        classement: ["ONC", "ONC", "ONC", "ONC", "ONC"],
    },
    100: {
        classement: ["X", "X", "X", "X", "X"],
    },
    101: {
        classement: ["O", "O", "X", "X", "X"],
    },
    102: {
        classement: ["O", "X", "X", "X", "X"],
    },
    103: {
        classement: ["O", "O", "O", "X", "X"],
    },
    104: {
        classement: ["O", "O", "O", "O", "O"],
    },
    105: {
        classement: ["X", "X", "X", "X", "X"],
    },
    106: {
        classement: ["X", "X", "X", "X", "X"],
    },
    107: {
        classement: ["O", "O", "X", "X", "X"],
    },
    108: {
        classement: ["O", "O", "O", "X", "X"],
    },
    109: {
        classement: ["O", "O", "X", "X", "X"],
    },
    110: {
        classement: ["O", "X", "X", "X", "X"],
    },
    111: {
        classement: ["X", "X", "X", "X", "X"],
    },
    112: {
        classement: ["O", "O", "O", "X", "X"],
    },
    113: {
        classement: ["O", "O", "O", "X", "X"],
        auto: true,
    },
    114: {
        classement: ["O", "O", "O", "X", "X"],
    },
    115: {
        classement: ["O", "O", "O", "O", "O"],
    },
    116: {
        classement: ["X", "X", "X", "X", "X"],
    },
    117: {
        classement: ["O", "O", "O", "O", "O"],
    },
    118: {
        classement: ["O", "O", "O", "O", "O"],
    },
    119: {
        classement: ["O", "O", "O", "O", "O"],
    },
    120: {
        classement: ["O", "O", "O", "O", "O"],
    },
    121: {
        classement: ["X", "X", "X", "X", "X"],
    },
    122: {
        classement: ["O", "O", "O", "O", "O"],
    },
    123: {
        classement: ["X", "X", "X", "X", "X"],
    },
    124: {
        classement: ["O", "O", "O", "O", "O"],
    },
    125: {
        classement: ["O", "O", "O", "O", "O"],
    },
    126: {
        classement: ["X", "X", "X", "X", "X"],
    },
    127: {
        classement: ["O", "O", "O", "O", "O"],
    },
    128: {
        classement: ["X", "X", "X", "X", "X"],
        na: true,
    },
    129: {
        classement: ["O", "O", "O", "O", "O"],
    },
    130: {
        classement: ["X", "X", "X", "X", "X"],
    },
    131: {
        classement: ["O", "O", "O", "O", "O"],
    },
    132: {
        classement: ["X", "X", "X", "X", "X"],
    },
    133: {
        classement: ["O", "O", "O", "O", "O"],
    }
};

export const GRILLE_TEXTES = {
    1: {
        title: "Surface totale minimum (cuisine et coin cuisine compris) du logement meublé hors salle d'eau et toilettes",
        aide: `Ne sont prises en compte que les superficies existantes sous une hauteur de plafond de 1,80m. Une pièce d'habitation doit comporter un ouvrant sur l'extérieur.
        Pour les catégories 1, 2 et 3 étoiles seulement, et pour les logements T2 et plus, les surfaces habitables requises peuvent être globalisées (réparties différemment par pièce) hors sanitaires dans la limite de 7m² minimum par pièce.`,
    },
    2: {
        title: "Surface totale majorée",
        aide: `Bonification de 1 point par tranche de 20 % de surface supplémentaire (de 1 à 5 points).
        Ne sont prises en compte que les superficies existantes sous une hauteur de plafond de 1,80m. Une pièce d'habitation doit comporter un ouvrant sur l'extérieur.
        Le critère peut être validé indépendamment du précédent.`,
    },
    3: {
        title: "Prise de courant libre dans chaque pièce d'habitation",
        aide: ``,
    },
    4: {
        title: "Tous les éclairages du logement fonctionnent et sont en bon état",
        aide: ``,
    },
    5: {
        title: "Mise à disposition d'un téléphone privatif à l'intérieur du logement",
        aide: `Téléphone en commun non accepté.
        Sauf impossibilité technique (ex. : zones blanches justifiées), alors le critère est non applicable.`,
    },
    6: {
        title: "Accès internet par un réseau local sans fil (WiFi)",
        aide: `Sauf impossibilité technique (ex. : zones blanches justifiées), alors le critère est non applicable.`,
    },
    7: {
        title: "Accès internet filaire avec câble fourni",
        aide: `Sauf impossibilité technique (ex : zones blanches justifiées), alors le critère est non applicable.`,
    },
    8: {
        title: "Télévision à écran plat avec télécommande",
        aide: ``,
    },
    9: {
        title: "Accès à des chaînes supplémentaires à l'offre de la TNT",
        aide: ``,
    },
    10: {
        title: "Possibilité d'accéder à au moins deux chaînes internationales",
        aide: ``,
    },
    11: {
        title: "Radio",
        aide: ``,
    },
    12: {
        title: "Enceinte connectée",
        aide: ``,
    },
    13: {
        title: "Mise à disposition d'un système de lecture de vidéos",
        aide: ``,
    },
    14: {
        title: "Occultation opaque : extérieure ou intérieure dans chaque pièce comportant un couchage principal",
        aide: ``,
    },
    15: {
        title: "Le logement est équipé de double vitrage",
        aide: `Ce critère est non applicable en cas d'impossibilité architecturale et/ ou impossibilité locale justifiée.`,
    },
    16: {
        title: "Existence d'un système de chauffage en état de fonctionnement",
        aide: `Un système de chauffage présent dans toutes les pièces d'habitation y compris la (ou les) salle(s) d'eau. Sauf exception justifiée par le climat (DROM-COM), alors le critère est non applicable.`,
    },
    17: {
        title: "Existence d'un système de climatisation et / ou de rafraîchissement d'air en état de fonctionnement",
        aide: `Sauf Saint-Pierre et Miquelon et haute montagne (>1000 mètres d'altitude) alors le critère est non applicable. A vérifier par tout moyen.`,
    },
    18: {
        title: "Machine à laver le linge pour les logements de 4 personnes et plus",
        aide: `Pour les catégories 1 et 2 étoiles, équipement pouvant être commun à plusieurs logements, avec accès indépendant, libre et gratuit. Pour les catégories 3, 4 et 5 étoiles, lave-linge privatif obligatoire - tolérance dans un local privatif extérieur au logement.`,
    },
    19: {
        title: "Séche-linge électrique pour les logements de 6 personnes et plus",
        aide: `Pour les catégories 1, 2 et 3 étoiles, équipement pouvant être commun à plusieurs logements avec accès indépendant et accessible librement et gratuitement.
        Pour les catégories 4 et 5 étoiles, sèche-linge privatif obligatoire - tolérance dans un local privatif extérieur au logement. Sauf exception justifiée par le climat (DROM-COM), alors le critère est non applicable.`,
    },
    20: {
        title: "Étendoir ou séchoir à linge à l'intérieur du logement",
        aide: ``,
    },
    21: {
        title: "Ustensiles de ménage appropriés au logement (minimum : un seau et un balai à brosse avec serpillière ou un balai de lavage à frange avec seau et presse, aspirateur ou équipement équivalent)",
        aide: ``,
    },
    22: {
        title: "Fer et table à repasser",
        aide: ``,
    },
    23: {
        title: "Placards ou éléments de rangement dans le logement",
        aide: ``,
    },
    24: {
        title: "Placards ou éléments de rangement dans chaque pièce d'habitation",
        aide: `Obligatoire dans chaque pièce d'habitation pour les catégories 3, 4 et 5 étoiles.`,
    },
    25: {
        title: "Présence d'une table et d'assises correspondant à la capacité d'accueil du logement",
        aide: ``,
    },
    26: {
        title: "Présence d'un canapé ou fauteuil(s) adapté(s) à la capacité d'accueil",
        aide: `Le critère devient non applicable s'il n'y a pas de séjour.`,
    },
    27: {
        title: "Présence d'une table basse",
        aide: `Le critère devient non applicable dans le cas de studios.`,
    },
    28: {
        title: "Respect des dimensions du (ou des) lit(s)",
        aide: `Tolérance pour :
        - lits superposés (80cm x 190cm)
        - lits jumeaux ou lits gigogne (80cm x 190cm) pouvant être assemblés en lit double de 160 cm - canapé-lits convertibles (130cm x 190cm) - uniquement pour les studios de catégorie 1* et 2* - lits escamotables pour les studios (toutes catégories)`,
    },
    29: {
        title: "Matelas haute densité et / ou avec une épaisseur de qualité",
        aide: `La densité doit être supérieure à 30 kg/m³ ou équivalent. L'épaisseur doit être minimum de 21 cm.`,
    },
    30: {
        title: "Présence d'oreiller(s) en quantité suffisante",
        aide: `Un oreiller par personne pour les catégories 1 et 2 étoiles. Deux oreillers par personne pour les catégories 3, 4 et 5 étoiles.`,
    },
    31: {
        title: "Deux couvertures ou une couette par lit - couette obligatoire pour les catégories 3, 4 et 5 étoiles",
        aide: `Les plaids sont autorisés pour valider le critère dans les DROM-COM.`,
    },
    32: {
        title: "Matelas et oreillers protégés par des alaises ou des housses amovibles",
        aide: ``,
    },
    33: {
        title: "Éclairage en-tête de lit par personne avec interrupteur individuel",
        aide: ``,
    },
    34: {
        title: "Interrupteur ou système de commande de l'éclairage central près du lit",
        aide: ``,
    },
    35: {
        title: "Présence d'une prise de courant libre située près du lit",
        aide: ``,
    },
    36: {
        title: "Présence d'une table de chevet par personne",
        aide: ``,
    },
    37: {
        title: "Une salle d'eau privative dans un espace clos et aéré intérieur au logement",
        aide: ``,
    },
    38: {
        title: "Une salle d'eau privative avec accès indépendant dans un espace intérieur au logement",
        aide: ``,
    },
    39: {
        title: `Présence d'une salle d'eau ainsi équipée :
        - un lavabo avec eau chaude
        - une douche et / ou une baignoire (équipée d'une douchette) avec pare-douche; une baignoire et une douche`,
        aide: ``,
    },
    40: {
        title: `Présence d'une salle d'eau ainsi équipée :
        - un lavabo avec eau chaude
        - une douche (dimensions supérieures au standard) et / ou une baignoire (équipée d'une douchette) avec pare-douche (dimensions supérieures au standard) ; une baignoire et une douche`,
        aide: `Dimension douche standard = 80 cm x 80 cm.
        Dimensions baignoire standard = 170 cm x 75 cm.`,
    },
    41: {
        title: "Un WC (avec cuvette, abattant, chasse d'eau, dérouleur et poubelle) privatif intérieur au logement",
        aide: `Toilette sèche acceptée.`,
    },
    42: {
        title: "Un WC (avec cuvette, abattant, chasse d'eau, dérouleur et poubelle) privatif intérieur au logement indépendant de la salle d'eau",
        aide: `Toilette sèche acceptée.`,
    },
    43: {
        title: "Une deuxième salle d'eau privative dans un espace clos et aéré intérieur au logement avec accès indépendant",
        aide: `Pour les logements de moins de 7 personnes, ce critère est non applicable en 1, 2, 3, 4 étoiles. Pour les logements de moins de 5 personnes, ce critère est non applicable en 5 étoiles.
        Si toutes les chambres sont équipées de salles d'eau, alors le critère est validé.
        Le critère peut être validé si cette deuxième salle d'eau n'a pas d'accès indépendant; uniquement si la première salle d'eau (jusqu'à 6 personnes) a bien un accès indépendant.`,
    },
    44: {
        title: `Présence d'une salle d'eau ainsi équipée :
        - un lavabo avec eau chaude
        - une douche et / ou une baignoire (équipée d'une douchette) avec pare-douche; une baignoire et une douche`,
        aide: `Pour les logements de moins de 7 personnes, ce critère est non applicable en 1, 2, 3, 4 étoiles. Pour les logements de moins de 5 personnes, ce critère est non applicable en 5 étoiles.`,
    },
    45: {
        title: "Un WC (avec cuvette, abattant, chasse d'eau, dérouleur et poubelle) privatif intérieur au logement",
        aide: `Toilette sèche acceptée.
        Pour les logements de moins de 7 personnes, ce critère est non applicable en 1, 2, 3, 4 étoiles. Pour les logements de moins de 5 personnes, ce critère est non applicable en 5 étoiles.`,
    },
    46: {
        title: "Deux points lumineux dont un sur le lavabo",
        aide: ``,
    },
    47: {
        title: "Présence de produits d'accueil",
        aide: ``,
    },
    48: {
        title: "Une prise de courant libre à proximité du miroir",
        aide: ``,
    },
    49: {
        title: "Patère(s) ou porte-serviettes",
        aide: ``,
    },
    50: {
        title: "Sèche-serviettes électrique",
        aide: ``,
    },
    51: {
        title: "Miroir de salle de bain",
        aide: ``,
    },
    52: {
        title: "Miroir en pied",
        aide: ``,
    },
    53: {
        title: `Tablette sous miroir, plan vasque ou étagère proche du miroir`,
        aide: ``,
    },
    54: {
        title: `Espace(s) de rangement supplémentaire(s)`,
        aide: ``,
    },
    55: {
        title: `Sèche-cheveux électrique en nombre suffisant`,
        aide: `Un deuxième sèche-cheveux est exigé dans le logement à partir de 7 personnes.`,
    },
    56: {
        title: `Évier avec robinet mélangeur ou mitigeur`,
        aide: ``,
    },
    57: {
        title: `Nombre de foyers respectés`,
        aide: `Pour les exigences d'une plaque à 4 foyers, si le logement est équipé d'une plaque à induction ou vitrocéramique à 3 foyers, alors le critère est réputé acquis.`,
    },
    58: {
        title: `Plaque vitrocéramique, à induction ou à gaz`,
        aide: ``,
    },
    59: {
        title: `Four ou mini-four`,
        aide: ``,
    },
    60: {
        title: `Four à micro-ondes`,
        aide: ``,
    },
    61: {
        title: `Ventilation ou ventilation mécanique contrôlée`,
        aide: `Il est entendu par "ventilation" une aération naturelle dans la cuisine ou le coin cuisine.`,
    },
    62: {
        title: `Hotte aspirante`,
        aide: ``,
    },
    63: {
        title: `Quantité de vaisselle de table non dépareillée minimum par personne :
        - 2 verres à eau, 1 verre à vin, 2 assiettes plates, 2 assiettes creuses, 2 assiettes à dessert, 2 grandes cuillères, 2 petites cuillères, 2 couteaux, 2 fourchettes, 2 bols, 2 tasses ou mugs.`,
        aide: ``,
    },
    64: {
        title: `Vaisselle supplémentaire : 1 coupe à champagne, 1 verre à apéritif par personne`,
        aide: ``,
    },
    65: {
        title: `Équipement minimum pour la préparation des repas : 1 saladier, 1 plat allant au four, 2 casseroles, 1 poêle, 1 fait-tout, 1 tire-bouchon, 1 décapsuleur, 1 paire de ciseaux, 1 planche à découper, 1 couteau à pain, 1 passoire, 1 couvercle, 1 essoreuse à salade, 1 moule à tarte et/ou moule à gâteau, 1 ouvre- boîte, 1 économe, 1 dessous de plat, 1 verre doseur, 1 louche, 1 écumoir, 1 spatule, 1 fouet`,
        aide: ``,
    },
    66: {
        title: `Au moins deux équipements de petit-électroménager`,
        aide: ``,
    },
    67: {
        title: `Autocuiseur ou cuit-vapeur ou robot de cuisine multifonctions`,
        aide: ``,
    },
    68: {
        title: `Cafetière`,
        aide: ``,
    },
    69: {
        title: `Machine à expresso`,
        aide: ``,
    },
    70: {
        title: `Bouilloire`,
        aide: ``,
    },
    71: {
        title: `Grille-pain`,
        aide: ``,
    },
    72: {
        title: `Lave-vaisselle pour les logements à partir de 2 personnes`,
        aide: ``,
    },
    73: {
        title: `Lave-vaisselle de 6 couverts ou plus pour les logements à partir de 4 personnes`,
        aide: ``,
    },
    74: {
        title: `Réfrigérateur avec compartiment conservateur`,
        aide: `110 litres pour deux personnes, 10 litres en plus par occupant supplémentaire.`,
    },
    75: {
        title: `Présence d'un congélateur ou compartiment congélateur`,
        aide: ``,
    },
    76: {
        title: `Poubelle fermée avec couvercle`,
        aide: ``,
    },
    77: {
        title: `Pour accéder au 4e étage à partir du rez-de-chaussée`,
        aide: `Sauf contrainte locale ou architecturale.
        Si le logement est situé en rez-de-chaussée, alors lorsque le critère est obligatoire il devient non applicable. Si le logement est situé entre le 1er et 3e étage, alors lorsque le critère est obligatoire il devient optionnel.`,
    },
    78: {
        title: `Pour accéder au 3e étage à partir du rez-de-chaussée`,
        aide: `Sauf contrainte locale ou architecturale.
        Pour les catégories 1 et 2 étoiles, les points se cumulent avec ceux du critère précédent.
        Si le logement est situé en rez-de-chaussée, alors lorsque le critère est obligatoire il devient non applicable. 
        Si le logement est situé entre le 1er et 2e étage, alors lorsque le critère est obligatoire, il devient optionnel.`,
    },
    79: {
        title: `Emplacement(s) à proximité`,
        aide: `En cas de contrainte locale le critère est non applicable.`,
    },
    80: {
        title: `Emplacement(s) privatif(s)`,
        aide: `En cas de contrainte locale le critère est non applicable.`,
    },
    81: {
        title: `Garage ou abri couvert privatif`,
        aide: ``,
    },
    82: {
        title: `Logement avec balcon, loggia ou veranda (3m² minimum)`,
        aide: ``,
    },
    83: {
        title: `Logement avec terrasse ou jardin privé (8m² minimum)`,
        aide: `Cour intérieure aménagée tolérée.`,
    },
    84: {
        title: `Logement avec parc ou jardin (50m² minimum)`,
        aide: `200m² minimum quand il est commun à d'autres logements`,
    },
    85: {
        title: `Présence de mobilier de jardin privatif propre et en bon état`,
        aide: ``,
    },
    86: {
        title: `Mise à disposition d’une plancha extérieure et/ou d’un barbecue extérieur`,
        aide: ``,
    },
    87: {
        title: `Un équipement léger de loisirs, détente ou sport, dédié au logement`,
        aide: `Accès gratuit en propriété pleine ou copropriété.`,
    },
    88: {
        title: `Un équipement aménagé de loisirs, détente ou sport, dédié au logement`,
        aide: `Accès gratuit en propriété pleine ou copropriété.`,
    },
    89: {
        title: `Piscine extérieure ou intérieure`,
        aide: `Accès gratuit en propriété pleine ou copropriété.`,
    },
    90: {
        title: `Piscine extérieure ou intérieure chauffée`,
        aide: `Accès gratuit en propriété pleine ou copropriété.`,
    },
    91: {
        title: `Existence de rangement(s) pour équipement sportif`,
        aide: `Accès gratuit en propriété pleine ou copropriété. NA si localisation non adaptée.`,
    },
    92: {
        title: `Logement avec vue paysagère (vue mer, montagne, plaine ou zone urbaine)`,
        aide: ``,
    },
    93: {
        title: `Logement avec accès immédiat à un environnement offrant la possibilité de faire des activités : nature, culture et sport`,
        aide: `Accès situé à 1km maximum.`,
    },
    94: {
        title: `Logement avec accès immédiat aux commerces, services et transports en commun`,
        aide: `Accès situé à 1km maximum.`,
    },
    95: {
        title: `Les sanitaires (toilette(s) et salle(s) d'eau) sont propres et en bon état`,
        aide: `Le sol, le plafond, la paroi de douche (ou rideau de douche) et les murs sont propres et en bon état (absence de moisissures, saletés, traces de calcaire, cheveux…). Absence de carrelage cassé et de revêtement abimé.`,
    },
    96: {
        title: `Les sols murs et plafonds sont propres et en bon état`,
        aide: `Absence de saletés sur les murs, de peintures écaillées, de papier peint abîmé et déchiré, de carrelage cassé, de moquette tâchée, de traces de moisissures…`,
    },
    97: {
        title: `Le mobilier est propre et en bon état`,
        aide: `Absence de mobiliers cassés, déboités et sales.`,
    },
    98: {
        title: `La literie est propre et en bon état`,
        aide: `Ensemble constitué par matelas et sommier. Absence de tâches, de trous, de décoloration.`,
    },
    99: {
        title: `La cuisine ou coin cuisine et les équipements sont propres et en bon état`,
        aide: `Absence d'équipements cassés, déboités et sales. Absence de saletés sur les murs, de peintures écaillées, de carrelage cassé, de traces de moisissures…`,
    },
    100: {
        title: `Mise à disposition de brochures d'informations locales et touristiques en français et dans au moins une langue étrangère`,
        aide: ``,
    },
    101: {
        title: `Mise à disposition d'un livret d'accueil`,
        aide: ``,
    },
    102: {
        title: `Accueil sur place par le propriétaire ou son représentant`,
        aide: ``,
    },
    103: {
        title: `Cadeau de bienvenue à l'arrivée du client`,
        aide: ``,
    },
    104: {
        title: `Existence d'une boite à clé ou système équivalent`,
        aide: ``,
    },
    105: {
        title: `Draps de lit proposés systématiquement par le loueur`,
        aide: ``,
    },
    106: {
        title: `Linge de toilette proposé systématiquement par le loueur`,
        aide: ``,
    },
    107: {
        title: `Linge de table`,
        aide: ``,
    },
    108: {
        title: `Lits faits à l'arrivée proposés sytématiquement par le loueur`,
        aide: ``,
    },
    109: {
        title: `Matériel pour bébé à la demande`,
        aide: ``,
    },
    110: {
        title: `Service de ménage proposé systématiquement`,
        aide: ``,
    },
    111: {
        title: `Présence de produits d'entretien`,
        aide: ``,
    },
    112: {
        title: `Adaptateurs électriques`,
        aide: ``,
    },
    113: {
        title: `Existence d'un site internet ou d'une page internet dédiée au logement`,
        aide: `Ce champ est pré coché si vous avez renseigné un site Internet dans les coordonnées du meublé.`,
    },
    114: {
        title: `Existence d'un site internet ou d'une page internet dédiée au logement en une langue étrangère`,
        aide: `Ce champ est verrouillé si vous n'avez pas renseigné un site Internet dans les coordonnées du meublé.`,
    },
    115: {
        title: `Animaux de compagnie admis`,
        aide: ``,
    },
    116: {
        title: `Informations concernant l'accessibilité sur les supports d'information`,
        aide: ``,
    },
    117: {
        title: `Mise à disposition de télécommande de télévision à grosses touches et de couleurs contrastées`,
        aide: ``,
    },
    118: {
        title: `Présence d'un siège de douche avec barre d'appui`,
        aide: ``,
    },
    119: {
        title: `Présence de WC avec barre d'appui`,
        aide: ``,
    },
    120: {
        title: `Largeur de toutes les portes adaptées`,
        aide: `Minimum 0,77 mètre (passage utile).`,
    },
    121: {
        title: `Document accessible mis à disposition`,
        aide: `Si le meublé est labellisé Tourisme et Handicap, le critère est validé.`,
    },
    122: {
        title: `Obtention du label Tourisme et Handicap`,
        aide: ``,
    },
    123: {
        title: `Mise en œuvre d'une mesure de réduction de consommation d'énergie`,
        aide: ``,
    },
    124: {
        title: `Mise en œuvre d'une mesure de réduction de consommation d'énergie supplémentaire`,
        aide: `Les points se cumulent avec ceux du critère précédent.`,
    },
    125: {
        title: `Borne de recharge pour les véhicules électriques`,
        aide: ``,
    },
    126: {
        title: `Mise en œuvre d'une mesure de réduction de consommation d'eau`,
        aide: ``,
    },
    127: {
        title: `Mise en œuvre d'une mesure de réduction de consommation d'eau supplémentaire`,
        aide: `Les points se cumulent avec ceux du critère précédent.`,
    },
    128: {
        title: `Existence d'un système de tri des déchets dédié au logement`,
        aide: `Si l'immeuble ou la commune n'a pas mis en place un système de tri sélectif alors le critère est non applicable.`,
    },
    129: {
        title: `Existence d'un composteur`,
        aide: ``,
    },
    130: {
        title: `Sensibilisation des clients sur les actions qu'ils peuvent réaliser lors de leur séjour en matière de respect de l'environnement`,
        aide: ``,
    },
    131: {
        title: `Présence de produits d'accueil écologiques dans la salle de bains`,
        aide: ``,
    },
    132: {
        title: `Mise à disposition d'au moins deux produits d'entretien respectueux de l'environnement`,
        aide: ``,
    },
    133: {
        title: `Obtention d'un label environnemental`,
        aide: `Les labels autorisés pour valider le critère sont les labels recommandés par l'ADEME.`,
    }
};