import { useDB, useFetch, useUtiles } from '@hooks';
import { useVisitesDispatch } from '@context';
import { NEW_VISITE } from '@constantes';
import { GRILLE } from '@/grille-classement';
import { CLASS_CSV_HEADER } from '@constantes';

const useVisitesActions = () => {

    const dispatchVisites = useVisitesDispatch();
    const { db } = useDB();
    const { normalizeCivilite } = useUtiles();
    const { postEntities, actionEntities } = useFetch();

    /**
     * 
     * @param {string} meubleId 
     * @param {Object} technicien 
     * 
     * @returns {Object} Nouvelle visite
     */
    const getNewVisite = async (meubleId, user) => {

        // Rechercher les contacts liés à ce meublé
        const contactMeuble = await db.contacts.filter(contact => {
            const proprio = contact.meubles.find(meuble => meuble.id === meubleId && meuble.contact_type === "Propriétaire");
            if(!proprio) {
                return contact.meubles.find(meuble => meuble.id === meubleId && meuble.contact_type === "Mandataire");
            } else {
                return proprio;
            }
        }).toArray();

        // Formatage de la date pour base de données
        const now = Date.now();
        const dateIso = new Date().toLocaleString('sv-SE'); // Formatage de la date en suédois : "yyyy-mm-dd hh:mm:ss"

        // Récupération du meublé lié à cette visite
        const dbMeuble = await db.meubles.get(meubleId);
        
        // Copie du meublé dans les infos de la visite
        const nextInfos = {...NEW_VISITE.infos, meuble: dbMeuble ? dbMeuble : { id: meubleId, date_update: dateIso }};

        // Création de la visite
        const nextVisite = {...NEW_VISITE, infos: nextInfos};
        
        const newVisite = {
            ...nextVisite, 
            uid: user.id + '-' + now, 
            date_visite: dateIso, 
            date_create: dateIso, 
            date_update: dateIso, 
            meuble_id: meubleId, 
            user_create: user.id,
            user_create_name: user.display_name,
            user_update: user.id,
            user_update_name: user.display_name,
            grille: structuredClone(GRILLE), 
        };

        // Si un contact est disponible, l'ajouter comme demandeur de la visite par défaut.
        if(contactMeuble[0]) {
            newVisite.demandeur = contactMeuble[0];
            newVisite.infos.demandeur_id = contactMeuble[0].id;
        }

        // Enregistrement de la nouvelle visite en base de données locales
        await db.visites.put(newVisite);

        // Enregistrement de la nouvelle visite sur le serveur
        await postEntities(user.id, 'visite', [newVisite]);

        return newVisite;        
    }

    /**
     * 
     * @param {string} uid 
     * @returns {Object} visite
     */
    const getVisiteByUid = async (uid) => {
        return await db.visites.get(uid);
    }

    /**
     * 
     * @returns {Array} Le nombre de visites par année et par classement
     */
    const getVisitesCountParAnnee = (visites) => {

        if(!visites) return;
        
        // Trouver les années des visites
        const annees = visites.reduce((result, current) => {
            const annee = new Date(current.date_visite).getFullYear();
            if(result.indexOf(annee) === -1) result.push(annee);
            return result;
        }, []);

        // Tableau du nombre de visites par année et par classement
        return [0,1,2,3,4,5].reduce((result, classement) => {

            const score = { classement: classement }

            const visitesClassement = visites.filter(visite => visite.infos.classement_obtenu === classement);

            annees.map(annee => {
                const visitesAnneeClassement = visitesClassement.filter(visite => new Date(visite.date_visite).getFullYear() === annee);
                score[annee] = visitesAnneeClassement.length.toString();
            });

            result.push(score);
            return result;

        }, []);

    }

    /**
     * 
     * @param {string} uid 
     * @returns La réponse du serveur
     */
    const deleteVisite = async (uid, userId) => {

        const response = await actionEntities(userId, [uid], 'visite', 'simple', null, 'jeter');
        if(response.success){
            await db.visites.delete(uid);
            return {
                success: true,
                data: {
                    message: "Visite supprimée"
                }
            }
        }
        
        return response;
    }

    /**
     * 
     * @param {string} uid 
     * @param {string} texte 
     * @returns La liste des visites mise à jour via dispatchVisites
     */
    const updateComment = async (uid, texte) => {
        const visites = await db.visites.toArray();
        const nextVisite = visites.find(visite => visite.uid === uid);
        nextVisite.infos.comment = texte;
        db.visites.put(nextVisite);
        return dispatchVisites({ type: 'comment', value: [...visites] });
    }

    /**
     * 
     * @param {object} user l'utilisateur courant
     * @param {string} visiteUid l'uid de la visite à copier
     * @param {string} meubleId l'id du meuble à lier à cette nouvelle visite.
     * @returns La copie de la visite.
     */
    const duplicateVisite = async (user, visiteUid, meubleId) => {
        
        const original = await db.visites.get(visiteUid);

        // Rechercher les contacts liés à ce meublé
        const contactMeuble = await db.contacts.filter(contact => {
            const proprio = contact.meubles.find(meuble => meuble.id === meubleId && meuble.contact_type === "Propriétaire");
            if(!proprio) {
                return contact.meubles.find(meuble => meuble.id === meubleId && meuble.contact_type === "Mandataire");
            } else {
                return proprio;
            }
        }).toArray();

        const now = Date.now();
        const dateIso = new Date().toISOString();

        const copy = {
            ...original, 
            uid: user.id + '-' + now, 
            date_visite: dateIso, 
            date_create: dateIso, 
            date_update: dateIso, 
            date_decision: null,
            meuble_id: meubleId, 
            user_create: user.id, 
            user_update: user.id,
            scelle: false,
            demandeur: contactMeuble[0] ? contactMeuble[0] : null,
            infos: {...original.infos, meuble: null, saved: false, demandeur_id: contactMeuble[0] ? contactMeuble[0].id : null}
        };

        await db.visites.put(copy);
        
        return copy;
    }

    /**
     * 
     * @param {Array} ids uids des visites à exporter
     * @returns {Array} tableau des lignes du fichier csv
     */
    const getVisitesCSVData = async (ids) => {

        if(!ids.length) return [];
        
        const selectedVisites = await db.visites.bulkGet(ids);
        const visitesAvecNumClassement = selectedVisites.filter(visite => visite.infos.meuble && visite.infos?.meuble.num_classement && visite.infos?.meuble.num_classement !== "");
        const visitesAvecProprio = await visitesAvecNumClassement.reduce(async (memo, visite) => {
            
            const contactsVisite = await db.contacts.filter(contact => {
                return contact.meubles.find(meuble => meuble.id === visite.infos.meuble.id);
            }).toArray();

            const proprio = contactsVisite.find(contact => {
                return contact.meubles.find(meuble => meuble.id === visite.infos.meuble.id && meuble.contact_type === 'Propriétaire') !== undefined;
            });

            if(proprio) (await memo).push(visite);
            return (await memo);

        }, []);

        const getVisiteRow = async visite => {

            const meuble = visite.infos.meuble;
        
            visite.contacts = await db.contacts.filter(contact => {
                const meubleContact = contact.meubles.find(m => m.id === meuble.id);
                return meubleContact !== undefined;
            }).toArray();

            const proprio = visite.contacts.find(contact => {
                const meubleProprio = contact.meubles.find(m => m.id === meuble.id);
                return meubleProprio.contact_type === "Propriétaire"
            });

            const mandataire = visite.contacts.find(contact => {
                const meubleMandataire = contact.meubles.find(m => m.id === meuble.id);
                return meubleMandataire.contact_type === "Mandataire"
            });

            const row = [
                normalizeCivilite(proprio?.civilite) || '', 
                proprio?.nom || '', 
                proprio?.prenom || '', 
                proprio?.raison_sociale || '', 
                proprio?.adresse1 || '', 
                proprio?.adresse2 || '', 
                proprio?.adresse3 || '', 
                proprio?.code_postal || '', 
                proprio?.commune || '', 
                proprio?.pays || '', 
                proprio?.telephone || proprio?.telephone2 || '', 
                proprio?.email || '', 
                proprio?.fax || '', 
                '', // proprio?.site_web || '', 
                mandataire?.civilite || '', 
                mandataire?.nom || '', 
                mandataire?.prenom || '', 
                mandataire?.raison_sociale || '', 
                mandataire?.adresse1 || '', 
                mandataire?.adresse2 || '', 
                mandataire?.adresse3 || '', 
                mandataire?.code_postal || '', 
                mandataire?.commune || '',  
                mandataire?.pays || '', 
                mandataire?.telephone || '', 
                mandataire?.email || '', 
                mandataire?.fax || '', 
                '', // mandataire?.site_web || '', 
                meuble?.titre || '', 
                meuble?.adresse1 || '', 
                meuble?.adresse2 || '', 
                meuble?.code_postal || '', 
                meuble?.code_insee || '', 
                meuble?.commune || '', 
                visite.infos.classement_obtenu.toString(), 
                visite.infos.capacite || '', 
                new Date(visite.date_visite).toLocaleDateString('fr-FR'),
                meuble?.num_classement || '', 
                meuble?.num_classement || ''
            ];

            return Promise.resolve(row);
        }

        const csvRows = async () => {
            return Promise.all(
                visitesAvecProprio.map(async visite => {
                    return await getVisiteRow(visite);
                })
            );
        }

        const rows = await csvRows();

        const csvData = [
            CLASS_CSV_HEADER,
            ...rows
        ]
        
        return csvData;
    }

    return {
        getVisiteByUid, 
        getNewVisite, 
        getVisitesCountParAnnee, 
        deleteVisite, 
        updateComment, 
        duplicateVisite, 
        getVisitesCSVData, 
    }
}

export default useVisitesActions;