import { useState } from '@wordpress/element';
import { ButtonCollapse } from "@composants/button";
import './section.scss';

export const Section = ({ id, title, children }) => {

    const [closed, setClosed] = useState(false);

    const toggleSection = () => {
        setClosed(!closed);
    }

    return(
        <div className={`section${closed ? ' collapsed' : ''}`}>
            <div className='section-header px-3 py-2'>
                <div className='d-flex justify-content-between'>
                    <h4 className='h4 section-title d-flex gap-3 mb-0'><strong>{id}</strong><span>{title}</span></h4>
                    <ButtonCollapse statut={closed ? 'collapsed' : ''} handler={toggleSection} />
                </div>
            </div>
            <div className="section-body">
                <div className='px-3 py-2 d-flex flex-column section-children'>
                    {children}
                </div>
            </div>
        </div>
    )
}