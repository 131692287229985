import { useState } from '@wordpress/element';
import { Modale } from '@composants';
import { useVisitesActions } from '@hooks';

const ModaleCommentaire = ({ show, setShow, comment='', uid, edit }) => {

    const { updateComment } = useVisitesActions();

    const [commentaire, setCommentaire] = useState(comment);

    const onComment = (event) => {
        setCommentaire(event.target.value);
    }

    const onHide = (event) => {
        if(event && event.target.name === 'save'){
            updateComment(uid, commentaire);
            setTimeout(() => {
                setShow(false);
            }, 300)
        } else {
            setCommentaire(comment);
            setShow(false);
        }
    }

    return(
        <Modale show={show} hideHandler={onHide} title="Saisie d'un commentaire" size="lg">
            {edit ? 
                <textarea className='form-control' rows="10" value={commentaire} onChange={onComment} /> :
                <div className='p-3'>{commentaire ? 
                    <span dangerouslySetInnerHTML={{__html: commentaire.replace(/\n/g, "<br />")}} /> : 
                    <span className='text-center'>Aucun commentaire pour cette visite.</span>
                }</div>
            }
        </Modale>
    );
}

export default ModaleCommentaire;